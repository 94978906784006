import Lib from '../../../commons/lib';

export default class Service {
  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  static penztar = window.location.origin + '/api/penzugykozos/bankszamlapenztar/';

  // FIZMOD START

  static listFizetesimod(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod/list',
      args: { requestType: 'fizmod-list' }
    }], fnDone);
  }

  static addFizetesimod(fizmodObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.penzugyUrl + 'fizmod',
      args: { requestType: 'fizmod-post' },
      data: fizmodObject
    }], fnDone);
  }

  static editFizetesimod(fizmodObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.penzugyUrl + 'fizmod',
      args: { requestType: 'fizmod-put' },
      data: fizmodObject,
      params: { id: id }
    }], fnDone);
  }

  static getFizetesimod(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod',
      args: { requestType: 'fizmod-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteFizetesimod(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.penzugyUrl + 'fizmod',
      args: { requestType: 'fizmod-delete' },
      params: { id: id }
    }], fnDone);
  }

  // FIZMOD END

  // PÉNZNEM START

  static listPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/list',
      args: { requestType: 'penznem-list' }
    }], fnDone);
  }

  static addPenznem(penznemObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.penzugyUrl + 'penznem',
      args: { requestType: 'penznem-post' },
      data: penznemObject
    }], fnDone);
  }

  static editPenznem(penznemObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.penzugyUrl + 'penznem',
      args: { requestType: 'penznem-put' },
      data: penznemObject,
      params: { id: id }
    }], fnDone);
  }

  static getPenznem(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem',
      args: { requestType: 'penznem-get' },
      params: { id: id }
    }], fnDone);
  }

  static deletePenznem(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.penzugyUrl + 'penznem',
      args: { requestType: 'penznem-delete' },
      params: { id: id }
    }], fnDone);
  }

  // PÉNZNEM END

  // INFLÁCIÓ START

  static listInflacio(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'inflacio/list',
      args: { requestType: 'inflacio-list' }
    }], fnDone);
  }

  static listInflacioEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'inflacio/enum',
      args: { requestType: 'inflacio-enum' }
    }], fnDone);
  }

  static addInflacio(inflacioObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.penzugyUrl + 'inflacio',
      args: { requestType: 'inflacio-post' },
      data: inflacioObject
    }], fnDone);
  }

  static editInflacio(inflacioObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.penzugyUrl + 'inflacio',
      args: { requestType: 'inflacio-put' },
      data: inflacioObject,
      params: { id: id }
    }], fnDone);
  }

  static getInflacio(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'inflacio',
      args: { requestType: 'inflacio-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteInflacio(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.penzugyUrl + 'inflacio',
      args: { requestType: 'inflacio-delete' },
      params: { id: id }
    }], fnDone);
  }

  // INFLÁCIÓ END

  // BANKTÍPUS START

  static listBankttipus(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'banktipus/list',
      args: { requestType: 'banktipus-list' }
    }], fnDone);
  }

  static addBanktipus(banktipusObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.penzugyUrl + 'banktipus',
      args: { requestType: 'banktipus-post' },
      data: banktipusObject
    }], fnDone);
  }

  static editBanktipus(banktipusObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.penzugyUrl + 'banktipus',
      args: { requestType: 'banktipus-put' },
      data: banktipusObject,
      params: { id: id }
    }], fnDone);
  }

  static getBanktipus(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'banktipus',
      args: { requestType: 'banktipus-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteBanktipus(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.penzugyUrl + 'banktipus',
      args: { requestType: 'banktipus-delete' },
      params: { id: id }
    }], fnDone);
  }

  // BANKTÍPUS END

  // BANKSZÁMLA START

  static listBankszamla(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'bankszamlapenztar/list',
      args: { requestType: 'bankszamlapenztar-list' },
      params: { tipus: 'BANK' }
    }], fnDone);
  }

  static addBankszamla(bankszamlaObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.penzugyUrl + 'bankszamlapenztar',
      args: { requestType: 'bankszamlapenztar-post' },
      data: bankszamlaObject,
      params: { tipus: 'BANK' }
    }], fnDone);
  }

  static deleteBankszamla(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.penzugyUrl + 'bankszamlapenztar',
      args: { requestType: 'bankszamlapenztar-delete' },
      params: { id: id }
    }], fnDone);
  }

  static editBankszamla(bankszamlaObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.penzugyUrl + 'bankszamlapenztar',
      args: { requestType: 'bankszamlapenztar-put' },
      data: bankszamlaObject,
      params: { id: id }
    }], fnDone);
  }

  static getBankszamla(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'bankszamlapenztar',
      args: { requestType: 'bankszamlapenztar-get' },
      params: { id: id }
    }], fnDone);
  }

  static listAktivPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-aktiv' }
    }], fnDone);
  }

  static listAktivCegadat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      params: {
        aktiv: true,
        resultclass: '30'
      },
      data: [id]
    }], fnDone);
  }

  static existsNew(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat/existsnew',
      args: { requestType: 'cegadat-existsnew' },
      params: {
        id: id,
        datum: null
      }
    }], fnDone);
  }

  // BANKSZÁMLA END

  // PÉNZTÁR START

  static listHaziPenztar(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penztar + 'list',
      args: { requestType: 'hazi-penztar-list' },
      params: { tipus: 'HAZI' },
    }], fnDone);
  }

  static getHaziPenztar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penztar,
      args: { requestType: 'hazi-penztar-get' },
      params: { id: id },
    }], fnDone);
  }

  static addHaziPenztar(hazipenztarobj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.penztar,
      args: { requestType: 'hazi-penztar-post' },
      data: hazipenztarobj,
    //  params: { tipus: 'HAZI' }
    }], fnDone);
  }

  static editHaziPenztar(hazipenztarobj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.penztar,
      args: { requestType: 'hazi-penztar-put' },
      data: hazipenztarobj,
    }], fnDone);
  }

  static deleteHaziPenztar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.penztar,
      args: { requestType: 'hazi-penztar-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listHaziPenztarPenztar(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penztar + 'listhazipenztarpenztar',
      args: { requestType: 'hazi-penztar-penztar-list' },
      params: { tipus: 'HAZI' },
    }], fnDone);
  }
}
