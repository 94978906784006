import React, { Component } from 'react';
import YouTube from '../../../commons/youtube';
import RaktarKeszletContent from './RaktarKeszletContent';

const videoData = [
  { text: 'Raktárkészlet lista', id: 'KAxQV77nCGQ' }
];

class RaktarkeszletBase extends Component {
  render() {
    let cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            Raktárkészlet lista
            <YouTube data={videoData} />
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <RaktarKeszletContent />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RaktarkeszletBase;
