import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadio, AvRadioGroup
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select/lib/Async';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import Services from './services';
import Mezo from './Mezo';
import { DatePicker, PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import QueryEmptyState from '../../../commons/queryempty';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import SwichButton from '../../../commons/SwitchButton';
import Loader from '../../../commons/loader';
import NumberFormatter from '../../../commons/NumberFormatter';
import SlaveAvInput from '../../../commons/SlaveAvInput';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import getTooltip from './CikkTooltips';
import Lib from '../../../commons/lib';

class CikktorzsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL', 'QUERY']
      tableStatus: 'QUERY',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'DATA',


      // FORM VALUES
      formTipus: '',
      formReceptura: false,
      formCikkcsoport: '',
      formCikkszam: '',
      formMegnevezes: '',
      formMennyiseg: '',
      formAfa: '',
      formAfaTable: [],
      formAfaKezdete: '',
      formWebshop: false,
      formRaktaronKivuli: false,
      formAktiv: false,
      formChilds: [],
      formMetadatas: {},

      // MODALS
      modalForm: false,
      modalView: false,
      modalDelete: false,
      modalMetadata: false,
      modalMenniyseg: false,
      modalAfaDelete: false,
      modalChildDelete: false,


      // UTILS
      modositas: false,
      hidden: false,
      expanded: [],
      currentId: null,
      deleteId: null,
      baseJson: [],
      viewObj: null,
      saveButtonLoading: false,

    };
  }

  // componentDidMount() {
  //   this.getData();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getData();
      }
    }
  }

  getData = () => {
    this.setState({
      tableStatus: 'LOAD'
    }, () => {
      Promise.all([
        this.listCikk(),
        this.getCikkProfil(),
      ])
        .then((prevState) => {
          this.setState({
            tableStatus: this.state.baseJson.length > 0 ? 'TABL' : 'DATA',
          });
        })
        .catch(() => {
          this.setState({
            tableStatus: 'NETW'
          });
        });
    });
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  getCikk = (id) => {
    return new Promise((resolve, reject) => {
      Services.getCikk(id, (err, res) => {
        if (!err) {
          this.setState({
            formCikkcsoport: this.nullToString(res[0].cikkcsoport.id),
            formMennyiseg: this.nullToString(res[0].mennyiseg.id),
            formAfa: this.nullToString(res[0].aktualisAfaId),
            formReceptura: res[0].childs.length != 0,
            formTipus: this.nullToString(res[0].tipus),
            formCikkszam: this.nullToString(res[0].cikkszam),
            formMegnevezes: this.nullToString(res[0].megnevezes),
            formAfaKezdete: res[0].aktualisAfaKezdete,
            formWebshop: res[0].webshop,
            formRaktaronKivuli: res[0].raktaronKivuli,
            formAktiv: res[0].aktiv,
            formChilds: res[0].childs,
            formMetadatas: res[0].metaadatok,
            formAfaTable: res[0].afak,
          }, () => {
            resolve();
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  handleSubmit = () => {
    const {
      formTipus,
      formReceptura,
      formCikkcsoport,
      formCikkszam,
      formMegnevezes,
      formMennyiseg,
      formAfa,
      formAfaKezdete,
      formWebshop,
      formRaktaronKivuli,
      formAktiv,
      formChilds,
      formMetadatas,
      modositas,
      currentId
    } = this.state;
    let submitObj = {};

    this.setState({ submitButtonLoading: true }, () => {
    /* formChilds.forEach( child => {
      child.parent = {id : currentId}
    }); */

      formChilds.forEach((child) => {
        child.mennyiseg = NumberFormatter.removeSpacesFromString(child.mennyiseg);
      });
      submitObj.afaid = this.emptyOrNull(formAfa);
      submitObj.csoportid = this.emptyOrNull(formCikkcsoport);
      submitObj.mennyisegid = this.emptyOrNull(formMennyiseg);
      submitObj.tipus = this.emptyOrNull(formTipus);
      submitObj.receptura = this.emptyOrNull(formReceptura);
      submitObj.cikkszam = this.emptyOrNull(formCikkszam);
      submitObj.megnevezes = this.emptyOrNull(formMegnevezes);
      submitObj.kezdete = this.emptyOrNull(formAfaKezdete);
      submitObj.metaadatok = this.emptyOrNull(formMetadatas);
      submitObj.aktiv = formAktiv;
      submitObj.raktaronKivuli = formRaktaronKivuli;
      submitObj.webshop = formWebshop;
      submitObj.childs = this.emptyOrNull(formChilds);

      if (modositas) {
        Services.editCikk(submitObj, currentId, (err, res) => {
          if (!err) {
            this.toggleForm();
            this.getData();
            this.props.globals.addSuccess('Cikk módosítva!');
          }
        });
      } else {
        Services.addCikk(submitObj, (err, res) => {
          if (!err) {
            this.toggleForm();
            this.getData();
            this.props.globals.addSuccess('Cikk felvéve!');
          }
        });
      }
    });
  }

  listCikk = () => {
    return new Promise((resolve, reject) => {
      Services.listCikk((err, res) => {
        if (!err) {
          this.setState({
            baseJson: res[0],
            expanded: []
          }, () => {
            resolve();
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  getCikkProfil = () => {
    return new Promise((resolve, reject) => {
      Services.getCikkProfil((err, res) => {
        if (!err) {
          this.setState({
            hidden: res[0] == 'false',
          }, () => {
            resolve();
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  renderCikkcsoportOptions = () => {
    return new Promise((resolve, reject) => {
      Services.listLeavesCikkcsoport((err, res) => {
        if (!err) {
          this.setState({
            cikkcsoportOptions: res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>)
          }, () => {
            resolve();
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  renderMennyisegOptions = () => {
    return new Promise((resolve, reject) => {
      Services.listMennyiseg((err, res) => {
        if (!err) {
          this.setState({
            mennyisegOptions: res[0].map(e => <option key={e.id} value={e.id}>{e.megnevezes}</option>)
          }, () => {
            resolve();
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  renderAfaOptions = () => {
    return new Promise((resolve, reject) => {
      Services.listAfa((err, res) => {
        if (!err) {
          this.setState({
            afaOptions: res[0].map(e => (
              <option key={e.id} value={e.id} disabled={!e.aktiv}>
                {e.megnevezes + ' -- ( ' + e.ertek + '% ) ' + (e.aktiv ? 'Aktív' : 'Inaktív')}
                {' '}
              </option>
            ))
          }, () => {
            resolve();
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  loadOptionsCikkChildsMegnevezes = (text, callback) => {
    this.loadOptionsCikkChilds(text, callback, { megnevezes: text });
  }

  loadOptionsCikkChildsCikkszam = (text, callback) => {
    this.loadOptionsCikkChilds(text, callback, { cikkszam: text, id: this.state.currentId });
  }

  loadOptionsCikkChilds = (text, callback, param) => {
    const newParam = Object.keys(param).reduce((object, key) => {
      if (param[key]) {
        object[key] = param[key];
      }
      return object;
    }, {});

    let newArray = [];
    if (text.length === 0) {
      callback(newArray);
    } else {
      Services.listCikkToChilds(newParam, (err, res) => {
        if (!err) {
          res[0].forEach((res) => {
            newArray.push(res);
          });
          callback(newArray);
        }
      });
    }
  }

  newCikkForm = () => {
    this.setState({
      formTipus: '',
      formReceptura: false,
      formCikkcsoport: '',
      formCikkszam: '',
      formMegnevezes: '',
      formMennyiseg: '',
      formAfa: '',
      formAfaKezdete: '',
      formWebshop: false,
      formRaktaronKivuli: false,
      formAktiv: false,
      formChilds: [],
      formMetadatas: {},
      modositas: false,
      currentId: null,
    }, () => {
      this.toggleForm();
    });
  }

  toggleForm = () => {
    this.setState({ formStatus: 'LOAD' }, () => {
      if (!this.state.modalForm) {
        Promise.all([
          this.renderAfaOptions(),
          this.renderCikkcsoportOptions(),
          this.renderMennyisegOptions()
        ])
          .then((prevState) => {
            this.setState({
              modalForm: !prevState.modalForm,
              formStatus: 'DATA'
            });
          })
          .catch(() => {
            this.setState({
              formStatus: 'NETW'
            });
          });
      } else {
        this.setState({
          modalForm: !this.state.modalForm,
          currentId: null,
          modositas: false
        }, () => {
          this.getData();
        });
      }
    });
  }

  toggleChildDelete = (idx) => {
    this._currentDeleteChildId = idx;
    this.setState({
      modalChildTorles: !this.state.modalChildTorles
    });
  }

  toggleAfaDelete = () => {
    this.setState({
      modalAfaTorles: !this.state.modalAfaTorles
    });
  }

  toggleView = () => {
    this.setState({
      modalView: !this.state.modalView
    });
  }

  toggleDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete
    });
  }

  toggleChildDelete = (idx) => {
    this._currentDeleteChildId = idx;
    this.setState({
      modalChildDelete: !this.state.modalChildDelete
    });
  }

  toggleMetadata = () => {
    this.setState({
      modalMetadata: !this.state.modalMetadata
    });
  }

  toggleMennyiseg = () => {
    this.setState({
      modalMennyiseg: !this.state.modalMennyiseg
    });
  }

  handleEditClick = (id) => {
    this.setState({ modositas: true, currentId: id }, () => {
      this.toggleForm();
      this.getCikk(id);
    });
  }

  handleChildsClick = (id) => {
    if (!this.state.expanded.includes(id)) {
      this.setState(() => ({
        expanded: [...this.state.expanded, id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== id)
      }));
    }
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleDelete();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.toggleView();
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  checkDate = (date) => {
    return date ? new Date(date) : null;
  }

  updateMetadata = (event, mezonev) => {
    this.state.formMetadatas[mezonev] = event.target.value;
  }

  handleChangeCikkChild = (v, idx) => {
    let formChildsArray = [...this.state.formChilds];
    if (v) {
      let cikkFound = 0;
      formChildsArray.splice(idx, 1, { child: v });
      for (let i = 0; i < formChildsArray.length; i++) {
        const cikk = formChildsArray[i];
        if (cikk.child.id === v.id) {
          cikkFound++;
          if (cikkFound > 1)
            break;
        }
      }
      if (cikkFound < 2) {
        this.setState({ formChilds: formChildsArray });
      } else {
        this.props.globals.addError('A kiválasztott cikk már szerepel a receptúrában!');
      }
    }
  }

  newChild = () => {
    this.setState(prevState => ({ formChilds: [...prevState.formChilds, {}] }));
  }

  toggleChildTorles = (idx) => {
    this._currentDeleteChildId = idx;
    this.setState({
      modalChildTorles: !this.state.modalChildTorles
    });
  }

  deleteChild = (idx) => {
    let { formChilds } = this.state;
    formChilds.splice(idx, 1);
    this.setState(prevState => ({ formChilds }), () => {
      this.toggleChildTorles();
    });
  }

  deleteCikktorzs = (id) => {
    Services.deleteCikk(id, (err, res) => {
      if (!err) {
        this.toggleDelete();
        this.getData();
        this.props.globals.addSuccess('Cikk törölve!');
      }
    });
  }

  deleteMennyiseg = () => {
    Services.deleteMennyiseg(this.state.formMennyiseg, (err, res) => {
      if (!err) {
        this.renderMennyisegOptions();
        this.props.globals.addSuccess('Mennyiség törölve!');
      }
    });
  }

  addMetadata = (event, mezoObj) => {
    let ujObj = {};
    ujObj[mezoObj.mezonev] = mezoObj.tartalom;
    this.setState({ formMetadatas: Object.assign(this.state.formMetadatas, ujObj) }, () => this.toggleMetadata());
  }

  addMennyiseg = (event, mennyisegObj) => {
    Services.addMennyiseg(mennyisegObj, (err) => {
      if (!err) {
        this.toggleMennyiseg();
        this.renderMennyisegOptions();
        this.props.globals.addSuccess('Mennyiségi egység hozzáadva!');
      }
    });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_TORZS', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + '-view'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + '-view'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}

        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_TORZS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + '-edit'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + '-edit'} delay={0}>
              Módosítás
            </UncontrolledTooltip>

            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + '-delete'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + '-delete'} delay={0}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}

        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_TORZS', 'LEK') && row.childs.length !== 0 && (
          <React.Fragment>
            <Button className="fa fa-cubes table-action-icon" id={'action-view-' + cell + '-childs'} onClick={() => this.handleChildsClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + '-childs'} delay={50}>
              {'Receptúra részei'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  iconChildFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_TORZS', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + '-view-child'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + '-view-child'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  statusFormatter = (cell) => {
    return (
      cell
        ? <span className="badge badge-success">Aktív</span>
        : <span className="badge badge-default">Inaktív</span>
    );
  }

  typeFormatter = (cell) => {
    return cell === 'T' ? 'Termék' : 'Szolgáltatás';
  }

  renderFormTitle = () => {
    return this.state.modositas ? 'Cikk módosítása' : 'Új cikk rögzítése';
  }

  renderMetadatas = () => {
    let mezok = [];
    let idx = 0;
    for (let mezonev in this.state.formMetadatas) {
      mezok.push(<Mezo mezonev={mezonev} tartalom={this.state.formMetadatas[mezonev]} updateMeta={e => this.updateMetadata(e, mezonev)} key={idx} />);
      idx++;
    }
    return mezok;
  }

  renderChilds = () => {
    let childs = [];
    this.state.formChilds.forEach((item, idx) => {
      childs.push(
        <span
          className="row"
          style={{
            marginLeft: 10, marginRight: 10
          }}
        >
          <div className="col-md-3" style={{ paddingTop: 7 }}>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name={'cikkszam-' + idx}
              id={'cikkszam-' + idx}
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeCikkChild(v, idx)}
              isClearable={false}
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadOptionsCikkChildsCikkszam, 1000)}
              isLoading={false}
              getOptionLabel={option => option.cikkszam}
              getOptionValue={option => option.id}
              value={this.state.formChilds[idx].child || ''}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3" style={{ paddingTop: 7 }}>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' },
                  border: '1px solid lightgray',
                  boxShadow: 'none',
                  borderRadius: '0'
                }),
              }}
              name={'megnevezes-' + idx}
              id={'megnevezes-' + idx}
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeCikkChild(v, idx)}
              isClearable={false}
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadOptionsCikkChildsMegnevezes, 1000)}
              isLoading={false}
              getOptionLabel={option => option.megnevezes}
              getOptionValue={option => option.id}
              value={this.state.formChilds[idx].child || ''}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-2">
            <SlaveAvInput
              id={'aktualisAfaNev' + idx}
              style={{ marginBottom: 0 }}
              value={item.child && item.child.aktualisAfaNev}
              disabled
              required
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
          <div className="col-md-2">
            <SlaveAvInput
              id={'mennyiseg' + idx}
              style={{ marginBottom: 0 }}
              value={item.mennyiseg}
              onChange={(e, v) => {
                const newChilds = this.state.formChilds;
                this.state.formChilds[idx].mennyiseg = v;
                this.setState({ formChilds: newChilds });
              }}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
          <div className="col-md-1">
            <Button color="danger" style={{ marginTop: '8px' }} type="button" onClick={() => this.toggleChildTorles(idx)}>
              <i className="fa fa-trash" />
            </Button>
          </div>
        </span>
      );
    });
    return childs;
  }

  renderForm = () => {
    const {
      formTipus,
      formReceptura,
      formCikkcsoport,
      formCikkszam,
      formMegnevezes,
      formMennyiseg,
      formAfa,
      formAfaKezdete,
      formWebshop,
      formRaktaronKivuli,
      formAktiv,
      formChilds,
      formMetadatas,
      cikkcsoportOptions,
      afaOptions,
      mennyisegOptions,
      hidden,
      modositas,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-8">
            <AvRadioGroup
              name="formTipus"
              label="Cikk típusa"
              errorMessage="Kötelező mező!"
              value={formTipus}
              onChange={(e, v) => this.setState({ formTipus: v })}
              required
            >
              <AvRadio label="Termék" value="T" />
              <AvRadio label="Szolgáltatás" value="SZ" />
            </AvRadioGroup>
          </div>
          <div className="col-md-4 row" style={{ marginTop: 20 }}>
            <SwichButton
              active={formReceptura}
              trueLabel="Összetett"
              trueToggle={() => this.setState({ formReceptura: true })}
              trueWidth={100}
              falseToggle={() => this.setState({ formReceptura: false, formChilds: [] })}
              falseLabel="Egyszerű"
              falseWidth={100}
              style={{ marginTop: 29 }}
            />
            <div style={{ marginTop: 32 }}>
              <CustomTooltip text={getTooltip('cikktipus')} id="cikktipusTT" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvField type="select" name="formCikkcsoport" label="Cikkcsoport: *" errorMessage="Kötelező mező!" value={formCikkcsoport} onChange={this.handleInputChange} required>
              <option value="" disabled>Válassz cikkcsoportot...</option>
              {cikkcsoportOptions}
            </AvField>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formCikkszam">
                Cikkszám: *
                <CustomTooltip text={getTooltip('cikkszam')} id="cikkszamTT" />
              </Label>
              <AvInput name="formCikkszam" id="formCikkszam" value={formCikkszam} onChange={this.handleInputChange} disabled={modositas} required />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formMegnevezes">Megnevezés: *</Label>
              <AvInput name="formMegnevezes" id="formMegnevezes" value={formMegnevezes} onChange={this.handleInputChange} required />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Label>
              Mennyiségi egység: *
              <CustomTooltip text={getTooltip('mennyisegiegyseg')} id="mennyisegiegysegTT" />
            </Label>
            <AvField type="select" name="formMennyiseg" errorMessage="Kötelező mező!" value={formMennyiseg} onChange={this.handleInputChange} required>
              <option value="" disabled>Válassz mennyiséget...</option>
              {mennyisegOptions}
            </AvField>
          </div>
          <div className="col-md-4">
            <Button type="button" id="MennyisegiEgysegModositas" className="btn-cursor-pointer" color="success" onClick={this.toggleMennyiseg} style={{ marginTop: '28px' }}>
              Új mennyiségi egység
            </Button>
            <UncontrolledTooltip placement="top" target="MennyisegiEgysegModositas"> Mennyiségi egység hozzáadása a már meglévőkhöz. </UncontrolledTooltip>

            { formMennyiseg != ''
              && (
              <React.Fragment>
                <Button type="button" id="MennyisegiEgysegDelModositas" className="btn-cursor-pointer" color="danger" onClick={this.deleteMennyiseg} style={{ marginTop: '28px' }}>
                  <i className="fa fa-trash" aria-hidden="true" />
                </Button>
                <UncontrolledTooltip placement="top" target="MennyisegiEgysegDelModositas"> Mennyiségi egység törlése. </UncontrolledTooltip>
              </React.Fragment>
              )
            }
          </div>
          <div className="col-md-1" />
        </div>
        <div className="row" style={{ paddingTop: 15 }}>
          <div className="col-md-4">
            <AvField
              type="select"
              name="formAfa"
              label="ÁFA: *"
              errorMessage="Kötelező mező!"
              value={formAfa}
              onChange={this.handleInputChange}
              required
            >
              <option value="" disabled>Válassz áfát...</option>
              {afaOptions}
            </AvField>
          </div>
          <div className="col-md-4">
            <Label for="kezdete">Kezdő dátum: *</Label>
            <DatePicker
              name="formAfaKezdete"
              value={this.checkDate(formAfaKezdete)}
                // defaultValue={new Date()}
                // onChange={this.handleInputChange}
              onChange={(e, v) => this.setState({ formAfaKezdete: v })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingLeft: 0 }}>
            <AvGroup check>
              <Label check style={{ marginBottom: 5 }}>
                <AvInput
                  type="checkbox"
                  name="formWebshop"
                  value={formWebshop}
                  onChange={this.handleInputChange}
                />
                  Webshop?
              </Label>
            </AvGroup>
          </div>
          <div hidden={hidden} className="col-md-12" style={{ paddingLeft: 0 }}>
            <AvGroup check>
              <Label check style={{ marginBottom: 5 }}>
                <AvInput
                  type="checkbox"
                  name="formRaktaronKivuli"
                  value={formRaktaronKivuli}
                  onChange={this.handleInputChange}
                />
                    Raktáron kívüli
              </Label>
            </AvGroup>
          </div>
          <div className="col-md-12" style={{ paddingLeft: 0 }}>
            <AvGroup check>
              <Label check style={{ marginBottom: 5 }}>
                <AvInput
                  type="checkbox"
                  name="formAktiv"
                  value={formAktiv}
                  onChange={this.handleInputChange}
                />
                  Aktív
              </Label>
            </AvGroup>
          </div>
        </div>
        {this.renderMetadatas()}
        <Button type="button" className="btn-cursor-pointer" color="success" onClick={this.toggleMetadata}>Új mező</Button>
        {modositas
          && (
          <div className="row">
            <div className="col-md-12 amnezis-btn">
              <h6>Áfa kapcsolatok</h6>
            </div>
            <div className="col-md-12 ">
              {this.renderAfaTabla()}
            </div>
          </div>
          )
        }
        {formReceptura
          && (
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <span>
              <h5>Receptúra részei: </h5>
            </span>
            {this.state.formChilds && this.state.formChilds.length > 0 && (
              <div
                className="border rounded"
                style={{
                  marginLeft: 10, marginRight: 10, paddingBottom: 10
                }}
              >
                <div className="row" style={{ paddingLeft: 25, paddingRight: 60, marginTop: 5 }}>
                  <div className="col-md-3" style={{ marginRight: 10 }}>
                    <Label style={{ marginBottom: 0 }}>Cikkszám:</Label>
                  </div>
                  <div className="col-md-3" style={{ marginRight: 10 }}>
                    <Label style={{ marginBottom: 0 }}>Megnevezés:</Label>
                  </div>
                  <div className="col-md-2" style={{ marginRight: 3 }}>
                    <Label style={{ marginBottom: 0 }}>Áfa:</Label>
                  </div>
                  <div className="col-md-2">
                    <Label style={{ marginBottom: 0 }}>Mennyiség:</Label>
                  </div>
                </div>
                <>
                  {this.renderChilds()}
                </>
              </div>
            )}
            <Button type="button" className="btn-cursor-pointer" style={{ marginTop: 10 }} color="success" onClick={this.newChild}>
                Hozzáadás
            </Button>
          </div>
          )
        }
      </React.Fragment>
    );
  }

  renderView = (id) => {
    let cikk = this.state.baseJson.find(cikk => cikk.id === id);
    let mezoObj = [];
    let idx = 0;
    for (let key in cikk.metaadatok) {
      mezoObj.push(<div key={idx}>
        <b>
          {key}
:
          {' '}
        </b>
        {cikk.metaadatok[key]}
      </div>);
      idx++;
    }
    return (
      <div>
        <h6>Cikktörzs információ:</h6>
        <b>Megnevezés: </b>
        {cikk.megnevezes}
        <br />
        <b>Cikkcsoport neve: </b>
        {cikk.cikkcsoport.nev}
        <br />
        <b>Cikkszám: </b>
        {cikk.cikkszam}
        <br />
        <b>Típus: </b>
        {cikk.tipus === 'T' ? 'Termék' : 'Szolgáltatás'}
        <br />
        <b>Cikk típusa(Receptúra): </b>
        {cikk.childs && cikk.childs.length != 0 ? 'Összetett' : 'Egyszerű'}
        <br />
        <b>Mennyiségi egység: </b>
        {cikk.mennyiseg.megnevezes}
        <br />
        <b>Áfa: </b>
        {cikk.aktualisAfaNev}
        {' '}
(
        {NumberFormatter.addSpacesToString(cikk.aktualisAfaErtek, NumberFormatter.OSSZEG)}
        {' '}
%)
        <br />
        <b>Áfa érvényesség kezdete: </b>
        {cikk.aktualisAfaKezdete}
        <br />
        <br />
        {mezoObj.length ? <h6>Kiegészítő adatok: </h6> : <div />}
        {mezoObj}
        {mezoObj.length ? <br /> : <div />}

        <b>Webshop: </b>
        {cikk.webshop ? 'Igen' : 'Nem' }
        <br />
        <b>Raktáron kívüli: </b>
        {cikk.raktaronKivuli ? 'Igen' : 'Nem' }
        <br />
        <b>Státusz: </b>
        {cikk.aktiv ? 'Aktív' : 'Inaktív' }
        <br />

      </div>
    );
  }

  renderAfaTabla = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }],
    };

    const columns = [
      {
        dataField: 'afaErtek',
        text: 'Áfa mértéke',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'kezdete',
        text: 'Kezdete',
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'vege',
        text: 'Vége',
        style: {
          whiteSpace: 'pre-line'
        }
      },
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.formAfaTable}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }],
    };

    const statusType = {
      true: 'Aktív',
      false: 'Inaktív'
    };

    const columns = [
      {
        dataField: 'cikkcsoport.nev',
        text: 'Cikkcsoport',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'tipus',
        text: 'Típus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        filterValue: this.typeFormatter,
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        sortValue: this.typeFormatter,
        formatter: this.typeFormatter
      },
      {
        dataField: 'cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'megnevezes',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'mennyisegiEgyseg',
        text: 'Mennyiségi egység',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'aktiv',
        text: 'Státusz',
        filter: selectFilter({
          options: statusType,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: this.statusFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.iconFormatter
      }
    ];

    const childColumns = [
      {
        dataField: 'child.cikkcsoport.nev',
        text: 'Cikkcsoport',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'child.tipus',
        text: 'Típus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: this.typeFormatter
      },
      {
        dataField: 'child.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'child.megnevezes',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'child.mennyisegiEgyseg',
        text: 'Mennyiségi egység',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'child.aktiv',
        text: 'Státusz',
        filter: selectFilter({
          options: statusType,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: this.statusFormatter
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        formatter: NumberFormatter.formatterSpaceOsszeg,
        sort: true,
      }
    ];

    const expandRow = {
      renderer: row => (row.childs
        ? (
          <React.Fragment>
            <h5>Receptúra részei:</h5>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.childs}
              columns={childColumns}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
              noDataIndication="Nincs megjelenítendő adat!"
            />
          </React.Fragment>
        )
        : null
      ),
      expanded: this.state.expanded,
      expandByColumnOnly: true
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          expandRow={expandRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render = () => {
    return (
      <React.Fragment>
        <div className="row">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_TORZS', 'KARB') && (
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.newCikkForm}>Új cikk</Button>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState hasImage title="Az adatok megjelenítéséhez lekérdezés szükséges!" subtitle="Kérem, használja a lekérdezés funkciót!" />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getData()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>

        <Modal isOpen={this.state.modalForm} toggle={this.toggleForm} size="lg">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getCikk(this.state.currentId)}
                />),
                DATA: (this.renderForm())
              }[this.state.formStatus]}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleForm}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.modalDelete} toggle={this.toggleDelete} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteCikktorzs(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleDelete}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalView} toggle={this.toggleView} className={this.props.className} backdrop="static">
          <ModalHeader>Cikk információ</ModalHeader>
          <ModalBody>{this.state.modalView ? this.renderView(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter>
            {' '}
            <Button type="button" color="primary" onClick={this.toggleView}>Vissza</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalMetadata} toggle={this.toggleMetadata} backdrop="static">
          <AvForm onValidSubmit={this.addMetadata}>
            <ModalHeader>Új cikk jellemző felvétel</ModalHeader>
            <ModalBody>
              <AvGroup>
                <Label for="mezonev">Mező neve: *</Label>
                <AvInput name="mezonev" id="mezonev" required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="tartalom">Mező tartalma: *</Label>
                <AvInput name="tartalom" id="tartalom" required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Hozzáadás</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleMetadata}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.modalMennyiseg} toggle={this.toggleMennyiseg} backdrop="static">
          <AvForm onValidSubmit={this.addMennyiseg}>
            <ModalHeader>Új mennyiségi egység felvétel</ModalHeader>
            <ModalBody>
              <AvGroup>
                <Label for="megnevezes">Mennyiségi egység neve: *</Label>
                <AvInput name="megnevezes" id="megnevezes" required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Hozzáadás</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleMennyiseg}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.modalAfaTorles} toggle={this.toggleAfaTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteAfa(this._currentDeleteAfaId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleAfaTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalChildTorles} toggle={this.toggleChildTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteChild(this._currentDeleteChildId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleChildTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

CikktorzsContent.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default CikktorzsContent;
