import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  CustomInput
} from 'reactstrap';
import {
  AvForm,
  AvGroup,  
  AvFeedback,
  AvInput,
} from 'availity-reactstrap-validation';
import { DateTimePicker } from 'react-widgets';
import { GithubPicker } from 'react-color';
import { browserHistory } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import momentLocalizer from 'react-widgets-moment';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import FileSaver from 'file-saver';
import DataEmptyState from '../../../../../commons/dataemptystate';
import QueryEmptyState from '../../../../../commons/queryempty';
import NetworkEmptyState from '../../../../../commons/networkemptystate';
import Loader from '../../../../../commons/loader';
import Services from '../services';
import { PaginationTotal } from '../../../../../commons/controls';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import Lib from '../../../../../commons/lib';

import 'moment/locale/hu';

momentLocalizer(Moment);

class LeltarLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      tableLeltarStatus: 'QUERY',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'DATA',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // DATA
      baseJson: [],
      leltarIvJson: [],
      expanded: [],
      currentId: undefined,
      torlesId: undefined,
      // MODAL UTILS
      modalForm: false,
      modalDelete: false,
      modalKorrekcio: false,
      saveButtonLoading: false,
      deleteButtonLoading: false,
      // FORM VALUES
      formFordulonap: Moment().format('YYYY-MM-DD'),
      formNev: '',
      formAzonosito: '',
      formSzinkod: '',
      formAkadalymentesitett: false,
      formHasznalhato: true,
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    // this.listAllapot();
  }

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.active !== prevProps.active) {
  //     if (this.props.active == 6) {
  //       this.listAllapot();
  //     }
  //   }
  // }

  toggleFormModal = () => {
    this.setState(prevState => ({ modalForm: !prevState.modalForm }));
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  }

  toggleKorrekcioModal = () => {
    this.setState(prevState => ({ modalKorrekcio: !prevState.modalKorrekcio }));
  }

  listLeltar = () => {
    this.setState({
      tableLeltarStatus: 'LOAD',
      tableLeltarIvStatus: undefined,
      expanded: []
    }, () => {
      Services.listLeltar({ forduloNap: this.state.formFordulonap }, (err, res) => {
        if (!err) {
          let formatedJson = [];
          res[0].forEach((leltar) => {
            leltar.kezdetevege = MomentTimezone.tz(leltar.leltarKezdete, this.tz).format('YYYY.MM.DD') + ' - ' + MomentTimezone.tz(leltar.leltarVege, this.tz).format('YYYY.MM.DD');
            leltar.fordulonap = MomentTimezone.tz(leltar.forduloDatum, this.tz).format('YYYY.MM.DD');
            formatedJson.push(leltar);
          });
          this.setState({
            tableLeltarStatus: formatedJson.length > 0 ? 'TABL' : 'DATA',
            baseJson: formatedJson,
          });
        } else {
          this.setState({
            tableLeltarStatus: 'NETW'
          });
        }
      });
    });
  }

  getAllapot = (id) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.getAllapot(id, (err, res) => {
      if (!err) {
        this.setState({
          formStatus: 'DATA',

          formNev: this.nullToString(res[0].nev),
          formAzonosito: this.nullToString(res[0].azonosito),
          formSzinkod: this.nullToString(res[0].szin),
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  //   handleDateInputChange = (e, v, name) => {
  //   const value = v === '' ? null : v;
  //   this.setState({
  //     [name]: value
  //   });
  // }

  handleColorChange = (color) => {
    this.setState({ formSzinkod: color.hex.substr(1, 6) });
  }

  // handleEditClick = (cell) => {
  //   this.setState({
  //     formType: 'MOD',
  //     currentId: cell,
  //     modalForm: true
  //   }, () => {
  //     this.getAllapot(cell);
  //   });
  // }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleDeleteModal();
    });
  }

  handleKorrekcioClick = (cell) => {
    this.setState({
      korrekcioId: cell
    }, () => {
      this.toggleKorrekcioModal();
    });
  }

  getLeltarPdf = (id, type) => {
    Services.getLeltarPdf(id, type, (err, res) => {
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), type.toLowerCase());
      }
    });
  }

  getLeltarivPdf = (id, type) => {
    Services.getLeltarivPdf(id, type, (err, res) => {
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), type.toLowerCase());
      }
    });
  }

  deleteLeltar = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteLeltar(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleDeleteModal();
        this.listLeltar();
        this.props.globals.addSuccess('Leltár törölve!');
        this.setState({
          deleteButtonLoading: false
        });
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  korrekcio = () => {
    this.setState({
      korrekcioButtonLoading: true
    });
    Services.getLeltarKorrekcio(this.state.korrekcioId, (err) => {
      if (!err) {
        this.toggleKorrekcioModal();
        this.listLeltar();
        this.props.globals.addSuccess('A korrekció végrehajtásra került!');
        this.setState({
          korrekcioButtonLoading: false
        });
      } else {
        this.setState({
          korrekcioButtonLoading: false
        });
      }
    });
  }

  handleCikkekClick = (id) => {
    if (!this.state.expanded.includes(id)) {
      this.setState(() => ({
        expanded: [...this.state.expanded, id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== id)
      }));
    }
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  handleNewClick = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/leltar/felvetel'));
  }

  handleEditClick = (id) => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/leltar/modositas?id=' + id));
  }

  submitForm = () => {
    const {
      currentId,
      formNev,
      formAzonosito,
      formSzinkod,
      formAkadalymentesitett,
      formHasznalhato,
      formType
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {};

      submitObj.azonosito = this.emptyOrNull(formAzonosito);
      submitObj.nev = this.emptyOrNull(formNev);
      submitObj.szin = this.emptyOrNull(formSzinkod);

      Services.addAllapot(submitObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Leltár felvéve!');
          this.setState({
            saveButtonLoading: false
          }, () => {
            this.toggleFormModal();
            this.listAllapot();
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    } else if (formType === 'MOD') {
      let submitObj = {};

      submitObj.id = this.emptyOrNull(currentId);
      submitObj.azonosito = this.emptyOrNull(formAzonosito);
      submitObj.nev = this.emptyOrNull(formNev);
      submitObj.szin = this.emptyOrNull(formSzinkod);

      Services.editAllapot(submitObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Leltár módosítva!');
          this.setState({
            saveButtonLoading: false
          }, () => {
            this.toggleFormModal();
            this.listAllapot();
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  tableIconFormatter = (cell, row) => {
    const hasKarb = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_LELTAR', 'KARB');
    return (
      <div>
        <React.Fragment>
          {Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['KESZLETNYILV_LELTAR_KORREKCIO']) && row.leltarAllapotEnum.name === 'LEZART' && !row.korrektalt && (
            <React.Fragment>
              <Button className="fa fa-files-o table-action-icon" id={'action-view-' + cell + 'korrekcio'} onClick={() => this.handleKorrekcioClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'korrekcio'} delay={50}>
                {'Korrekció'}
              </UncontrolledTooltip>
            </React.Fragment>
          )}
          {row.leltarAllapotEnum.name === 'LEZART' && (
            <>
              <Button className="fa fa-file-pdf-o table-action-icon" id={'action-pdf-' + cell + 'jegyzokonyv'} onClick={() => this.getLeltarPdf(cell, 'JEGYZOKONYV')} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-pdf-' + cell + 'jegyzokonyv'} delay={50}>
                {'Jegyzőkönyv'}
              </UncontrolledTooltip>
            </>
          )}
          {hasKarb && row.leltarAllapotEnum.name !== 'LEZART' && (
            <React.Fragment>
              <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
                {'Módosítás'}
              </UncontrolledTooltip>
              <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-delete-' + cell} delay={50}>
                {'Törlés'}
              </UncontrolledTooltip>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }

  tableBooleanFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Igen</span>
      : <span className="badge badge-danger">Nem</span>;
  }

  styleSzinkod = (szinkod) => {
    return {
      textAlign: 'center',
      whiteSpace: 'pre-line',
      backgroundColor: '#' + szinkod,
      color: this.getCorrectTextColor('#' + szinkod)
    };
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új leltár létrehozása';
      case 'MOD':
        return 'Leltár módosítása';
      default:
        return '';
    }
  }

  listLeltarIv = (leltarid) => {
    this.setState({
      currentLeltarId: leltarid,
      tableLeltarIvStatus: 'LOAD'
    }, () => {
      Services.listLeltarIv({ leltarId: leltarid }, (err, res) => {
        if (!err) {
          let formatedJson = [];
          res[0].forEach((leltarIv) => {
            formatedJson.push(leltarIv);
          });
          this.setState({
            tableLeltarIvStatus: formatedJson.length > 0 ? 'TABL' : 'DATA',
            leltarIvJson: formatedJson,
          }, () => {
            console.table(this.state.leltarIvJson)
          });
        } else {
          this.setState({
            tableLeltarIvStatus: 'NETW'
          });
        }
      });
    });
  }

  onRowSelect = (row, isSelected, e) => {
    this.listLeltarIv(row.id);
  }

  renderTable = () => {
    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectRow = {
      mode: 'radio',
      // clickToSelect: true,
      onSelect: this.onRowSelect,
      nonSelectable: Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_LELTAR', 'LEK') ? [] : this.state.baseJson.map(a => a.id)
      // style: { background: 'red' }
    };

    const columns = [
      {
        dataField: 'kezdetevege',
        text: 'Leltár kezdete-vége',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'fordulonap',
        text: 'Fordulónap',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'mod.caption',
        text: 'Leltározás módja',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'bizonylat.bizonylatszam',
        text: 'Bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'leltarAllapotEnum.caption',
        text: 'Állapot',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_LELTAR', 'KARB')
          && !this.state.baseJson.some(leltar => leltar.leltarAllapotEnum && leltar.leltarAllapotEnum.name === 'LEZART'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <div className="col-md-12" style={{ paddingLeft: 0 }}>
          <h4>Leltár: </h4>
        </div>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          wrapperClasses="table-responsive"
          classes="table-scroll"
          headerClasses="table-th"
          filter={filterFactory()}
          selectRow={selectRow}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  szemelyFormatter = (cell, row) => {
    if (!cell) {
      return row.szemelyNev;
    }

    return cell;
  }

  leltarIvFormatter = (cell, row) => {
    return (
      <div>
        {
          <React.Fragment>
            {/* <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => {}} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => {}} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
              {'Módosítás'}
             </UncontrolledTooltip> */}
            <React.Fragment>
              <Button className="fa fa-list table-action-icon" id={'action-view-' + cell + 'cikk'} onClick={() => this.handleCikkekClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'cikk'} delay={50}>
                {'Cikkek'}
              </UncontrolledTooltip>
            </React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_LELTARIV', 'KARB') && (
              <React.Fragment>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'action-pdf-' + cell + 'elteres'} onClick={() => this.getLeltarivPdf(cell, 'ELTERES')} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-pdf-' + cell + 'elteres'} delay={50}>
                  {'Eltérés PDF'}
                </UncontrolledTooltip>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'action-pdf-' + cell + 'felveteli'} onClick={() => this.getLeltarivPdf(cell, 'FELVETELI_IV')} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-pdf-' + cell + 'felveteli'} delay={50}>
                  {'Felvételi iv PDF'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {/* <Button className="icon-trash table-action-icon" id={'action-delete-' + cell + 'leltariv'} onClick={() => this.handleLeltarIvDelete(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-delete-' + cell + 'leltariv'} delay={50}>
              {'Törlés'}
            </UncontrolledTooltip>
            <Button className="fa fa-plus table-action-icon" id={'action-add-' + cell + 'cikk'} onClick={() => {}} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-add-' + cell + 'cikk'} delay={50}>
              {'Cikk hozzáadása'}
            </UncontrolledTooltip> */}
          </React.Fragment>
      }
      </div>
    );
  }

  leltarCikkFormatter = (cell, row) => {
    return (
      <div>
        {
          <React.Fragment>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell + 'leltarcikk'} style={{ backgroundColor: 'transparent', borderColor: 'transparent' }} onClick={() => { this.handleLeltarCikkDelete(cell) }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-delete-' + cell + 'leltarcikk'} delay={50}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
      }
      </div>
    );
  }

  renderLeltarIvTable = () => {
    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 50 }
      ],
    };

    const optionsCikk = {
      sizePerPage: 50,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 }
      ],
    };

    const columns = [
      {
        dataField: 'raktar.nev',
        text: 'Raktár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'szemely.nev',
        text: 'Felelős',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: this.szemelyFormatter
      },
      {
        dataField: 'bizonylat.bizonylatszam',
        text: 'Bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.leltarIvFormatter
      }
    ];

    const childColumns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        editable: false
      },
      {
        dataField: 'cikk.megnevezes',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        editable: false
      },
      {
        dataField: 'cikk.mennyisegiEgyseg',
        text: 'Mennyiségi egység',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        editable: false
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        editable: false,
      },
      {
        dataField: 'leltarMennyiseg',
        text: 'Leltározott mennyiség',
        // filter: textFilter({
        //   delay: 400, // default is 500ms
        //   placeholder: 'Keresés...',
        // }),
        // style: {
        //   whiteSpace: 'pre-line'
        // }
        editable: false
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: true
      }
    ];

    const expandRow = {
      renderer: row => (row.leltarIvCikkEntityList ?
        (
          <React.Fragment>
            <h5>Cikkek:</h5>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.leltarIvCikkEntityList}
              columns={childColumns}
              filter={filterFactory()}
              pagination={paginationFactory(optionsCikk)}
              noDataIndication="Nincs megjelenítendő adat!"
            />
          </React.Fragment>
        )
        : null
      ),
      expanded: this.state.expanded,
      expandByColumnOnly: true
    };

    return (
      <div className="animated fadeIn">
        <div className="col-md-12" style={{ paddingLeft: 0 }}>
          <h4>Leltárív: </h4>
        </div>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.leltarIvJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          expandRow={expandRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    const {
      formFordulonap
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-3">
            <Label for="formFordulonap">Fordulónap:</Label>
            <DateTimePicker
              containerClassName="organw-date-time"
              format="YYYY-MM-DD"
              time={false}
              messages={{ dateButton: 'dátum', timeButton: 'idő' }}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formFordulonap')}
              value={formFordulonap && new Date(formFordulonap)}
              name="formFordulonap"
              id="formFordulonap"
            />
          </div>
          <div className="col-md-3">
            <Button className="pull-left table-new-button" color="success" onClick={() => { this.listLeltar(); }} style={{ marginTop: 28 }}>
              {'Keresés'}
            </Button>
          </div>
        </div>
        <div className="row">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_LELTAR', 'KARB') && (
            <div className="col-md-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új leltár'}
              </Button>
            </div>
          )}
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => { this.listLeltar(); }}
              />),
              TABL: (this.renderTable())
            }[this.state.tableLeltarStatus]}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => { this.listLeltarIv(this.state.currentLeltarId); }}
              />),
              TABL: (this.renderLeltarIvTable())
            }[this.state.tableLeltarIvStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalDelete} toggle={this.toggleDeleteModal} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.deleteLeltar()}
              loading={this.state.deleteButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalKorrekcio} toggle={this.toggleKorrekcioModal} backdrop="static">
          <ModalHeader>Korrekció</ModalHeader>
          <ModalBody>Biztosan végrehajtja a készlet korrekciót? A korrekcióval automatikusan létrehozásra kerülnek a többlet, és hiánynak megfelelő készletnövekedési és készletcsökkenési tételek!</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.korrekcio()}
              loading={this.state.korrekcioButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleKorrekcioModal}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

LeltarLista.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object
};

export default LeltarLista;
