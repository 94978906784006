import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
import {
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import Cleave from 'cleave.js';
import NumberFormatter from './NumberFormatter';

class SlaveAvInput extends Component {
  constructor(props) {
    super(props);
    this.classname = 'cleave-' + this.props.id;
    this.style = { textAlign: 'right' };
  }

  componentDidMount() {
    this.createCleave();
  }

  createCleave = () => {
    switch (this.props.szuroTipus) {
      case NumberFormatter.ADOSZAM:
        this.style = {
          textAlign: 'left'
        };
        new Cleave('.' + this.classname, {
          delimiters: ['-', '-'],
          blocks: [8, 1, 2],
        });
        break;
      case NumberFormatter.ADOSZAMAZON:
        this.style = {
          textAlign: 'left'
        };
        new Cleave('.' + this.classname, {
          blocks: [10],
          uppercase: true
        });
        break;
      case NumberFormatter.BANKSZAMLA:
        this.style = {
          textAlign: 'left'
        };
        new Cleave('.' + this.classname, {
          delimiter: ' ',
          blocks: [8, 8, 8]
        });
        break;
      case NumberFormatter.OSSZEG:
        this.style = {
          textAlign: 'right',
        };
        new Cleave('.' + this.classname, {
          delimiter: ' ',
          numeral: true,
          numeralDecimalScale: 10,
          numeralThousandsGroupStyle: 'thousand'
        });
        break;
      case NumberFormatter.SZAM:
        this.style = {
          textAlign: 'right',
        };
        new Cleave('.' + this.classname, {
          delimiter: ' ',
          numeral: true,
          numeralDecimalScale: 10,
          numeralThousandsGroupStyle: 'none'
        });
        break;
      case NumberFormatter.OSSZEG_6:
        this.style = {
          textAlign: 'right',
        };
        new Cleave('.' + this.classname, {
          delimiter: ' ',
          numeral: true,
          numeralDecimalScale: 6,
          numeralThousandsGroupStyle: 'thousand',
        });
        break;
      case NumberFormatter.EGESZ_SZAM:
        this.style = {
          textAlign: 'right',
        };
        new Cleave('.' + this.classname, {
          delimiter: ' ',
          numeral: true,
          numeralDecimalScale: 0,
          numeralThousandsGroupStyle: 'none'
        });
        break;
      case NumberFormatter.TAJSZAM:
        this.style = {
          textAlign: 'left',
        };
        new Cleave('.' + this.classname, {
          delimiter: ' ',
          blocks: [3, 3, 3]
        });
        break;
      default:
        break;
    }
  }

  render() {
    let value;

    switch (this.props.szuroTipus) {
      case NumberFormatter.ADOSZAM:
        value = NumberFormatter.addHyphensToString(this.props.value);
        break;

      case NumberFormatter.SZAM:
        value = NumberFormatter.removeSpacesFromString(this.props.value);
        break;

      default:
        value = NumberFormatter.addSpacesToString(this.props.value, this.props.szuroTipus, this.props.emptyStringOnZero);
    }

    return (
      <AvGroup
        key={this.props.key}
        style={this.props.style}
      >
        {!this.props.hideLabel && <Label for={this.props.id}>{this.props.label}</Label>}
        <InputGroup>
          <AvInput
            style={this.style}
            className={this.classname}
            name={this.props.id}
            id={this.props.id}
            type={this.props.type}
            value={value}
            maxLength={this.props.maxLength}
            required={this.props.required}
            disabled={this.props.disabled}
            onBlur={this.props.onChange}
            onChange={this.props.onChange}
            pattern={this.props.pattern}
            ref={this.props.ref}
            autoComplete={this.props.autoComplete}
          />
          {this.props.inputGroupAddon ? (
            <InputGroupAddon addonType="prepend">
              {this.props.inputGroupAddon}
            </InputGroupAddon>
          ) : <div />}

          {this.props.required ? <AvFeedback>{this.props.errorMessage ? this.props.errorMessage : 'Kötelező mező!'}</AvFeedback> : <div />}
        </InputGroup>
      </AvGroup>
    );
  }
}

SlaveAvInput.propTypes = {
  value: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.string,
  szuroTipus: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func,
  inputGroupAddon: PropTypes.string,
  pattern: PropTypes.string,
  ref: PropTypes.string,
  emptyStringOnZero: PropTypes.bool,
  autoComplete: PropTypes.string,
  hideLabel: PropTypes.bool,
  style: PropTypes.object,
};

export default SlaveAvInput;
