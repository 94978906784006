import Async from 'async';
import Request from 'superagent';
import fileSaver from 'file-saver';
import Moment from 'moment';
// import 'moment/locale/hu';

import dateFormat from 'dateformat'; // http://blog.stevenlevithan.com/archives/date-time-format
import ReactDOM from 'react-dom';
import { PaginationTotal } from './controls';

export default class Lib {
  static KeyCloak = class {
    static hasHigherRole = (keycloak, module, role) => {
      let roles = ['LEK', 'KARB'];
      let idx = roles.indexOf(role);
      if (idx >= 0) {
        for (idx; idx < roles.length; idx += 1) {
          if (keycloak.hasResourceRole('ROLE_' + module + '_' + roles[idx])) return true;
        }
        return false;
      }
      throw new Error('HIBÁS ROLE LETT MEGADVA');
    }

    static hasRole = (keycloak, roles, onlyLEK) => {
      for (let i = 0; i < roles.length; i += 1) {
        if (keycloak.hasResourceRole('ROLE_' + roles[i] + '_LEK') || (!onlyLEK && keycloak.hasResourceRole('ROLE_' + roles[i] + '_KARB'))) {
          return true;
        }
      }
      return false;
    }

    static hasRoleSpec = (keycloak, roles) => {
      for (let i = 0; i < roles.length; i += 1) {
        if (keycloak.hasResourceRole('ROLE_' + roles[i])) {
          return true;
        }
      }
      return false;
    }
  }

  static Browser = class {
    static detect() {
      let isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      // Firefox 1.0+
      let isFirefox = typeof InstallTrigger !== 'undefined';
      // At least Safari 3+: '[object HTMLElementConstructor]'
      let isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
      // Internet Explorer 6-11
      let isIE = /* @cc_on!@ */false || !!document.documentMode;
      // Edge 20+
      let isEdge = !isIE && !!window.StyleMedia;
      // Chrome 1+
      let isChrome = !!window.chrome && !!window.chrome.webstore;
      // Blink engine detection
      let isBlink = (isChrome || isOpera) && !!window.CSS;
      if (isFirefox) return 'FF';
      if (isIE || isEdge) return 'IE';
      if (isChrome) return 'GC';
      if (isSafari) return 'AS';
      if (isBlink) return '00';
      return '00';
    }

    static getHashElement(token) {
      let result = null;
      try {
        let hash = window.location.hash;
        if (hash) {
          result = hash.substring(1).split('&').filter(e => e.split('=').some(ee => ee == token));
          if (result && result.length) result = result[0].split('=')[1];
          else result = null;
        }
      } catch (e) {
        console.log(e);
      }
      return result;
    }

    static clearHash() {
      window.location.hash = '';
    }

    static stripTags(html) {
      let tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText;
    }

    static focusElement(element, selectAll = true) {
      if (element instanceof Object) {
        try {
          element = ReactDOM.findDOMNode(element);
        } catch (e) {
          console.log(e);
        }
      }
      if (element && element.focus) element.focus();
      if (element && selectAll && element.select) element.select();
    }

    static blurElement(element) {
      if (element instanceof Object) {
        try {
          element = ReactDOM.findDOMNode(element);
        } catch (e) {
          console.log(e);
        }
      }
      if (element && element.blur) element.blur();
    }

    static enableButton(button, enabled) {
      let classNames = button.className.split(' ').filter(e => e != 'disabled');
      if (!enabled) classNames.push('disabled');
      button.className = classNames.join(' ');
      button.disabled = !enabled;
    }

    static setCookie(cname, cvalue, expminutes) {
      let d = new Date();
      d.setTime(d.getTime() + (expminutes * 60 * 1000));
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    static getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }

    static uuidv4 = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }
  };

  static MicroServices = class {
    static EntityTrans = class {
      static toEntity(data, fieldMappings) {
        fieldMappings.forEach((e) => {
          e.fields.forEach((m) => {
            if (data[m.field]) {
              if (!data[e.entity]) data[e.entity] = {};
              if (Array.isArray(data[m.field])) {
                if (m.type) {
                  data[e.entity] = data[m.field].filter(e => (e.hasOwnProperty('kulcsTipus') ? e.kulcsTipus.indexOf(m.type) != -1 : false)); // e.kulcstipus helyett általános
                } else data[e.entity] = data[m.field];
              } else {
                data[e.entity][m.entityAttr] = data[m.field];
              }
            }
          });
        });
      }
      static fromEntity(data, fieldMappings) {
        fieldMappings.forEach((e) => {
          e.fields.forEach((m) => {
            data[m.field] = data[e.entity] ? data[e.entity][m.entityAttr] : null;
          });
        });
      }
    };

    static errorHandler = null;
    static keycloak = null;
    static handleError(args, err) {
      if (err.response && err.response.text && !err.response.body) {
        try {
          err.response.body = JSON.parse(err.response.text);
        } catch (e) {}
      }
      console.log('MSErr ' + JSON.stringify(err));
      if (args.requestType) delete Lib.MicroServices.runningRequests[args.requestType];
      if (Lib.MicroServices.errorHandler) Lib.MicroServices.errorHandler(err);
      args.fnDone({
        status: err.status,
        errorThrown: err,
        responseText: err.response && err.response.body ? err.response.body.msg : '',
        responseJSON: err.response ? err.response.body : ''
      }, err);
    }
    static commonHeaders = {
      Pragma: 'no-cache',
      'X-Requested-With': 'XMLHttpRequest',
      Expires: '-1',
      'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
    };
    static runningRequests = {};

    static request(args) {
      // args: {method, url, params, data, user, pass, contentType, acceptType, fnDone(err, result)}
      if (args.requestType && Lib.MicroServices.runningRequests[args.requestType]) {
        try {
          Lib.MicroServices.runningRequests[args.requestType].abort();
        } catch (e) {
          console.log(e);
        }
      }
      let req;
      switch (args.method) {
        case 'PUT':
          req = Request.put(args.url);
          break;
        case 'POST':
          req = Request.post(args.url);
          break;
        case 'DELETE':
          req = Request.del(args.url);
          break;
        default:
          req = Request.get(args.url);
      }
      if (args.params) {
        for (let k in args.params) {
          if (args.params[k] instanceof Date) {
            args.params[k] = args.params[k].toISOString();
          }
        }
        // console.log(args.params);
        req = req.query(args.params);
      }
      if (args.data) req = req.send(args.data);
      if (args.user && args.pass) req = req.auth(args.user, args.pass);
      if (args.timeout) req = req.timeout(args.timeout);
      if (args.acceptType && args.acceptType && args.acceptType.indexOf('application/octet-stream') >= 0) {
        req.responseType('blob'); // or arraybuffer
      }
      for (let key in Lib.MicroServices.commonHeaders) {
        if (Lib.MicroServices.commonHeaders[key] !== null) {
          req.set(key, Lib.MicroServices.commonHeaders[key]);
        }
      }

      req.set('ClientId', Lib.MicroServices.keycloak.clientId);
      if (Lib.MicroServices.keycloak && Lib.MicroServices.keycloak.token) {
        Lib.MicroServices.keycloak.updateToken(3600).error(() => {
            console.log('Failed to refresh the token, or the session has expired');
        });
        req.set('Authorization', 'Bearer ' + Lib.MicroServices.keycloak.token);
      }
      console.log('args: ', args);
      req
        .set('Content-Type', args.contentType ? args.contentType : 'application/json')
        .set('Accept', args.acceptType ? args.acceptType : 'application/json')
        .end((err, res) => {
          if (err) {
            let xhr = req.xhr;
            console.log('xhr responseType: ', xhr.responseType);
            if ((xhr && xhr.responseType == 'blob' && xhr.response && xhr.response.type == 'application/json') || args.acceptType === "application/octet-stream;charset=ISO-8859-2") {
              let fr = new FileReader();
              fr.addEventListener('loadend', () => {
                let response = JSON.parse(fr.result);
                Lib.MicroServices.handleError(args, { status: xhr.status, response: { body: response } });
              });
              console.log(args.acceptType);
              fr.readAsText(xhr.response);
            } else {
              Lib.MicroServices.handleError(args, err);
            }
          } else {
            if (args.requestType) delete Lib.MicroServices.runningRequests[args.requestType];
            if (args.acceptType === 'application/octet-stream;charset=utf-8') {
              console.log(res);
              let reader = new FileReader();
              console.log('reader');
              console.log(reader);
              reader.onload = (event) => {
                console.log('result');
                console.log(event.target.result);
                args.fnDone(
                  err,
                  event.target.result
                );
              };
              reader.readAsText(res.xhr.response, 'utf-8');
            } else {
              args.fnDone(
                err,
                res.body
                  ? res.body
                  : res.xhr
                    ? res.xhr.response
                      ? res.xhr.response
                      : res.text
                    : res.text
              );
            }
          }
        });
      if (args.requestType && Lib.MicroServices.runningRequests[args.requestType]) Lib.MicroServices.runningRequests[args.requestType] = req;
      return req;
    }
    static buildUrl(url, args) {
      if (args) {
        Object.keys(args).forEach((key) => {
          url = url.replace('$' + key + '$', args[key]);
        });
      }
      return url;
    }
    /**
     * async (ajax) request data
     * @param  {Object} urlParams {url, args, params, method, data}
     * @param  {method} fnDone   Callback method when done
     */
    static requestService(urlParams, fnDone, asyncType = 'series') {
      console.log('urlparams');
      console.log(urlParams);
      let parallels = urlParams.map(urlParam =>
        foob =>
          Lib.MicroServices.request({
            method: urlParam.method ? urlParam.method : 'GET',
            url: Lib.MicroServices.buildUrl(urlParam.url, urlParam.args),
            params: urlParam.params,
            data: urlParam.data,
            requestType: urlParam.requestType,
            contentType: urlParam.contentType,
            acceptType: urlParam.acceptType,
            fnDone: foob
          }));
      switch (asyncType) {
        case 'parallel':
          Async.parallel(parallels, fnDone);
          break;
        default:
          Async.series(parallels, fnDone);
          break;
      }
    }
    static getUrl(Config, service, action, args) {
      let url = Config[service].rootUrl + Config[service].actions[action];
      if (args) {
        Object.keys(args).forEach((key) => {
        // url = url.replace('/\\$' + k + '/\\$/g', v);
          url = url.replace('$' + key + '$', args[key]);
        });
      }
      return url;
    }

    /**
    * async (ajax) request data
    * @param  {Object} Config   Global configuration object
    * @param  {Object} services {service, action, args, method, data}
    * @param  {method} fnDone   Callback method when done
    */
    static requestData(Config, services, fnDone) {
      let parallels = services.map(sea =>
        foob =>
          Lib.MicroServices.request({
            method: sea.method ? sea.method : 'GET',
            url: Lib.MicroServices.getUrl(Config, sea.service, sea.action, sea.args),
            params: sea.params,
            data: sea.data,
            requestType: sea.requestType,
            acceptType: sea.acceptType,
            fnDone: foob
          }));
      Async.parallel(parallels, fnDone);
    }
  };

  static readFile(file, fnDone) {
    let reader = new FileReader();
    reader.onload = function () {
      fnDone(new Uint8Array(reader.result));
    };
    reader.readAsArrayBuffer(file);
  }

  static readTextFile(file, encoding, fnDone) {
    let reader = new FileReader();
    reader.onload = function () {
      fnDone(reader.result.split(/\r\n/g));
    };
    reader.readAsText(file, encoding);
  }

  static arrayBufferToBinaryString(arrayBuffer) {
    let bString = '';
    if (arrayBuffer) {
      let bytes = new Uint8Array(arrayBuffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i += 1) {
        bString += String.fromCharCode(bytes[i]);
      }
    }
    return bString;
  }

  static toBlob(bytes, type) {
    return new Blob([bytes], { type: type });
  }

  static saveFile(file, type, fileName) {
    Lib.saveBlob(Lib.toBlob(file, type), fileName);
    // window.navigator.msSaveBlob(Lib.toBlob(file, type), fileName);
  }

  static saveBlob(blob, fileName) {
    fileSaver.saveAs(blob, fileName);
  }

  static bankAccountFormatter(bc) {
    let str = bc.toString();
    let result = '';
    for (let i = 0; i < str.length; i += 1) {
      if ((i + 1) % 8 === 0) {
        result += str.charAt(i) + '-';
      } else {
        result += str.charAt(i);
      }
    }
    return result.substr(0, result.length - 1);
  }

  static today() {
    let date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  }

  static dateTimeToDate(date) {
    if (!date) date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static toDate(dateString) {
    try {
      return dateString ? new Date(dateString) : null;
    } catch (e) {
      return dateString ? typeof dateString === 'string' ? new Date(parseInt(dateString.substr(0, 4)), parseInt(dateString.substr(5, 2)) - 1, parseInt(dateString.substr(8, 2))) : dateString : null;
    }
  }

  static toDateTime(dateString) {
    try {
      return dateString ? new Date(dateString) : null;
    } catch (e) {
      return dateString ? typeof dateString === 'string' ? new Date(
        parseInt(dateString.substr(0, 4)), parseInt(dateString.substr(5, 2)) - 1, parseInt(dateString.substr(8, 2)),
        parseInt(dateString.substr(11, 2)), parseInt(dateString.substr(14, 2)), parseInt(dateString.substr(17, 2))
      ) : dateString : null;
    }
  }

  static toDateString(date, format) { // 'YYYY-MM-DD'
    if (date && !(date instanceof Date)) {
      if (isNaN(Date.parse(date))) return date;
      try {
        date = new Date(date);
      } catch (e) {
        return date;
      }
    }
    return date ? (format ? dateFormat(date, format) : date.toLocaleDateString()) : null;
  }

  static toDateTimeString(date, format) {
    if (date && !(date instanceof Date)) { date = new Date(date); }
    return date ? (format ? dateFormat(date, format) : date.toLocaleString()) : null;
  }

  static toRelativeTime(datetime) {
    if (datetime) {
      Moment.locale('hu');
      let moment = Moment(datetime);
      if (moment.diff(new Date()) > 0) {
        return 'pár másodperce';
      }
      return moment.calendar(null, { sameElse: 'YYYY[.]MM[.]DD[.] HH:mm' }) + ', ' + Moment(datetime).fromNow();
    }
    return null;
  }

  static storage = sessionStorage;
  static storeP(key, obj) {
    Lib.storage.setItem(key, obj);
  }
  static loadP(key) {
    return Lib.storage.getItem(key);
  }
  static removeP(key) {
    return Lib.storage.removeItem(key);
  }

  static differences(a, b, n = 'obj', inspectedA = [], maxdeepness = 3) {
    let diffs = [];
    if (typeof a !== 'function') {
      if (a !== null && typeof a === 'object' && b !== null && typeof b === 'object') {
        inspectedA.push(a);
        let ka = Object.keys(a);
        ka.forEach((i) => {
          if (!b.hasOwnProperty(i)) { diffs.push({ n: n + '.' + i, a: a[i], b: null }); } else
          if (maxdeepness > 0 && !inspectedA.some(v => v === a[i])) {
            diffs = diffs.concat(Lib.differences(a[i], b[i], n + '.' + i, inspectedA, maxdeepness - 1));
          }
        });
      } else
      if ((a === null && b !== null) || (a !== null && b === null) || a != b) {
        diffs.push({ n, a, b });
      }
    }
    return diffs;
  }

  static shallowEqual(objA, objB, strict = true) {
    if (objA === objB || (!strict && objA == objB) || ((objA === null || objA === undefined) && (objB === null || objB === undefined))) {
      return true;
    }
    if ((objA === null || objA === undefined) && objB !== null && objB !== undefined) { return false; }
    if ((objB === null || objB === undefined) && objA !== null && objA !== undefined) { return false; }
    let key;
    // Test for A's keys different from B.
    for (key in objA) {
      if (objA.hasOwnProperty(key) &&
          (!objB.hasOwnProperty(key) || (strict && objA[key] !== objB[key]) || (!strict && objA[key] != objB[key]))) {
        return false;
      }
    }
    // Test for B'a keys missing from A.
    for (key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  static shallowEqual0(objA, objB) {
    if (objA === objB) {
      return true;
    }

    if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    // Test for A's keys different from B.
    const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
    const IGNORE_FUNCTIONS = ['onChange', 'onBlur', 'onFocus', 'onUpdate', 'handleChange', 'handleBlur', 'handleFocus'];
    for (let i = 0; i < keysA.length; i += 1) {
      if (!bHasOwnProperty(keysA[i])) {
        return false;
      }
      if (['fields', 'errors'].some(val => val === keysA[i])) {
        if (!Lib.shallowEqual(objA[keysA[i]], objB[keysA[i]])) {
          return false;
        }
      } else if (typeof objA[keysA[i]] === 'function' && IGNORE_FUNCTIONS.some(val => val === keysA[i])) {
        // don't compare function is the IGNORE_FUNCTIONS array
        continue;
      } else if (objA[keysA[i]] !== objB[keysA[i]]) {
        return false;
      }
    }
    return true;
  }

  static stripProps(componentClass, props) { // allows only props that are pure HTML attributes _and_ component class properties according to its propTypes
    return [
      'about', 'datatype', 'inlist', 'prefix', 'property',
      'resource', 'typeof', 'vocab', 'autoCapitalize', 'autoCorrect',
      'color', 'itemProp', 'itemScope', 'itemType', 'itemRef', 'itemID',
      'security', 'unselectable', 'results', 'autoSave',
      'onBlur', 'onChange', 'onClick', 'onContextMenu', 'onCopy',
      'onCut', 'onDoubleClick', 'onDrag', 'onDragEnd', 'onDragEnter',
      'onDragExit', 'onDragLeave', 'onDragOver', 'onDragStart', 'onDrop',
      'onFocus', 'onInput', 'onKeyDown', 'onKeyPress', 'onKeyUp',
      'onMouseDown', 'onMouseEnter', 'onMouseLeave', 'onMouseMove',
      'onMouseOut', 'onMouseOver', 'onMouseUp', 'onPaste',
      'onScroll', 'onSubmit', 'onTouchCancel', 'onTouchEnd', 'onTouchMove',
      'onTouchStart', 'onWheel'
    ]
      .concat(Object.keys(componentClass.propTypes || {}))
      .concat(Object.keys(componentClass.defaultProps || {}))
    // .concat(Object.keys(HtmlAttrs))
      .reduce((stripped, prop) => { if (props.hasOwnProperty(prop)) stripped[prop] = props[prop]; return stripped; }, {});
  }

  static stripFormsyProps(props) {
    let formsyProps = [
      'setValidations',
      'setValue',
      'resetValue',
      'getValue',
      'hasValue',
      'getErrorMessage',
      'getErrorMessages',
      'isFormDisabled',
      'isValid',
      'isPristine',
      'isFormSubmitted',
      'isRequired',
      'showRequired',
      'showError',
      'isValidValue',
      'validationError',
      'validationErrors',
      'validations',
    ];
    let stripped = {};
    for (let prop in props) {
      if (formsyProps.indexOf(prop) < 0) { stripped[prop] = props[prop]; }
    }
    return stripped;
  }

  static getFilextFromName = (filename) => {
    return filename.split('.').pop();
  }

  static bootstraptableProps = {
    prePageTitle: 'Előző',
    nextPageTitle: 'Következő',
    firstPageTitle: 'Első',
    lastPageTitle: 'Utolsó',
    sizePerPage: 5,
    paginationShowsTotal: PaginationTotal,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '25', value: 25 }],
    exportCSVText: 'Exportálás CSV-be',
    exportCSVSeparator: ';'
  }
}
