import React, { Component } from 'react';
import _ from 'lodash';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField
} from 'availity-reactstrap-validation';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { browserHistory } from 'react-router';
import moment from 'moment';
import Async from 'react-select/lib/Async';
import Select from 'react-select';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';
import { DatePicker } from '../../commons/controls';
import Services from './services';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';
import SlaveAvInput from '../../commons/SlaveAvInput';

/**
 * Osztály üdülésijog űrlap létrehozására.
 * @extends Component
 */
class UdulesiJogUrlap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modositoObj: null,
      currentModositoId: null,
      hetszine: null,
      vevoValue: [],
      jogtulajdonosok: [],
      statuszok: [],
      ev: [],
      gyakorisagValues: [],
      hetek: [],
      lejaratistatuszOptions: [],
      statusz: [],
      aktivSzallodak: [],
      aktivEpuletek: [],
      aktivApartmanok: [],
      aktivPenznem: [],
      aktivFizetesimod: [],
      szamlaNyelv: [],
      lezarasOk: [],
      currentSzerzodesId: null,
      modositoStatus: false,
      felvetelStatus: false,
      dataLoaded: false,
      apartmanValue: null,
      hetValue: null,
      formSzerzodesId: null,
      reszletCheckBox: false,
      szallodaHasValue: false,
      epuletHasValue: false,
      apartmanHasValue: false,
      hetHasValue: false,
      szallitoValue: '',
      // KOMPENZACIO VÁLTOZÓK
      ervenyessegVege: null,
      // evekHetek: [],
      osszesHetes: false,
      kompJogtulajdonosok: [],
      kompSzallitoValue: '',
      komptipus: [],
      // Reszvény
      modalReszveny: false,
      reszvenyek: [],
      reszvenyfajta: [],
      reszvenyJogtulaj: 0,
      osszesReszveny: [],
      // Évek És Hetek
      evekEsHetek: [],
      egyebdij: [],
      ujJogtualjdonos: true,
      vanujReszveny: false,
      vanujEvEsHet: false,
      kompenzaciosFeltetlek: false,
      kompenzaciosUdulesijogStatusz: {},
      rCIHet: 0,
      modalTorles: false,
      modalKuldes: false,
      koltsegKodOptions: [],
      evHetInsert: null,
      kompenzacioSzallodaOptions: [],
      penznem: '',
      penznemValues: [],
      tizedesjegy: 0,
      szallodaValues: [],
    };
  }

  componentWillMount() {
    let params = new URL(window.location.href).searchParams;
    let szerzodesId = this.props.route.felvetel === 'true' ? params.get('szid') : null;
    let id = this.props.route.modositas === 'true' ? params.get('id') : null;
    this.setState({ currentSzerzodesId: szerzodesId });

    this.genWeeks();
    this.renderSzallodaOptions();

    Services.initForm(id, false, (err, res) => {
      if (!err) {
        let egyebDijArray = [];
        if (res[0].udulesijog && res[0].udulesijog.egyebdij) {
          res[0].udulesijog.egyebdij.forEach((item) => {
            let obj = {};
            let kkObj = {};
            obj.id = item.id;
            kkObj.value = item.koltsegkodid;
            kkObj.label = item.koltsegkod;
            obj.koltsegkodValue = kkObj;
            obj.osszeg = NumberFormatter.removeSpacesFromString(item.osszeg);
            obj.alapdijhozadas = item.alapdijhozadas;

            egyebDijArray.push(obj);
          });
        }

        let evHetArray = [];
        if (res[0].udulesijog && res[0].udulesijog.evhet) {
          res[0].udulesijog.evhet.sort((a, b) => { return a.ev > b.ev ? 1 : -1; }).forEach((item) => {
            let obj = item;
            obj.id = item.id;
            obj.szamlaviseloValue = item.szamlaviselobean;
            obj.szamlaviseloid = item.szamlaviselobean.id;

            if (item.egyebdij) {
              let egyebDijArray2 = [];
              item.egyebdij.forEach((item2) => {
                let obj2 = {};
                let kkObj2 = {};
                kkObj2.value = item2.koltsegkodid;
                kkObj2.label = item2.koltsegkod;
                obj2.koltsegkodValue = kkObj2;
                obj2.osszeg = NumberFormatter.removeSpacesFromString(item2.osszeg);
                obj2.alapdijhozadas = item2.alapdijhozadas;
                obj2.id = item2.id;

                egyebDijArray2.push(obj2);
              });
              obj.egyebdij = egyebDijArray2;
            }

            evHetArray.push(obj);
          });
        }

        let szallodaOptionsArray = [];
        res[0].kompenzacioszalloda.forEach((item) => {
          let obj = {};
          obj.id = null;
          if (res[0].udulesijog && res[0].udulesijog.kompenzacioszallodaentities && res[0].udulesijog.kompenzacioszallodaentities.length > 0) {
            let szallodaEntity = res[0].udulesijog.kompenzacioszallodaentities.find(x => x.szallodaid === item.id);
            if (szallodaEntity) {
              obj.id = szallodaEntity.id;
            }
          }
          obj.szallodaid = item.id;
          obj.nev = item.nev;

          szallodaOptionsArray.push(obj);
        });

        let szallodaValuesArray = [];
        if (res[0].udulesijog && res[0].udulesijog.kompenzacioszallodaentities && res[0].udulesijog.kompenzacioszallodaentities.length > 0) {
          res[0].udulesijog.kompenzacioszallodaentities.forEach((x) => {
            let obj = {};
            obj.id = x.id;
            obj.szallodaid = x.szallodaid;
            obj.nev = res[0].kompenzacioszalloda.find(y => x.szallodaid === y.id).nev;

            szallodaValuesArray.push(obj);
          });
        }

        this.setState({
          aktivPenznem: res[0].penznem.map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.azonosito + ')'}</option>),
          penznemValues: res[0].penznem,
          aktivFizetesimod: res[0].fizmod.map(e => <option key={e.variansid ? e.variansid : e.id} value={e.variansid ? e.variansid : e.id}>{e.variansnev ? e.variansnev : e.nev}</option>),
          ev: res[0].gyakorisag.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          gyakorisagValues: res[0].gyakorisag,
          lejaratistatuszOptions: res[0].lejaratistatusz.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          statusz: res[0].statusz.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          szamlaNyelv: res[0].nyelv.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          lezarasOk: res[0].lezarasoka.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          komptipus: res[0].kompenzaciotipus.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          reszvenyfajta: res[0].kompenzacioreszveny.map(e => <option key={e.name} value={e.name}>{e.caption}</option>),
          formSzerzodesId: szerzodesId || res[0].udulesijog.szerzodesid,
          koltsegKodOptions: res[0].kompenzaciokoltsegkod.map(e => ({ value: e.id, label: e.kod })),
          egyebdij: egyebDijArray,
          evekEsHetek: evHetArray,
          kompenzacioSzallodaOptions: szallodaOptionsArray,
          szallodaValues: szallodaValuesArray,
        });

        if (this.props.route.modositas === 'true') {
          if (id) {
            Services.getRCIhet((err2, res2) => {
              if (!err2) {
                this.setState({ rCIHet: res2[0] });
              }
            });

            let penznem = res[0].penznem.find(x => x.id === res[0].udulesijog.penznemid);

            this.setState({
              penznem: penznem && penznem.azonosito,
              tizedesjegy: penznem ? penznem.tizedesjegy : 0,
              ervenyessegVege: res[0].udulesijog.vege,
              currentModositoId: id,
              modositoObj: res[0].udulesijog,
              hetszine: res[0].udulesijog.apartmanbean.hetszine,
              szallitoValue: res[0].udulesijog.szamlakibocsatobean,
              kompSzallitoValue: res[0].udulesijog.kompenzacioszamlaviselobean,
              statuszok: res[0].udulesijog.udulesijogstatuszentites,
              reszletCheckBox: res[0].udulesijog.gyakorisag.name !== 'MINDKETTO',
              dataLoaded: true,
              kompenzaciosUdulesijogStatusz: res[0].udulesijog.kompenzaciostatusz,
            });

            this.renderEpuletekOptionsById(res[0].udulesijog.apartmanbean.szallodaid);
            this.renderApartmanokOptionsById(res[0].udulesijog.apartmanbean.epuletid);
            let futamidoveg = this.state.modositoObj;

            if (futamidoveg.futamidoveg == null) {
              this.setState({ modositoObj: futamidoveg });
            }

            let jogtulajdonosValues = [];
            let kompJogtulajdonosValues = [];
            let jogtulajdonosok = [];
            let osszesReszveny = [];

            res[0].udulesijog.udulesijogtulajdonosentities.forEach((jogtulaj) => {
              jogtulaj.reszvenyEntities.forEach((reszveny) => {
                reszveny.udulesijogtulajdonosentities = { id: jogtulaj.id };
                reszveny.udulesiJogTulajdonos = { id: jogtulaj.id };
                reszveny.fajta = reszveny.fajta.name;
                reszveny.adatID = reszveny.id;
                reszveny.id = null;
                reszveny.rid = jogtulaj.reszvenyEntities.length;
                osszesReszveny.push(reszveny);
              });
              if (jogtulaj.eladta == null) jogtulaj.eladta = false;
            });

            if (res[0].udulesijog.kompenzaciosszerzodes) {
              res[0].udulesijog.udulesijogtulajdonosentities.forEach((item) => {
                item.reszvenyEntities = [];
                if (!item.kompenzacios) {
                  jogtulajdonosValues.push({
                    cim: item.tulajdonosbean.cim,
                    id: item.tulajdonosbean.id,
                    nev: item.tulajdonosbean.nev,
                    azon: item.tulajdonosbean.azon,
                    adoszam: item.tulajdonosbean.adoszam,
                    jogid: item.id,
                  });
                  let data = item;
                  data.reszvenyMegjelen = true;
                  jogtulajdonosok.push(data);
                }
                if (item.kompenzacios) {
                  kompJogtulajdonosValues.push({
                    cim: item.tulajdonosbean.cim,
                    id: item.tulajdonosbean.id,
                    nev: item.tulajdonosbean.nev,
                    azon: item.tulajdonosbean.azon,
                    adoszam: item.tulajdonosbean.adoszam,
                    kompenzacios: item.kompenzacios,
                    kezdete: item.kezdete,
                    vege: item.vege,
                    elhunyt: item.elhunyt,
                    fotulajdonos: item.fotulajdonos,
                    eladta: false,
                    added: false
                  });
                }
              });
            } else {
              res[0].udulesijog.udulesijogtulajdonosentities.forEach((item) => {
                jogtulajdonosValues.push({
                  cim: item.tulajdonosbean.cim,
                  id: item.tulajdonosbean.id,
                  nev: item.tulajdonosbean.nev,
                  azon: item.tulajdonosbean.azon,
                  adoszam: item.tulajdonosbean.adoszam,
                  jogid: item.id
                });
                jogtulajdonosok.push(item);
              });
            }

            let kompHossz = kompJogtulajdonosValues.length;
            if (kompHossz > 0) {
              let elhunyt_eladta = 0;
              kompJogtulajdonosValues.forEach((item) => {
                if (item.elhunyt || item.eladta) {
                  elhunyt_eladta += 1;
                }
              });
              if (elhunyt_eladta > kompHossz) {
                this.setState({ ujJogtualjdonos: false });
              }
            } else {
              this.setState({ ujJogtualjdonos: false });
            }

            this.setState({ vevoValue: jogtulajdonosValues, jogtulajdonosok: jogtulajdonosok }, () => {
              // console.log('jogtulaj***', jogtulajdonosValues);
            });
            this.setState({ kompJogtulajdonosok: kompJogtulajdonosValues }, () => {
              // console.log('Kompjogtulaj***', kompJogtulajdonosValues);
            });
            this.setState({ osszesReszveny: osszesReszveny }, () => {
              // console.log('osszesReszveny***', osszesReszveny);
            });
          }
        } else {
          this.setState({
            modositoObj: null,
            dataLoaded: true
          });
        }
      }
    });
  }

  componentDidMount() {
    this.onMount();
  }

  isTypeOneThree = () => {
    return this.state.modositoObj && this.state.modositoObj.kompenzaciotipus && (this.state.modositoObj.kompenzaciotipus.name === 'R' || this.state.modositoObj.kompenzaciotipus.name === 'UNF');
  }

  isTypeTwo = () => {
    return this.state.modositoObj && this.state.modositoObj.kompenzaciotipus && (this.state.modositoObj.kompenzaciotipus.name === 'UF');
  }

  isKompenzacioFelvittLezart = () => {
    return this.state.modositoObj.kompenzaciostatusz && (this.state.modositoObj.kompenzaciostatusz.name === 'FELVITT' || this.state.modositoObj.kompenzaciostatusz.name === 'LEZART');
  }

  getReszvenyek = (id) => {
    let newReszvenyek = this.state.reszvenyek;
    let resertek = this.state.osszesReszveny;

    if (resertek.length > 0) {
      resertek.forEach((item) => {
        if (item.udulesijogtulajdonosentities.id == id) {
          newReszvenyek.push({
            id: item.id,
            adatID: item.adatID,
            fajta: item.fajta && item.fajta.name ? item.fajta.name : item.fajta,
            darabszam: item.darabszam,
            bekerulesiertek: item.bekerulesiertek,
            nevertek: item.nevertek,
            ertekpapszamla: item.ertekpapszamla,
          });
        }
      });

      this.setState({ reszvenyek: newReszvenyek });
    }
    this.setState({ modalReszveny: true });
  }

  onMount = () => {
    if (this.props.route.modositas === 'true') {
      this.setState({ modositoStatus: true });
    }
    if (this.props.route.felvetel === 'true') {
      this.setState({ felvetelStatus: true });
    }
  }

  onChangeSzalloda = (e) => {
    Services.listEpuletekById(e.target.value, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(a => <option key={a.id} value={a.id}>{a.nev}</option>);
        if (res[0].length > 0) {
          this.setState({
            szallodaHasValue: true,
            aktivEpuletek: aktivEpuletek,
            aktivApartmanok: []
          });
        } else {
          this.setState({
            szallodaHasValue: false,
            aktivEpuletek: [],
            aktivApartmanok: []
          });
          this.props.route.globals.addInfo('A szállodához nincs aktív épület rendelve!');
        }
      }
    });
  }

  // onChangeKompenzacioSzalloda = (e) => {
  //   let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
  //   newState.kompenzacioszallodaid = e.target.value;

  //   this.setState({ modositoObj: newState });
  // }

  onChangeEpulet = (e) => {
    Services.listApartmanokById(e.target.value, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(a => <option key={a.id} value={a.id}>{a.nev + (a.kategoria ? (' (' + a.kategoria + ')') : '')}</option>);
        if (res[0].length > 0) {
          this.setState({
            epuletHasValue: true,
            aktivApartmanok: aktivApartmanok
          });
        } else {
          this.setState({
            epuletHasValue: false,
            aktivApartmanok: []
          });
          this.props.route.globals.addInfo('Az épülethez nincs aktív apartman rendelve!');
        }
      }
    });
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i += 1) {
      weeks.push(<option style={{ textAlign: 'right' }} key={i.toString()} value={i}>{i}</option>);
    }
    this.setState({ hetek: weeks });
  }

  generalasIsNotVaild = () => {
    return !this.state.evHetInsert || !this.state.evHetInsert.kezdoev || !this.state.evHetInsert.zaroev || !this.state.evHetInsert.szamlaviseloid
      || !this.state.evHetInsert.szamlaviseloValue || !this.state.evHetInsert.het || !this.state.evHetInsert.dij;
  }

  generateEvHet = () => {
    if (this.generalasIsNotVaild()) {
      return;
    }

    let hiba = 0;
    if (this.state.modositoObj && !this.state.modositoObj.kompenzaciofutamidokezdete) {
      this.props.route.globals.addError('Kompenzáció futamidejének kezdete megadása kötelező!');
      hiba += 1;
    }

    if (hiba === 0 && (!this.state.modositoObj || !this.state.kompenzaciogyakorisag)) {
      this.props.route.globals.addError('Kompenzációs gyakoriság megadása kötelező!');
      hiba += 1;
    }

    if (hiba === 0 && parseInt(this.state.evHetInsert.kezdoev, 10) < new Date().getFullYear()) {
      this.props.route.globals.addError('Kompenzáció kezdő éve nem lehet kisebb, mint a jelenlegi év!');
      hiba += 1;
    }

    if (hiba === 0 && parseInt(this.state.evHetInsert.zaroev, 10) < parseInt(this.state.evHetInsert.kezdoev, 10)) {
      this.props.route.globals.addError('Kompenzáció záró éve nem lehet kisebb a kezdő évnél!');
      hiba += 1;
    }

    if (hiba === 0 && new Date(this.state.modositoObj.kompenzaciofutamidokezdete).getFullYear() > parseInt(this.state.evHetInsert.kezdoev, 10)) {
      this.props.route.globals.addError('Kompenzáció kezdő év adatnak a kompenzáció futamidején belül kell lennie!');
      hiba += 1;
    }

    if (hiba === 0 && this.state.modositoObj.kompenzaciofutamidovege && new Date(this.state.modositoObj.kompenzaciofutamidovege).getFullYear() < parseInt(this.state.evHetInsert.zaroev, 10)) {
      this.props.route.globals.addError('Kompenzáció záró év adatnak a kompenzáció futamidején belül kell lennie!');
      hiba += 1;
    }

    if (hiba === 0 && (parseFloat(this.state.evHetInsert.het, 10) <= 0 || parseFloat(this.state.evHetInsert.het, 10) % 1 !== 0)) {
      this.props.route.globals.addError('Kompenzációs hetek mennyiségének pozitív egész számnak kell lennie!');
      hiba += 1;
    }

    if (hiba === 0 && parseFloat(this.state.evHetInsert.dij, 10) < 0) {
      this.props.route.globals.addError('Kompenzációs díj csak 0 vagy nagyobb összeg lehet!');
      hiba += 1;
    }

    if (hiba === 0 && this.state.evHetInsert.egyebdij && this.state.evHetInsert.egyebdij.find(x => (!x.koltsegkod && !x.koltsegkodValue) || !x.osszeg || x.alapdijhozadas === null)) {
      this.props.route.globals.addError('Az egyéb díjak költségkódjának, összegének, valamint alapdíjhoz adás tényének megadása soronként kötelező!');
      hiba += 1;
    }

    if (hiba === 0 && this.state.evHetInsert.egyebdij && this.state.evHetInsert.egyebdij.length !== new Set(this.state.evHetInsert.egyebdij.map(x => (x.koltsegkod ? x.koltsegkod : x.koltsegkodValue.label))).size) {
      this.props.route.globals.addError('Az egyéb díjak között minden költségkód legfeljebb egyszer szerepelhet!');
      hiba += 1;
    }

    if (hiba === 0) {
      let evHetArray = [];

      let gyakorisag = this.state.gyakorisagValues.find(x => x.name === this.state.kompenzaciogyakorisag || x.name === this.state.kompenzaciogyakorisag.name);
      let ev = parseInt(this.state.evHetInsert.kezdoev, 10);
      do {
        if ((ev % 2 === 0 && gyakorisag.paros) || (ev % 2 === 1 && gyakorisag.paratlan)) {
          if (ev >= new Date(this.state.modositoObj.kompenzaciofutamidokezdete).getFullYear() && (!this.state.modositoObj.kompenzaciofutamidovege || ev <= new Date(this.state.modositoObj.kompenzaciofutamidovege).getFullYear())) {
            let obj = {};
            obj.ev = ev;
            obj.szamlaviseloid = this.state.evHetInsert.szamlaviseloid;
            obj.szamlaviseloValue = this.state.evHetInsert.szamlaviseloValue;
            obj.het = NumberFormatter.removeSpacesFromString(this.state.evHetInsert.het);
            obj.dij = NumberFormatter.removeSpacesFromString(this.state.evHetInsert.dij);
            obj.igenybevetthet = 0;
            obj.foglalthet = 0;
            obj.torolthet = 0;

            if (this.state.evHetInsert.egyebdij) {
              obj.egyebdij = [];
              this.state.evHetInsert.egyebdij.forEach((item) => {
                let i = {};
                i.koltsegkodValue = item.koltsegkodValue;
                i.osszeg = item.osszeg;
                i.alapdijhozadas = item.alapdijhozadas;

                obj.egyebdij.push(i);
              });
            }

            evHetArray.push(obj);
          }
        }
        ev += 1;
      } while (ev <= parseInt(this.state.evHetInsert.zaroev, 10));

      this.setState({ evekEsHetek: evHetArray });
    }
  }

  calculateKompenzacioDijOneThree = () => {
    if (!this.state.modositoObj.kompenzaciohetmennyiseg || !this.state.modositoObj.kompenzaciodij || !this.state.modositoObj.penznemid) {
      return '';
    }

    let decDigit = this.state.tizedesjegy ? this.state.tizedesjegy : 0;
    const decMult = decDigit === 0 ? 1 : decDigit;
    let sumEgyebDij = 0;

    if (this.state.egyebdij) {
      this.state.egyebdij.forEach((item) => { sumEgyebDij += item.alapdijhozadas && item.osszeg
        ? Math.round(parseFloat(NumberFormatter.removeSpacesFromString(this.state.modositoObj.kompenzaciohetmennyiseg), 10) * parseFloat(NumberFormatter.removeSpacesFromString(item.osszeg), 10) * (10 ** decMult)) / (10 ** decMult)
        : 0; });
    }


    return (Math.round((sumEgyebDij + parseFloat(NumberFormatter.removeSpacesFromString(this.state.modositoObj.kompenzaciohetmennyiseg), 10) * parseFloat(NumberFormatter.removeSpacesFromString(this.state.modositoObj.kompenzaciodij))) * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit);
  }

  calculateKompenzacioHetDijTwo = () => {
    if (!this.state.evekEsHetek || this.state.evekEsHetek.length === 0 || !this.state.modositoObj.penznemid) {
      return ['', ''];
    }

    let decDigit = this.state.tizedesjegy ? this.state.tizedesjegy : 0;
    const decMult = decDigit === 0 ? 1 : decDigit;
    let sumHet = 0;
    let sumDij = 0;
    let sumEgyebDij = 0;

    this.state.evekEsHetek.forEach((item) => {
      sumHet += item.het ? parseFloat(NumberFormatter.removeSpacesFromString(item.het), 10) : 0;

      if (item.egyebdij) {
        item.egyebdij.forEach((item2) => { sumEgyebDij += item2.alapdijhozadas && item2.osszeg
          ? Math.round(parseFloat(NumberFormatter.removeSpacesFromString(item.het), 10) * parseFloat(NumberFormatter.removeSpacesFromString(item2.osszeg), 10) * (10 ** decMult)) / (10 ** decMult)
          : 0; });
      }
      sumDij += item.het && item.dij ? Math.round((parseFloat(NumberFormatter.removeSpacesFromString(item.het), 10) * parseFloat(NumberFormatter.removeSpacesFromString(item.dij))) * (10 ** decMult)) / (10 ** decMult) : 0;
    });

    return [sumHet, (Math.round((sumEgyebDij + sumDij) * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit)];
  }

  addJogtulajdonosRow = () => {
    let newJogtulajdonosok = this.state.jogtulajdonosok;
    newJogtulajdonosok.push({
      partnerTortenetId: '',
      kezdete: null,
      vege: null,
      fotulajdonos: false,
      elhunyt: false,
      eladta: false,
      rcitag: false,
      reszvenyMegjelen: false,
    });
    this.setState({ jogtulajdonosok: newJogtulajdonosok });
  }

  addKompenzacioJogtulajdonosRow = () => {
    let newKompenzacioJogtulajdonosok = this.state.kompJogtulajdonosok;
    newKompenzacioJogtulajdonosok.push({
      kompenzacios: true,
      partnerTortenetId: '',
      elhunyt: false,
      rcitag: false,
      eladta: false,
      kezdete: null,
      vege: null,
      fotulajdonos: false,
      added: true,
    });
    this.setState({ kompJogtulajdonosok: newKompenzacioJogtulajdonosok });
  }

  addEvekesHetekRow = () => {
    let newevekHetek = this.state.evekEsHetek;
    newevekHetek.push({
      foglalt: 0,
      het: '',
      igenybevett: 0,
      ev: '',
      torolt: 0
    });
    this.setState({ evekEsHetek: newevekHetek, vanujEvEsHet: true });
  }

  deleteEvekEsHetekRow = (idx) => {
    let newState = this.state.evekEsHetek;
    newState.splice(idx, 1);
    this.setState({
      evekEsHetek: newState,
    });
  }

  addEgyebDijOneThree = () => {
    let egyebdij = JSON.parse(JSON.stringify(this.state.egyebdij));
    egyebdij.push({
      koltsegkod: '',
      osszeg: '',
      alapdijhozadas: false
    });
    this.setState({ egyebdij: egyebdij });
  }

  addEgyebDijTwoInsert = () => {
    let newEvHetInsert = this.state.evHetInsert ? this.state.evHetInsert : {};
    newEvHetInsert.egyebdij = newEvHetInsert.egyebdij ? newEvHetInsert.egyebdij : [];
    newEvHetInsert.egyebdij.push({
      koltsegkod: '',
      osszeg: '',
      alapdijhozadas: false
    });
    this.setState({ evHetInsert: newEvHetInsert });
  }

  addEgyebDijTwo = (idx) => {
    let newEvekEsHetek = this.state.evekEsHetek ? this.state.evekEsHetek : {};
    let newEvekEsHetekIdx = newEvekEsHetek[idx] ? newEvekEsHetek[idx] : {};
    let newEvekEsHetekIdxEgyebDij = newEvekEsHetekIdx.egyebdij ? newEvekEsHetekIdx.egyebdij : [];
    newEvekEsHetekIdxEgyebDij.push({
      koltsegkod: '',
      osszeg: '',
      alapdijhozadas: false
    });
    newEvekEsHetekIdx.egyebdij = newEvekEsHetekIdxEgyebDij;
    newEvekEsHetek[idx] = newEvekEsHetekIdx;

    this.setState({ evekEsHetek: newEvekEsHetek });
  }

  addReszvenyekRow = () => {
    let newReszvenyek = JSON.parse(JSON.stringify(this.state.reszvenyek));
    newReszvenyek.push({
      fajta: '0',
      darabszam: 0,
      bekerulesiertek: 0,
      nevertek: null,
      ertekpapszamla: '0',
      udulesijogtulajdonosentities: { id: 0 },
    });
    this.setState({ reszvenyek: newReszvenyek, vanujReszveny: true });
  }

  getSzezonSzin = () => {
    // Aktív épületek.
    Services.getSzezonSzin(this.state.apartmanValue, this.state.hetValue, (err, res) => {
      if (!err) {
        if (res[0] && res[0].hetszine) this.setState({ hetszine: res[0].hetszine });
      }
    });
  }

  renderSzallodaOptions = () => {
    // Aktív szállodák.
    Services.listSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  renderEpuletekOptionsById = (id) => {
    // Aktív épületek.
    Services.listEpuletekById(id, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek, epuletHasValue: true });
      }
    });
  }

  toggleTorles = () => {
    this.setState({
      modalTorles: !this.state.modalTorles
    });
  }


  toggleKuldes = () => {
    this.setState({
      modalKuldes: !this.state.modalKuldes
    });
  }

  renderApartmanokOptionsById = (id) => {
    // Aktív épületek.
    Services.listApartmanokById(id, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + (e.kategoria ? (' (' + e.kategoria + ')') : '')}</option>);
        this.setState({ aktivApartmanok: aktivApartmanok, apartmanHasValue: true, hetHasValue: true });
      }
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  mentesModal = (event, submitObj) => {
    this._submitObj = submitObj;
    if (this.state.currentModositoId) {
      this.toggleKuldes();
    } else {
      this.mentes(event, submitObj, false, false);
    }
  }

  emptyOrNull = (data) => {
    return !data || data === '' ? null : data;
  }

  mentes = (event, udulesijogObj, hwAtadas, lezaras) => {
    let submitObj = udulesijogObj;
    submitObj.dijnetto = NumberFormatter.removeSpacesFromString(submitObj.dijnetto);
    submitObj.letszam = NumberFormatter.removeSpacesFromString(submitObj.letszam);
    submitObj.kkamat = NumberFormatter.removeSpacesFromString(submitObj.kkamat);
    submitObj.dij = NumberFormatter.removeSpacesFromString(submitObj.dij);
    submitObj.kompenzaciodij = NumberFormatter.removeSpacesFromString(submitObj.kompenzaciodij);
    submitObj.kompenzacioszallodaentities = this.state.szallodaValues;

    submitObj.gyakorisag = this.emptyOrNull(submitObj.gyakorisag);
    submitObj.nyelv = this.emptyOrNull(submitObj.nyelv);

    // if (!submitObj.kezdete) {
    //   this.props.route.globals.addError('Az érvényesség kezdetének megadása kötelező!');
    // }
    // if (!this.state.szallitoValue.id) {
    //   this.props.route.globals.addError('Számlakibocsátó választása kötelező!');
    // }
    if (this.state.modositoStatus) {
      if (this.isTypeOneThree()) {
        let egyebDijArray = [];
        for (let [key, value] of Object.entries(JSON.parse(JSON.stringify(this.state.egyebdij)))) {
          let obj = value;
          obj.id = value.id;
          obj.koltsegkodid = value.koltsegkodValue && value.koltsegkodValue.value;
          obj.koltsegkod = value.koltsegkodValue && value.koltsegkodValue.label;
          obj.osszeg = NumberFormatter.removeSpacesFromString(value.osszeg);
          obj.alapdijhozadas = value.alapdijhozadas;
          egyebDijArray.push(obj);
        }
        submitObj.egyebdij = egyebDijArray;
        submitObj.kompenzacioszamlaviseloid = this.state.kompSzallitoValue && this.state.kompSzallitoValue.id && this.state.kompSzallitoValue.id;
      }

      if (this.isTypeTwo()) {
        submitObj.kompenzaciogyakorisag = this.state.kompenzaciogyakorisag;
        if (this.state.evekEsHetek.length === 0 && this.state.evHetInsert) { // 2es típus, felvitelkor
          let evHetArray = [];
          // delete this.state.evHetInsert.szamlaviseloValue;

          let obj = this.state.evHetInsert;
          obj.dij = NumberFormatter.removeSpacesFromString(obj.dij);

          if (this.state.evHetInsert.egyebdij) {
            let egyebDijArray = [];
            for (let [key, value] of Object.entries(JSON.parse(JSON.stringify(this.state.evHetInsert.egyebdij)))) {
              let objDij = value;
              objDij.id = value.id;
              objDij.koltsegkodid = value.koltsegkodValue && value.koltsegkodValue.value;
              objDij.koltsegkod = value.koltsegkodValue && value.koltsegkodValue.label;
              objDij.osszeg = NumberFormatter.removeSpacesFromString(value.osszeg);
              objDij.alapdijhozadas = value.alapdijhozadas;
              egyebDijArray.push(objDij);
            }
            obj.egyebdij = egyebDijArray;
          }

          evHetArray.push(obj);
          submitObj.evhet = evHetArray.length > 0 ? evHetArray : null;
        } else if (this.state.evekEsHetek.length !== 0) { // 2es típus, módosításkor
          let evHetArray = [];

          this.state.evekEsHetek.forEach((item) => {
            let evhet = {};
            evhet.id = item.id;
            evhet.ev = item.ev;
            evhet.szamlaviseloid = item.szamlaviseloid;
            evhet.het = item.het;
            evhet.dij = NumberFormatter.removeSpacesFromString(item.dij);

            if (item.egyebdij) {
              let egyebDijArray2 = [];
              for (let [key, value] of Object.entries(JSON.parse(JSON.stringify(item.egyebdij)))) {
                let obj = value;
                obj.id = value.id;
                obj.koltsegkodid = value.koltsegkodValue && value.koltsegkodValue.value;
                obj.koltsegkod = value.koltsegkodValue && value.koltsegkodValue.label;
                obj.osszeg = NumberFormatter.removeSpacesFromString(value.osszeg);
                obj.alapdijhozadas = value.alapdijhozadas;
                egyebDijArray2.push(obj);
              }

              evhet.egyebdij = egyebDijArray2;
            }
            evHetArray.push(evhet);
          });
          submitObj.evhet = evHetArray.length > 0 ? evHetArray : null;
        }
      }

      if (submitObj.reszlet === undefined) submitObj.reszlet = false;
      // submitObj.kompenzaciogyakorisag = 'MINDKETTO';
      submitObj.udulesijogtulajdonosentities = JSON.parse(JSON.stringify(this.state.jogtulajdonosok));
      submitObj.udulesijogstatuszentites = this.state.statuszok;
      submitObj.utolsoHwAtadas = submitObj.utolsoHwAtadas ? moment(submitObj.utolsoHwAtadas).format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
      submitObj.hostwareAtadas = hwAtadas;
      let tulajdonosbean = [];
      this.state.kompJogtulajdonosok.forEach((item) => {
        tulajdonosbean.push({
          cim: item.cim,
          id: item.id,
          nev: item.nev,
          p: item.p,
          fotulajdonos: item.fotulajdonos ? item.fotulajdonos : false,
          elhunyt: item.elhunyt ? item.elhunyt : false,
          eladta: false,
          kezdete: item.kezdete,
          vege: item.vege
        });
      });

      submitObj.id = this.state.currentModositoId;

      if (this.state.szallitoValue.id) {
        submitObj.szamlazoid = this.state.szallitoValue.id;
      } else {
        submitObj.szamlazoid = null;
      }
      for (let i = 0; i < submitObj.udulesijogtulajdonosentities.length; i += 1) {
        if (submitObj.udulesijogtulajdonosentities[i].rcitipus && submitObj.udulesijogtulajdonosentities[i].rcitipus.name) {
          submitObj.udulesijogtulajdonosentities[i].rcitipus = submitObj.udulesijogtulajdonosentities[i].rcitipus.name;
        }

        if (!submitObj.udulesijogtulajdonosentities[i].rcitag) {
          submitObj.udulesijogtulajdonosentities[i].rcitag = false;
        }

        if (submitObj.udulesijogtulajdonosentities[i].rcistatusz && submitObj.udulesijogtulajdonosentities[i].rcistatusz.name) {
          submitObj.udulesijogtulajdonosentities[i].rcistatusz = submitObj.udulesijogtulajdonosentities[i].rcistatusz.name;
        }
      }

      submitObj.kompenzaciostatusz = this.state.kompenzaciosUdulesijogStatusz && this.state.kompenzaciosUdulesijogStatusz.name;

      submitObj.udulesijogtulajdonosentities.forEach((item) => {
        item.kompenzacios = false;
      });
      let komptulaj = [];
      tulajdonosbean.forEach((item) => {
        item.p = 'NevCimAzonAdoNevo';
        komptulaj.push({
          tulajdonosbean: item,
          partnerTortenetId: item.id,
          kompenzacios: true,
          fotulajdonos: item.fotulajdonos ? item.fotulajdonos : false,
          elhunyt: item.elhunyt ? item.elhunyt : false,
          eladta: false,
          kezdete: item.kezdete,
          vege: item.vege,
        });
      });

      komptulaj.forEach((item) => {
        submitObj.udulesijogtulajdonosentities.push(item);
      });

      if (submitObj.kompenzaciotipus && submitObj.kompenzaciotipus.name) {
        submitObj.kompenzaciotipus = submitObj.kompenzaciotipus.name;
      }

      submitObj.udulesijogtulajdonosentities.forEach((jogtulaj, idx) => {
        jogtulaj.reszvenyEntities = [];
        this.state.osszesReszveny.forEach((reszveny, idx2) => {
          if (jogtulaj && reszveny.udulesijogtulajdonosentities && jogtulaj.id == reszveny.udulesijogtulajdonosentities.id) {
            jogtulaj.reszvenyEntities.push(reszveny);
          }
        });
      });

      submitObj.szerzodesid = this.state.formSzerzodesId;
      this.emptyToNull(submitObj);
      // if (updatementes) {
      if (lezaras) {
        Services.updateUdulesijogkompenzaciostatuszLezar(submitObj, (err, res) => {
          if (!err) {
            this.setState({ kompenzaciosUdulesijogStatusz: res[0].kompenzaciostatusz }, () => {
              this.toggleKuldes();
              this.navToList();
            });
            this.props.route.globals.addSuccess('Sikeres üdülési jog lezárás!');
          }
        });
      } else {
        Services.updateUdulesijog(submitObj, (err, res) => {
          if (!err) {
            this.props.route.globals.addSuccess('Üdülési jog módosítva!');
            this.navToList();
            this.toggleKuldes();
          }
        });
      }
      // }
    } else {
      if (submitObj.reszlet === undefined) submitObj.reszlet = false;
      submitObj.azonosito = '';
      submitObj.szerzodesid = this.state.currentSzerzodesId;
      submitObj.udulesijogtulajdonosentities = this.state.jogtulajdonosok;
      submitObj.szamlazoid = this.state.szallitoValue.id;
      Services.createUdulesijog(submitObj, (err, res) => {
        if (!err) {
          this.props.route.globals.addSuccess('Üdülési jog felvéve!');
          this.navToList();
          this.toggleKuldes();
        }
      });
    }
  }

  idFilter = (object) => {
    return object.udulesiJogTulajdonos.id !== this.state.vevoValue[this.state.reszvenyJogtulaj].jogid;
  }

  mentesReszveny = () => {
    let osszesReszveny = [];
    this.state.reszvenyek.forEach((reszvenyek) => {
      if (reszvenyek.darabszam != 0 || reszvenyek.bekerulesiertek !== 0) {
        reszvenyek.udulesiJogTulajdonos = { id: this.state.vevoValue[this.state.reszvenyJogtulaj].jogid };
        reszvenyek.udulesijogtulajdonosentities = { id: this.state.vevoValue[this.state.reszvenyJogtulaj].jogid };
        reszvenyek.bekerulesiertek = parseInt(reszvenyek.bekerulesiertek, 10);
        reszvenyek.darabszam = parseInt(reszvenyek.darabszam, 10);
        reszvenyek.ertekpapszamla = reszvenyek.ertekpapszamla;
        reszvenyek.rid = osszesReszveny.length;
      }
      osszesReszveny.push(reszvenyek);
    });

    let osszesReszvenyWithoutCurrent = JSON.parse(JSON.stringify(this.state.osszesReszveny)).filter(this.idFilter);
    let result = osszesReszvenyWithoutCurrent.concat(osszesReszveny);
    this.setState({ osszesReszveny: result });
    this.toggleReszveny();
  }

  navToList = () => {
    browserHistory.push({
      pathname: '/szerzodes',
      state: {
        type: 'UDULJ',
        kompenzacioszamlaviseloid: null,
        jogosultid: null,
        szallodaid: this.props.location && this.props.location.state && this.props.location.state.szallodaid,
        epuletid: this.props.location && this.props.location.state && this.props.location.state.epuletid,
        partner: this.props.location && this.props.location.state && this.props.location.state.partner,
        azonosito: this.props.location && this.props.location.state && this.props.location.state.azonosito,
      }
    });
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderPenzugyiForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Pénzügyi adatok:</h5></div>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="dijev">Kezdő év: *</Label>
            <AvInput
              name="dijev"
              id="dijev"
              type="number"
              disabled={this.state.modositoStatus && this.state.modositoObj && ((this.state.modositoObj.dijev && this.state.modositoObj.dijnetto && this.state.modositoObj.dijnetto != 0) || this.state.modositoObj.kompenzaciosszerzodes)}
              value={this.state.modositoObj && this.state.modositoObj.dijev && this.state.modositoObj.dijev.toString()}
              min="1900"
              max="3000"
              style={{ textAlign: 'right' }}
            />
            <AvFeedback>Kötelező szám mező (1900,3000 között)!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <SlaveAvInput
            id="dijnetto"
            name="dijnetto"
            label="Kezdő fenntartási ktg. (nettó): "
            disabled={this.state.modositoStatus && this.state.modositoObj && ((this.state.modositoObj.dijnetto && this.state.modositoObj.dijnetto != 0) || this.state.modositoObj.kompenzaciosszerzodes)}
            value={this.state.modositoObj && this.state.modositoObj.dijnetto && this.state.modositoObj.dijnetto}
            szuroTipus={NumberFormatter.OSSZEG}
            inputGroupAddon={this.state.penznem}
          />
        </div>
        <div className="col-md-6 margin-top-35">
          {/* <AvGroup>
            <Label check inline="true">
              <AvInput type="checkbox" name="szamlazasfelfuggesztve" value={this.state.modositoObj && this.state.modositoObj.szamlazasfelfuggesztve} />
              {'Számlázás felfüggesztve'}
            </Label>
          </AvGroup> */}
        </div>
        <div className="col-md-3">
          <AvField type="select" name="penznemid" id="penznemid" label="Pénznem: *" errorMessage="Kötelező mező!" value={this.state.modositoObj && this.state.modositoObj.penznemid} onChange={this.handlePenznemChange}>
            <option disabled value="">Válassz pénznemet...</option>
            {this.state.aktivPenznem}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField type="select" name="fizmodid" id="fizmodid" label="Fizetési mód: *" errorMessage="Kötelező mező!" value={this.state.modositoObj && this.state.modositoObj.fizmodid}>
            <option disabled value="">Válassz fizetési módot...</option>
            {this.state.aktivFizetesimod}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField type="select" name="nyelv" id="nyelv" label="Számlázás nyelve: *" errorMessage="Kötelező mező!" value={this.state.modositoObj && this.state.modositoObj.nyelv.name}>
            <option disabled value="">Válassz nyelvet...</option>
            {this.state.szamlaNyelv}
          </AvField>
        </div>
        <div className="col-md-3">
          <SlaveAvInput
            id="kkamat"
            name="kkamat"
            label="Késedelmi kamat: *"
            value={this.state.modositoObj && this.state.modositoObj.kkamat && this.state.modositoObj.kkamat}
            szuroTipus={NumberFormatter.OSSZEG}
            inputGroupAddon="%"
          />
        </div>
      </div>
    );
  }

  renderUdulesijogJellemzoForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Üdülési jog jellemzői:</h5></div>
        </div>
        {this.state.modositoStatus && (
          <div className="col-md-2">
            <AvGroup>
              <Label for="azonosito">Üdülési jog azonosítója: </Label>
              <AvInput name="azonosito" id="azonosito" disabled={this.state.modositoStatus} value={this.state.modositoObj && this.state.modositoObj.azonosito} />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        )}
        <div className="col-md-2">
          <Label for="kezdete">Érvényesség kezdete: *</Label>
          <DatePicker
            type="select"
            name="kezdete"
            defaultValue={this.state.modositoObj && this.state.modositoObj.kezdete && new Date(this.state.modositoObj.kezdete)}
          />
        </div>
        <div className="col-md-2">
          <Label for="vege">Érvényesség vége:</Label>
          <DatePicker
            type="select"
            name="vege"
            defaultValue={this.state.modositoObj && this.state.modositoObj.vege && new Date(this.state.modositoObj.vege)}
            onChange={v => this.handleErvenyessegVegeChange(v)}
          />
        </div>
        <div className="col-md-2  margin-top-35">
          <AvGroup>
            <Label check inline="true">
              <AvInput type="checkbox" name="ujrahasznosithato" value={this.state.modositoObj && this.state.modositoObj.ujrahasznosithato} />
              {' Újrahasznosítható hét *'}
            </Label>
          </AvGroup>
        </div>
        <div className="col-md-2 margin-top-35">
          {this.state.modositoStatus && (
            <AvGroup>
              <Label check inline="true">
                <AvInput
                  type="checkbox"
                  name="ellenorzott"
                  value={this.state.modositoObj && this.state.modositoObj.ellenorzott}
                />
                {' Ellenőrzött'}
              </Label>
            </AvGroup>
          )}
        </div>

        {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_HWNAPLO']) && (
          <div className="col-md-2 ">
            <AvGroup>
              <Label for="utolsoHwAtadas">Utolsó HostWare átadás: </Label>
              <AvInput
                name="utolsoHwAtadas"
                id="utolsoHwAtadas"
                value={this.state.modositoObj && this.state.modositoObj.utolsoHwAtadas && moment(this.state.modositoObj.utolsoHwAtadas).format('YYYY-MM-DD HH:mm:ss.SSS')}
              />
            </AvGroup>
          </div>
        )}
        <div className="col-md-4">
          <AvField
            type="select"
            name="szalloda"
            id="szalloda"
            label="Szálloda: *"
            errorMessage="Kötelező mező!"
            onChange={this.onChangeSzalloda}
            disabled={!this.state.aktivSzallodak.length || this.state.modositoStatus}
            value={this.state.modositoObj && this.state.modositoObj.apartmanbean && this.state.modositoObj.apartmanbean.szallodaid}
          >
            <option value="">Válassz szállodát...</option>
            {this.state.aktivSzallodak}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField
            type="select"
            name="epulet"
            id="epulet"
            label="Épület: *"
            errorMessage="Kötelező mező!"
            onChange={this.onChangeEpulet}
            disabled={!this.state.aktivEpuletek.length || this.state.modositoStatus}
            value={this.state.modositoObj && this.state.modositoObj.apartmanbean && this.state.modositoObj.apartmanbean.epuletid}
          >
            <option value="">Válassz épületet...</option>
            {this.state.aktivEpuletek}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField
            type="select"
            name="apartmanid"
            id="apartmanid"
            label="Apartman: *"
            errorMessage="Kötelező mező!"
            onChange={(e) => {
              this.setState({ apartmanHasValue: true, apartmanValue: e.target.value });
            }}
            disabled={!this.state.aktivApartmanok.length || !this.state.aktivEpuletek.length || this.state.modositoStatus}
            value={this.state.modositoObj && this.state.modositoObj.apartmanid}
          >
            <option value="">Válassz apartmant...</option>
            {this.state.aktivApartmanok}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="het"
            id="het"
            label="Hét azonosítója: *"
            errorMessage="Kötelező mező!"
            onChange={(e) => {
              this.setState({ hetHasValue: true, hetValue: e.target.value }, () => {
                this.getSzezonSzin();
              });
            }}
            disabled={!this.state.apartmanHasValue || this.state.modositoStatus}
            value={this.state.modositoObj && this.state.modositoObj.het}
            style={{ paddingRight: '30px' }}
          >
            <option style={{ textAlign: 'right' }} value="">Válassz hetet...</option>
            {this.state.hetek}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="gyakorisag"
            id="gyakorisag"
            label="Páros/páratlan év: *"
            errorMessage="Kötelező mező!"
            onChange={e => this.setState({ reszletCheckBox: e.target.value !== 'MINDKETTO' })}
            disabled={!this.state.hetHasValue || this.state.modositoStatus}
            value={this.state.modositoObj && this.state.modositoObj.gyakorisag && this.state.modositoObj.gyakorisag.name}
          >
            <option value="" disabled>Válassz gyakoriságot...</option>
            {this.state.ev}
          </AvField>
        </div>
        {this.state.reszletCheckBox ? (
          <div className="col-md-2 margin-top-35">
            <AvGroup>
              <Label check for="reszlet">
                <AvInput type="checkbox" name="reszlet" value={this.state.modositoObj && this.state.modositoObj.reszlet} />
                {' Osztott díj *'}
              </Label>
            </AvGroup>
          </div>
        ) : <span /> }
        <div className={this.state.reszletCheckBox ? 'col-md-4' : 'col-md-6'}>
          <AvGroup>
            <Label for="hetszine">Szezon színe: </Label>
            <AvInput name="hetszine" id="hetszine" disabled value={this.state.hetszine && this.state.hetszine} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        {this.state.modositoStatus && (
          <div className="col-md-2">
            <AvGroup style={{ marginTop: '35px' }}>
              <Label check inline="true">
                <AvInput
                  type="checkbox"
                  name="kompenzaciosszerzodes"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.modositoObj && this.state.modositoObj.kompenzaciosszerzodes}
                  disabled={this.state.modositoObj && this.state.modositoObj.kompenzacionemmodosithato}
                />
                {' Kompenzációs szerződés'}
              </Label>
            </AvGroup>
          </div>
        )}
        <div className={this.state.modositoStatus ? 'col-md-10' : 'col-md-12'}>
          <Label for="szamlazoid">Számlakibocsátó: *</Label>
          <Async
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="szamlazoid"
            id="szamlazoid"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeSzallito(v)}
            isClearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadSzallitoChange, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev + ' (' + option.cim + ')' + (option.adoszam ? ' [' + NumberFormatter.addHyphensToString(option.adoszam) + '] ' : '')}
            getOptionValue={option => option.id}
            value={this.state.szallitoValue}
            cache={false}
            autoload={false}
          />
        </div>
        <div className="col-md-12 margin-top-30">
          <AvGroup>
            <Label for="jellemzo">Jellemző:</Label>
            <AvInput className="munkalap-profil-textarea" type="textarea" name="jellemzo" id="jellemzo" disabled={!this.state.hetHasValue} value={this.state.modositoObj && this.state.modositoObj.jellemzo} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    );
  }

  renderJogtulajdonosAdatokForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Jogtulajdonos(ok) adatai:</h5></div>
        </div>
        {this.renderJogtulajdonosok()}
        <div className="col-md-12">
          <Button style={{ marginTop: 4 }} className="pull-left table-new-button" color="success" onClick={() => this.addJogtulajdonosRow()}>Jogtulajdonos hozzáadása</Button>
        </div>
      </div>
    );
  }

  deleteJogtulajdonosModal = (idx) => {
    let vanReszveny = false;
    let resertek = this.state.osszesReszveny;
    if (resertek.length > 0) {
      resertek.forEach((item) => {
        if (this.state.vevoValue[idx] && (item.udulesijogtulajdonosentities.id == this.state.vevoValue[idx].jogid)) {
          vanReszveny = true;
          this._currentDeleteId = idx;
          this._vanReszveny = true;
          this.toggleTorles();
        }
      });
    }

    if (!vanReszveny) {
      // this.deleteJogtulajdonos (idx,vanReszveny);
      this._currentDeleteId = idx;
      this._vanReszveny = false;
      this.toggleTorles();
    }
  }

  deleteJogtulajdonos = (idx, vanReszveny) => {
    let newState = this.state.jogtulajdonosok;
    let newNameState = this.state.vevoValue;
    newState.splice(idx, 1);
    newNameState.splice(idx, 1);
    this.setState({
      jogtulajdonosok: newState,
      vevoValue: newNameState
    });

    // if (vanReszveny == true){
    //  this.toggleTorles();
    // }

    this.toggleTorles();
  }

  deleteKompenzacioJogtulajdonos = (idx) => {
    let newState = this.state.kompJogtulajdonosok;
    newState.splice(idx, 1);
    this.setState({
      kompJogtulajdonosok: newState,
    }, () => {
      if (newState.length === 0) this.setState({ ujJogtualjdonos: false });
    });
  }

  deleteEgyebdijOneThree = (idx) => {
    let deletedArray = this.state.egyebdij;
    deletedArray.splice(idx, 1);
    this.setState({
      egyebdij: deletedArray,
    });
  }

  deleteEgyebdijTwoInsert = (idx) => {
    let newEvHet = this.state.evHetInsert;
    let deletedArray = this.state.evHetInsert.egyebdij;
    deletedArray.splice(idx, 1);
    newEvHet.egyebdij = deletedArray;
    this.setState({ evHetInsert: newEvHet });
  }

  deleteEgyebdijTwo = (idx, idx2) => {
    let newEvHet = this.state.evekEsHetek;
    let newEvHetIdx = this.state.evekEsHetek[idx];
    let deletedArray = this.state.evekEsHetek[idx].egyebdij;
    deletedArray.splice(idx2, 1);
    newEvHetIdx.egyebdij = deletedArray;
    newEvHet[idx] = newEvHetIdx;
    this.setState({ evekEsHetek: newEvHet });
  }

  deleteReszvenyek = (idx) => {
    let newState = this.state.reszvenyek;
    let { osszesReszveny } = this.state;
    if (newState[idx].adatID != null) {
      Services.deleteRszveny(newState[idx].adatID, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Sikeres részvény törlés!');
        } else {
          this.props.route.globals.addError('Sikertelen részvény törlés!');
        }
      });
    }
    osszesReszveny.splice(idx, 1);
    newState.splice(idx, 1);
    this.setState({
      reszvenyek: newState,
      osszesReszveny: osszesReszveny,
    });
  }

  handleJogtulajChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].partnerTortenetId = e.target.value;
    this.setState({ jogtulajdonosok: newState });
  }

  loadVevoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getAktivPartnerByCsoport('UDULESIJOG_TULAJ_FORM', input, null, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            // item.nev += ' - ' + item.azon + ' - (' + item.cim + ') ';
            // if (item.adoszam) {
            // item.nev += ' [' + item.adoszam.slice(0, 8) + '-' + item.adoszam.slice(8, 10) + '-' + item.adoszam.charAt(10) + '] ';
            // }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadSzallitoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listSzallitoVevo('UDULESIJOG_SZAMLAKIBOCSATO_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            // item.nev += ' (' + item.cim + ') ';
            // if (item.adoszam) {
            //   item.nev += ' [' + item.adoszam.slice(0, 8) + '-' + item.adoszam.slice(8, 9) + '-' + item.adoszam.slice(9, 11) + '] ';
            // }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeVevo = (value, idx) => {
    let newVevoValue = JSON.parse(JSON.stringify(this.state.vevoValue));
    let newState = JSON.parse(JSON.stringify(this.state.jogtulajdonosok));
    newVevoValue[idx] = value && value.length !== 0 ? value : '';
    newState[idx].partnerTortenetId = value && value.length !== 0 ? value.id : null;
    this.setState({ vevoValue: newVevoValue, jogtulajdonosok: newState });
  }

  handleKompKezdeteChange = (value, idx) => {
    let newState = this.state.kompJogtulajdonosok;
    newState[idx].kezdete = moment(value).format('YYYY-MM-DD');
    if (newState[idx].kezdete === 'Invalid date') {
      newState[idx].kezdete = null;
    }
    this.setState({ kompJogtulajdonosok: newState });
  }

  handleKompVegeChange = (value, idx) => {
    let newState = this.state.kompJogtulajdonosok;
    newState[idx].vege = moment(value).format('YYYY-MM-DD');
    if (newState[idx].vege === 'Invalid date') {
      newState[idx].vege = null;
    }
    this.setState({ kompJogtulajdonosok: newState });
  }

  handleKezdeteChange = (value, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].kezdete = moment(value).format('YYYY-MM-DD');
    if (newState[idx].kezdete === 'Invalid date') {
      newState[idx].kezdete = null;
    }
    this.setState({ jogtulajdonosok: newState });
  }

  handleVegeChange = (value, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].vege = moment(value).format('YYYY-MM-DD');
    if (newState[idx].vege === 'Invalid date') {
      newState[idx].vege = null;
    }
    this.setState({ jogtulajdonosok: newState });
  }

  handleErvenyessegVegeChange = (value) => {
    let newValue = moment(value).format('YYYY-MM-DD');
    if (newValue === 'Invalid date') {
      newValue = null;
    }
    this.setState({ ervenyessegVege: newValue });
  }

  handlePenznemChange = (e) => {
    let penznem = this.state.penznemValues.find(x => x.id == e.target.value);
    this.setState({
      penznem: penznem && penznem.azonosito,
      tizedesjegy: penznem ? penznem.tizedesjegy : 0,
    });
  }

  handleElhunytChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].elhunyt = !newState[idx].elhunyt;
    this.setState({ jogtulajdonosok: newState });
  }

  handleEladtaChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].eladta = !newState[idx].eladta;
    this.setState({ jogtulajdonosok: newState });
  }

  handleKompElhunytChange = (e, idx) => {
    let newState = JSON.parse(JSON.stringify(this.state.kompJogtulajdonosok));
    newState[idx].elhunyt = !newState[idx].elhunyt;
    this.setState({
      kompJogtulajdonosok: newState,
      // ujJogtualjdonos: this.state.ujJogtualjdonos == true && this.state.kompJogtulajdonosok[idx].eladta==true ? true
      // : this.state.ujJogtualjdonos == false && this.state.kompJogtulajdonosok[idx].eladta==false ? true : false
    });
  }

  handleKompTulajdonosChange = (e, idx) => {
    let newState = JSON.parse(JSON.stringify(this.state.kompJogtulajdonosok));
    newState[idx].fotulajdonos = !newState[idx].fotulajdonos;
    this.setState({
      kompJogtulajdonosok: newState,
      // ujJogtualjdonos: this.state.ujJogtualjdonos == true && this.state.kompJogtulajdonosok[idx].fotulajdonos==true ? true
      // : this.state.ujJogtualjdonos == false && this.state.kompJogtulajdonosok[idx].fotulajdonos==false ? true : false
    });
  }

  handleRCItagChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    if (newState[idx].rcitag) newState[idx].rcistatusz = '';
    newState[idx].rcitag = !newState[idx].rcitag;
    this.setState({ jogtulajdonosok: newState });
  }

  handleRCIstatuszChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].rcistatusz = e.target.value;
    this.setState({ jogtulajdonosok: newState });
  }

  handleRCIazonositoChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].rciazonosito = e.target.value;
    this.setState({ jogtulajdonosok: newState });
  }

  handleRCItipusChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].rcitipus = e.target.value;
    this.setState({ jogtulajdonosok: newState });
  }

  handleRCIWeeksAzonChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].rciweeksazon = e.target.value;
    this.setState({ jogtulajdonosok: newState });
  }

  handleRCIPointsAzonChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].rcipointsazon = e.target.value;
    this.setState({ jogtulajdonosok: newState });
  }

  handleChangeSzallito = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szallitoValue: value });
    } else {
      this.setState({ szallitoValue: '' });
    }
  }

  handleUDJOGstatuszChange = (e) => {
    let modosit = this.state.modositoObj;

    if (e.target.value === 'AKTIV') {
      this.setState({
        // kompenzaciosFeltetlek: false,
        // evekHetek: [],
        // kompJogtulajdonosok: [],
        // futamidoVeg: '',
        // kompSzallitoValue: '',
        // kompenzacioDij: 0,
        // hetekSzamaOsszesen: 0,
        // evekEsHetek: [],
        // ujJogtualjdonos: true,
        vanujReszveny: false,
        // vanujEvEsHet: false,
        // kompenzaciosFeltetlek: false,
      });

      modosit.lezarasoka = { name: '' };
      modosit.lejaratistatusz = { name: '' };
    }

    modosit.statusz = { name: e.target.value };
    this.setState({ modositoObj: modosit });
  }

  handleChangeKompSzallito = (value) => {
    if (value && value.length !== 0) {
      this.setState({ kompSzallitoValue: value });
    } else {
      this.setState({ kompSzallitoValue: '' });
    }
  }

  handleChangeKompenzacioJogtulaj = (value, idx) => {
    let newKompJogtulaj = this.state.kompJogtulajdonosok;

    let newKompJogtulajIdx = newKompJogtulaj[idx];
    newKompJogtulaj[idx] = value && value.length !== 0 ? value : {};
    newKompJogtulaj[idx].kezdete = newKompJogtulajIdx.kezdete;
    newKompJogtulaj[idx].vege = newKompJogtulajIdx.vege;
    newKompJogtulaj[idx].elhunyt = newKompJogtulajIdx.elhunyt;
    newKompJogtulaj[idx].eladta = newKompJogtulajIdx.eladta;
    newKompJogtulaj[idx].added = newKompJogtulajIdx.added;
    newKompJogtulaj[idx].kompenzacios = newKompJogtulajIdx.kompenzacios;
    newKompJogtulaj[idx].partnerTortenetId = newKompJogtulajIdx.partnerTortenetId;
    newKompJogtulaj[idx].rcitag = newKompJogtulajIdx.rcitag;
    newKompJogtulaj[idx].fotulajdonos = newKompJogtulajIdx.fotulajdonos;

    this.setState({ kompJogtulajdonosok: newKompJogtulaj });
  }

  handleInputChange = (e) => {
    let modObj = JSON.parse(JSON.stringify(this.state.modositoObj));
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    modObj[name] = value;
    this.setState({
      modositoObj: modObj
    });
  }

  handleLezarasOka = (e) => {
    let newState = this.state.modositoObj;
    newState.lezarasoka = { name: e.target.value };
    this.setState({ modositoObj: newState });
  }

  loadKompenzacioJogtualjChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getAktivPartnerByCsoport('UDULESIJOG_TULAJ_FORM', input, null, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            // item.nev += ' - ' + item.azonosito + ' - (' + item.cim + ') ';
            // if (item.adoszam) {
            //   item.nev += ' [' + item.adoszam.slice(0, 8) + '-' + item.adoszam.slice(8, 9) + '-' + item.adoszam.slice(9, 11) + '] ';
            // }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  handleKompenzacioHetekszamaOsszesenChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    newState.kompenzaciohetmennyiseg = e.target.value;
    this.setState({ modositoObj: newState });
  }

  handleKompenzacioDijChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    newState.kompenzaciodij = e.target.value;
    this.setState({ modositoObj: newState });
  }

  handleUgyszamChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    newState.kompenzaciougyszam = e.target.value;
    this.setState({ modositoObj: newState });
  }

  handleKompenzacioInfoChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    newState.kompenzacioinformacio = e.target.value;
    this.setState({ modositoObj: newState });
  }

  handleKompenzacioSzallodaChange = (e) => {
    this.setState({ szallodaValues: e });
  }

  handleKompenzacioGyakorisagChange = (e) => {
    this.setState({ kompenzaciogyakorisag: e.target.value });
  }

  handleFutamidoVegChange = (value) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    newState.kompenzaciofutamidovege = moment(value).format('YYYY-MM-DD');

    if (newState.kompenzaciofutamidovege === 'Invalid date') {
      newState.kompenzaciofutamidovege = '';
    } else {
      newState.kompenzaciofutamidovege = moment(value).format('YYYY-MM-DD');
    }

    this.setState({ modositoObj: newState });
  }

  handleFutamidoKezdeteChange = (value) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    newState.kompenzaciofutamidokezdete = moment(value).format('YYYY-MM-DD');

    if (newState.kompenzaciofutamidokezdete === 'Invalid date') {
      newState.kompenzaciofutamidokezdete = '';
    } else {
      newState.kompenzaciofutamidokezdete = moment(value).format('YYYY-MM-DD');
    }
    /* if (this.state.modositoObj.vege && (this.state.modositoObj.vege > newState.kompenzaciofutamidokezdete)) {
      this.props.route.globals.addError('Nem lehet kisebb a futamidő vége, mint az üdülési jog vége!');
      newState.kompenzaciofutamidokezdete = '';
    } else {
      newState.kompenzaciofutamidokezdete = moment(value).format('YYYY-MM-DD');
    } */
    this.setState({ modositoObj: newState });
  }

  handleKompenzaciotipusChange = (e) => {
    let newState = JSON.parse(JSON.stringify(this.state.modositoObj));
    if (newState.kompenzaciotipus != null) {
      newState.kompenzaciotipus.name = e.target.value;
    } else {
      newState.kompenzaciotipus = { name: e.target.value };
    }

    this.setState({ modositoObj: newState, evekEsHetek: [] });
  }

  toggleReszveny = (idx) => {
    this.setState({ vanujReszveny: false });
    if (idx >= 0) {
      if (this.state.vevoValue[idx] != null) {
        this.getReszvenyek(this.state.vevoValue[idx].jogid);
        this.setState({ reszvenyJogtulaj: idx });
      }
    } else {
      this.setState({ modalReszveny: !this.state.modalReszveny, reszvenyek: [] });
    }
  }

  handleFotulajdonosChange = (e, idx) => {
    let newState = this.state.jogtulajdonosok;
    newState[idx].fotulajdonos = !newState[idx].fotulajdonos;
    this.setState({ jogtulajdonosok: newState });
  }

  handlReszvenyBekerulChange = (e, idx) => {
    let newState = this.state.reszvenyek;
    newState[idx].bekerulesiertek = e.target.value;
    this.setState({ reszvenyek: newState });
  }

  handlReszvenyErtekPapChange = (e, idx) => {
    let newState = this.state.reszvenyek;
    newState[idx].ertekpapszamla = e.target.value;
    this.setState({ reszvenyek: newState });
  }

  handlReszvenyDarabChange = (e, idx) => {
    let newState = this.state.reszvenyek;
    newState[idx].darabszam = e.target.value;
    this.setState({ reszvenyek: newState });
  }

  handleReszvenyFajtaChange = (e, idx) => {
    let newState = this.state.reszvenyek;
    newState[idx].fajta = e.target.value;
    if (e.target.value === 'AAO') newState[idx].nevertek = 10000;
    if (e.target.value === 'AAT') newState[idx].nevertek = 10000;
    if (e.target.value === 'AT') newState[idx].nevertek = 10000;
    if (e.target.value === 'PO') newState[idx].nevertek = 100000;
    if (e.target.value === 'TAT') newState[idx].nevertek = 10000;
    this.setState({ reszvenyek: newState });
  }

  handleEvHetInsertKezdoEvChange = (e) => {
    let newState = this.state.evHetInsert ? this.state.evHetInsert : {};
    newState.kezdoev = e.target.value;

    this.setState({ evHetInsert: newState });
  }

  handleEvHetInsertZaroEvChange = (e) => {
    let newState = this.state.evHetInsert ? this.state.evHetInsert : {};
    newState.zaroev = e.target.value;

    this.setState({ evHetInsert: newState });
  }

  handleEvHetInsertSzamlaviseloChange = (value) => {
    let newState = this.state.evHetInsert ? this.state.evHetInsert : {};
    if (value && value.length !== 0) {
      newState.szamlaviseloValue = value;
      newState.szamlaviseloid = value.id;
    } else {
      newState.szamlaviseloValue = '';
      newState.szamlaviseloid = null;
    }

    this.setState({ evHetInsert: newState });
  }

  handleEvHetInsertHetChange = (e) => {
    let newState = this.state.evHetInsert ? this.state.evHetInsert : {};
    newState.het = e.target.value;

    this.setState({ evHetInsert: newState });
  }

  handleEvHetInsertDijChange = (e) => {
    let newState = this.state.evHetInsert ? this.state.evHetInsert : {};
    newState.dij = e.target.value;

    this.setState({ evHetInsert: newState });
  }

  handleEvHetModifyEvChange = (e, idx) => {
    let newState = this.state.evekEsHetek;
    newState[idx].ev = e.target.value;
    this.setState({ evekEsHetek: newState });
  }

  handleEvHetModifySzamlaviseloChange = (value, idx) => {
    let newState = this.state.evekEsHetek;
    let newStateIdx = newState[idx];

    if (value && value.length !== 0) {
      newStateIdx.szamlaviseloValue = value;
      newStateIdx.szamlaviseloid = value.id;
    } else {
      newStateIdx.szamlaviseloValue = '';
      newStateIdx.szamlaviseloid = null;
    }

    newState[idx] = newStateIdx;

    this.setState({ evekEsHetek: newState });
  }

  handleEvHetModifyHetChange = (e, idx) => {
    let newState = this.state.evekEsHetek;
    newState[idx].het = e.target.value;
    this.setState({ evekEsHetek: newState });
  }

  handleEvHetModifyDijChange = (e, idx) => {
    let newState = this.state.evekEsHetek;
    newState[idx].dij = e.target.value;
    this.setState({ evekEsHetek: newState });
  }

  renderJogtulajdonosok = () => {
    let jogtulajdonosok = [];
    this.state.jogtulajdonosok.forEach((item, idx) => {
      jogtulajdonosok.push(
        <span className="col-md-12" key={idx.toString()} style={{ border: '2px dashed #6b6b6b', marginBottom: 4, paddingTop: 8 }}>
          <div className="col-md-12 row">
            <div className="col-md-6">
              <Label for={'vevoid-' + idx}>Jogtulajdonos:</Label>
              <Async
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name={'vevoid-' + idx}
                id={'vevoid-' + idx}
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeVevo(v, idx)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadVevoChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.azon + ' - ' + option.nev + ' - (' + option.cim + ')' + (option.adoszam ? ' [' + NumberFormatter.addHyphensToString(option.adoszam) + '] ' : '')}
                getOptionValue={option => option.id}
                value={this.state.vevoValue[idx]}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-3">
              <Label for={'kezdete-' + idx}>Érvényesség kezdete: *</Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                type="select"
                id={'kezdete-' + idx}
                name={'kezdete-' + idx}
                value={this.state.jogtulajdonosok[idx].kezdete && new Date(this.state.jogtulajdonosok[idx].kezdete)}
                onChange={v => this.handleKezdeteChange(v, idx)}
              />
            </div>
            <div className="col-md-3">
              <Label for={'vege-' + idx}>Érvényesség vége:</Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                type="select"
                id={'vege-' + idx}
                name={'vege-' + idx}
                value={this.state.jogtulajdonosok[idx].vege ? new Date(this.state.jogtulajdonosok[idx].vege) : null}
                onChange={v => this.handleVegeChange(v, idx)}
              />
            </div>
          </div>
          <div className="col-md 12 row">
            <div className="col-md-1">
              <AvGroup style={{ marginTop: '35px' }}>
                <Label check inline="true">
                  <AvInput
                    type="checkbox"
                    name="fotulajdonos"
                    onChange={e => this.handleFotulajdonosChange(e, idx)}
                    value={this.state.jogtulajdonosok[idx].fotulajdonos}
                  />
                  {'  '}
                  Fő
                </Label>
              </AvGroup>
            </div>
            <div className="col-md-1">
              <AvGroup style={{ marginTop: '35px' }}>
                <Label check inline="true">
                  <AvInput
                    type="checkbox"
                    name="elhunyt"
                    onChange={e => this.handleElhunytChange(e, idx)}
                    value={this.state.jogtulajdonosok[idx].elhunyt}
                  />
                  {'  '}
                  Elhunyt
                </Label>
              </AvGroup>
            </div>
            {this.state.modositoObj && (
              <div className="col-md-1">
                <AvGroup style={{ marginTop: '35px' }}>
                  <Label check inline="true">
                    <AvInput
                      type="checkbox"
                      name="eladta"
                      onChange={e => this.handleEladtaChange(e, idx)}
                      value={this.state.jogtulajdonosok[idx].eladta}
                    />
                    {'  '}
                    Eladta
                  </Label>
                </AvGroup>
              </div>
            )}
            <div className="col-md-1">
              <AvGroup style={{ marginTop: '35px' }}>
                <Label check inline="true">
                  <AvInput
                    type="checkbox"
                    name="rcitag"
                    onChange={e => this.handleRCItagChange(e, idx)}
                    value={this.state.modositoObj && this.state.jogtulajdonosok[idx].rcitag}
                  />
                  {'  '}
                  RCI tag
                </Label>
              </AvGroup>
            </div>
            <div className="col-md-2">
              <AvField
                type="select"
                name="rcistatusz"
                id="rcistatusz"
                label="RCI státusz: "
                onChange={e => this.handleRCIstatuszChange(e, idx)}
                value={this.state.modositoObj && this.state.jogtulajdonosok[idx].rcistatusz && this.state.jogtulajdonosok[idx].rcistatusz.name}
              >
                <option value="" disabled>Válassz státuszt...</option>
                <option value="AKTIV">Aktív</option>
                <option value="INAKTIV">Inaktív</option>
              </AvField>
            </div>
            <div className="col-md-2">
              <AvGroup>
                <Label for="rciweeksazon">RCI Weeks azonosító: </Label>
                <AvInput
                  name="rciweeksazon"
                  id="rciweeksazon"
                  onChange={e => this.handleRCIWeeksAzonChange(e, idx)}
                  value={this.state.modositoObj && this.state.jogtulajdonosok[idx].rciweeksazon}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-2">
              <AvGroup>
                <Label for="rcipointsazon">RCI Points azonosító: </Label>
                <AvInput
                  name="rcipointsazon"
                  id="rcipointsazon"
                  onChange={e => this.handleRCIPointsAzonChange(e, idx)}
                  value={this.state.modositoObj && this.state.jogtulajdonosok[idx].rcipointsazon}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-2" style={{ marginBottom: 8 }}>
              {this.state.modositoObj && this.state.modositoObj.statusz.name === 'AKTIV' && this.state.jogtulajdonosok[idx].id != null && (
                <Button color="primary" style={{ marginTop: '25px' }} type="button" onClick={() => this.toggleReszveny(idx)}>Részvények</Button>
              )}
              {' '}
              <Button className="pull-right" color="danger" style={{ marginTop: '25px' }} type="button" onClick={() => this.deleteJogtulajdonosModal(idx)}>Törlés</Button>
              {' '}
            </div>
          </div>
        </span>
      );
    });
    return (
      jogtulajdonosok
    );
  }

  renderEvesdijForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Éves díj:</h5></div>
        </div>
        <div className="col-md-2">
          <AvGroup>
            <Label for="evesdijev">Év: </Label>
            <AvInput name="evesdijev" id="evesdijev" value={this.state.modositoObj && this.state.modositoObj.evesdijev} />
          </AvGroup>
        </div>
        <div className="col-md-2">
          <SlaveAvInput
            id="evesdij"
            label="Díj: "
            value={this.state.modositoObj && this.state.modositoObj.evesdij}
            szuroTipus={NumberFormatter.OSSZEG}
          />
        </div>
      </div>
    );
  }

  renderUdulesijogStatuszForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Üdülési jog státusza:</h5></div>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="statusz"
            id="statusz"
            label="Státusz: *"
            errorMessage="Kötelező mező!"
            onChange={e => this.handleUDJOGstatuszChange(e)}
            value={this.state.modositoObj && this.state.modositoObj.statusz && this.state.modositoObj.statusz.name}
          >
            <option value="" disabled>Válassz státuszt...</option>
            {this.state.statusz}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="lejaratistatusz"
            id="lejaratistatusz"
            label="Lejárati státusz: "
            errorMessage="Kötelező mező!"
            value={this.state.modositoObj && this.state.modositoObj.lejaratistatusz && this.state.modositoObj.lejaratistatusz.name}
            onChange={this.handleInputChange}
          >
            <option value="">Válassz lejárati státuszt...</option>
            {this.state.lejaratistatuszOptions}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="lezarasoka"
            id="lezarasoka"
            label="Lezárás oka: "
            errorMessage="Kötelező mező!"
            onChange={e => this.handleLezarasOka(e)}
            value={this.state.modositoObj && this.state.modositoObj.lezarasoka && this.state.modositoObj.lezarasoka.name}
            disabled={this.state.modositoObj && this.state.modositoObj.statusz && this.state.modositoObj.statusz.name && this.state.modositoObj.statusz.name !== 'LEZART'}
          >
            <option value="">Válassz okot...</option>
            {this.state.lezarasOk}
          </AvField>
        </div>
      </div>
    );
  }

  renderKompenzacioJogtulajdonosok = () => {
    let kompJogtulajdonosok = [];
    if (this.state.kompJogtulajdonosok) {
      this.state.kompJogtulajdonosok.forEach((item, idx) => {
        kompJogtulajdonosok.push(
          <div
            className="col-md-12 row"
            key={idx.toString()}
            style={{
              border: '2px dashed #6b6b6b',
              margin: 0,
              paddingTop: 8,
              paddingBottom: 8,
              marginBottom: 4
            }}
          >
            <div className="col-md-4">
              <Label for={'vevoid-' + idx}>Jogtulajdonos:</Label>
              <Async
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name={'vevoid-' + idx}
                id={'vevoid-' + idx}
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeKompenzacioJogtulaj(v, idx)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadKompenzacioJogtualjChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.azon && option.nev && option.cim && (option.azon + ' - ' + option.nev + ' - (' + option.cim + ')' + (option.adoszam ? ' [' + NumberFormatter.addHyphensToString(option.adoszam) + '] ' : ''))}
                getOptionValue={option => option.id}
                value={this.state.kompJogtulajdonosok[idx]}
                cache={false}
                autoload={false}
                isDisabled={!this.state.kompJogtulajdonosok[idx].added && this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && (this.state.modositoObj.kompenzaciostatusz.name === 'LEZART' || this.state.modositoObj.kompenzacionemmodosithato)}
              />
            </div>
            <div className="col-md-2">
              <Label for={'kezdete-' + idx}>Érvényesség kezdete:</Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                type="select"
                id={'kezdete-' + idx}
                name={'kezdete-' + idx}

                value={this.state.kompJogtulajdonosok[idx].kezdete && new Date(this.state.kompJogtulajdonosok[idx].kezdete)}
                onChange={v => this.handleKompKezdeteChange(v, idx)}
                disabled={!this.state.kompJogtulajdonosok[idx].added && this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && (this.state.modositoObj.kompenzaciostatusz.name === 'LEZART' || this.state.modositoObj.kompenzacionemmodosithato)}
              />
            </div>
            <div className="col-md-2">
              <Label for={'vege-' + idx}>Érvényesség vége:</Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                type="select"
                id={'vege-' + idx}
                name={'vege-' + idx}

                value={this.state.kompJogtulajdonosok[idx].vege && new Date(this.state.kompJogtulajdonosok[idx].vege)}
                onChange={v => this.handleKompVegeChange(v, idx)}
                disabled={!this.state.kompJogtulajdonosok[idx].added && this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              />
            </div>
            <div className="col-md-1">
              <AvGroup style={{ marginTop: '35px' }}>
                <Label check inline="true">
                  <AvInput
                    type="checkbox"
                    name={'fotulajdonos' + idx}
                    onChange={e => this.handleKompTulajdonosChange(e, idx)}
                    value={this.state.kompJogtulajdonosok[idx].fotulajdonos}
                    disabled={!this.state.kompJogtulajdonosok[idx].added && this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && (this.state.modositoObj.kompenzaciostatusz.name === 'LEZART' || this.state.modositoObj.kompenzacionemmodosithato)}
                  />
                  {' '}
                  Fő
                </Label>
              </AvGroup>
            </div>
            <div className="col-md-1">
              <AvGroup style={{ marginTop: '35px' }}>
                <Label check inline="true">
                  <AvInput
                    type="checkbox"
                    name={'elhunyt' + idx}
                    onChange={e => this.handleKompElhunytChange(e, idx)}
                    value={this.state.kompJogtulajdonosok[idx].elhunyt}
                    disabled={!this.state.kompJogtulajdonosok[idx].added && this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
                  />
                  {' '}
                  Elhunyt
                </Label>
              </AvGroup>
            </div>
            {this.state.modositoObj && !this.state.modositoObj.kompenzacionemmodosithato && (
              <div className="col-md-1">
                <Button
                  color="danger"
                  className="pull-right"
                  disabled={!this.state.kompJogtulajdonosok[idx].added && this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                  style={{ marginTop: '29px' }}
                  type="button"
                  onClick={() => this.deleteKompenzacioJogtulajdonos(idx)}
                >
                  Törlés
                </Button>
              </div>
            )}
          </div>
        );
      });
    }

    return kompJogtulajdonosok;
  }

  renderEgyebDijOneThree = () => {
    let egyebdij = [];
    this.state.egyebdij.forEach((item, idx) => {
      egyebdij.push(
        <div className="col-md-12 row" key={idx.toString()} style={{ marginLeft: '5px' }}>
          <div className="col-md-3">
            {idx === 0 && <Label for={'koltsegkod' + idx}>Költségkód:</Label>}
            <CreatableSelect
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name={'koltsegkod' + idx}
              id={'koltsegkod' + idx}
              isClearable
              placeholder="Kezdjen el gépelni..."
              formatCreateLabel={this.formatCreateLabel}
              onChange={v => this.handleChangeKoltsegKodOneThree(v, idx)}
              onCreateOption={v => this.handleCreateKoltsegKod(v)}
              isMulti={false}
              options={this.state.koltsegKodOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={this.state.egyebdij && this.state.egyebdij[idx] && this.state.egyebdij[idx].koltsegkodValue}
              cache={false}
              autoload={false}
              isDisabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
          <div className="col-md-3">
            <SlaveAvInput
              name={'osszeg' + idx}
              id={'osszeg' + idx}
              label="Összeg: "
              value={this.state.egyebdij && this.state.egyebdij[idx] && this.state.egyebdij[idx].osszeg}
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
              szuroTipus={NumberFormatter.OSSZEG}
              autoComplete="off"
              onChange={v => this.handleChangeEgyebDijOsszegOneThree(v, idx)}
              emptyStringOnZero
              inputGroupAddon={this.state.penznem}
              hideLabel={idx !== 0}
            />
          </div>
          <div className="col-md-3">
            {idx === 0 && <Label for={'alapdijhozadas' + idx}>Alapdíjhoz hozzáadni?</Label>}
            <AvInput
              type="checkbox"
              name={'alapdijhozadas' + idx}
              id={'alapdijhozadas' + idx}
              onChange={v => this.handleChangeAlapdijhozOneThree(v, idx)}
              checked={this.state.egyebdij && this.state.egyebdij[idx] && this.state.egyebdij[idx].alapdijhozadas}
              value={this.state.egyebdij && this.state.egyebdij[idx] && this.state.egyebdij[idx].alapdijhozadas}
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
              style={{ marginTop: '9px' }}
            />
          </div>
          <div className="col-md-3">
            <Button
              color="danger"
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
              style={{ marginTop: idx === 0 ? '29px' : '0px' }}
              onClick={() => this.deleteEgyebdijOneThree(idx)}
              className="fa fa-times"
            />
            {this.state.egyebdij && this.state.egyebdij.length > 0 && idx === this.state.egyebdij.length - 1 && (
              <React.Fragment>
                {' '}
                <Button
                  color="success"
                  style={{ marginTop: idx === 0 ? '29px' : '0px' }}
                  disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                  onClick={() => this.addEgyebDijOneThree()}
                  className="fa fa-plus"
                />
              </React.Fragment>
            )}
          </div>
        </div>
      );
    });
    return egyebdij;
  }

  renderEgyebDijTwoInsert = () => {
    let egyebdij = [];

    if (!this.state.evHetInsert || !this.state.evHetInsert.egyebdij) {
      return egyebdij;
    }

    this.state.evHetInsert.egyebdij.forEach((item, idx) => {
      egyebdij.push(
        <div className="col-md-12 row" key={idx.toString()} style={{ marginLeft: '5px' }}>
          <div className="col-md-3">
            {idx === 0 && <Label for={'koltsegkod' + idx}>Költségkód:</Label>}
            <CreatableSelect
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name={'koltsegkod' + idx}
              id={'koltsegkod' + idx}
              isClearable
              placeholder="Kezdjen el gépelni..."
              formatCreateLabel={this.formatCreateLabel}
              onChange={v => this.handleChangeKoltsegKodTwoInsert(v, idx)}
              onCreateOption={v => this.handleCreateKoltsegKod(v)}
              isMulti={false}
              options={this.state.koltsegKodOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={this.state.evHetInsert && this.state.evHetInsert.egyebdij && this.state.evHetInsert.egyebdij[idx] && this.state.evHetInsert.egyebdij[idx].koltsegkodValue}
              cache={false}
              autoload={false}
              isDisabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
          <div className="col-md-3">
            <SlaveAvInput
              name={'osszeg' + idx}
              id={'osszeg' + idx}
              label="Összeg: "
              value={this.state.evHetInsert && this.state.evHetInsert.egyebdij && this.state.evHetInsert.egyebdij[idx] && this.state.evHetInsert.egyebdij[idx].osszeg}
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              szuroTipus={NumberFormatter.OSSZEG}
              autoComplete="off"
              onChange={v => this.handleChangeEgyebDijOsszegTwoInsert(v, idx)}
              emptyStringOnZero
              inputGroupAddon={this.state.penznem}
              hideLabel={idx !== 0}
            />
          </div>
          <div className="col-md-3">
            {idx === 0 && <Label for={'alapdijhozadas' + idx}>Alapdíjhoz hozzáadni?</Label>}
            <AvInput
              type="checkbox"
              name={'alapdijhozadas' + idx}
              id={'alapdijhozadas' + idx}
              onChange={e => this.handleChangeAlapdijhozTwoInsert(e, idx)}
              checked={this.state.evHetInsert && this.state.evHetInsert.egyebdij && this.state.evHetInsert.egyebdij[idx] && this.state.evHetInsert.egyebdij[idx].alapdijhozadas}
              value={this.state.evHetInsert && this.state.evHetInsert.egyebdij && this.state.evHetInsert.egyebdij[idx] && this.state.evHetInsert.egyebdij[idx].alapdijhozadas}
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              style={{ marginTop: '9px' }}
            />
          </div>
          <div className="col-md-3">
            <Button
              color="danger"
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              style={{ marginTop: idx === 0 ? '29px' : '0px' }}
              onClick={() => this.deleteEgyebdijTwoInsert(idx)}
              className="fa fa-times"
            />
            {this.state.evHetInsert.egyebdij.length > 0 && idx === this.state.evHetInsert.egyebdij.length - 1 && (
              <React.Fragment>
                {' '}
                <Button
                  color="success"
                  style={{ marginTop: idx === 0 ? '29px' : '0px' }}
                  disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
                  onClick={() => this.addEgyebDijTwoInsert()}
                  className="fa fa-plus"
                />
              </React.Fragment>
            )}
          </div>
        </div>
      );
    });
    return egyebdij;
  }

  renderEgyebDijTwo = (idx) => {
    let egyebdij = [];

    if (!this.state.evekEsHetek || !this.state.evekEsHetek[idx] || !this.state.evekEsHetek[idx].egyebdij) {
      return egyebdij;
    }

    this.state.evekEsHetek[idx].egyebdij.forEach((item, idx2) => {
      egyebdij.push(
        <div className="col-md-12 row" key={idx2.toString()} style={{ marginLeft: '5px' }}>
          <div className="col-md-3">
            {idx2 === 0 && <Label for={'koltsegkod' + idx2}>Költségkód:</Label>}
            <CreatableSelect
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name={'koltsegkod' + idx2}
              id={'koltsegkod' + idx2}
              isClearable
              placeholder="Kezdjen el gépelni..."
              formatCreateLabel={this.formatCreateLabel}
              onChange={v => this.handleChangeKoltsegKodTwo(v, idx, idx2)}
              onCreateOption={v => this.handleCreateKoltsegKod(v)}
              isMulti={false}
              options={this.state.koltsegKodOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={this.state.evekEsHetek && this.state.evekEsHetek[idx] && this.state.evekEsHetek[idx].egyebdij && this.state.evekEsHetek[idx].egyebdij[idx2] && this.state.evekEsHetek[idx].egyebdij[idx2].koltsegkodValue}
              cache={false}
              autoload={false}
              isDisabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
          <div className="col-md-3">
            <SlaveAvInput
              name={'osszeg' + idx2}
              id={'osszeg' + idx2}
              label="Összeg: "
              value={this.state.evekEsHetek && this.state.evekEsHetek[idx] && this.state.evekEsHetek[idx].egyebdij && this.state.evekEsHetek[idx].egyebdij[idx2] && this.state.evekEsHetek[idx].egyebdij[idx2].osszeg}
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
              szuroTipus={NumberFormatter.OSSZEG}
              autoComplete="off"
              onChange={v => this.handleChangeEgyebDijOsszegTwo(v, idx, idx2)}
              emptyStringOnZero
              inputGroupAddon={this.state.penznem}
              hideLabel={idx2 !== 0}
            />
          </div>
          <div className="col-md-3">
            {idx2 === 0 && <Label for={'alapdijhozadas' + idx2}>Alapdíjhoz hozzáadni?</Label>}
            <AvInput
              type="checkbox"
              name={'alapdijhozadas' + idx2}
              id={'alapdijhozadas' + idx2}
              onChange={v => this.handleChangeAlapdijhozTwo(v, idx, idx2)}
              checked={this.state.evekEsHetek && this.state.evekEsHetek[idx] && this.state.evekEsHetek[idx].egyebdij && this.state.evekEsHetek[idx].egyebdij[idx2] && this.state.evekEsHetek[idx].egyebdij[idx2].alapdijhozadas}
              value={this.state.evekEsHetek && this.state.evekEsHetek[idx] && this.state.evekEsHetek[idx].egyebdij && this.state.evekEsHetek[idx].egyebdij[idx2] && this.state.evekEsHetek[idx].egyebdij[idx2].alapdijhozadas}
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
              style={{ marginTop: '9px' }}
            />
          </div>
          <div className="col-md-3">
            <Button
              color="danger"
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
              style={{ marginTop: idx2 === 0 ? '29px' : '0px' }}
              onClick={() => this.deleteEgyebdijTwo(idx, idx2)}
              className="fa fa-times"
            />
            {this.state.evekEsHetek[idx].egyebdij.length > 0 && idx2 === this.state.evekEsHetek[idx].egyebdij.length - 1 && (
              <React.Fragment>
                {' '}
                <Button
                  color="success"
                  style={{ marginTop: idx2 === 0 ? '29px' : '0px' }}
                  disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
                  onClick={() => this.addEgyebDijTwo(idx)}
                  className="fa fa-plus"
                />
              </React.Fragment>
            )}
          </div>
        </div>
      );
    });
    return egyebdij;
  }

  formatCreateLabel = (input) => {
    return (
      <span>
        <i>Hozzáadás: </i>
        {input}
      </span>
    );
  }

  handleCreateKoltsegKod = (koltsegKod) => {
    const { koltsegKodOptions } = this.state;
    this.setState({
      koltsegKodOptions: [...koltsegKodOptions, { value: null, label: koltsegKod }],
    });
    this.props.route.globals.addSuccess('Költségkód sikeresen hozzáadva!');
  }

  handleChangeKoltsegKodOneThree = (koltsegKod, idx) => {
    let newEgyebDij = this.state.egyebdij;
    newEgyebDij[idx].koltsegkodValue = koltsegKod;
    this.setState({ egyebdij: newEgyebDij });
  }

  handleChangeEgyebDijOsszegOneThree = (e, idx) => {
    let newEgyebDij = this.state.egyebdij;
    newEgyebDij[idx].osszeg = e.target.value;
    this.setState({ egyebdij: newEgyebDij });
  }

  handleChangeAlapdijhozOneThree = (e, idx) => {
    let newEgyebDij = this.state.egyebdij;
    newEgyebDij[idx].alapdijhozadas = e.target.checked;
    this.setState({ egyebdij: newEgyebDij });
  }

  handleChangeKoltsegKodTwoInsert = (koltsegKod, idx) => {
    let newEvHet = this.state.evHetInsert;
    let newEgyebDij = newEvHet.egyebdij;
    newEgyebDij[idx].koltsegkodValue = koltsegKod;
    newEvHet.egyebdij = newEgyebDij;
    this.setState({ evHetInsert: newEvHet });
  }

  handleChangeEgyebDijOsszegTwoInsert = (e, idx) => {
    let newEvHet = this.state.evHetInsert;
    let newEgyebDij = newEvHet.egyebdij;
    newEgyebDij[idx].osszeg = e.target.value;
    newEvHet.egyebdij = newEgyebDij;
    this.setState({ evHetInsert: newEvHet });
  }

  handleChangeAlapdijhozTwoInsert = (e, idx) => {
    let newEvHet = this.state.evHetInsert;
    let newEgyebDij = newEvHet.egyebdij;
    newEgyebDij[idx].alapdijhozadas = e.target.checked;
    newEvHet.egyebdij = newEgyebDij;
    this.setState({ evHetInsert: newEvHet });
  }

  handleChangeKoltsegKodTwo = (koltsegKod, idx, idx2) => {
    let newEvHet = this.state.evekEsHetek;
    let newEvHetIdx = newEvHet[idx];
    let newEgyebDij = newEvHetIdx.egyebdij;
    let newEgyebDijIdx = newEvHetIdx.egyebdij[idx2];

    newEgyebDijIdx.koltsegkodValue = koltsegKod;

    newEgyebDij[idx2] = newEgyebDijIdx;
    newEvHetIdx[idx] = newEgyebDij;
    newEvHet.egyebdij = newEvHetIdx;
    this.setState({ evekEsHetek: newEvHet });
  }

  handleChangeEgyebDijOsszegTwo = (e, idx, idx2) => {
    let newEvHet = this.state.evekEsHetek;
    let newEvHetIdx = newEvHet[idx];
    let newEvHetIdxEgyebDij = newEvHetIdx.egyebdij;
    let newEgyebDijIdx = newEvHetIdxEgyebDij[idx2];

    newEgyebDijIdx.osszeg = e.target.value;

    newEvHetIdxEgyebDij[idx2] = newEgyebDijIdx;
    newEvHetIdx[idx] = newEvHetIdxEgyebDij;
    newEvHet.egyebdij = newEvHetIdx;
    this.setState({ evekEsHetek: newEvHet });
  }

  handleChangeAlapdijhozTwo = (e, idx, idx2) => {
    let newEvHet = this.state.evekEsHetek;
    let newEvHetIdx = newEvHet[idx];
    let newEvHetIdxEgyebDij = newEvHetIdx.egyebdij;
    let newEgyebDijIdx = newEvHetIdxEgyebDij[idx2];

    newEgyebDijIdx.alapdijhozadas = e.target.checked;
    newEvHetIdxEgyebDij[idx2] = newEgyebDijIdx;
    newEvHetIdx[idx] = newEvHetIdxEgyebDij;
    newEvHet.egyebdij = newEvHetIdx;
    this.setState({ evekEsHetek: newEvHet });
  }

  rednerEvEsHet = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    if (this.state.evekEsHetek.length === 0) { // 2es eset, felvitel
      return (
        <React.Fragment>
          <div className="col-md-1">
            <SlaveAvInput
              id="kezdoev"
              name="kezdoev"
              label="Kezdő év: *"
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              onChange={e => this.handleEvHetInsertKezdoEvChange(e)}
              value={this.state.evHetInsert && this.state.evHetInsert.kezdoev}
              szuroTipus={NumberFormatter.SZAM}
              maxLength="4"
              autoComplete="off"
            />
          </div>
          <div className="col-md-1">
            <SlaveAvInput
              id="zaroev"
              name="zaroev"
              label="Záró év: *"
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              onChange={e => this.handleEvHetInsertZaroEvChange(e)}
              value={this.state.evHetInsert && this.state.evHetInsert.zaroev}
              szuroTipus={NumberFormatter.SZAM}
              maxLength="4"
              autoComplete="off"
            />
          </div>
          <div className="col-md-5">
            <Label for="szamlaviselo">Kompenzációs számlaviselő: *</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szamlaviselo"
              id="szamlaviselo"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleEvHetInsertSzamlaviseloChange(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzallitoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev + ' (' + option.cim + ')' + (option.adoszam ? ' [' + NumberFormatter.addHyphensToString(option.adoszam) + '] ' : '')}
              getOptionValue={option => option.id}
              value={this.state.evHetInsert && this.state.evHetInsert.szamlaviseloValue}
              isDisabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              cache={false}
              autoload={false}
            />
            <br />
          </div>
          <div className="col-md-2">
            <SlaveAvInput
              id="hetek"
              name="hetek"
              label="Hetek mennyisége: *"
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              onChange={e => this.handleEvHetInsertHetChange(e)}
              value={this.state.evHetInsert && this.state.evHetInsert.het}
              szuroTipus={NumberFormatter.EGESZ_SZAM}
              inputGroupAddon="db"
              autoComplete="off"
            />
          </div>
          <div className="col-md-2">
            <SlaveAvInput
              name="dij"
              id="dij"
              label="Kompenzációs díj/hét: *"
              value={this.state.evHetInsert && this.state.evHetInsert.dij}
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              szuroTipus={NumberFormatter.OSSZEG}
              autoComplete="off"
              onChange={e => this.handleEvHetInsertDijChange(e)}
              emptyStringOnZero
              inputGroupAddon={this.state.penznem}
            />
          </div>
          <div className="col-md-1">
            <Button
              style={{ marginTop: '29px' }}
              className="pull-right table-new-button"
              color="success"
              disabled={this.generalasIsNotVaild()}
              onClick={() => this.generateEvHet()}
            >
              Generálás
            </Button>
          </div>
          <div className="col-md-12 row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <h6 style={titleStyle}>Egyéb díj:</h6>
              {this.renderEgyebDijTwoInsert()}
              {(!this.state.evHetInsert || !this.state.evHetInsert.egyebdij || this.state.evHetInsert.egyebdij.length === 0) && (
                <div className="col-md-12">
                  <Button
                    className="pull-left table-new-button"
                    color="success"
                    disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
                    onClick={() => this.addEgyebDijTwoInsert()}
                  >
                    Egyéb díj hozzáadása
                  </Button>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
    // 2-es eset, módosítás
    let evekEsHetek = [];
    this.state.evekEsHetek.forEach((item, idx) => {
      evekEsHetek.push(
        <div
          className="col-md-12 row"
          key={idx.toString()}
          style={{
            marginLeft: '5px',
            border: '2px dashed #6b6b6b',
            marginBottom: 4,
            paddingTop: 8
          }}
        >
          <div className="col-md-2">
            <AvGroup>
              <Label for="ev">Kompenzáció éve: </Label>
              <AvInput
                name="ev"
                id="ev"
                maxlength="4"
                disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
                onChange={e => this.handleEvHetModifyEvChange(e, idx)}
                value={this.state.evekEsHetek && this.state.evekEsHetek[idx].ev}
                style={{ textAlign: 'right' }}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-5">
            <Label for="szamlaviselo">Kompenzációs számlaviselő:</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szamlaviselo"
              id="szamlaviselo"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleEvHetModifySzamlaviseloChange(v, idx)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzallitoChange, 1000)}
              isLoading={false}
              // getOptionLabel={option => option.nev}
              getOptionLabel={option => option.nev + ' (' + option.cim + ')' + (option.adoszam ? ' [' + NumberFormatter.addHyphensToString(option.adoszam) + '] ' : '')}
              getOptionValue={option => option.id}
              value={this.state.evekEsHetek && this.state.evekEsHetek[idx].szamlaviseloValue}
              isDisabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
              cache={false}
              autoload={false}
            />
            <br />
          </div>
          <div className="col-md-2">
            <SlaveAvInput
              id="hetek"
              name="hetek"
              label="Hetek mennyisége: "
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              onChange={e => this.handleEvHetModifyHetChange(e, idx)}
              value={this.state.evekEsHetek && this.state.evekEsHetek[idx].het}
              szuroTipus={NumberFormatter.EGESZ_SZAM}
              inputGroupAddon="db"
            />
          </div>
          <div className="col-md-2">
            <SlaveAvInput
              name="dij"
              id="dij"
              label="Kompenzációs díj/hét: "
              value={this.state.evekEsHetek && this.state.evekEsHetek[idx].dij}
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
              szuroTipus={NumberFormatter.OSSZEG}
              autoComplete="off"
              onChange={e => this.handleEvHetModifyDijChange(e, idx)}
              emptyStringOnZero
              inputGroupAddon={this.state.penznem}
            />
          </div>
          {this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name !== 'LEZART' && !this.state.evekEsHetek[idx].kompenzacionemmodosithato && (
            <div className="col-md-1" style={{ marginTop: '29px' }}>
              <Button
                className="pull-right fa fa-times"
                color="danger"
                onClick={() => this.deleteEvekEsHetekRow(idx)}
              />
            </div>
          )}
          {this.isKompenzacioFelvittLezart() ? (
            <div className="col-md-4">
              <div>
                <SlaveAvInput
                  name="foglalt"
                  id="foglalt"
                  label="Foglalt hetek: "
                  value={this.state.evekEsHetek && this.state.evekEsHetek[idx].foglalthet && this.state.evekEsHetek[idx].foglalthet.toString()}
                  disabled
                  szuroTipus={NumberFormatter.OSSZEG}
                  autoComplete="off"
                  inputGroupAddon="db"
                />
              </div>
              <div>
                <SlaveAvInput
                  name="igenybevett"
                  id="igenybevett"
                  label="Igénybe vett hetek: "
                  value={this.state.evekEsHetek && this.state.evekEsHetek[idx].igenybevetthet && this.state.evekEsHetek[idx].igenybevetthet.toString()}
                  disabled
                  szuroTipus={NumberFormatter.OSSZEG}
                  autoComplete="off"
                  inputGroupAddon="db"
                />
              </div>
              <div>
                <SlaveAvInput
                  name="torolt"
                  id="torolt"
                  label="Törölt hetek: "
                  value={this.state.evekEsHetek && this.state.evekEsHetek[idx].torolthet && this.state.evekEsHetek[idx].torolthet.toString()}
                  disabled
                  szuroTipus={NumberFormatter.OSSZEG}
                  autoComplete="off"
                  inputGroupAddon="db"
                />
              </div>
            </div>
          ) : (<div className="col-md-4" />)}

          <div className="col-md-8">
            <h6 style={titleStyle}>Egyéb díj:</h6>
            <div className="col-md-12">
              {this.renderEgyebDijTwo(idx)}
              {(!this.state.evekEsHetek || !this.state.evekEsHetek[idx] || !this.state.evekEsHetek[idx].egyebdij || this.state.evekEsHetek[idx].egyebdij.length === 0) && (
                <div className="col-md-12">
                  <Button
                    className="pull-left table-new-button"
                    color="success"
                    disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.evekEsHetek[idx].kompenzacionemmodosithato)}
                    onClick={() => this.addEgyebDijTwo(idx)}
                  >
                    Egyéb díj hozzáadása
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return (evekEsHetek);
  }

  renderKompenzacioAdatokForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    let sum = this.calculateKompenzacioHetDijTwo();

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Kompenzációs feltételek:</h5></div>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="kompenzaciotipus"
            id="kompenzaciotipus"
            label="Kompenzáció típusa: *"
            disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
            onChange={e => this.handleKompenzaciotipusChange(e)}
            value={this.state.modositoObj && this.state.modositoObj.kompenzaciotipus != null ? this.state.modositoObj.kompenzaciotipus.name : ''}
          >
            <option value="">Válassz típust...</option>
            {this.state.komptipus}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="kompenzaciougyszam">Ügyszám: *</Label>
            <AvInput
              maxlength="40"
              name="kompenzaciougyszam"
              id="kompenzaciougyszam"
              value={this.state.modositoObj && this.state.modositoObj.kompenzaciougyszam}
              onChange={v => this.handleUgyszamChange(v)}
              disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {true && (
            <React.Fragment>
              <Label for="kompenzaciofutamidokezdete">Futamidő kezdete: *</Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                type="select"
                name="kompenzaciofutamidokezdete"
                id="kompenzaciofutamidokezdete"
                disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                value={this.state.modositoObj && this.state.modositoObj.kompenzaciofutamidokezdete ? new Date(this.state.modositoObj.kompenzaciofutamidokezdete) : null}
                onChange={v => this.handleFutamidoKezdeteChange(v)}
              />
            </React.Fragment>
          )}
        </div>
        <div className="col-md-3">
          <React.Fragment>
            <Label for="kompenzaciofutamidovege">
              {'Futamidő vége:' + (this.state.modositoObj && this.state.modositoObj.kompenzaciotipus && this.state.modositoObj.kompenzaciotipus.name === 'UNF' ? ' *' : '')}
            </Label>
            <DatePicker
              placeholder="éééé-hh-nn"
              type="select"
              name="kompenzaciofutamidovege"
              id="kompenzaciofutamidovege"
              disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
              value={this.state.modositoObj && this.state.modositoObj.kompenzaciofutamidovege ? new Date(this.state.modositoObj.kompenzaciofutamidovege) : null}
              onChange={v => this.handleFutamidoVegChange(v)}
            />
          </React.Fragment>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="kompenzacioinformacio">Információk:</Label>
            <AvInput
              className="munkalap-profil-textarea"
              type="textarea"
              name="kompenzacioinformacio"
              id="kompenzacioinformacio"
              value={this.state.modositoObj && this.state.modositoObj.kompenzacioinformacio}
              onChange={v => this.handleKompenzacioInfoChange(v)}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-6">
          <Label for="kompenzacioszalloda">Szállodák: </Label>
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="kompenzacioszalloda"
            id="kompenzacioszalloda"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={e => this.handleKompenzacioSzallodaChange(e)}
            options={this.state.kompenzacioSzallodaOptions}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.szallodaid}
            value={this.state.szallodaValues}
            isLoading={false}
            isClearable
            isMulti
            isDisabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
          />
        </div>
        {this.isTypeTwo() && (
          <React.Fragment>
            {this.state.evekEsHetek.length === 0 ? (
              <div className="col-md-3">
                <AvField
                  type="select"
                  name="kompenzaciogyakorisag"
                  id="kompenzaciogyakorisag"
                  label="Gyakoriság: *"
                  errorMessage="Kötelező mező!"
                  onChange={v => this.handleKompenzacioGyakorisagChange(v)}
                  disabled={(this.state.evekEsHetek && this.state.evekEsHetek.length > 0) || (this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato))}
                  value={this.state.kompenzaciogyakorisag}
                >
                  <option value="" disabled>Válassz gyakoriságot...</option>
                  {this.state.ev}
                </AvField>
              </div>
            ) : (
              <React.Fragment>
                <div className="col-md-3">
                  <SlaveAvInput
                    id="kompenzaciohetosszesen"
                    name="kompenzaciohetosszesen"
                    label="Kompenzációs hetek összesen: "
                    disabled
                    value={this.state.modositoObj && sum[0]}
                    szuroTipus={NumberFormatter.OSSZEG}
                    inputGroupAddon="db"
                  />
                </div>
                <div className="col-md-3">
                  <SlaveAvInput
                    id="kompenzaciodijosszesen"
                    name="kompenzaciodijosszesen"
                    label="Kompenzációs díj összesen: "
                    disabled
                    value={this.state.modositoObj && sum[1]}
                    szuroTipus={NumberFormatter.OSSZEG}
                    inputGroupAddon={this.state.penznem}
                  />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {this.isTypeOneThree() && (
          <React.Fragment>
            <div className="col-md-6" style={{ paddingBottom: '13px' }}>
              <Label for="kompszamlazoid">Kompenzációs számlaviselő:</Label>
              <Async
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="kompszamlazoid"
                id="kompszamlazoid"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeKompSzallito(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadSzallitoChange, 1000)}
                isLoading={false}
                // getOptionLabel={option => option.nev}
                getOptionLabel={option => option.nev + ' (' + option.cim + ')' + (option.adoszam ? ' [' + NumberFormatter.addHyphensToString(option.adoszam) + '] ' : '')}
                getOptionValue={option => option.id}
                value={this.state.kompSzallitoValue}
                isDisabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-6" />
            <div className="col-md-2">
              <SlaveAvInput
                id="kompenzaciohetmennyiseg"
                name="kompenzaciohetmennyiseg"
                label="Hetek mennyisége: "
                disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART'}
                onChange={e => this.handleKompenzacioHetekszamaOsszesenChange(e)}
                value={this.state.modositoObj && this.state.modositoObj.kompenzaciohetmennyiseg}
                szuroTipus={NumberFormatter.EGESZ_SZAM}
                inputGroupAddon="db"
              />
            </div>
            <div className="col-md-2">
              <SlaveAvInput
                id="kompenzaciodij"
                name="kompenzaciodij"
                label="Kompenzációs díj/hét: "
                disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                onChange={e => this.handleKompenzacioDijChange(e)}
                value={this.state.modositoObj && this.state.modositoObj.kompenzaciodij}
                szuroTipus={NumberFormatter.OSSZEG}
                inputGroupAddon={this.state.penznem}
              />
            </div>
            <div className="col-md-2">
              <SlaveAvInput
                id="kompenzaciodijosszesen"
                name="kompenzaciodijosszesen"
                label="Kompenzációs díj összesen: "
                disabled
                value={this.state.modositoObj && this.calculateKompenzacioDijOneThree()}
                szuroTipus={NumberFormatter.OSSZEG}
                inputGroupAddon={this.state.penznem}
              />
            </div>
            <div className="col-md-12 row">
              {this.isKompenzacioFelvittLezart() ? (
                <div className="col-md-4">
                  <div>
                    <SlaveAvInput
                      name="foglalt"
                      id="foglalt"
                      label="Foglalt hetek: "
                      value={this.state.modositoObj.foglalthet.toString()}
                      disabled
                      szuroTipus={NumberFormatter.OSSZEG}
                      autoComplete="off"
                      inputGroupAddon="db"
                    />
                  </div>
                  <div>
                    <SlaveAvInput
                      name="igenybevett"
                      id="igenybevett"
                      label="Igénybe vett hetek: "
                      value={this.state.modositoObj.igenybevetthet.toString()}
                      disabled
                      szuroTipus={NumberFormatter.OSSZEG}
                      autoComplete="off"
                      inputGroupAddon="db"
                    />
                  </div>
                  <div>
                    <SlaveAvInput
                      name="torolt"
                      id="torolt"
                      label="Törölt hetek: "
                      value={this.state.modositoObj.torolthet.toString()}
                      disabled
                      szuroTipus={NumberFormatter.OSSZEG}
                      autoComplete="off"
                      inputGroupAddon="db"
                    />
                  </div>
                </div>
              ) : (<div className="col-md-4" />)}

              <div className="col-md-8">
                <h6 style={titleStyle}>Egyéb díj:</h6>
                {this.renderEgyebDijOneThree()}
                {(!this.state.egyebdij || this.state.egyebdij.length === 0) && (
                  <div className="col-md-12">
                    <Button
                      className="pull-left table-new-button"
                      color="success"
                      disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                      onClick={() => this.addEgyebDijOneThree()}
                    >
                      Egyéb díj hozzáadása
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}

        {this.isTypeTwo() && (
          <React.Fragment>
            <div className="col-md-12">
              <div><h5 style={titleStyle}>Kompenzációs év-hét adatok:</h5></div>
            </div>
            {this.rednerEvEsHet()}
            {this.state.evekEsHetek.length > 0 && (
              <div className="col-md-12">
                <Button
                  className="pull-left table-new-button"
                  color="success"
                  disabled={this.state.modositoObj && ((this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name === 'LEZART') || this.state.modositoObj.kompenzacionemmodosithato)}
                  onClick={() => this.addEvekesHetekRow()}
                >
                  Kompenzációs év-hét hozzáadása
                </Button>
              </div>
            )}
          </React.Fragment>
        )}

        <div className="col-md-12">
          <div><h5 style={titleStyle}>Kompenzációs jogtulajdonos(ok) adatai:</h5></div>
        </div>
        {this.renderKompenzacioJogtulajdonosok()}
        {this.state.modositoObj && (!this.state.kompenzaciostatusz || this.state.modositoObj.kompenzaciostatusz.name !== 'LEZART') && (
          <div className="col-md-12" style={{ marginTop: 6 }}>
            <Button
              className="pull-left table-new-button"
              color="success"
              onClick={() => this.addKompenzacioJogtulajdonosRow()}
            >
              Kompenzációs jogtulajdonos hozzáadása
            </Button>
          </div>
        )}
        <div className="col-md-4">
          <Label for="kompenzaciostatusz">Kompenzáció státusz: </Label>
          <AvInput
            name="kompenzaciostatusz"
            id="kompenzaciostatusz"
            disabled
            value={this.state.kompenzaciosUdulesijogStatusz && this.state.kompenzaciosUdulesijogStatusz.caption}
          />
        </div>
        <div className="col-md-5">
          <Button
            className="margin-top-30"
            type="submit"
            onClick={() => { this.setState({ modalType: 'LEZARAS' }); }}
            disabled={this.state.modositoObj && (this.state.modositoObj.kompenzaciostatusz === null || this.state.modositoObj.kompenzaciostatusz.name === 'LEZART')}
            color="primary"
          >
            Lezárás
          </Button>
        </div>
      </div>
    );
  }

  renderReszveny = () => {
    let reszvenyek = [];
    this.state.reszvenyek.forEach((item, idx) => {
      reszvenyek.push(
        <div className="col-md-12 row" key={idx.toString()} style={{ marginLeft: '5px' }}>
          <div className="col-md-5">
            <AvField
              type="select"
              name={'fajta' + idx}
              id={'fajta' + idx}
              label="Részvény fajták: "
              required
              onChange={e => this.handleReszvenyFajtaChange(e, idx)}
              value={this.state.reszvenyek && this.state.reszvenyek[idx].fajta}
            >
              <option value="0" disabled>Válassz státuszt...</option>
              {this.state.reszvenyfajta}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for={'darabszam' + idx}>Darabszám: </Label>
              <AvInput
                name={'darabszam' + idx}
                max="99999999"
                required
                id={'darabszam' + idx}
                onChange={e => this.handlReszvenyDarabChange(e, idx)}
                value={this.state.reszvenyek && this.state.reszvenyek[idx].darabszam}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-3" />

          <div className="col-md-3">
            <AvGroup>
              <Label for={'bekerulesiertek' + idx}>Bekerülési érték: </Label>
              <AvInput
                required
                name={'bekerulesiertek' + idx}
                max="99999999"
                id={'bekerulesiertek' + idx}
                onChange={e => this.handlReszvenyBekerulChange(e, idx)}
                value={this.state.reszvenyek && this.state.reszvenyek[idx].bekerulesiertek}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="nevertek">Névérték: </Label>
              <AvInput
                required
                name={'nevertek' + idx}
                id={'nevertek' + idx}
              // onChange={e => this.handleRCIPointsAzonChange(e, idx)}
                value={this.state.reszvenyek && this.state.reszvenyek[idx].nevertek}
                disabled
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvField
              type="select"
              name={'ertekpappirszamlanelhejezett' + idx}
              id={'ertekpappirszamlanelhejezett' + idx}
              label="Értékpapírszámlán elhelyezett: "
              required
              onChange={e => this.handlReszvenyErtekPapChange(e, idx)}
              value={this.state.reszvenyek && this.state.reszvenyek[idx].ertekpapszamla.toString()}
            >
              <option value="0" disabled>Válassz értéket...</option>
              <option value="true">Igen</option>
              <option value="false">Nem</option>
            </AvField>
          </div>

          <div className="col-md-1">
            <Button color="danger" style={{ marginTop: '25px' }} type="button" onClick={() => this.deleteReszvenyek(idx)}>Törlés</Button>
            {' '}
          </div>
          <div className="col-md-12">
            <hr style={{ height: '10px', boreder: '10px', margin: '0px' }} />
          </div>
        </div>
      );
    });

    return (
      reszvenyek
    );
  }

  renderFormButtons = () => {
    return (
      <div className="row">
        <div className="col-md-12 margin-top-30">
          <Button color="primary" type="submit" onClick={() => { this.setState({ modalType: 'MENTES' }); }}>Mentés</Button>
          {' '}
          <Button type="button" color="secondary" onClick={this.navToList}>Mégsem</Button>
        </div>
      </div>
    );
  }

  kompenzacioStatuszmodositasLezaras = () => {
    this.mentes(null, this._submitObj, false, true);
  }

  render() {
    let cardStyle = {
      padding: 20
    };
    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {this.state.modositoStatus ? 'Üdülési jog módosítása' : 'Új üdülési jog' }
            </div>
            <div style={cardStyle}>
              <AvForm>
                <Modal
                  isOpen={this.state.modalReszveny}
                  toggle={this.toggleReszveny}
                  className={this.props.className}
                  backdrop="static"
                  size="lg"
                >
                  <ModalHeader>Részvények</ModalHeader>
                  <ModalBody>
                    <div className="row">
                      {this.renderReszveny()}
                    </div>
                    <Button className="pull-left table-new-button" color="success" onClick={() => this.addReszvenyekRow()}>Részvény hozzáadása</Button>
                    {' '}
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="primary"  onClick={this.toggleReszveny} >Mentés</Button>{' '} */}
                    <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.mentesReszveny}>Mentés</Button>
                    <Button type="button" color="secondary" onClick={this.toggleReszveny}>Mégsem</Button>
                  </ModalFooter>
                </Modal>
              </AvForm>

              <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
                <ModalHeader>
                  Törlés
                </ModalHeader>
                <ModalBody>
                  {this._vanReszveny ? 'A törölni kívánt jogtulajdonoshoz tartoznak részvények. Így is törölni kívánja?' : 'Biztosan törölni akarja a jogtulajdonost?' }
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => this.deleteJogtulajdonos(this._currentDeleteId, this._vanReszveny)}>
                    Igen
                  </Button>
                  <Button type="button" color="secondary" onClick={this.toggleTorles}>
                    Nem
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={this.state.modalKuldes} toggle={this.toggleKuldes} className={this.props.className} backdrop="static">
                <ModalHeader toggle={this.toggleKuldes}>
                  {this.state.modalType === 'MENTES' && 'HostWare küldés'}
                  {this.state.modalType === 'LEZARAS' && 'Lezárás'}
                </ModalHeader>
                <ModalBody>
                  {this.state.modalType === 'MENTES' && 'Kívánja hostware számára átadásra állítani az üdülésijogot?'}
                  {this.state.modalType === 'LEZARAS' && 'Kívánja lezárni a kiválasztott tételt?'}
                </ModalBody>
                <ModalFooter>
                  {this.state.modalType === 'MENTES' && (
                    <React.Fragment>
                      <Button color="primary" onClick={() => this.mentes(null, this._submitObj, true, false)}>
                        Igen
                      </Button>
                      <Button type="button" color="secondary" onClick={() => this.mentes(null, this._submitObj, false, false)}>
                        Nem
                      </Button>
                    </React.Fragment>
                  )}
                  {this.state.modalType === 'LEZARAS' && (
                    <Button
                      type="submit"
                      disabled={this.state.modositoObj && this.state.modositoObj.kompenzaciostatusz && this.state.modositoObj.kompenzaciostatusz.name !== 'FELVITT'}
                      color="success"
                      onClick={() => this.mentes(null, this._submitObj, true, true)}
                    >
                      Lezárás
                    </Button>
                  )}
                </ModalFooter>
              </Modal>

              {this.state.dataLoaded ? (
                <AvForm onValidSubmit={(e, o) => this.mentesModal(e, o)}>
                  {this.renderUdulesijogJellemzoForm()}
                  {this.renderPenzugyiForm()}
                  {this.renderJogtulajdonosAdatokForm()}
                  {/* {this.state.modositoStatus ? this.renderEvesdijForm() : <div />} */}
                  {this.state.modositoStatus ? this.renderUdulesijogStatuszForm() : <div />}
                  {(this.state.modositoObj && this.state.modositoObj.kompenzaciosszerzodes) && this.renderKompenzacioAdatokForm()}
                  {this.renderFormButtons()}
                </AvForm>
              ) : this.renderLoader()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UdulesiJogUrlap.propTypes = {
  globals: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  className: PropTypes.string,
};

export default UdulesiJogUrlap;
