import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class InflacioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFelvitel: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      baseJson: [],
      aktivTipusok: [],
      aktivStatuszok: [],
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.updateTable();
  }

  /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
  getEnums = () => {
    Services.listInflacioEnums((err, res) => {
      if (!err) {
        let aktivTipusok = res[0][0].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        let aktivStatuszok = res[0][1].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({
          aktivTipusok: aktivTipusok,
          aktivStatuszok: aktivStatuszok
        });
      }
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  updateTable = () => {
    this.getEnums();
    this.setState({ dataLoaded: false, dataFailed: false });

    Services.listInflacio((err, res) => {
      if (err) {
        this.setState({ dataLoaded: true, dataFailed: true });
      } else {
        this.setState({
          baseJson: res[0],
          dataLoaded: true,
          dataFailed: false
        });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });

    Services.getInflacio(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false });
        res[0].felhasznalva ? res[0].felhasznalva = 'true' : res[0].felhasznalva = 'false';
        _modositoObj = res[0];
        this.toggleFelvitel();
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      ev: '',
      tipus: '',
      mertek: '',
      statusz: ''
    };
  }


  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.clearModositoObj();
    this.toggleFelvitel();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_INFLACIO', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    return cell.name === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  felhasznalvaFormatter = (cell) => {
    return cell ? 'Igen' : 'Nem';
  }

  tipusFormatter = (cell) => {
    return cell.caption;
  }

  addInflacio = (event, inflacioObj) => {
    this.emptyToNull(inflacioObj);
    Services.addInflacio(inflacioObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Infláció felvéve!');
      }
    });
  }

  updateInflacio = (event, inflacioObj) => {
    inflacioObj.id = this._currentEditId;

    Services.editInflacio(inflacioObj, this._currentEditId, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Infláció módosítva!');
      }
    });
  }

  deleteInflacio = (id) => {
    this.toggleTorles();
    Services.deleteInflacio(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Infláció törölve!');
      }
    });
  }

  filterType = (cell) => {
    // just return type for filtering or searching.
    return cell.caption;
  }

  renderTable = () => {
    const pagination = true;
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    return this.state.baseJson.length ? <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options} >
      <TableHeaderColumn
        dataField="ev"
        tdStyle={{ whiteSpace: 'normal' }}
        filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
      >
        Év
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="tipus"
        dataFormat={this.tipusFormatter}
        tdStyle={{ whiteSpace: 'normal' }}
      >
        Típus
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="mertek"
        tdStyle={{ whiteSpace: 'normal' }}
        filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
      >
        Infláció mértéke %
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="felhasznalva"
        dataFormat={this.felhasznalvaFormatter}
        tdStyle={{ whiteSpace: 'normal' }}
      >
        Felhasználva
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="statusz"
        dataFormat={this.statusFormatter}
        dataAlign="center"
      >
        Státusz
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="id"
        isKey
        dataFormat={this.iconFormatter}
        hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_INFLACIO', 'KARB')}
      >
        Műveletek
      </TableHeaderColumn>
    </BootstrapTable> : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_INFLACIO', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>Új infláció</Button>
            </div>
          </div>
        )}
        {(this.state.dataLoaded && !this.state.dataFailed) ? this.renderTable() : (this.state.dataFailed) ? <NetworkEmptyState submitClick={this.updateTable} /> : this.renderLoader()}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteInflacio(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addInflacio : this.updateInflacio}>
            {_muvelet === 0 ? <ModalHeader>Új infláció rögzítése</ModalHeader> : <ModalHeader>Meglévő infláció módosítása</ModalHeader> }
            <ModalBody>
              <AvGroup>
                <Label for="ev">Év: *</Label>
                <AvInput
                  name="ev"
                  id="ev"
                  required
                  type="number"
                  value={_modositoObj && _modositoObj.ev}
                  min="1900"
                  max="3000"
                />
                <AvFeedback>Kötelező szám mező (1900,3000 között)!</AvFeedback>
              </AvGroup>
              <AvField
                type="select"
                name="tipus"
                label="Típus: *"
                errorMessage="Kötelező mező!"
                required
                value={_modositoObj && _modositoObj.tipus && _modositoObj.tipus.name}
              >
                <option value="" >Válassz típust...</option>
                {this.state.aktivTipusok}
              </AvField>
              <AvGroup>
                <Label for="mertek">Infláció mértéke %: *</Label>
                <AvInput
                  name="mertek"
                  id="mertek"
                  required
                  type="number"
                  value={_modositoObj && _modositoObj.mertek}
                  min="0"
                  max="100"
                  step="0.01"
                />
                <AvFeedback>Kötelező szám mező (0,100 között)!</AvFeedback>
              </AvGroup>
              <AvField
                type="select"
                name="statusz"
                label="Státusz: *"
                errorMessage="Kötelező mező!"
                required
                value={_modositoObj && _modositoObj.statusz && _modositoObj.statusz.name}
              >
                <option value="" >Válassz státuszt...</option>
                {this.state.aktivStatuszok}
              </AvField>
              {_muvelet === 1 ? <AvField
                type="select"
                name="felhasznalva"
                label="Felhasználva: *"
                errorMessage="Kötelező mező!"
                required
                disabled
                value={_modositoObj && _modositoObj.felhasznalva}
              >
                <option value="false">Nem</option>
                <option value="true">Igen</option>
              </AvField> : <span /> }
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default InflacioLista;
