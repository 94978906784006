import Lib from '../../../commons/lib';

export default class Service {
  static cikkUrl = window.location.origin + '/api/cikk/';

  // CIKKCSOPORT START

  static listCikkcsoport(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikkcsoport/list',
      args: { requestType: 'cikkcsoport-list' }
    }], fnDone);
  }

  static addCikkcsoport(cikkcsoportObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cikkUrl + 'cikkcsoport',
      args: { requestType: 'cikkcsoport-post' },
      data: cikkcsoportObject
    }], fnDone);
  }

  static editCikkcsoport(cikkcsoportObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cikkUrl + 'cikkcsoport',
      args: { requestType: 'cikkcsoport-put' },
      data: cikkcsoportObject,
      params: { id: id }
    }], fnDone);
  }

  static listEmptyCikkcsoport(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikkcsoport/list/empty',
      args: { requestType: 'cikkcsoport-empty-list' },
      params: { id: id }
    }], fnDone);
  }

  static listLeavesCikkcsoport(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikkcsoport/list/leaves',
      args: { requestType: 'cikkcsoport-list-leaves' }
    }], fnDone);
  }

  static deleteCikkcsoport(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cikkUrl + 'cikkcsoport',
      args: { requestType: 'cikkcsoport-delete' },
      params: { id: id }
    }], fnDone);
  }

  // CIKKCSOPORT END

  // CIKKTÖRZS START

  static listCikk(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk/list',
      args: { requestType: 'cikk-list' }
    }], fnDone);
  }

  static listCikkToChilds(params, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk/list/receptura',
      args: { requestType: 'cikk-childs' },
      params: params
    }], fnDone);
  }

  static getCikkProfil(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'parameter/boolean/1by1',
      args: { requestType: 'parameter-boolean-1by1' },
      params: { azon: 'CIKK_RAKTARON_KIVUL' },
    }], fnDone);
  }

  static addCikk(cikkObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cikkUrl + 'cikk',
      args: { requestType: 'cikk-post' },
      data: cikkObject
    }], fnDone);
  }

  static editCikk(cikkObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cikkUrl + 'cikk',
      args: { requestType: 'cikk-put' },
      data: cikkObject,
      params: { id: id }
    }], fnDone);
  }

  static getCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk',
      args: { requestType: 'cikk-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteCikkAfa(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cikkUrl + 'cikk/afa',
      args: { requestType: 'cikk-afa-delete' },
      params: { id: id }
    }], fnDone);
  }

  static deleteCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cikkUrl + 'cikk',
      args: { requestType: 'cikk-delete' },
      params: { id: id }
    }], fnDone);
  }

  // CIKKTÖRZS END

  // MENNYISÉG START

  static listMennyiseg(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'mennyiseg/list',
      args: { requestType: 'mennyiseg-list'}
    }], fnDone);
  }

  static addMennyiseg(mennyisegObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cikkUrl + 'mennyiseg',
      args: { requestType: 'mennyiseg-post'},
      data: mennyisegObject
    }], fnDone);
  }

  static deleteMennyiseg(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cikkUrl + 'mennyiseg',
      args: { requestType: 'mennyiseg-delete'},
      params: {id: id}
    }], fnDone);
  }

  // MENNYISÉG END


  // ÁFA START

  static listAfa(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'afa/list',
      args: { requestType: 'afa-list' }
    }], fnDone);
  }

  static addAfa(afaObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cikkUrl + 'afa',
      args: { requestType: 'afa-post' },
      data: afaObject
    }], fnDone);
  }

  static editAfa(afaObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cikkUrl + 'afa',
      args: { requestType: 'afa-put' },
      data: afaObject,
      params: { id: id }
    }], fnDone);
  }

  static listAktivAfa(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'afa/list/aktiv',
      args: { requestType: 'afa-list' },
      params: { id: id }
    }], fnDone);
  }

  static deleteAfa(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cikkUrl + 'afa',
      args: { requestType: 'afa-delete' },
      params: { id: id }
    }], fnDone);
  }

  // ÁFA END
}
