import Lib from '../../commons/lib';

export default class Service {
  static munkalapUrl = window.location.origin + '/api/munkalap/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static kartotekUrl = window.location.origin + '/api/kartotek/';

  static cikkUrl = window.location.origin + '/api/cikk/';

  static keszletnyilvUrl = window.location.origin + '/api/keszletnyilv/';

  // MUNKALAP START

  static getMegjegyzes(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'profil/base',
      args: { requestType: 'munkalap-profil-base' }
    }], fnDone);
  }

  static searchPartnerList(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'MUNKALAP_LISTA_ELO',
        text: nev
      }
    }], fnDone);
  }

  static getMunkalapInitFilter(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/list/initfilter',
    }], fnDone);
  }

  static getMunkalapInitFilterUser(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/list/initfilter/user',
    }], fnDone);
  }

  static getMunkalapList(query, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.munkalapUrl + 'munkalap/list',
      data: query
    }], fnDone);
  }

  static getMunkalapInit(munkalapId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/init',
      params: { id: munkalapId }
    }], fnDone);
  }

  static getMunkalap(munkalapId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap',
      params: { id: munkalapId }
    }], fnDone);
  }

  static getMunkalapFiles(munkalapId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/list/file',
      params: { id: munkalapId }
    }], fnDone);
  }

  static getMunkalapPdf(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/pdf',
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      params: {
        id: id,
      },
    }], fnDone);
  }

  static sendEmail(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/email',
      params: {
        id: id,
      },
    }], fnDone);
  }

  static downloadObject(objid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/object',
      acceptType: 'application/octet-stream',
      params: { objid }
    }], fnDone);
  }

  static deleteMunkalap(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.munkalapUrl + 'munkalap',
      params: { id: id }
    }], fnDone);
  }

  static deleteCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.munkalapUrl + 'cikk/delete',
      params: { id }
    }], fnDone);
  }

  static getCikkInit(munkalapId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'cikk/init',
      params: { id: munkalapId }
    }], fnDone);
  }

  static editCikk(obj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.munkalapUrl + 'cikk/modify',
      data: obj
    }], fnDone);
  }

  static saveCikk(id, array, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.munkalapUrl + 'cikk/save',
      params: { id },
      data: array
    }], fnDone);
  }

  static listActiveTelephely(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      params: {
        resultclass: 90,
        aktiv: true
      }
    }], fnDone);
  }

  static listPartner(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static listFeladat(szerviz, text, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/search/specialis',
      params: {
        szerviz,
        text,
        id
      }
    }], fnDone);
  }

  /*
    @RequestParam(name = "oszerviz", required = false) MSClients eredetiSzerviz,
    @RequestParam(required = false) MSClients szerviz,
    @RequestParam(name = "id", required = false) Long szervizId,
    @RequestParam(name = "fid", required = false) Long masodlagosId
  */
  static listFeladatSelect(oszerviz, szerviz, id, fid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/search/specialis/select',
      params: {
        oszerviz,
        szerviz,
        id,
        fid
      }
    }], fnDone);
  }

  static listFeladatSpecialis(szerviz, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/search/specialis/select',
      params: {
        szerviz: szerviz,
        id
      }
    }], fnDone);
  }

  static getVizsgalatok(partnerId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'munkalapspecialis/list',
      params: {
        id: partnerId,
      }
    }], fnDone);
  }

  static saveForm(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.munkalapUrl + 'munkalap/create',
      data: data
    }], fnDone);
  }

  static modifyForm(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.munkalapUrl + 'munkalap/modify',
      data: data
    }], fnDone);
  }

  static startMunkalap(munkalapId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/start',
      params: {
        id: munkalapId,
      }
    }], fnDone);
  }

  static closeMunkalap(munkalapId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.munkalapUrl + 'munkalap/close',
      params: {
        id: munkalapId,
      }
    }], fnDone);
  }

  static queryCikk(param, raktar, ignoretipus, onlyaktiv, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk/query',
      args: { requestType: 'cikk-query' },
      params: {
        param,
        raktar,
        ignoretipus,
        onlyaktiv,
        receptura: false
      }
    }], fnDone);
  }

  static getCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk',
      args: { requestType: 'cikk' },
      params: {
        id
      }
    }], fnDone);
  }

  static getRaktarList(cikkid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletnyilvUrl + 'keszletinfo/list',
      args: { requestType: 'keszletinfo-list' },
      params: {
        cikkek: cikkid,
        raktarak: null,
        keszletinfoSzures: true
      }
    }], fnDone);
  }

  static editFile(id, data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.munkalapUrl + 'munkalap/file',
      args: { requestType: 'munkalap-file-put' },
      params: { id },
      data: data
    }], fnDone);
  }

  // MUNKALAP END
}
