import Lib from '../../../commons/lib';

export default class Service {
  static szemelyUrl = window.location.origin + '/api/szervezet/';

  static helyszinUrl = window.location.origin + '/api/helyszin/';

  // KEYCLOAK START

  static listSzemelyKeycloak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szemelyUrl + 'szemely/getuserslist',
      args: { requestType: 'szemely-list' }
    }], fnDone);
  }

  static keycloakMasolas(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szemelyUrl + 'szemely/ujteteleklist',
      args: { requestType: 'szemely-post' },
      data: data
    }], fnDone);
  }

  // KEYCLOAK END

  // SZEMELY START

  static listSzemely(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szemelyUrl + 'szemely/list',
      args: { requestType: 'szemely-list' }
    }], fnDone);
  }

  static getSzemely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szemelyUrl + 'szemely',
      args: { requestType: 'szemely-list' },
      params: { id: id }
    }], fnDone);
  }

  static addSzemely(szemelyObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szemelyUrl + 'szemely',
      args: { requestType: 'szemely-post' },
      data: szemelyObject
    }], fnDone);
  }

  static editSzemely(szemelyObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szemelyUrl + 'szemely',
      args: { requestType: 'szemely-put' },
      data: szemelyObject
    }], fnDone);
  }

  static deleteSzemely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szemelyUrl + 'szemely',
      args: { requestType: 'szemely-delete' },
      params: { id: id }
    }], fnDone);
  }

  // SZEMELY END

  // HELYSZIN START

  static listHelyszin(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.helyszinUrl + 'helyszin/list',
      args: { requestType: 'helyszin-list-get' },
      params: { kep: false, tipus: 'SAJAT' }
    }], fnDone);
  }

  // HELYSZIN END

  // ALLAPOT START

  static listAllapotok(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szemelyUrl + 'allapot/list',
      args: { requestType: 'allapot-list-get' }
    }], fnDone);
  }

  // ALLAPOT END

  // BEOSZTASOK START

  static listBeosztasok(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szemelyUrl + 'beosztas/list',
      args: { requestType: 'beosztas-list-get' }
    }], fnDone);
  }

  static addBeosztas(ujBeosztas, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szemelyUrl + 'beosztas',
      args: { requestType: 'beosztas-put' },
      data: ujBeosztas
    }], fnDone);
  }

  static delBeosztas(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szemelyUrl + 'beosztas',
      args: { requestType: 'beosztas-delete' },
      params: { id: id }
    }], fnDone);
  }

  // BEOSZTASOK END
}
