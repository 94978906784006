/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import {
  Alert,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import SelectAsync from 'react-select/lib/Async';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { serializer } from '@organw/wysiwyg-editor';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from '../services';
import NumberFormatter from '../../../commons/NumberFormatter';
import Lib from '../../../commons/lib';
import { CustomOption } from '../../../commons/customOption';
import EditorEmail from '../../Email/EditorEmail';
import { defaultValue } from '../../../commons/EditorFunctions';
import SlaveAvInput from '../../../commons/SlaveAvInput';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import getTooltip from '../RendelesTooltips';
import SwitchButton from '../../../commons/SwitchButton';

const RendelesContent = (props) => {
  const { route } = props;

  const EDITOR_INIT = defaultValue();

  const [ugyfel, setUgyfel] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [printUrl, setPrintUrl] = useState();

  const [rendelesJSON, setRendelesJSON] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [tableRendelesStatus, setTableRendelesStatus] = useState('HIDD'); // ['LOAD', 'DATA', 'NETW', 'TABL', 'HIDD']
  const [rendelesModal, setRendelesModal] = useState(false);
  const [rendelesViewModal, setRendelesViewModal] = useState(false);
  const [rendelesDeleteModal, setRendelesDeleteModal] = useState(false);
  const [rendelesAbortModal, setRendelesAbortModal] = useState(false);
  const [ajanlatModal, setAjanlatModal] = useState(false);
  const [cikkModal, setCikkModal] = useState(false);
  const [cikkViewModal, setCikkViewModal] = useState(false);
  const [rendelesSzamlazasModal, setRendelesSzamlazasModal] = useState(false);
  const [penznemOptions, setPenznemOptions] = useState([]);
  const [szallitasiModOptions, setSzallitasiModOptions] = useState([]);
  const [fizetesiModOptions, setFizetesiModOptions] = useState([]);
  const [ajanlatJSON, setAjanlatJSON] = useState([]);

  const [rendeles, setRendeles] = useState({});
  const [cegadat, setCegadat] = useState({});
  const [partner, setPartner] = useState({});
  const [ajanlat, setAjanlat] = useState({});
  const [ajanlatModalVariable, setAjanlatModalVariable] = useState();
  const [ajanlatModalCikkJSON, setAjanlatModalCikkJSON] = useState([]);
  const [ajanlatModalCikkSelectedIdList, setAjanlatModalCikkSelectedList] = useState([]);
  const [kelte, setKelte] = useState(new Date());
  const [szallitasiMod, setSzallitasiMod] = useState();
  const [szallitasiHatarido, setSzallitasiHatarido] = useState(new Date());
  const [fizetesiMod, setFizetesiMod] = useState();
  const [fizetesiHatarido, setFizetesiHatarido] = useState(new Date());
  const [megjegyzes, setMegjegyzes] = useState();
  const [targy, setTargy] = useState();
  const [szoveg, setSzoveg] = useState(EDITOR_INIT);
  const [cikkList, setCikkList] = useState([]);

  const [rendelesCikk, setRendelesCikk] = useState({});
  const [mennyiseg, setMennyiseg] = useState('');
  const [penznem, setPenznem] = useState();
  const [nettoEgysegar, setNettoEgysegar] = useState('');
  const [nettoOsszAr, setNettoOsszAr] = useState('');
  const [bruttoEgysegar, setBruttoEgysegar] = useState('');
  const [bruttoOsszAr, setBruttoOsszAr] = useState('');

  const [hasLekRole, setHasLekRole] = useState(false);
  const [hasKarbRole, setHasKarbRole] = useState(false);
  const [hasSzamlazasLekRole, setHasSzamlazasLekRole] = useState(false);
  const [hasSzamlazasKarbRole, setHasSzamlazasKarbRole] = useState(false);

  const [szamlaRendelesId, setSzamlaRendelesId] = useState();
  const [szamlaDatum, setSzamlaDatum] = useState(new Date());
  const [szamlaAdoszam, setSzamlaAdoszam] = useState();
  const [szamlaFizetve, setszamlaFizetve] = useState(false);
  const [szamlaBankszamlaOptions, setszamlaBankszamlaOptions] = useState([]);
  const [szamlaBankszamla, setszamlaBankszamla] = useState();
  const [szamlaMegjegyzes, setSzamlaMegjegyzes] = useState();
  const [szamlaResult, setSzamlaResult] = useState();
  const [szamlaDisabled, setSzamlaDisabled] = useState();
  const [szamlaModalTipus, setSzamlaModalTipus] = useState();

  const [afaKulcsJSON, setAfaKulcsJSON] = useState([]);
  const [afaKulcsOptions, setAfaKulcsOptions] = useState([]);
  const [afaId, setAfaId] = useState('');
  const [afaSzazalek, setAfaSzazalek] = useState('');
  const [isNullaAfa, setIsNullaAfa] = useState(false);
  const [afaEgysegar, setAfaEgysegar] = useState('');
  const [afaOsszAr, setAfaOsszAr] = useState('');
  const [szamolasIrany, setSzamolasIrany] = useState(null); // 1 = nettó egységártól előre, 2 = bruttó értéktől vissza

  const { removeRedundantZeros } = NumberFormatter;

  const [orszag, setOrszag] = useState('');
  const [kereses, setKereses] = useState('');
  const [kozterulet, setKozterulet] = useState('');
  const [kozteruletjelleg, setKozteruletjelleg] = useState('');
  const [hazszam, setHazszam] = useState('');
  const [hrsz, setHrsz] = useState('');
  const [lepcsohaz, setLepcsohaz] = useState('');
  const [epulet, setEpulet] = useState('');
  const [emelet, setEmelet] = useState('');
  const [ajto, setAjto] = useState('');
  const [postafiok, setPostafiok] = useState('');
  const [cimSwitchActive, setCimSwitchActive] = useState(true);
  const [irszNev, setIrszNev] = useState('');
  const [telepulesNev, setTelepulesNev] = useState('');

  const cikkTableTypes = {
    ajanlat: 'ajanlat',
    rendeles: 'rendeles'
  };

  const clearPrices = () => {
    setNettoEgysegar('');
    setAfaEgysegar('');
    setBruttoEgysegar('');
    setNettoOsszAr('');
    setAfaOsszAr('');
    setBruttoOsszAr('');
  };

  const renderFuncButtons = (start, to, total) => {
    return (
      <span>
        {' '}
        <Button className="text-black">
          {start}
          {' - ' + to + ' / ' + total + ' tétel megjelenítése'}
        </Button>
      </span>
    );
  };

  const options = {
    sizePerPage: 5,
    showTotal: true,
    paginationTotalRenderer: renderFuncButtons,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '25', value: 25 }
    ],
  };

  const formatCreateLabel = input => (
    <span>
      <i>Hozzáadás: </i>
      {' '}
      {input}
    </span>
  );

  const selectStyles = {
    control: base => ({
      ...base,
      '&:focus': { borderColor: 'red' }, // border style on hover
      border: '1px solid lightgray', // default border color
      boxShadow: 'none', // no box-shadow
      borderRadius: '0'
    }),
  };

  const styleNoMarginBottom = {
    marginBottom: 0
  };

  // Ha nem lehet módosító modalt nyitni megrendelt állapotnál, akkor felesleges, egyenlőre bent hagyom
  const shouldFieldsBeDisabled = () => {
    return rendeles.allapot && rendeles.allapot.name !== 'FELVITT';
  };

  const addAndSetDaysToCurrentDate = (days, callback) => {
    let date = new Date();
    date.setDate(date.getDate() + days);
    callback(date);
  };

  const handleUjRendelesClick = async () => {
    // Aktuális beállítás használat miatt van itt
    listBeallitas();

    setRendeles({ rendelescikkbeans: [] });
    setCegadat({});
    setPartner({});
    setKelte(new Date());
    setSzallitasiMod();
    setFizetesiMod();
    setAjanlat({});
    setMegjegyzes();
    setCikkList([]);
    setRendelesModal(true);
    setOrszag('');
    setKereses('');
    setKozterulet('');
    setKozteruletjelleg('');
    setHazszam('');
    setHrsz('');
    setLepcsohaz('');
    setEpulet('');
    setEmelet('');
    setAjto('');
    setPostafiok('');
    setCimSwitchActive(true);
    setIrszNev('');
    setTelepulesNev('');
  };

  const listRendeles = async () => {
    try {
      setExpandedRows([]);
      const json = await Services.listVevoiRendeles({ partnertortenetid: ugyfel, kezdete: startDate, vege: endDate });
      if (!json.length) {
        setTableRendelesStatus('DATA');
      } else {
        setRendelesJSON(json);
        setTableRendelesStatus('TABL');
      }
    } catch (ex) {
      setTableRendelesStatus('NETW');
    }
  };

  const listFizetesiMod = () => {
    let array = [];
    Services.listAktivFizetesimod({}, (err, res) => {
      if (!err) {
        res[0].forEach((fizetesimod) => {
          array.push(<option value={fizetesimod.id}>{fizetesimod.nev}</option>);
        });
        setFizetesiModOptions(array);
      }
    });
  };

  const listPenznem = () => {
    Services.listAktivPenznem(null, (err, res) => {
      if (!err) {
        let penznemArray = [];
        res[0].forEach((penznemItem) => {
          penznemArray.push(<option value={penznemItem.id}>{penznemItem.azonosito}</option>);
        });
        setPenznemOptions(penznemArray);
      }
    });
  };

  const setDefaultPenznem = () => {
    Services.getDefaultCurrency(true, (err, res) => {
      if (!err) {
        setPenznem(res[0]);
      }
    });
  };

  const listBeallitas = async () => {
    try {
      const json = await Services.listBeallitas();
      addAndSetDaysToCurrentDate(json.fizetesi, setFizetesiHatarido);
      addAndSetDaysToCurrentDate(json.szallitasi, setSzallitasiHatarido);
    } catch (ex) { }
  };

  const getRendelesSablon = async (id) => {
    try {
      const res = await Services.getRendelesSablon({ id });
      if (res) {
        setTargy(res.targy);
        setSzoveg(serializer.deserialize(res.szoveg));
      }
    } catch (ex) { }
  };

  const loadSzallitasiModOptions = async () => {
    const optionList = await Services.listSzallitasiModRendeles({});
    setSzallitasiModOptions(optionList.map((m) => { return { value: m.id, label: m.nev }; }));
  };

  const checkLekRole = useCallback(
    () => {
      setHasLekRole(Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_VEVOI', 'LEK'));
    },
    [route.globals.keycloak],
  );

  const checkKarbRole = useCallback(
    () => {
      setHasKarbRole(Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_VEVOI', 'KARB'));
    },
    [route.globals.keycloak],
  );

  const checkSzamlazasLekRole = useCallback(
    () => {
      setHasSzamlazasLekRole(Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_VEVOI_SZAMLAZAS', 'LEK'));
    },
    [route.globals.keycloak],
  );

  const checkSzamlazasKarbRole = useCallback(
    () => {
      setHasSzamlazasKarbRole(Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_VEVOI_SZAMLAZAS', 'KARB'));
    },
    [route.globals.keycloak],
  );

  const nullToString = (data) => {
    if (!data) return '';
    return data;
  };

  const emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  };

  const buildSubmitObj = () => {
    if (!cegadat.id) {
      route.globals.addError('Cég választása kötelező!');
    } else if (!partner.id) {
      route.globals.addError('Megrendelő választása kötelező!');
    } else if (!kelte) {
      route.globals.addError('Megrendelés kelte megadása kötelező!');
    } else if (!szallitasiMod) {
      route.globals.addError('Szállítási mód megadása kötelező!');
    } else if (!szallitasiHatarido) {
      route.globals.addError('Szállítási határidő megadása kötelező!');
    } else if (!fizetesiMod) {
      route.globals.addError('Fizetési mód megadása kötelező!');
    } else if (!fizetesiHatarido) {
      route.globals.addError('Fizetési határidő megadása kötelező!');
    } else if (!cikkList.length) {
      route.globals.addError('Legalább egy cikk megadása kötelező!');
    } else {
      let rendelesObj = { ...rendeles };
      rendelesObj.allapot = null;
      rendelesObj.cegadat = { id: cegadat.id };
      rendelesObj.partner = { id: partner.id, p: 'NevSzamSzallCimFull' };
      rendelesObj.ajanlat = ajanlat.id ? { id: ajanlat.id } : null;
      rendelesObj.penznem = { id: penznem.id };
      [rendelesObj.kelte] = new Date(kelte).toISOString().split('T');
      rendelesObj.szallitasimod = szallitasiMod.value;
      [rendelesObj.szallitasihatarido] = new Date(szallitasiHatarido).toISOString().split('T');
      rendelesObj.fizmodid = fizetesiMod;
      [rendelesObj.fizetesihatarido] = new Date(fizetesiHatarido).toISOString().split('T');
      rendelesObj.megjegyzes = megjegyzes;
      rendelesObj.targy = targy;
      rendelesObj.szoveg = serializer.serialize(szoveg);

      let submitCikkek = [];
      cikkList.forEach((c) => {
        let cikk = Object.assign({}, c);
        cikk.cikkid = c.cikk.id;
        cikk.mennyiseg = NumberFormatter.removeSpacesFromString(cikk.mennyisegCikk);
        // cikk.fromajanlat = c.fromajanlat === null ? false : c.fromajanlat;

        let keys = ['id', 'cikkid', 'mennyiseg', 'afaid', 'fromajanlat'];
        if (c.szamolasIrany === 1 || c.irany === 1) { // nettó egységártól
          keys.push('nettoEgysegAr');
          cikk.nettoEgysegAr = NumberFormatter.removeSpacesFromString(c.nettoegysegar);
          if (c.id) {
            delete cikk.bruttoEgysegAr;
          }
        } else if (c.szamolasIrany === 2 || c.irany === 2) { // bruttó értéktől
          keys.push('bruttoErtek');
          cikk.bruttoErtek = NumberFormatter.removeSpacesFromString(c.bruttoertek);
          if (c.id) {
            delete cikk.nettoEgysegAr;
          }
        }

        Object.keys(cikk).forEach(key => keys.includes(key) || delete cikk[key]);

        submitCikkek.push(cikk);
      });
      rendelesObj.rendelescikkbeans = submitCikkek;

      let cim = {};
      cim.orszagid = orszag ? orszag.orszagid : null;

      if (orszag && orszag.keresheto) {
        cim.telepulesid = emptyOrNull(kereses.telepulesid);
        cim.irszid = emptyOrNull(kereses.irszid);
      } else {
        cim.telepules = emptyOrNull(telepulesNev);
        cim.irsz = emptyOrNull(irszNev);
      }

      if (cimSwitchActive) { // cím
        cim.kozterulet = kozterulet;
        cim.kozteruletjelleg = kozteruletjelleg;
        cim.hazszam = hazszam;
        cim.hrsz = hrsz;
        cim.lepcsohaz = lepcsohaz;
        cim.epulet = epulet;
        cim.emelet = emelet;
        cim.ajto = ajto;

        cim.postafiok = null;
      } else { // postafiók
        cim.postafiok = postafiok;

        cim.kozterulet = null;
        cim.kozteruletjelleg = null;
        cim.hazszam = null;
        cim.hrsz = null;
        cim.lepcsohaz = null;
        cim.epulet = null;
        cim.emelet = null;
        cim.ajto = null;
      }

      rendelesObj.cim = cim;

      return rendelesObj;
    }
    return false;
  };

  const sendRendeles = async (params, fromTable) => {
    let rendelesObj;
    let paramObj = params;
    if (!fromTable) {
      if (rendeles.allapot.name === 'FELVITT') {
        rendelesObj = buildSubmitObj();
      } else {
        paramObj = { id: rendeles.id };
      }
    }
    try {
      await Services.sendRendeles(paramObj, rendelesObj);
      route.globals.addSuccess('Vevői rendelés elküldve!');
      setRendelesModal(false);
      listRendeles();
    } catch (ex) { }
  };

  const printRendeles = async (params, fromTable) => {
    let rendelesObj;
    let paramObj = params;
    if (!fromTable) {
      if (rendeles.allapot.name === 'FELVITT') {
        rendelesObj = buildSubmitObj();
      } else {
        paramObj = { id: rendeles.id };
      }
    }
    try {
      const res = await Services.printRendeles(paramObj, rendelesObj);
      setRendelesModal(false);
      setPrintUrl(URL.createObjectURL(new Blob([res], { type: 'application/pdf' })));
      listRendeles();
    } catch (ex) { }
  };

  const loadPartnerOptions = (input, callback) => {
    if (_.isEmpty(input)) {
      callback([]);
    } else {
      Services.listPartner({ type: 'RENDELES_VEVOI_SZALLITASI_CIM_AKTIV_GET', text: input }, (err, res) => {
        if (!err) {
          callback(res[0]);
        } else {
          callback([]);
        }
      });
    }
  };

  const loadOrszagOptions = (input, callback) => {
    if (_.isEmpty(input)) {
      callback([]);
    } else {
      Services.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        } else {
          callback([]);
        }
      });
    }
  };

  const loadKeresesOptions = (input, callback) => {
    if (_.isEmpty(input)) {
      callback([]);
    } else {
      Services.listCim(input, (err, res) => {
        if (!err) {
          let cimOptions = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              cimOptions.push({
                telepulesnev: item.telepulesnev + ' (' + iranyitoszam.iranyitoszam + ')',
                telepulesid: item.telepulesid,
                iranyitoszam: iranyitoszam.iranyitoszam,
                irszid: iranyitoszam.telepuirszid,
                nev: item.telepulesnev,
                // irsz:
              });
            });
          });
          callback(cimOptions);
        } else {
          callback([]);
        }
      });
    }
  };

  const handleChangeOrszagInput = (value) => {
    if (value && value !== '') {
      setOrszag(value);
      setKereses('');
      setIrszNev('');
      setTelepulesNev('');
    } else {
      setOrszag('');
      setKereses('');
      setIrszNev('');
      setTelepulesNev('');
    }
  };

  const handleChangeKeresesInput = (value) => {
    if (value && value !== '') {
      setKereses(value);
      setTelepulesNev(value.nev);
      setIrszNev(value.iranyitoszam);
    } else {
      setKereses('');
      setTelepulesNev('');
      setIrszNev('');
    }
  };

  const toggleCimSwitch = (trueFalse) => {
    if (trueFalse !== cimSwitchActive) {
      setCimSwitchActive(!cimSwitchActive);
      // rem-elve, hogy cím/postafiók váltáskor ne tűnjön el a mező(k)ből az érték!, mentes()-ben kezelve! MG
      // postafiok: '',
      // kozterulet: '',
      // kozteruletjelleg: '',
      // hrsz: '',
      // hazszam: '',
      // lepcsohaz: '',
      // epulet: '',
      // emelet: '',
      // ajto: ''
    }
  };

  // const roundNumber = (number) => {
  //   let multiplier = (10 ** penznem.tizedesjegy);
  //   let num = number * multiplier;
  //   if (penznem.kerekitesiszabaly === 'HALF_UP') {
  //     num = Math.round(num);
  //   } else if (penznem.kerekitesiszabaly === 'HALF_DOWN') {
  //     // A toFixed() nem megbízható (pl 1.005 => 1.00, 1.006 => 1.01) ezért a round van használva (.5-nél "pozitív irányba" kerekít, tehát -2.5 => -2, 2.5 => 3)
  //     num = -Math.round(-num);
  //   }
  //   return num / multiplier;
  // };

  useEffect(() => {
    loadSzallitasiModOptions();
    listFizetesiMod();
    listRendeles();
    listPenznem();
    setDefaultPenznem();
    checkLekRole();
    checkKarbRole();
    checkSzamlazasLekRole();
    checkSzamlazasKarbRole();
  }, []);

  useEffect(() => {
    if (printUrl) {
      window.setTimeout(() => {
        window.frames['pdf-area-vevoi-rendeles'].focus();
        window.frames['pdf-area-vevoi-rendeles'].print();
        window.setTimeout(() => {
          window.close();
        }, 50);
      }, 1000);
      route.globals.addSuccess('Vevői rendelés nyomtatás!');
    }
  }, [printUrl]);

  useEffect(() => {
    if (!rendelesCikk.cikk) {
      setNettoEgysegar();
      setNettoOsszAr();
      setBruttoEgysegar();
      setBruttoOsszAr();
    }
  }, [rendelesCikk]);

  const renderRendelesTable = () => {
    const handleViewClick = (id) => {
      const rendelesObj = rendelesJSON.find(r => r.id === id);
      setRendeles(rendelesObj);
      setRendelesViewModal(true);
    };

    const handleEditClick = async (id) => {
      const rendelesObj = rendelesJSON.find(r => r.id === id);

      setRendeles(rendelesObj);
      setCegadat(rendelesObj.cegadat);
      setPartner(rendelesObj.partner);
      setAjanlat(rendelesObj.ajanlat || {});
      setKelte(rendelesObj.kelte);
      setSzallitasiMod(szallitasiModOptions.find(sz => sz.value === rendelesObj.szallitasimod));
      setSzallitasiHatarido(rendelesObj.szallitasihatarido);
      setFizetesiMod(rendelesObj.fizmodid);
      setFizetesiHatarido(rendelesObj.fizetesihatarido);

      let cikkBeans = [];
      rendelesObj.rendelescikkbeans.forEach((c) => {
        let cikkEdit = Object.assign({}, c);

        cikkEdit.mennyisegCikk = c.mennyiseg;
        cikkEdit.nettoegysegar = c.nettoEgysegAr;
        cikkEdit.afaegysegar = c.afaEgysegAr;
        cikkEdit.bruttoegysegar = c.bruttoEgysegAr;
        cikkEdit.nettoertek = c.nettoErtek;
        cikkEdit.afaertek = c.afaErtek;
        cikkEdit.bruttoertek = c.bruttoErtek;
        cikkEdit.irany = c.irany;
        cikkEdit.fromajanlat = c.fromajanlat;

        if (!c.id) {
          delete c.mennyiseg;
          delete c.nettoEgysegAr;
          delete c.afaEgysegAr;
          delete c.bruttoEgysegAr;
          delete c.nettoErtek;
          delete c.afaErtek;
          delete c.bruttoErtek;
        }

        cikkBeans.push(cikkEdit);
      });

      setCikkList(cikkBeans);

      setMegjegyzes(rendelesObj.megjegyzes);
      setTargy(rendelesObj.targy);
      setSzoveg(serializer.deserialize(rendelesObj.szoveg));
      try {
        const json = await Services.listAjanlat({ partnerTortenetId: rendelesObj.partner.id, felvitt: true, vanbizonylat: true });
        setAjanlatJSON(json);
      } catch (ex) { }
      setRendelesModal(true);


      setOrszag({
        keresheto: nullToString(rendelesObj.cim.keresheto),
        nev: nullToString(rendelesObj.cim.orszag),
        orszagid: nullToString(rendelesObj.cim.orszagid),
      });
      setKereses({
        iranyitoszam: nullToString(rendelesObj.cim.irsz),
        irszid: nullToString(rendelesObj.cim.irszid),
        nev: nullToString(rendelesObj.cim.telepules),
        telepulesid: nullToString(rendelesObj.cim.telepulesid),
        telepulesnev: rendelesObj.cim.telepules && rendelesObj.cim.irsz ? nullToString(rendelesObj.cim.telepules + ' (' + rendelesObj.cim.irsz + ')') : '',
      });
      setKozterulet(nullToString(rendelesObj.cim.kozterulet));
      setKozteruletjelleg(nullToString(rendelesObj.cim.kozteruletjelleg));
      setHrsz(nullToString(rendelesObj.cim.hrsz));
      setHazszam(nullToString(rendelesObj.cim.hazszam));
      setLepcsohaz(nullToString(rendelesObj.cim.lepcsohaz));
      setEpulet(nullToString(rendelesObj.cim.epulet));
      setEmelet(nullToString(rendelesObj.cim.emelet));
      setAjto(nullToString(rendelesObj.cim.ajto));
      setPostafiok(nullToString(rendelesObj.cim.postafiok));
      setCimSwitchActive(!rendelesObj.cim.postafiok);
      setTelepulesNev(nullToString(rendelesObj.cim.telepules));
      setIrszNev(nullToString(rendelesObj.cim.irsz));
    };

    const handleSzamlazasClick = (cell, row) => {
      setSzamlaRendelesId(cell);
      setSzamlaAdoszam(row.partner.adoszam);

      let array = [];
      let bankszamlaId = null;
      (row.cegadat.bankszamlaszam ? row.cegadat.bankszamlaszam : []).forEach((m, i) => {
        if (i === 0) {
          bankszamlaId = m.id;
        }
        array.push(<option value={m.id}>{NumberFormatter.addHyphensToStringBankszamla(m.bankszamlaszam) + ' (' + m.penzintezet + ')'}</option>);
      });
      setszamlaBankszamlaOptions(array);
      setszamlaBankszamla(bankszamlaId);

      setRendelesSzamlazasModal(true);
    };

    const handleSzamlaDownloadClick = (cell) => {
      route.globals.addSuccess('Letöltés indítása...');
      Services.downloadSzamla(cell, (err, res) => {
        if (!err) {
          let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }), 'Számla.pdf');
          window.open(fileURL);
        }
      });
    };

    const handleSztornozasClick = (cell) => {
      setSzamlaRendelesId(cell);
      setRendelesSzamlazasModal(true);
    };

    const handleSztornoDownloadClick = (cell) => {
      route.globals.addSuccess('Letöltés indítása...');
      Services.downloadSztornoSzamla(cell, (err, res) => {
        if (!err) {
          let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }), 'Sztornó számla.pdf');
          window.open(fileURL);
        }
      });
    };

    // const arFormatter = (cell, row, field) => {
    //   let fieldvalue = 0;
    //   cell.forEach((rendelescikkobj) => {
    //     fieldvalue += rendelescikkobj.mennyiseg * rendelescikkobj[field];
    //   });
    //   return NumberFormatter.formatterSpaceOsszeg(roundNumber(fieldvalue)) + ' ' + row.penznem.jel;
    // };

    const loadExpandedRows = (row, isExpand) => {
      if (isExpand) {
        const rendelesObj = rendelesJSON.find(item => item.id === row.id);
        setExpandedRows([...expandedRows, ...Array.from(rendelesObj.rendelescikkbeans).map((rc) => { return { ...rc, penznem: rendelesObj.penznem }; })]);
      } else {
        setExpandedRows(expandedRows.filter(r => r.rendelesid !== row.id));
      }
    };

    const loadAllExpandedRows = (isExpandAll) => {
      if (isExpandAll) {
        let rows = [];
        rendelesJSON.forEach(r => rows.push(...r.rendelescikkbeans.map((rc) => { return { ...rc, penznem: r.penznem }; })));
        setExpandedRows(rows);
      } else {
        setExpandedRows([]);
      }
    };

    const tableIconFormatter = (cell, row) => {
      return (
        <div>
          {hasLekRole && (
            <>
              <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => handleViewClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
                {'Megtekintés'}
              </UncontrolledTooltip>
            </>
          )}
          {hasKarbRole && (
            <>
              {row.allapot.name === 'FELVITT' && (
                <>
                  <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => handleEditClick(cell)} />
                  <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={0}>
                    {'Módosítás'}
                  </UncontrolledTooltip>
                  <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => { setRendelesDeleteModal(true); setRendeles({ id: cell }); }} />
                  <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
                    {'Törlés'}
                  </UncontrolledTooltip>
                </>
              )}
              {row.allapot.name === 'MEGRENDELT' && (
                <>
                  <Button className="icon-close table-action-icon" id={'action-view-' + cell + 'meghiusit'} onClick={() => { setRendelesAbortModal(true); setRendeles({ id: cell }); }} />
                  <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'meghiusit'} delay={0}>
                    {'Meghiúsítás'}
                  </UncontrolledTooltip>
                </>
              )}
              {row.allapot.name !== 'MEGHIUSULT' && (
                <>
                  <Button className="icon-envelope table-action-icon" id={'action-view-' + cell + 'email'} onClick={() => { sendRendeles({ id: cell }, true); }} />
                  <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'email'} delay={0}>
                    {'Visszaigazolás (E-mail küldés)'}
                  </UncontrolledTooltip>
                  <Button className="icon-printer table-action-icon" id={'action-view-' + cell + 'print'} onClick={() => { printRendeles({ id: cell }, true); }} />
                  <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'print'} delay={0}>
                    {'Visszaigazolás (Nyomtatás)'}
                  </UncontrolledTooltip>
                </>
              )}
            </>
          )}
          {hasSzamlazasKarbRole && row.szamlazasallapot && row.szamlazasallapot.szamlaicon && (
            <>
              <Button
                className={'fa ' + row.szamlazasallapot.szamlaicon + ' table-action-icon'}
                id={'action-view-' + cell + 'szamlazas'}
                onClick={() => {
                  setSzamlaModalTipus('SZAMLA');
                  handleSzamlazasClick(cell, row);
                }}
              />
              <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'szamlazas'} delay={0}>
                {'Számlázás'}
              </UncontrolledTooltip>
            </>
          )}
          {hasSzamlazasLekRole && row.szamlazasallapot && row.szamlazasallapot.szamladownloadicon && (
            <>
              <Button className={'fa ' + row.szamlazasallapot.szamladownloadicon + ' table-action-icon'} id={'action-view-' + cell + 'szamladownload'} onClick={() => handleSzamlaDownloadClick(cell, row)} />
              <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'szamladownload'} delay={0}>
                {'Számla letöltése'}
              </UncontrolledTooltip>
            </>
          )}
          {hasSzamlazasKarbRole && row.szamlazasallapot && row.szamlazasallapot.sztornoicon && (
            <>
              <Button
                className={'fa ' + row.szamlazasallapot.sztornoicon + ' table-action-icon'}
                id={'action-view-' + cell + 'sztornozas'}
                onClick={() => {
                  setSzamlaModalTipus('SZTORNO');
                  handleSztornozasClick(cell, row);
                }}
              />
              <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'sztornozas'} delay={0}>
                {'Számla sztornózása'}
              </UncontrolledTooltip>
            </>
          )}
          {hasSzamlazasLekRole && row.szamlazasallapot && row.szamlazasallapot.sztornodownloadicon && (
            <>
              <Button className={'fa ' + row.szamlazasallapot.sztornodownloadicon + ' table-action-icon'} id={'action-view-' + cell + 'sztornodownload'} onClick={() => handleSztornoDownloadClick(cell, row)} />
              <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'sztornodownload'} delay={0}>
                {'Sztornó számla letöltése'}
              </UncontrolledTooltip>
            </>
          )}
        </div>
      );
    };

    const columns = [
      {
        dataField: 'bizonylatszam',
        text: 'Bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        sort: true,
      },
      {
        dataField: 'partner',
        text: 'Megrendelő',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        filterValue: (cell) => {
          if (cell) return cell.nev + ' (' + cell.teljescim + ')';
          return '';
        },
        sort: true,
        formatter: (cell) => {
          if (cell) return cell.nev + ' (' + cell.teljescim + ')';
          return '';
        }
      },
      {
        dataField: 'kelte',
        text: 'Megrend. kelte',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        sort: true,
      }, {
        dataField: 'szallitasihatarido',
        text: 'Szállítási hat.idő',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        sort: true,
      },
      // {
      //   dataField: 'rendelescikkbeans',
      //   text: 'Nettó érték',
      //   filter: textFilter({
      //     style: { display: 'none' },
      //   }),
      //   filterValue: (cell, row) => {
      //     // Nem elírás, a bootstrap table modul hibájából nem lehet különböző szűrőket használni azokon az oszlopokon, amiknek megegyezik a dataField-je (mivel ez azonosítja egyedileg őket)
      //     // Ez miatt az a döntés született, hogy csak a bruttó szűrhető és az így lett megoldva
      //     return arFormatter(cell, row, 'bruttoEgysegar');
      //   },
      //   sort: true,
      //   style: { whiteSpace: 'pre' },
      //   formatter: (cell, row) => {
      //     return arFormatter(cell, row, 'nettoEgysegar');
      //   }
      // },
      // {
      //   dataField: 'rendelescikkbeans',
      //   text: 'Bruttó érték',
      //   filter: textFilter({
      //     delay: 400, // default is 500ms
      //     placeholder: 'Keresés...',
      //   }),
      //   sort: true,
      //   style: { whiteSpace: 'pre' },
      //   formatter: (cell, row) => {
      //     return arFormatter(cell, row, 'bruttoEgysegar');
      //   }
      // },
      {
        dataField: 'nettoOsszeg',
        text: 'Nettó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      },
      {
        dataField: 'bruttoOsszeg',
        text: 'Bruttó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      },
      {
        dataField: 'allapot.caption',
        text: 'Állapot',
        filter: selectFilter({
          options: {
            Felvitt: 'Felvitt',
            Megrendelt: 'Megrendelt',
            Meghiúsult: 'Meghiúsult'
          },
          placeholder: 'Összes',
        }),
        sort: true,
        style: { textAlign: 'center' },
      },
      {
        dataField: 'ajanlat.bizonylatszam',
        text: 'Kapcs. ajánlat',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        sort: true,
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: tableIconFormatter
      },
    ];

    if (hasSzamlazasLekRole) {
      columns.splice(columns.length-1, 0, {
        dataField: 'szamlazasallapot.caption',
        text: 'Számlázás állapota',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: { textAlign: 'center' },
    })};

    const cikkColumns = [
      {
        dataField: 'cikk.megnevezes',
        text: 'Cikk (Cikkszám)',
        formatter: (cell, row) => {
          return cell + ' (' + row.cikk.cikkszam + ')';
        },
      },
      {
        dataField: 'mennyiseg',
        text: 'Menny.',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg;
        }
      },
      {
        dataField: 'kiadott',
        text: 'Kiadott menny.',
        formatter: (cell, row) => {
          if (cell) return NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg;
          return '';
        }
      },
      {
        dataField: 'szallitoiBizonylatSzam',
        text: 'Szállítói rendelés bizonylatszám',
        headerStyle: { whiteSpace: 'pre-line' },
        style: { whiteSpace: 'pre-line' },
      },
      {
        dataField: 'afaSzazalek',
        text: 'Áfa',
        formatter: (cell) => {
          return (cell || 0) + ' %';
        }
      },
      {
        dataField: 'nettoEgysegAr',
        text: 'Nettó egységár',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, row);
        }
      },
      {
        dataField: 'nettoErtek',
        text: 'Nettó érték',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, row);
        }
      },
      {
        dataField: 'bruttoErtek',
        text: 'Bruttó érték',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, row);
        }
      },
    ];

    const expandRow = {
      renderer: (row) => {
        const expandedRowFiltered = expandedRows.filter(x => x.rendelesid == row.id);
        return expandedRowFiltered && (
        <>
          <div className="row">
            <div className="col-md-2">
              <h6>Cikkek</h6>
            </div>
          </div>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={expandedRowFiltered}
            columns={cikkColumns}
            filter={filterFactory()}
            noDataIndication="A rendeléshez még nem lett cikk kiválasztva!"
          />
        </>
        );
      },
      expanded: expandedRows.map(x => x.rendelesid),
      onExpand: loadExpandedRows,
      onExpandAll: loadAllExpandedRows,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return isAnyExpands ? <b>-</b> : <b>+</b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        return expanded ? <b>-</b> : <b>+</b>;
      }
    };

    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={rendelesJSON || []}
        expandRow={expandRow}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
      />
    );
  };

  const renderCikkTable = (tableType, data) => {
    const handleCikkViewClick = (id) => {
      setRendelesCikk(data.find(cikkItem => cikkItem.cikk.id == id));
      setCikkViewModal(true);
    };

    const handleCikkEditClick = (id) => {
      const cikkEdit = data.find(cikkItem => cikkItem.cikk.id == id);
      setRendelesCikk(cikkEdit);
      setMennyiseg(cikkEdit.mennyisegCikk);
      setNettoEgysegar(cikkEdit.nettoegysegar);
      setAfaEgysegar(cikkEdit.afaegysegar);
      setBruttoEgysegar(cikkEdit.bruttoegysegar);
      setNettoOsszAr(cikkEdit.nettoertek);
      setAfaOsszAr(cikkEdit.afaertek);
      setBruttoOsszAr(cikkEdit.bruttoertek);
      setAfaId(cikkEdit.afaid);
      setAfaSzazalek(cikkEdit.afaSzazalek);
      setIsNullaAfa(cikkEdit.afaSzazalek == 0);
      setSzamolasIrany(cikkEdit.szamolasIrany);

      Services.listAktivAfa(cikkEdit.afaid, (err, res) => {
        if (!err) {
          let afaJSON = [];
          let afaOptions = res[0].map((e) => {
            let afa = {};
            afa.id = e.id;
            afa.ertek = e.ertek;
            afaJSON.push(afa);

            return <option key={e.id} value={e.id}>{e.kulcs + ' (' + e.ertek + '%)'}</option>;
          });
          setAfaKulcsJSON(afaJSON);
          setAfaKulcsOptions(afaOptions);
          setCikkModal(true);
        }
      });
    };

    const handleCikkDeleteClick = (id, statefield) => {
      setCikkList(statefield.filter(item => item.cikk.id != id));
    };

    const handleRowSelect = (row, isSelect) => {
      if (isSelect) {
        ajanlatModalCikkSelectedIdList.push(row.id);
      } else {
        setAjanlatModalCikkSelectedList(ajanlatModalCikkSelectedIdList.filter(id => id != row.id));
      }
    };

    const handleRowSelectAll = (isSelect, rows) => {
      if (isSelect) {
        setAjanlatModalCikkSelectedList(rows.map(r => r.id));
      } else {
        setAjanlatModalCikkSelectedList([]);
      }
    };

    const tableIconFormatter = (cell, row, fed) => {
      return (
        <div>
          <Button className="icon-eye table-action-icon" id={'action-cikk-view-' + cell + 'view'} onClick={() => handleCikkViewClick(cell)} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-cikk-view-' + cell + 'view'} delay={50}>
            {'Megtekintés'}
          </UncontrolledTooltip>
          {/* Csak a rendelés modalon lesz mutatva */}
          {!shouldFieldsBeDisabled() && tableType === cikkTableTypes.rendeles && hasKarbRole && (
            <>
              {!row.rendelesid && (
                <>
                  <Button className="icon-pencil table-action-icon" id={'action-cikk-view-' + cell + 'modositas'} onClick={() => handleCikkEditClick(cell)} />
                  <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-cikk-view-' + cell + 'modositas'} delay={0}>
                    {'Módosítás'}
                  </UncontrolledTooltip>
                </>
              )}
              <Button className="icon-trash table-action-icon" id={'action-cikk-view-' + cell + 'torles'} onClick={() => handleCikkDeleteClick(cell, fed)} />
              <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-cikk-view-' + cell + 'torles'} delay={0}>
                {'Törlés'}
              </UncontrolledTooltip>
            </>
          )}
        </div>
      );
    };

    let selectRow = {};
    let columns = [
      {
        dataField: 'cikk.megnevezes',
        text: 'Cikk (Cikkszám)',
        formatter: (cell, row) => {
          return cell + ' (' + row.cikk.cikkszam + ')';
        },
      },
      {
        dataField: 'mennyisegCikk',
        text: 'Menny.',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg;
        }
      },
      {
        dataField: 'afaSzazalek',
        text: 'Áfa',
        formatter: (cell) => {
          return (cell || 0) + ' %';
        }
      },
      {
        dataField: 'nettoegysegar',
        text: 'Nettó egységár',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, tableType !== cikkTableTypes.ajanlat ? row : null, penznem.jel);
        }
      },
      {
        dataField: 'nettoertek',
        text: 'Nettó érték',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, tableType !== cikkTableTypes.ajanlat ? row : null, penznem.jel);
        }
      },
      {
        dataField: 'bruttoertek',
        text: 'Bruttó érték',
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, tableType !== cikkTableTypes.ajanlat ? row : null, penznem.jel);
        }
      },
    ];
    if (tableType === cikkTableTypes.ajanlat) {
      selectRow = {
        mode: 'checkbox',
        selected: ajanlatModalCikkSelectedIdList,
        onSelect: handleRowSelect,
        onSelectAll: handleRowSelectAll
      };
    } else if (tableType === cikkTableTypes.rendeles) {
      selectRow = { hideSelectColumn: true };
      columns = [...columns,
        {
          dataField: 'cikk.id',
          text: 'Műveletek',
          formatter: (cell, row, rid, fed) => {
            return tableIconFormatter(cell, row, fed);
          },
          formatExtraData: data
        }];
    }

    if (!data.length) return <div />;

    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={data}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
        selectRow={selectRow}
      />
    );
  };

  const renderRendelesModal = (d, sd) => {
    const loadCegadatOptions = (input, callback) => {
      if (_.isEmpty(input)) callback([]);
      Services.listCegadat({}, (err, res) => {
        if (!err) {
          const optionsCegadat = res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase()));
          if (optionsCegadat.length == 1) setCegadat(optionsCegadat[0]);
          callback(optionsCegadat);
        } else {
          callback([]);
        }
      });
    };

    const createSzallitasiModOption = async (newSzallitasiMod) => {
      try {
        const res = await Services.addSzallitasiModRendeles({ nev: newSzallitasiMod });
        setSzallitasiModOptions([...szallitasiModOptions, { value: res.id, label: res.nev }]);
      } catch (ex) { }
    };

    const deleteSzallitasiModOption = async (id) => {
      try {
        await Services.deleteSzallitasiModRendeles(id);
        setSzallitasiMod(null);
        loadSzallitasiModOptions();
      } catch (ex) { }
    };

    const handlePartnerChange = async (v) => {
      setPartner(v || {});
      setAjanlat({});
      setCikkList(cikkList.filter(item => !item.fromajanlat));

      if (v && v.szallitasicim) {
        setOrszag({
          keresheto: v.szallitasicim.keresheto,
          nev: v.szallitasicim.orszag,
          orszagid: v.szallitasicim.orszagid,
        });
        setKereses({
          iranyitoszam: v.szallitasicim.irsz,
          irszid: v.szallitasicim.irszid,
          nev: v.szallitasicim.telepules,
          telepulesid: v.szallitasicim.telepulesid,
          telepulesnev: v.szallitasicim.telepules && v.szallitasicim.irsz ? nullToString(v.szallitasicim.telepules + ' (' + v.szallitasicim.irsz + ')') : '',
        });
        setKozterulet(v.szallitasicim.kozterulet);
        setKozteruletjelleg(v.szallitasicim.kozteruletjelleg);
        setHrsz(v.szallitasicim.hrsz);
        setHazszam(v.szallitasicim.hazszam);
        setLepcsohaz(v.szallitasicim.lepcsohaz);
        setEpulet(v.szallitasicim.epulet);
        setEmelet(v.szallitasicim.emelet);
        setAjto(v.szallitasicim.ajto);
        setPostafiok(v.szallitasicim.postafiok);
        setCimSwitchActive(!v.szallitasicim.postafiok);
        setTelepulesNev(v.szallitasicim.telepules);
        setIrszNev(v.szallitasicim.irsz);
      } else if (v && v.cim) {
        setOrszag({
          keresheto: v.cim.keresheto,
          nev: v.cim.orszag,
          orszagid: v.cim.orszagid,
        });
        setKereses({
          iranyitoszam: v.cim.irsz,
          irszid: v.cim.irszid,
          nev: v.cim.telepules,
          telepulesid: v.cim.telepulesid,
          telepulesnev: v.cim.telepules && v.cim.irsz ? nullToString(v.cim.telepules + ' (' + v.cim.irsz + ')') : '',
        });
        setKozterulet(v.cim.kozterulet);
        setKozteruletjelleg(v.cim.kozteruletjelleg);
        setHrsz(v.cim.hrsz);
        setHazszam(v.cim.hazszam);
        setLepcsohaz(v.cim.lepcsohaz);
        setEpulet(v.cim.epulet);
        setEmelet(v.cim.emelet);
        setAjto(v.cim.ajto);
        setPostafiok(v.cim.postafiok);
        setCimSwitchActive(!v.cim.postafiok);
        setTelepulesNev(v.cim.telepules);
        setIrszNev(v.cim.irsz);
      } else {
        setOrszag('');
        setKereses('');
        setKozterulet('');
        setKozteruletjelleg('');
        setHazszam('');
        setHrsz('');
        setLepcsohaz('');
        setEpulet('');
        setEmelet('');
        setAjto('');
        setPostafiok('');
        setCimSwitchActive(true);
        setIrszNev('');
        setTelepulesNev('');
      }
      try {
        const json = await Services.listAjanlat({ partnerTortenetId: v.id, felvitt: true, vanbizonylat: true });
        setAjanlatJSON(json);
      } catch (ex) { }
    };

    const handleFizetesiModChange = (event) => {
      setFizetesiMod(event.target.value);
    };

    const handleMegjegyzesChange = (event) => {
      setMegjegyzes(event.target.value);
    };

    const handleTargyChange = (event) => {
      setTargy(event.target.value);
    };

    // Később lehet majd választani pénznemet, most statikusan HUF
    const handlePenznemChange = (event) => {
      // setPenznem(penznemJSON.find(penznemItem => penznemItem.id == event.target.value));
    };

    const handleRendelesSubmitClick = async () => {
      const rendelesObj = buildSubmitObj();
      if (rendelesObj) {
        try {
          if (rendelesObj.id) {
            await Services.editVevoiRendeles(rendelesObj);
            route.globals.addSuccess('Vevői rendelés módosítva!');
          } else {
            await Services.insertVevoiRendeles(rendelesObj);
            route.globals.addSuccess('Vevői rendelés felvéve!');
          }
          listRendeles();
          setRendelesModal(false);
        } catch (ex) { }
      }
    };

    const handleAjanlatDelete = () => {
      setAjanlat({});
      setCikkList(cikkList.filter(item => !item.fromajanlat));
    };

    const handleAjanlatModalClick = () => {
      setAjanlat({});
      setAjanlatModalVariable();
      setAjanlatModalCikkJSON([]);
      setAjanlatModalCikkSelectedList([]);
      setAjanlatModal(true);
    };

    const handleCikkModalClick = () => {
      setRendelesCikk({});
      setMennyiseg('');
      setNettoEgysegar('');
      setAfaEgysegar('');
      setBruttoEgysegar('');
      setNettoOsszAr('');
      setAfaOsszAr('');
      setBruttoOsszAr('');
      setCikkModal(true);

      Services.listAktivAfa(null, (err, res) => {
        if (!err) {
          let afaJSON = [];
          let afaOptions = res[0].map((e) => {
            let afa = {};
            afa.id = e.id;
            afa.ertek = e.ertek;
            afaJSON.push(afa);

            return <option key={e.id} value={e.id}>{e.kulcs + ' (' + e.ertek + '%)'}</option>;
          });
          setAfaKulcsJSON(afaJSON);
          setAfaKulcsOptions(afaOptions);
        }
      });
    };

    const renderModalHeaderInfo = (label, value) => {
      return (
        <Row className="justify-content-end mb-1">
          <Row className="col-md-4">
            <Row className="align-items-end justify-content-end col-md-6">
              <Label>{label}</Label>
            </Row>
            <Col md="6">
              <Input value={value} disabled />
            </Col>
          </Row>
        </Row>
      );
    };

    const renderCustomLabel = (label) => {
      return <Col md="2" className="row justify-content-end align-self-center">{label}</Col>;
    };

    const renderInputField = (label, children, placeholder, disabled) => {
      return (
        <Row className="mb-1">
          {renderCustomLabel(label)}
          {children || (
            <Col>
              <Input placeholder={placeholder} disabled={disabled} />
            </Col>
          )}
        </Row>
      );
    };

    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <Modal
        isOpen={rendelesModal}
        toggle={() => setRendelesModal(!rendelesModal)}
        backdrop="static"
        size="lg"
      >
        <AvForm onValidSubmit={() => { handleRendelesSubmitClick(); }}>
          <ModalHeader>{ 'Vevői rendelés ' + (rendeles.id ? 'módosítása' : 'rögzítése')}</ModalHeader>
          <ModalBody>
            <div className="container">
              {renderModalHeaderInfo('Bizonylatszám', rendeles.bizonylatszam)}
              {renderModalHeaderInfo('Állapot', rendeles.allapot && rendeles.allapot.caption)}
              <h4>Cég adatok:</h4>
              {renderInputField('Cég neve: *',
                <Col>
                  <SelectAsync
                    styles={selectStyles}
                    name="cegadat"
                    id="cegadat"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    onChange={(v) => {
                      setCegadat(v || {});
                      if (v) getRendelesSablon(v.id);
                    }}
                    loadOptions={_.debounce(loadCegadatOptions, 1000)}
                    value={(cegadat.id && cegadat) || null}
                    getOptionLabel={option => (option.nev || option.teljesnev) + ' (' + option.cim + ')'}
                    getOptionValue={option => option.id}
                    isDisabled={shouldFieldsBeDisabled()}
                    isClearable
                    defaultOptions
                    required
                  />
                </Col>)}
              <h4>
                Megrendelő adatok:
              </h4>
              {renderInputField('Név: *',
                <Col>
                  <SelectAsync
                    styles={selectStyles}
                    name="partner"
                    id="partner"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    onChange={v => handlePartnerChange(v)}
                    loadOptions={_.debounce(loadPartnerOptions, 1000)}
                    value={(partner.id && partner) || null}
                    getOptionLabel={option => option.nev + ' (' + option.teljescim + (option.teljesszallitasicim ? (' | Szállítási cím: ' + option.teljesszallitasicim) : '') + ')' + (option.email ? ' - ' + option.email : '')}
                    getOptionValue={option => option.id}
                    isDisabled={shouldFieldsBeDisabled()}
                    isClearable
                    required
                  />
                </Col>)}
              {renderInputField('Szállítási cím: *', <Col />)}
              <div className="row">
                <div className="col-md-3" style={{ marginBottom: '1rem' }}>
                  <Label for="orszag">Ország: *</Label>
                  <SelectAsync
                    styles={{
                      control: base => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="orszag"
                    id="orszag"
                    placeholder="Kezdjen el gépelni..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    onChange={v => handleChangeOrszagInput(v)}
                    isClearable
                    ignoreAccents={false}
                    loadOptions={_.debounce(loadOrszagOptions, 1000)}
                    isLoading={false}
                    getOptionLabel={option => option.nev}
                    getOptionValue={option => option.orszagid}
                    value={orszag}
                    cache={false}
                    autoload={false}
                    isDisabled={shouldFieldsBeDisabled()}
                  />
                </div>
                {orszag && orszag.keresheto && (
                  <div className="col-md-3" style={{ marginBottom: '1rem' }}>
                    <Label for="kereses">Keresés: </Label>
                    <SelectAsync
                      styles={{
                        control: base => ({
                          ...base,
                          '&:focus': { borderColor: 'red' }, // border style on hover
                          border: '1px solid lightgray', // default border color
                          boxShadow: 'none', // no box-shadow
                          borderRadius: '0'
                        }),
                      }}
                      name="kereses"
                      id="kereses"
                      placeholder="Település vagy irányítószám"
                      noOptionsMessage={() => 'Nincs keresési eredmény'}
                      searchPromptText="Kezdjen el gépelni a kereséshez..."
                      loadingMessage={() => 'Keresés...'}
                      onChange={v => handleChangeKeresesInput(v)}
                      // isClearable
                      ignoreAccents={false}
                      // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
                      loadOptions={_.debounce(loadKeresesOptions, 1000)}
                      getOptionLabel={option => option.telepulesnev}
                      getOptionValue={option => option.telepulesid}
                      isLoading={false}
                      value={kereses}
                      cache={false}
                      autoload={false}
                      isDisabled={shouldFieldsBeDisabled()}
                    />
                  </div>
                )}
                <div className="col-md-3">
                  <AvGroup>
                    <Label for="irszNev">Irányítószám: *</Label>
                    <AvInput
                      name="irszNev"
                      id="irszNev"
                      type="text"
                      maxLength="20"
                      value={irszNev}
                      onChange={e => setIrszNev(e.target.value)}
                      disabled={shouldFieldsBeDisabled() || (orszag && orszag.keresheto) || orszag === ''}
                      autoComplete="off"
                    />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-3">
                  <AvGroup>
                    <Label for="telepulesNev">Település: *</Label>
                    <AvInput
                      name="telepulesNev"
                      id="telepulesNev"
                      type="text"
                      value={telepulesNev}
                      onChange={e => setTelepulesNev(e.target.value)}
                      disabled={shouldFieldsBeDisabled() || (orszag && orszag.keresheto) || orszag === ''}
                      autoComplete="off"
                    />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <SwitchButton
                    active={cimSwitchActive}
                    trueToggle={() => toggleCimSwitch(true)}
                    falseToggle={() => toggleCimSwitch(false)}
                    style={{ marginBottom: 15, marginTop: 8 }}
                    trueLabel="Cím"
                    falseLabel="Postafiók"
                    disbled={shouldFieldsBeDisabled()}
                  />
                </div>
              </div>
              {cimSwitchActive && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="kozterulet">Közterület neve: *</Label>
                        <AvInput
                          name="kozterulet"
                          id="kozterulet"
                          type="text"
                          value={kozterulet}
                          onChange={e => setKozterulet(e.target.value)}
                          maxLength="50"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                        <AvFeedback>Kötelező mező!</AvFeedback>
                      </AvGroup>
                    </div>
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="kozteruletjelleg">Közterület jellege: *</Label>
                        <AvInput
                          name="kozteruletjelleg"
                          id="kozteruletjelleg"
                          value={kozteruletjelleg}
                          onChange={e => setKozteruletjelleg(e.target.value)}
                          type="text"
                          maxLength="20"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                        <AvFeedback>Kötelező mező!</AvFeedback>
                      </AvGroup>
                    </div>
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="hazszam">Házszám:</Label>
                        <AvInput
                          name="hazszam"
                          id="hazszam"
                          value={hazszam}
                          onChange={e => setHazszam(e.target.value)}
                          type="text"
                          maxLength="20"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                        <AvFeedback>Kötelező mező!</AvFeedback>
                      </AvGroup>
                    </div>
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="hrsz">Hrsz:</Label>
                        <AvInput
                          name="hrsz"
                          id="hrsz"
                          value={hrsz}
                          onChange={e => setHrsz(e.target.value)}
                          type="text"
                          maxLength="20"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                        <AvFeedback>Kötelező mező!</AvFeedback>
                      </AvGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="epulet">Épület:</Label>
                        <AvInput
                          name="epulet"
                          id="epulet"
                          value={epulet}
                          onChange={e => setEpulet(e.target.value)}
                          type="text"
                          maxLength="4"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                      </AvGroup>
                    </div>
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="lepcsohaz">Lépcsőház: </Label>
                        <AvInput
                          name="lepcsohaz"
                          id="lepcsohaz"
                          value={lepcsohaz}
                          onChange={e => setLepcsohaz(e.target.value)}
                          type="text"
                          maxLength="4"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                      </AvGroup>
                    </div>
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="emelet">Emelet:</Label>
                        <AvInput
                          name="emelet"
                          id="emelet"
                          value={emelet}
                          onChange={e => setEmelet(e.target.value)}
                          type="text"
                          maxLength="10"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                      </AvGroup>
                    </div>
                    <div className="col-md-3">
                      <AvGroup>
                        <Label for="ajto">Ajtó:</Label>
                        <AvInput
                          name="ajto"
                          id="ajto"
                          value={ajto}
                          onChange={e => setAjto(e.target.value)}
                          type="text"
                          maxLength="15"
                          autoComplete="off"
                          disbled={shouldFieldsBeDisabled()}
                        />
                        <AvFeedback>Szám mező!</AvFeedback>
                      </AvGroup>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {!cimSwitchActive && (
                <div className="row">
                  <div className="col-md-3">
                    <AvGroup>
                      <Label for="postafiok">Postafiók: *</Label>
                      <AvInput
                        name="postafiok"
                        id="postafiok"
                        value={postafiok}
                        onChange={e => setPostafiok(e.target.value)}
                        type="text"
                        maxLength="40"
                        autoComplete="off"
                        disbled={shouldFieldsBeDisabled()}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </div>
                </div>
              )}
              <h4>
                Megrendelés adatok:
              </h4>
              {renderInputField('Kapcsolódó ajánlat:',
                <>
                  <Col md="3">
                    {/* ajanlat.bizonylat.bizonylatszam => ajánlat hozzáadása lekérdezés esetén, ajanlat.bizonylatszam => rendelés módosítás esetén */}
                    <Input value={(ajanlat && ((ajanlat.bizonylat && ajanlat.bizonylat.bizonylatszam) || ajanlat.bizonylatszam)) || ''} disabled />
                  </Col>
                  <Col md="2">
                    <Button
                      color="danger"
                      type="button"
                      disabled={shouldFieldsBeDisabled() || (!ajanlat || !ajanlat.id)}
                      onClick={handleAjanlatDelete}
                    >
                      Törlés
                    </Button>
                  </Col>
                </>)}
              {renderInputField('',
                <Col md="2">
                  <Button
                    color="primary"
                    type="button"
                    disabled={shouldFieldsBeDisabled() || !partner.id || (ajanlat && ajanlat.id)}
                    onClick={handleAjanlatModalClick}
                  >
                    Ajánlat hozzáadása
                  </Button>
                </Col>)}
              {renderInputField('Megrendelés kelte: *',
                <>
                  <Col md="4">
                    <DateTimePicker
                      id="kelte"
                      name="kelte"
                      value={(kelte && new Date(kelte))}
                      onChange={(e, v) => { if (v) setKelte(new Date(v)); else setKelte(null); }}
                      containerClassName="organw-date-time"
                      time={false}
                      format="YYYY-MM-DD"
                      messages={{ dateButton: 'dátum' }}
                      style={styleNoMarginBottom}
                      disabled={shouldFieldsBeDisabled()}
                      required
                    />
                  </Col>
                  {renderCustomLabel('Pénznem: *')}
                  <Col md="4">
                    <AvGroup style={styleNoMarginBottom}>
                      <AvInput type="select" onChange={handlePenznemChange} value={penznem && penznem.id} name="penznem" required disabled>
                        <option value="">
                          Válassz pénznemet...
                        </option>
                        {penznemOptions}
                      </AvInput>
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                </>)}
              {renderInputField('Szállítás módja: *',
                <>
                  <Col md="4">
                    <CreatableSelect
                      styles={selectStyles}
                      name="szallitasiMod"
                      id="szallitasiMod"
                      placeholder="Kezdjen el gépelni..."
                      noOptionsMessage={() => 'Nincs keresési eredmény'}
                      formatCreateLabel={i => formatCreateLabel(i)}
                      onCreateOption={v => createSzallitasiModOption(v)}
                      onDeleteOption={id => deleteSzallitasiModOption(id)}
                      components={{ Option: CustomOption }}
                      onChange={v => setSzallitasiMod(v)}
                      options={szallitasiModOptions}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                      value={szallitasiMod}
                      isDisabled={shouldFieldsBeDisabled()}
                      isClearable
                    />
                  </Col>
                  {renderCustomLabel('Szállítási határidő: *')}
                  <Col md="4">
                    <DateTimePicker
                      id="szallitasihido"
                      name="szallitasihido"
                      value={(szallitasiHatarido && new Date(szallitasiHatarido))}
                      onChange={(e, v) => { if (v) setSzallitasiHatarido(new Date(v)); else setSzallitasiHatarido(null); }}
                      containerClassName="organw-date-time"
                      time={false}
                      format="YYYY-MM-DD"
                      messages={{ dateButton: 'dátum' }}
                      style={styleNoMarginBottom}
                      disabled={shouldFieldsBeDisabled()}
                      required
                    />
                  </Col>
                </>)}
              {renderInputField('Fizetés módja: *',
                <>
                  <Col md="4">
                    <AvGroup style={styleNoMarginBottom}>
                      <AvInput
                        type="select"
                        name="fizmod"
                        value={fizetesiMod}
                        onChange={handleFizetesiModChange}
                        disabled={shouldFieldsBeDisabled()}
                        required
                      >
                        <option value={null}>Válassz fizetési módot...</option>
                        {fizetesiModOptions}
                      </AvInput>
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </Col>
                  {renderCustomLabel('Fizetési határidő: *')}
                  <Col md="4">
                    <DateTimePicker
                      id="fizetesihido"
                      name="fizetesihido"
                      value={(fizetesiHatarido && new Date(fizetesiHatarido))}
                      onChange={(e, v) => { if (v) setFizetesiHatarido(new Date(v)); else setFizetesiHatarido(null); }}
                      containerClassName="organw-date-time"
                      time={false}
                      format="YYYY-MM-DD"
                      messages={{ dateButton: 'dátum' }}
                      style={styleNoMarginBottom}
                      disabled={shouldFieldsBeDisabled()}
                      required
                    />
                  </Col>
                </>)}
              {renderInputField('Nyomtatás időpontja:',
                <>
                  <Col md="2">
                    <Input value={rendeles.nyomtatasido} disabled />
                  </Col>
                  {renderCustomLabel('Elküldés időpontja:')}
                  <Col md="2">
                    <Input value={rendeles.elkuldesido} disabled />
                  </Col>
                </>)}
              {renderInputField('Megjegyzés:',
                <Col>
                  <Input
                    type="textarea"
                    value={megjegyzes}
                    onChange={handleMegjegyzesChange}
                    disabled={shouldFieldsBeDisabled()}
                  />
                </Col>)}
              {renderInputField('Tárgy: ',
                <Col>
                  <Input
                    value={targy}
                    onChange={handleTargyChange}
                    disabled={shouldFieldsBeDisabled()}
                  />
                </Col>)}
              {renderInputField('E-mail tartalom: ',
                <Col>
                  <EditorEmail
                    text={szoveg}
                    setText={setSzoveg}
                  />
                </Col>)}
              <h4>Cikk adatok:</h4>
              <Col md="2">
                <Button
                  color="primary"
                  className="mb-1"
                  onClick={handleCikkModalClick}
                  disabled={shouldFieldsBeDisabled()}
                >
                  Cikk kiválasztása
                </Button>
              </Col>
              {cikkList.length !== 0 && renderCikkTable(cikkTableTypes.rendeles, d, sd)}
            </div>

          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              disabled={shouldFieldsBeDisabled()}
            >
              Mentés
            </Button>
            <Button
              type="button"
              color="primary"
              disabled={!rendeles.allapot || rendeles.allapot.name === 'MEGHIUSULT'}
              onClick={() => { printRendeles(); }}
            >
              Visszaigazolás nyomtatással
            </Button>
            <Button
              type="button"
              color="primary"
              disabled={!rendeles.allapot || rendeles.allapot.name === 'MEGHIUSULT'}
              onClick={() => { sendRendeles(); }}
            >
              {' '}
              Visszaigazolás e-mail küldéssel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setRendelesModal(false);
              }}
            >
              Mégse
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  };

  const renderSzamlazasModal = () => {
    const handleSzamlaMegjegyzesChange = (event) => {
      setSzamlaMegjegyzes(event.target.value);
    };

    const handleSzamlaAdoszamChange = (event) => {
      setSzamlaAdoszam(event.target.value ? NumberFormatter.removeHyphensFromString(event.target.value) : null);
    };

    const handleSzamlaFizetveChange = (event) => {
      setszamlaFizetve(event.target.checked);
    };

    const handleSzamlaBankszamlaChange = (event) => {
      setszamlaBankszamla(event.target.value);
    };

    const handleRendelesSzamlazasSubmitClick = async (isPreview) => {
      try {
        setSzamlaDisabled(true);

        const szamlazasObj = {};
        szamlazasObj.id = szamlaRendelesId;
        szamlazasObj.megjegyzes = szamlaMegjegyzes;
        if (szamlaModalTipus === 'SZAMLA') {
          if (szamlaDatum) {
            let [datum] = new Date(szamlaDatum).toISOString().split('T');
            szamlazasObj.datum = datum;
          }
          szamlazasObj.adoszam = szamlaAdoszam;
          szamlazasObj.fizetve = szamlaFizetve;
          szamlazasObj.bankszamlaid = szamlaBankszamla;
          if (isPreview) {
            szamlazasObj.elonezet = isPreview;
            // route.globals.addSuccess('Letöltés indítása...');
            let res = await Services.previewSzamla(szamlazasObj);
            if (res) {
              let fileURL = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }), 'Számla_preview.pdf');
              window.open(fileURL);
            }
          } else {
            setSzamlaResult(await Services.createSzamla(szamlazasObj));
          }
        } else if (szamlaModalTipus === 'SZTORNO') {
          setSzamlaResult(await Services.sztornoSzamla(szamlazasObj));
        }
      } catch (ex) {}
      finally {
        setSzamlaDisabled(false);
      }
    };

    const renderResultElement = (caption, value, style, currency) => {
      if (value || value === 'false') {
        let finalValue = style ? NumberFormatter.addSpacesToString(value, NumberFormatter.OSSZEG) : value;
        finalValue += currency ? ' ' + currency : '';
        return (
          <>
            <br />
            <b>
              {caption + ': '}
            </b>
            {finalValue}
          </>
        );
      }

      return '';
    };

    const renderModalCaptions = () => {
      if (szamlaModalTipus === 'SZAMLA') {
        return ['Számlázás', 'Száml', 'Számlázás', 'A számla kiállítása siker' + (szamlaResult && szamlaResult.sikeres ? 'esen megtörtént.' : 'telen. Az adatok javítása szükséges!')];
      }

      if (szamlaModalTipus === 'SZTORNO') {
        return ['Sztornózás', 'Sztornó száml', 'Sztornózás', 'A számla sztornózása siker' + (szamlaResult && szamlaResult.sikeres ? 'esen megtörtént.' : 'telen. Az adatok javítása szükséges!')];
      }

      return '';
    };

    const renderSzamlaResult = () => {
      if (szamlaResult) {
        return (
          <>
            <Alert color={szamlaResult.sikeres ? 'success' : 'danger'}>
              {renderModalCaptions()[3]}
              <br />
              {renderResultElement('Hibakód', szamlaResult.hibakod)}
              {renderResultElement('Hibaüzenet', szamlaResult.hibauzenet)}
              {renderResultElement('Számlaszám', szamlaResult.szamlaszam)}
              {renderResultElement('Nettó érték', szamlaResult.netto, NumberFormatter.OSSZEG, szamlaResult.penznem)}
              {renderResultElement('Bruttó érték', szamlaResult.brutto, NumberFormatter.OSSZEG, szamlaResult.penznem)}
            </Alert>
          </>
        );
      }

      return '';
    };

    return (
      <Modal
        isOpen={rendelesSzamlazasModal}
        toggle={() => setRendelesSzamlazasModal(!rendelesSzamlazasModal)}
        backdrop="static"
        size="lg"
      >
        {/* <AvForm onValidSubmit={() => { handleRendelesSzamlazasSubmitClick(); }}> */}
        <AvForm>
          <ModalHeader>{renderModalCaptions()[0] + ' adatkiegészítés'}</ModalHeader>
          <ModalBody>
            <div className="container">
              <Row className="mb-1">
                <Col md="4">
                  {szamlaModalTipus === 'SZAMLA' && (
                    <React.Fragment>
                      <Label for="szamlaDatum">
                        {'Teljesítés dátuma: *'}
                      </Label>
                      <DateTimePicker
                        id="szamlaDatum"
                        name="szamlaDatum"
                        value={szamlaDatum}
                        onChange={(e, v) => { if (v) setSzamlaDatum(new Date(v)); else setSzamlaDatum(null); }}
                        containerClassName="organw-date-time"
                        time={false}
                        format="YYYY-MM-DD"
                        messages={{ dateButton: 'dátum' }}
                        style={styleNoMarginBottom}
                        required
                      />
                    </React.Fragment>
                  )}
                  <React.Fragment>
                    <Label for="szamlaKelte">
                      {renderModalCaptions()[1] + 'a kelte: *'}
                    </Label>
                    <DateTimePicker
                      id="szamlaKelte"
                      name="szamlaKelte"
                      value={new Date()}
                      containerClassName="organw-date-time"
                      time={false}
                      format="YYYY-MM-DD"
                      messages={{ dateButton: 'dátum' }}
                      style={styleNoMarginBottom}
                      disabled
                      required
                    />
                  </React.Fragment>
                  {szamlaModalTipus === 'SZAMLA' && (
                    <React.Fragment>
                      <React.Fragment>
                        <Label for="szamlaAdoszam">
                          {'Vevő adószáma:'}
                          <CustomTooltip text={getTooltip('rendelesAdoszam')} id="rendelesAdoszamTT" />
                        </Label>
                        <SlaveAvInput
                          id="szamlaAdoszam"
                          type="text"
                          value={szamlaAdoszam}
                          szuroTipus={NumberFormatter.ADOSZAM}
                          onChange={handleSzamlaAdoszamChange}
                          disabled
                        />
                      </React.Fragment>
                      <React.Fragment>
                        <Label for="szamlaBankszamla">Kibocsátó bankszámlaszáma:</Label>
                        <AvInput
                          type="select"
                          name="szamlaBankszamla"
                          value={szamlaBankszamla}
                          onChange={handleSzamlaBankszamlaChange}
                          disabled={szamlaBankszamlaOptions.length <= 1}
                        >
                          {szamlaBankszamlaOptions}
                        </AvInput>
                      </React.Fragment>
                      <React.Fragment>
                        <AvGroup>
                          <Label for="szamlaFizetve" style={{ marginBottom: 5, marginTop: 15 }}>
                            <AvInput
                              id="szamlaFizetve"
                              name="szamlaFizetve"
                              type="checkbox"
                              value={szamlaFizetve}
                              onChange={handleSzamlaFizetveChange}
                            />
                            {' Kifizetve'}
                          </Label>
                        </AvGroup>
                      </React.Fragment>
                    </React.Fragment>
                  )}
                </Col>
                <Col md="8">
                  {renderSzamlaResult()}
                </Col>
                <Col md="12">
                  <React.Fragment>
                    <Label for="szamlaMegjegyzes">
                      {renderModalCaptions()[1] + 'án feltüntetendő megjegyzés:'}
                    </Label>
                    <AvInput
                      id="szamlaMegjegyzes"
                      name="szamlaMegjegyzes"
                      type="textarea"
                      value={szamlaMegjegyzes}
                      onChange={handleSzamlaMegjegyzesChange}
                      maxLength="250"
                    />
                  </React.Fragment>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            {szamlaModalTipus === 'SZAMLA' && (!szamlaResult || (szamlaResult && !szamlaResult.sikeres)) && (
              <Button
                type="submit"
                color="primary"
                disabled={szamlaDisabled}
                onClick={() => handleRendelesSzamlazasSubmitClick(true)}
              >
                {'Számla előnézet'}
              </Button>
            )}
            {(!szamlaResult || (szamlaResult && !szamlaResult.sikeres)) && (
              <Button
                type="submit"
                color="primary"
                disabled={szamlaDisabled}
                onClick={() => handleRendelesSzamlazasSubmitClick(false)}
              >
                {renderModalCaptions()[2] + ' indítása'}
              </Button>
            )}
            {(szamlaResult && szamlaResult.sikeres) && (
              <Button
                type="submit"
                color="primary"
                onClick={() => {
                  setRendelesSzamlazasModal(false);
                  setSzamlaRendelesId(null);
                  setSzamlaDatum(new Date());
                  setSzamlaAdoszam(null);
                  setszamlaFizetve(false);
                  setszamlaBankszamlaOptions([]);
                  setszamlaBankszamla(null);
                  setSzamlaMegjegyzes(null);
                  setSzamlaResult(null);
                  listRendeles();
                }}
              >
                Bezárás
              </Button>
            )}
            {(!szamlaResult || !szamlaResult.sikeres) && (
              <Button
                type="button"
                disabled={szamlaDisabled}
                onClick={() => {
                  setRendelesSzamlazasModal(false);
                  setSzamlaRendelesId(null);
                  setSzamlaDatum(new Date());
                  setSzamlaAdoszam(null);
                  setszamlaFizetve(false);
                  setszamlaBankszamlaOptions([]);
                  setszamlaBankszamla(null);
                  setSzamlaMegjegyzes(null);
                  setSzamlaResult(null);
                }}
              >
                Mégse
              </Button>
            )}
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  };

  const renderRendelesViewModal = () => {
    const szallmod = szallitasiModOptions.find(sz => sz.value === rendeles.szallitasimod);
    const fizmodOption = fizetesiModOptions.find(f => f.props.value === rendeles.fizmodid);
    return (
      <Modal
        isOpen={rendelesViewModal}
        toggle={() => setRendelesViewModal(false)}
        backdrop="static"
      >
        <ModalHeader>Rendelés megtekintés</ModalHeader>
        <ModalBody>
          <b>Bizonylatszám: </b>
          {rendeles.bizonylatszam}
          <br />
          <b>Állapot: </b>
          {rendeles.allapot && rendeles.allapot.caption}
          <br />
          <b>Cég neve: </b>
          {rendeles.cegadat && rendeles.cegadat.teljesnev}
          <br />
          <b>Cég címe: </b>
          {rendeles.cegadat && rendeles.cegadat.cim}
          <br />
          <b>Megrendelő neve: </b>
          {rendeles.partner && rendeles.partner.nev}
          <br />
          <b>Megrendelő címe: </b>
          {rendeles.partner && rendeles.partner.teljescim}
          <br />
          <b>Megrendelő szállítási címe: </b>
          {rendeles.teljescim}
          <br />
          <b>Megrendelő e-mail címe: </b>
          {rendeles.partner ? rendeles.partner.email : ''}
          <br />
          <b>Megrendelő telefonszáma: </b>
          {rendeles.partner ? rendeles.partner.telefon : ''}
          <br />
          <b>Kapcsolódó ajánlat: </b>
          {rendeles.ajanlat && rendeles.ajanlat.bizonylatszam}
          <br />
          <b>Kelte: </b>
          {rendeles.kelte}
          <br />
          <b>Pénznem: </b>
          {rendeles.penznem && rendeles.penznem.azonosito}
          <br />
          <b>Fizetési határidő: </b>
          {rendeles.fizetesihatarido}
          <br />
          <b>Fizetési mód: </b>
          {fizmodOption && fizmodOption.props && fizmodOption.props.children}
          <br />
          <b>Szállítási határidő: </b>
          {rendeles.szallitasihatarido}
          <br />
          <b>Szállítási mód: </b>
          {szallmod && szallmod.label}
          <br />
          <b>Nyomtatás időpontja: </b>
          {rendeles.nyomtatasido}
          <br />
          <b>Elküldés időpontja: </b>
          {rendeles.elkuldesido}
          <br />
          <b>Megjegyzés: </b>
          {rendeles.megjegyzes}
          {hasSzamlazasLekRole && (rendeles.szamlakelte || rendeles.sztornodownloadicon) && <br />}
          {hasSzamlazasLekRole && rendeles.szamlakelte && (
            <>
              <br />
              <b>Számla kelte: </b>
              {rendeles.szamlakelte}
              <br />
              <b>Számla száma: </b>
              {rendeles.szamlaszam}
            </>
          )}
          {hasSzamlazasLekRole && rendeles.sztornoszamlakelte && (
            <>
              <br />
              <b>Sztornó számla kelte: </b>
              {rendeles.sztornoszamlakelte}
              <br />
              <b>Sztornó számla száma: </b>
              {rendeles.sztornoszamlaszam}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setRendelesViewModal(false)}>
            Bezárás
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleDeleteClick = async () => {
    try {
      await Services.deleteVevoiRendeles(rendeles.id);
      route.globals.addSuccess('Vevői rendelés törölve!');
      setRendelesDeleteModal(false);
      listRendeles();
    } catch (ex) { }
  };

  const handleAbortClick = async () => {
    try {
      await Services.abortVevoiRendeles(rendeles.id);
      route.globals.addSuccess('Vevői rendelés meghiúsítva!');
      setRendelesAbortModal(false);
      listRendeles();
    } catch (ex) { }
  };

  const renderRendelesDeleteModal = () => {
    return (
      <Modal
        isOpen={rendelesDeleteModal}
        toggle={() => {
          setRendelesDeleteModal(false);
        }}
        backdrop="static"
      >
        <ModalHeader>Rendelés törlése</ModalHeader>
        <ModalBody>Biztosan törölni szeretné a rendelést?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleDeleteClick}>Igen</Button>
          <Button onClick={() => {
            setRendelesDeleteModal(false);
          }}
          >
            Mégse
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderRendelesAbortModal = () => {
    return (
      <Modal
        isOpen={rendelesAbortModal}
        toggle={() => {
          setRendelesAbortModal(false);
        }}
        backdrop="static"
      >
        <ModalHeader>Rendelés meghiúsítása</ModalHeader>
        <ModalBody>Biztosan meg szeretné hiúsítani a rendelést?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAbortClick}>Igen</Button>
          <Button onClick={() => {
            setRendelesAbortModal(false);
          }}
          >
            Mégse
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderCikkModal = () => {
    const loadCikkOptions = (input, callback) => {
      if (_.isEmpty(input)) callback([]);
      Services.listCikk({ param: input, receptura: false, ignoretipus: true }, (err, res) => {
        if (!err) {
          callback(res[0].map((item) => { return { cikk: item }; }));
        } else {
          callback([]);
        }
      });
    };

    const handleMennyisegChange = (e, value) => {
      calculate(null, null, value, null);
      setMennyiseg(value || '');
    };

    const round = (value, precision) => {
      const multiplier = 10 ** (precision || 0);
      return Math.round(value * multiplier) / multiplier;
    };

    const calculate = (netUnit, grossVal, quantity, vatValue) => {
      const decDigit = penznem ? penznem.tizedesjegy : 6;
      const decMult = decDigit === 0 ? 1 : decDigit;

      let netto = parseFloat(netUnit || nettoEgysegar ? NumberFormatter.removeSpacesFromString(netUnit || nettoEgysegar) : 0);
      let brutto = parseFloat(grossVal || bruttoOsszAr ? NumberFormatter.removeSpacesFromString(grossVal || bruttoOsszAr) : 0);
      let menny = parseFloat(quantity || mennyiseg ? NumberFormatter.removeSpacesFromString(quantity || mennyiseg) : 0);
      let afaSzazalekErtek = parseFloat(vatValue || vatValue === 0 ? vatValue : afaSzazalek);

      if (menny <= 0) {
        return;
      }

      afaSzazalekErtek /= 100;

      if (netUnit || quantity || vatValue || vatValue === 0) {
        let bruttoE = ((netto * (1 + afaSzazalekErtek)) * (10 ** decMult)) / (10 ** decMult);
        let bruttoO = ((netto * (1 + afaSzazalekErtek)) * menny * (10 ** decMult)) / (10 ** decMult);
        let afaO = round((round(bruttoO, decDigit) / (1 + afaSzazalekErtek) * afaSzazalekErtek) * (10 ** decMult) / (10 ** decMult), decDigit);
        let nettoO = (round(bruttoO, decDigit) - afaO) * (10 ** decMult) / (10 ** decMult);
        let afaE = (netto * afaSzazalekErtek * (10 ** decMult)) / (10 ** decMult);

        setNettoEgysegar(removeRedundantZeros(netUnit || nettoEgysegar));
        setAfaEgysegar(removeRedundantZeros(afaE.toFixed(6)));
        setBruttoEgysegar(removeRedundantZeros(bruttoE.toFixed(6)));
        setNettoOsszAr(removeRedundantZeros(nettoO.toFixed(decDigit)));
        setAfaOsszAr(removeRedundantZeros(round(afaO, decDigit).toFixed(decDigit)));
        setBruttoOsszAr(removeRedundantZeros(round(bruttoO, decDigit).toFixed(decDigit)));
        setSzamolasIrany(1);
      } else if (grossVal) {
        let afaO = round((brutto / (1 + afaSzazalekErtek) * afaSzazalekErtek * (10 ** decMult)) / (10 ** decMult), decDigit);
        let nettoO = brutto - afaO;
        let afaE = afaO / menny;
        let nettoE = nettoO / menny;
        let bruttoE = brutto / menny;

        setNettoEgysegar(removeRedundantZeros(nettoE.toFixed(6)));
        setAfaEgysegar(removeRedundantZeros(afaE.toFixed(6)));
        setBruttoEgysegar(removeRedundantZeros(bruttoE.toFixed(6)));
        setNettoOsszAr(removeRedundantZeros(nettoO.toFixed(decDigit)));
        setAfaOsszAr(removeRedundantZeros(afaO.toFixed(decDigit)));
        setBruttoOsszAr(removeRedundantZeros(grossVal || bruttoEgysegar));
        setSzamolasIrany(2);
      }
    };

    const handleCikkSubmitClick = () => {
      if (!rendelesCikk.cikk) {
        route.globals.addError('Cikk választása kötelező!');
      } else {
        // Ha már van ilyen cikk, akkor az törlésre kerül, hogy az új felülírja
        let array = [...cikkList];
        array = array.filter(item => item.cikk.id != rendelesCikk.cikk.id);
        let nettoegysegar = NumberFormatter.removeSpacesFromString(nettoEgysegar);
        let afaegysegar = NumberFormatter.removeSpacesFromString(afaEgysegar);
        let bruttoegysegar = NumberFormatter.removeSpacesFromString(bruttoEgysegar);
        let nettoertek = NumberFormatter.removeSpacesFromString(nettoOsszAr);
        let afaertek = NumberFormatter.removeSpacesFromString(afaOsszAr);
        let bruttoertek = NumberFormatter.removeSpacesFromString(bruttoOsszAr);
        let mennyisegCikk = NumberFormatter.removeSpacesFromString(mennyiseg);
        let afaid = afaId && parseInt(afaId, 10);
        let fromajanlat = false;
        array.push({
          ...rendelesCikk,
          // nettoEgysegar,
          // afaEgysegar,
          // bruttoEgysegar,
          // nettoOsszAr,
          // afaOsszAr,
          // bruttoOsszAr,
          // mennyiseg,
          nettoegysegar,
          afaegysegar,
          bruttoegysegar,
          nettoertek,
          afaertek,
          bruttoertek,
          mennyisegCikk,
          afaid,
          afaSzazalek,
          szamolasIrany,
          fromajanlat
        });
        setCikkList(array);
        setCikkModal(false);
      }
    };

    const renderInputField = (label, onChange, value, disabled, required, name, onBlur) => {
      if (required) {
        return (
          <Col md="4" className="mb-1">
            <AvGroup>
              <Label>{label}</Label>
              <AvInput onChange={onChange} onBlur={onBlur} value={value} disabled={disabled} name={name} required />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </Col>
        );
      }

      return (
        <Col md="4" className="mb-1">
          <Label>{label}</Label>
          <Input onChange={onChange} value={value} disabled={disabled} />
        </Col>
      );
    };

    return (
      <Modal
        isOpen={cikkModal}
        toggle={() => {
          setCikkModal(!cikkModal);
        }}
        backdrop="static"
      >
        <AvForm onValidSubmit={handleCikkSubmitClick}>
          <ModalHeader>Cikk kiválasztása</ModalHeader>
          <ModalBody>
            <Row className="mb-1">
              <Col md="12">
                <Label>Cikk: *</Label>
              </Col>
              <Col md="12">
                <SelectAsync
                  styles={selectStyles}
                  name="cikk"
                  id="cikky"
                  placeholder="Kezdjen el gépelni a kereséshez..."
                  loadingMessage={() => 'Keresés...'}
                  noOptionsMessage={() => 'Nincs keresési eredmény'}
                  onChange={(v) => {
                    if (!v) {
                      setMennyiseg('');
                      clearPrices();
                    }
                    setRendelesCikk(v || {});
                    setAfaId(v && v.cikk ? v.cikk.aktualisAfaId : '');
                    setAfaSzazalek(v && v.cikk ? v.cikk.aktualisAfaErtek : '');
                    setIsNullaAfa(v && v.cikk && v.cikk.aktualisAfaErtek === 0);
                  }}
                  loadOptions={_.debounce(loadCikkOptions, 1000)}
                  // Ha létezik a belső cikk object és megvan adva az id értéke, akkor a cikk object-et használja value-ként, egyébként null
                  value={(rendelesCikk.cikk && rendelesCikk.cikk.id && rendelesCikk) || null}
                  getOptionLabel={option => (option && option.cikk.megnevezes + ' (' + option.cikk.cikkszam + ')') || ''}
                  getOptionValue={option => option && option.cikk.id}
                  isDisabled={rendelesCikk.mennyiseg}
                  isClearable
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="7" className="mb-1">
                <SlaveAvInput
                  key="mennyiseg"
                  id="mennyiseg"
                  label="Mennyiség: *"
                  required
                  disabled={!rendelesCikk.cikk}
                  value={mennyiseg}
                  autoComplete="off"
                  onChange={(e, value) => {
                    setMennyiseg(value);
                    calculate(null, null, value || -1, null);
                    if (!value || value === '0') {
                      clearPrices();
                    }
                  }}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero
                  inputGroupAddon={rendelesCikk && rendelesCikk.cikk && rendelesCikk.cikk.mennyisegiEgyseg ? rendelesCikk.cikk.mennyisegiEgyseg : ''}
                />
              </Col>
              <Col md="5" className="mb-1">
                <Label for="afa">ÁFA kulcs: *</Label>
                <AvGroup>
                  <AvInput
                    type="select"
                    name="afa"
                    id="afa"
                    value={afaId}
                    onChange={(e, value) => {
                      setAfaId(value);
                      const { ertek } = value && afaKulcsJSON.find(a => a.id === parseInt(value, 10));
                      setAfaSzazalek(ertek);
                      setIsNullaAfa(ertek === 0);
                      calculate(null, null, null, ertek);
                    }}
                    disabled={!rendelesCikk.cikk}
                    required
                  >
                    {rendelesCikk.cikk && afaKulcsOptions}
                  </AvInput>
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </Col>
            </Row>
            <div className="row">
              <div className="col-md-6">
                <b>{'Egységár' + (penznem ? ' (' + penznem.jel + '):' : ':')}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <SlaveAvInput
                  key="nettoEgysegar"
                  id="nettoEgysegar"
                  label="Nettó egységár: *"
                  required={mennyiseg}
                  disabled={!rendelesCikk || !mennyiseg || mennyiseg === '0'}
                  value={nettoEgysegar}
                  onChange={(e, value) => calculate(value, null, null, null)}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero
                  autoComplete="off"
                />
              </div>
              <div className="col-md-4">
                <SlaveAvInput
                  id="afa"
                  label="ÁFA érték:"
                  disabled
                  value={afaEgysegar}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero={!isNullaAfa || (!nettoEgysegar || !bruttoEgysegar)}
                />
              </div>
              <div className="col-md-4">
                <SlaveAvInput
                  id="bruttoEgysegar"
                  label="Bruttó egységár:"
                  disabled
                  value={bruttoEgysegar}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <b>{'Össz. érték' + (penznem ? ' (' + penznem.jel + '):' : ':')}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <SlaveAvInput
                  key="netto"
                  id="nettoErtek"
                  label="Nettó érték:"
                  disabled
                  value={nettoOsszAr}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero
                />
              </div>
              <div className="col-md-4">
                <SlaveAvInput
                  id="afa"
                  label="ÁFA érték:"
                  disabled
                  value={afaOsszAr}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero={!isNullaAfa || (!nettoEgysegar || !bruttoEgysegar)}
                />
              </div>
              <div className="col-md-4">
                <SlaveAvInput
                  id="bruttoErtek"
                  label="Bruttó érték: *"
                  required={mennyiseg}
                  disabled={!rendelesCikk || !mennyiseg || mennyiseg === '0'}
                  value={bruttoOsszAr}
                  onChange={(e, value) => calculate(null, value, null, null)}
                  szuroTipus={NumberFormatter.OSSZEG_6}
                  emptyStringOnZero
                  autoComplete="off"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">Hozzáadás</Button>
            <Button
              type="button"
              onClick={() => {
                setCikkModal(false);
              }}
            >
              Mégse
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  };

  const renderCikkViewModal = () => {
    const { cikk: cikkBean } = rendelesCikk;

    return (
      <Modal
        isOpen={cikkViewModal}
        toggle={() => setCikkViewModal(!cikkViewModal)}
        backdrop="static"
      >
        <ModalHeader>Cikk megtekintés</ModalHeader>
        <ModalBody>
          <b>Megnevezés: </b>
          {cikkBean && cikkBean.megnevezes}
          <br />
          <b>Cikkszám: </b>
          {cikkBean && cikkBean.cikkszam}
          <br />
          <b>Mennyiség: </b>
          {rendelesCikk.mennyisegCikk + ' ' + (cikkBean && cikkBean.mennyisegiEgyseg)}
          <br />
          <b>ÁFA: </b>
          {rendelesCikk.afaSzazalek + '%'}
          <br />
          <b>Nettó egységár: </b>
          {NumberFormatter.formatterSpaceOsszegPenznem(rendelesCikk.nettoegysegar, null, penznem && penznem.jel)}
          <br />
          <b>Bruttó egységár: </b>
          {NumberFormatter.formatterSpaceOsszegPenznem(rendelesCikk.bruttoegysegar, null, penznem && penznem.jel)}
          <br />
          <b>Nettó érték: </b>
          {NumberFormatter.formatterSpaceOsszegPenznem(rendelesCikk.nettoertek, null, penznem && penznem.jel)}
          <br />
          <b>Áfa érték: </b>
          {NumberFormatter.formatterSpaceOsszegPenznem(rendelesCikk.afaertek, null, penznem && penznem.jel)}
          <br />
          <b>Bruttó érték: </b>
          {NumberFormatter.formatterSpaceOsszegPenznem(rendelesCikk.bruttoertek, null, penznem && penznem.jel)}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => {
            setCikkViewModal(false);
          }}
          >
            Bezárás
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderAjanlatModal = () => {
    const handleAjanlatSubmitClick = () => {
      const selectedArray = [];
      ajanlatModalCikkJSON.forEach((item) => {
        if (ajanlatModalCikkSelectedIdList.includes(item.id)) {
          selectedArray.push({ ...item, fromajanlat: true });
        }
      });
      // Felülírja a meglévő cikket az újjal amennyiben az id passzol
      setCikkList(cikkList.filter(cikkItem => !selectedArray.map(item => item.cikk.id).includes(cikkItem.cikk.id)).concat(selectedArray));
      setAjanlat(ajanlatModalVariable);
      setAjanlatModal(false);
    };

    const handleAjanlatChange = async (v) => {
      setAjanlatModalCikkJSON([]);
      setAjanlatModalCikkSelectedList([]);
      setAjanlatModalVariable(v);
      if (v) {
        try {
          const json = await Services.listAjanlatCikk({ id: v.id });
          let array = [];

          json.forEach((j) => {
            let id = null;
            let nettoegysegar = j.nettoEgysegar;
            let afaegysegar = j.afaEgysegar;
            let bruttoegysegar = j.bruttoEgysegar;
            let nettoertek = j.nettoErtek;
            let afaertek = j.afaErtek;
            let bruttoertek = j.bruttoErtek;
            let mennyisegCikk = j.mennyiseg;
            let afaid = j.afaId;
            let afaSzazalek = j.afaSzazalek;
            let szamolasIrany = j.irany;
            array.push({
              ...j,
              id,
              // nettoEgysegar,
              // afaEgysegar,
              // bruttoEgysegar,
              // nettoOsszAr,
              // afaOsszAr,
              // bruttoOsszAr,
              // mennyiseg,
              nettoegysegar,
              afaegysegar,
              bruttoegysegar,
              nettoertek,
              afaertek,
              bruttoertek,
              mennyisegCikk,
              afaid,
              afaSzazalek,
              szamolasIrany,
            });
          });
          setAjanlatModalCikkJSON(array);
        } catch (ex) { }
      }
    };

    return (
      <Modal
        isOpen={ajanlatModal}
        toggle={() => {
          setAjanlatModal(false);
        }}
        backdrop="static"
        size="lg"
      >
        <ModalHeader>Ajánlat hozzáadása</ModalHeader>
        <ModalBody>
          <Col className="mb-1">
            <Select
              styles={selectStyles}
              name="ajanlat"
              id="ajanlat"
              placeholder="Válassz ajánlatot..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => handleAjanlatChange(v)}
              options={ajanlatJSON}
              getOptionLabel={option => option.bizonylat && option.bizonylat.bizonylatszam}
              getOptionValue={option => option.id}
              isClearable
            />
          </Col>
          <Col>
            {ajanlatModalVariable && ajanlatModalCikkJSON && renderCikkTable(cikkTableTypes.ajanlat, ajanlatModalCikkJSON, setAjanlatModalCikkJSON)}
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAjanlatSubmitClick} disabled={!ajanlatModalVariable}>Hozzáadás</Button>
          <Button onClick={() => {
            setAjanlatModal(false);
          }}
          >
            Mégse
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      <iframe name="pdf-area-vevoi-rendeles" id="pdf-area-vevoi-rendeles" title="print" src={printUrl} style={{ visibility: 'hidden', height: 0, width: 0 }} />
      <Row className="mb-1" style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Col md="1" className="row justify-content-center align-self-center">
          Ügyfél:
        </Col>
        <Col>
          <SelectAsync
            styles={selectStyles}
            style={styleNoMarginBottom}
            name="ugyfel"
            id="ugyfel"
            placeholder="Kezdjen el gépelni..."
            loadingMessage={() => 'Keresés...'}
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => setUgyfel((v && v.id) || null)}
            loadOptions={_.debounce(loadPartnerOptions, 1000)}
            getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
            getOptionValue={option => option.id}
            defaultOptions
            isClearable
          />
        </Col>
        <Col className="row justify-content-center align-self-center" style={{ marginRight: -30, marginLeft: -30, padding: 0 }}>
          Megrendelés kelte (-tól/-ig):
        </Col>
        <Col>
          <DateTimePicker
            id="startDate"
            name="startDate"
            value={startDate && new Date(startDate)}
            onChange={(e, v) => setStartDate(v === '' ? null : v)}
            style={styleNoMarginBottom}
            containerClassName="organw-date-time"
            format="YYYY-MM-DD"
            messages={{ dateButton: 'dátum' }}
            time={false}
          />
        </Col>
        <Col>
          <DateTimePicker
            id="endDate"
            name="endDate"
            value={endDate && new Date(endDate)}
            onChange={(e, v) => setEndDate(v === '' ? null : v)}
            style={styleNoMarginBottom}
            containerClassName="organw-date-time"
            format="YYYY-MM-DD"
            messages={{ dateButton: 'dátum' }}
            time={false}
          />
        </Col>
        <Button
          type="button"
          color="primary"
          onClick={() => listRendeles()}
        >
          Keresés
        </Button>
      </Row>
      {hasKarbRole && (
        <Button type="button" onClick={handleUjRendelesClick} color="primary" className="mb-1">
          Új vevői rendelés
        </Button>
      )}
      {
        {
          TABL: renderRendelesTable(),
          DATA: <DataEmptyState />,
          NETW: <NetworkEmptyState hasImage submitClick={() => listRendeles()} />,
          LOAD: <Loader />,
        }[tableRendelesStatus]
      }
      {renderRendelesModal(cikkList, setCikkList)}
      {renderRendelesViewModal()}
      {renderRendelesDeleteModal()}
      {renderRendelesAbortModal()}
      {renderSzamlazasModal()}
      {renderCikkModal()}
      {renderCikkViewModal()}
      {renderAjanlatModal()}
    </>
  );
};

export default RendelesContent;
