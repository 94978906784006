import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

class BizonylattipusLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      modalRogzit: false,
      modalModosit: false,
      modalTorles: false,
      bizonylattipusJson: [],
      bizonylattipusModositObj: {},
      enums: [],
      raktarMezo: [],
      raktarMezoInaktiv: true,
      existsRaktar: false,
    };

    this.iconFormatter = this.iconFormatter.bind(this);
    this.statusFormatter = this.statusFormatter.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.updateTable();
        this.getEnums();
      }
    }
  }

  // componentDidMount() {
  //   this.updateTable();
  //   this.getEnums();
  // }

  // componentWillReceiveProps() {
  //   this.updateTable();
  //   this.getEnums();
  // }

    /** Táblázat adatainak frissítése adatbázisból. */
    updateTable = () => {
      Services.listBizonylattipus((err, res) => {
        if (!err) {
          let newArray = res[0];
          // Szűkítő feltételek stringgé alakítása
          newArray.forEach((bt) => {
            // INT és BOOLEAN mezők STRING-é alakítása
            for (let b in bt) {
              if (bt.hasOwnProperty(b)) {
                if ((Number(bt[b]) === bt[b] && bt[b] % 1 === 0) || typeof (bt[b]) === 'boolean') {
                  bt[b] = bt[b].toString();
                }
              }
            }
            // Státusz stringgé alakítása
            if (bt.statusz) {
              bt.statusz = JSON.stringify(bt.statusz);
            }
            // Típus stringgé alakítása
            if (bt.tipus) {
              bt.tipus = JSON.stringify(bt.tipus);
            }
          });
          this.setState({ bizonylattipusJson: newArray });
        }
      });
      this.setExistsRaktar();
    }

    setExistsRaktar = () => {
      Services.existsRaktar((err, res) => {
        if (!err) {
          this.setState({ existsRaktar: res[0].existsraktar });
        }
      });
    }

    /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
    getEnums = () => {
      this.setState({ enums: [] });
      Services.listBizonylattipusEnums((err, res) => {
        if (!err) {
          this.setState({ enums: res[0] });
        }
      });
    }

    /**
    * Paraméterként kapott bizonylattípus objektum felvétele adatbázisba.
    * @param {SytheticEvent} event
    * @param {object} bizonylattipusObj Bizonylattípus adatokat tartalamzó objektum.
    */
    addBizonylattipus = (event, bizonylattipusObj) => {
      this.setExistsRaktar();
      if (!this.state.existsRaktar) {
        bizonylattipusObj.raktarhozkapcs = false;
      }
      Services.createBizonylattipus(bizonylattipusObj, (err, res) => {
        if (!err) {
          this.toggleRogzit();
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattípus felvéve!');
        }
      });
    }

    /**
    * Paraméterként kapott bizonylattípus objektum módosítása adatbázisba.
    * @param {SytheticEvent} event
    * @param {object} bizonylattipusObj Bizonylattípus adatokat tartalamzó objektum.
    */
    modifyBizonylattipus = (event, bizonylattipusObj) => {
      this.setExistsRaktar();
      if (!this.state.existsRaktar) {
        bizonylattipusObj.raktarhozkapcs = false;
      }
      bizonylattipusObj.id = this.state.bizonylattipusModositObj.id;
      Services.updateBizonylattipus(bizonylattipusObj, (err, res) => {
        if (!err) {
          this.toggleModosit();
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattípus módosítva!');
        }
      });
    }

    /**
    * Paraméterként kapott Bizonylattípus id törlése adatbázisban
    * @param {int} id
    */
    deleteBizonylattipus = (id) => {
      this.toggleTorles();

      Services.deleteBizonylattipus(id, (err, res) => {
        if (!err) {
          this.updateTable();
          this.props.globals.addSuccess('Bizonylattípus törölve!');
        }
      });
    }

    toggleRogzit = () => {
      this.setState({
        modal: !this.state.modal
      });
    }

    toggleModosit = () => {
      this.setState({
        modalModosit: !this.state.modalModosit
      });
    }

    toggleTorles = () => {
      this.setState({
        modalTorles: !this.state.modalTorles
      });
    }

    handleEditClick = (id) => {
      const bizonylattipus = this.state.bizonylattipusJson.find(element => element.id === id);
      this._currentEditId = id;
      this.setState({
        bizonylattipusModositObj: bizonylattipus
      });
      this.getEnums();
      this.setExistsRaktar();
      this.toggleModosit();
    }

    handleDeleteClick = (id) => {
      this._currentDeleteId = id;

      this.getEnums();
      this.toggleTorles();
    }

    iconFormatter(cell) {
      return (
        <div>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BIZONYLAT_TIPUS', 'KARB') && (
            <React.Fragment>
              <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
                Módosítás
              </UncontrolledTooltip>
              <Button className="icon-trash table-action-icon"  id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
                Törlés
              </UncontrolledTooltip>
            </React.Fragment>
          )}
        </div>
      );
    }

    /**
    * Visszaadja a bizonylattípushoz tartozó státus jelvényeket.
    * @param {boolean} cell - Cella adatot tartalmazó szöveg.
    * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
    */
    statusFormatter(cell) {
      if (!cell) return;
      if (cell === '{"name":"AKTIV","caption":"Aktív"}') {
        return <span className="badge badge-success">Aktív</span>;
      }
      return <span className="badge badge-default">Inaktív</span>;
    }

    /**
    * Visszaadja a raktarhozkapcs tartozó státus jelvényeket.
    * @param {boolean} cell - Cella adatot tartalmazó szöveg.
    * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
    */
    raktarhozkapcsFormatter(cell) {
      if (cell === 'true') return <span className="badge badge-success">Igen</span>;
      if (cell === 'false') return <span className="badge badge-default">Nem</span>;
    }

    renderStatusOptions = () => {
      return this.state.enums.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
    }

    renderStatusTypes = () => {
      let statusType = {};
      this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
      return statusType;
    }

    render() {
      const options = {
        sizePerPage: 5,
        paginationShowsTotal: PaginationTotal,
        sizePerPageList: [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: '25', value: 25
        }],
      };

      const enums = this.state.enums;
      const modifyObj = this.state.bizonylattipusModositObj;

      // Státusz típusok meghatározása
      let statusType = {};
      if (enums.length) {
        enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
      }

      return (
        <div>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BIZONYLAT_TIPUS', 'KARB') && (
            <div className="row">
              <div className="col-sm-12">
                <Button className="pull-left table-new-button" color="primary" onClick={this.toggleRogzit}>Új bizonylattípus</Button>
              </div>
            </div>
          )}

          <BootstrapTable data={this.state.bizonylattipusJson} pagination options={options}>
            <TableHeaderColumn dataField="azonosito" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Azonosító</TableHeaderColumn>
            <TableHeaderColumn dataField="nev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Bizonylattípus neve</TableHeaderColumn>
            <TableHeaderColumn
              dataField="raktarhozkapcs"
              dataFormat={this.raktarhozkapcsFormatter}
              filter={{ type: 'SelectFilter', options: { true: 'Igen', false: 'Nem' }, placeholder: 'Összes' }}
              dataAlign="center"
              hidden={!this.state.existsRaktar}
            >
              Raktárhoz kapcsolódik?
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="statusz"
              dataFormat={this.statusFormatter}
              filter={{ type: 'SelectFilter', options: this.renderStatusTypes(), placeholder: 'Összes' }}
              dataAlign="center"
            >
              Státusz
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              isKey
              dataFormat={this.iconFormatter}
              hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BIZONYLAT_TIPUS', 'KARB')}
            >
              Műveletek
            </TableHeaderColumn>
          </BootstrapTable>

          {/* Felvitel BEGIN */}
          <Modal isOpen={this.state.modal} toggle={this.toggleRogzit} className={this.props.className}>
            <AvForm onValidSubmit={this.addBizonylattipus}>
              <ModalHeader toggle={this.toggleRogzit}>Bizonylattípus felvétel</ModalHeader>
              <ModalBody>
                <AvGroup>
                  <Label for="azonosito">Bizonylattípus azonosító: *</Label>
                  <AvInput name="azonosito" id="azonosito" maxLength="10" required />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="nev">Bizonylattípus megnevezése: *</Label>
                  <AvInput name="nev" id="nev" maxLength="50" required />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
                {this.state.existsRaktar && (
                  <AvRadioGroup name="raktarhozkapcs" label="Raktárhoz kapcsolódó" defaultValue="false" errorMessage="Kötelező mező!" required>
                    <AvRadio label="Igen" value="true" id="radioOption1" />
                    <AvRadio label="Nem" value="false" id="radioOption2" />
                  </AvRadioGroup>
                )}
                <Row>
                  <Col md={12}>
                    <AvField type="select" name="statusz" label="Státusz" value="AKTIV" errorMessage="Kötelező mező!" required>
                      {this.renderStatusOptions()}
                    </AvField>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary">Mentés</Button>{' '}
                <Button type="button" color="secondary" onClick={this.toggleRogzit}>Mégse</Button>
              </ModalFooter>
            </AvForm>
          </Modal>
          {/* Felvitel END */}

          {/* Módosítás BEGIN */}
          <Modal isOpen={this.state.modalModosit} toggle={this.toggleModosit} className={this.props.className} backdrop="static" size="md">
            <AvForm onValidSubmit={this.modifyBizonylattipus}>
              <ModalHeader>Bizonylattípus módosítás</ModalHeader>
              <ModalBody>
                <AvGroup>
                  <Label for="azonosito">Bizonylattípus azonosító: *</Label>
                  <AvInput name="azonosito" id="azonosito" value={modifyObj.azonosito} maxLength="10" required disabled />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="nev">Bizonylattípus megnevezése: *</Label>
                  <AvInput name="nev" id="nev" value={modifyObj.nev} maxLength="50" required />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
                {this.state.existsRaktar && (
                  <AvRadioGroup name="raktarhozkapcs" label="Raktárhoz kapcsolódó" value={modifyObj.raktarhozkapcs} errorMessage="Kötelező mező!" required>
                    <AvRadio label="Igen" value="true" id="radioOption1" />
                    <AvRadio label="Nem" value="false" id="radioOption2" />
                  </AvRadioGroup>
                )}
                <Row>
                  <Col md={12}>
                    <AvField type="select" name="statusz" value={modifyObj.statusz && JSON.parse(modifyObj.statusz).name} label="Státusz" errorMessage="Kötelező mező!" required>
                      {this.renderStatusOptions()}
                    </AvField>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="primary">Mentés</Button>{' '}
                <Button type="button" color="secondary" onClick={this.toggleModosit}>Mégse</Button>
              </ModalFooter>
            </AvForm>
          </Modal>
          {/* Módosítás END */}

          {/* Törlés BEGIN */}
          <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className}>
            <ModalHeader toggle={this.toggleTorles}>Törlés</ModalHeader>
            <ModalBody>
                          Valóban törölni kívánja a kiválasztott tételt?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.deleteBizonylattipus(this._currentDeleteId)}>OK</Button>
              <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
            </ModalFooter>
          </Modal>
          {/* Törlés END */}

        </div>
      );
    }
}

BizonylattipusLista.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default BizonylattipusLista;
