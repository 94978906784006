import React, { Component } from 'react';
import {
  Button,
  Label,
  FormGroup,
  Input
} from 'reactstrap';
import Select from 'react-select';
import FlagIcon from 'react-flag-icon-css';
import Lib from './lib';

const partnerUrl = window.location.origin + '/api/partner/';

/**
 * Form to save Phone numbers.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
class PhoneNumbers extends Component {
  static listTelefonEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: partnerUrl + 'partner/telefonenum',
      args: { requestType: 'partner-telefonenum-list' }
    }], fnDone);
  }

  constructor(props) {
    super(props);
    this.state = {
      telefonszamok: [],
    };
  }

  componentDidMount() {
    this.updateTelefonszamok(this.props.numbers);
    this.getTelepulesEnum();
  }

  componentWillReceiveProps(nextProps) {
    let newArray = [];
    if (nextProps && nextProps.numbers ) {
      nextProps.numbers.forEach((item) => {
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        newArray.push(item);
      });
    }
    this.updateTelefonszamok(newArray);
  }

  getTelepulesEnum = () => {
    PhoneNumbers.listTelefonEnum((err, res) => {
      if (!err) {
        let telefonTipusOptions = res[0][1].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({
          rawTelefonOptions: res[0][1],
          telefonTipusOptions: telefonTipusOptions,
          orszagOptions: res[0][0]
        });
      }
    });
  }

  updateTelefonszamok = (numbers) => {
    this.setState({ telefonszamok: numbers });
  }

  flagFormatter = (props, idx) => {
    let handleMouseDown = (event) => {
      this.setValue(props.option.num, idx);
    };

    return (
      <div onMouseDown={handleMouseDown} className="flag-format">
        <span
          className={'flag-icon flag-icon-' + props.option.name.toLowerCase()}
        /> <b>{props.option.caption}</b> +{props.option.num}
      </div>
    );
  }

  handleAddTelefonszam = () => {
    let telefon = this.state.telefonszamok;
    telefon.push({
      tipus: '',
      orszag: {},
      szam: '',
      elsodleges: false
    });
    this.setState({ telefonszamok: telefon });
  }

  handleDeleteTelefonszam = (idx) => {
    let newState = this.state.telefonszamok;
    newState.splice(idx, 1);
    this.setState({
      telefonszamok: newState
    });
  }

  handleElsodlegesChange = (val) => {
    let newState = this.state.telefonszamok;
    if (val.target.checked) {
      newState.forEach((item, index) => {
        if (index === val.target.idx) {
          newState[index].elsodleges = true;
        } else {
          newState[index].elsodleges = false;
        }
      });
    }
    this.setState({ telefonszamok: newState }, () => {
    });
  }

  handleTelefonSzamChange = (e) => {
    let idx = e.target.getAttribute('idx');
    let newState = this.state.telefonszamok;
    newState[idx].szam = e.target.value;
    this.setState({ telefonszamok: newState });
  }

  handleTelefonTipusChange = (val) => {
    let idx = val.target.idx;
    let value = val.target.value;

    let newState = this.state.telefonszamok;
    newState[idx].tipus = value;
    this.setState({ telefonszamok: newState });
  }

  setValue = (value, idx) => {
    let newState = this.state.telefonszamok;
    if (newState[idx]) {
      this.state.orszagOptions.forEach((item) => {
        if (item.num === value) {
          newState[idx].elohivo = value;
          newState[idx].orszag = item.name.toUpperCase();
        }
      });
      this.setState({ telefonszamok: newState });
    }
  }

  // handleAddTelefonszam = (e) => {
  //   // e.preventDefault();
  //   let telefonszamok = this.state.telefonszamok.concat([{ szam: 'asd' }]);
  //   this.setState({
  //     telefonszamok: telefonszamok
  //   });
  // }
  //

  renderTelefonszamok = () => {
    let telefonszamok = [];
    this.state.telefonszamok.forEach((telefonszam, idx) => {
      telefonszamok.push(<React.Fragment key={idx.toString()}>
        <div className="col-md-2 form-group">
          <Label for={'telefonszamtipus-' + idx}>Típus:</Label>
          {/* <Select
            name={'telefonszamtipus-' + idx}
            value={this.state.telefonszamok && this.state.telefonszamok[idx].tipus}
            onChange={(val) => { this.handleTelefonTipusChange({ target: { idx: idx, value: val } }); }}
            idx={idx}
            simpleValue
            clearable={false}
            searchable={false}
            placeholder="Válassz típust..."
            noResultsText="Nincs keresési eredmény"
            searchPromptText="Válassz típust..."
            loadingPlaceholder="Töltés..."
            options={[
              { value: 'MOBIL', label: 'Mobiltelefon' },
              { value: 'VEZETEK', label: 'Vezetékes' },
              { value: 'FAX', label: 'Fax' },
            ]}
            valueKey="value"
            labelKey="label"
          /> */}
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="formOrszag"
            id="formOrszag"
            placeholder="Típus..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            // searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={(val) => { this.handleTelefonTipusChange({ target: { idx: idx, value: val } }); }}
            isClearable={false}
            isSearchable={false}
            ignoreAccents={false}
            // loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
            isLoading={false}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            value={this.state.orszagValue}
            cache={false}
            autoload={false}
            options={[
              { value: 'MOBIL', label: 'Mobiltelefon' },
              { value: 'VEZETEK', label: 'Vezetékes' },
              { value: 'FAX', label: 'Fax' },
            ]}
          />
        </div>
        <div className="col-md-3 form-group">
          <Label for={'oszaghivo' + idx}>Országhívó:</Label>
          <Select
            name={'oszaghivo' + idx}
            id={'oszaghivo' + idx}
            optionComponent={props => this.flagFormatter(props, idx)}
            options={this.state.orszagOptions}
            clearable={false}
            searchable={false}
            onChange={this.setValue}
            placeholder="Válassz országhívót..."
            noResultsText="Nincs keresési eredmény"
            searchPromptText="Válassz országhívót..."
            loadingPlaceholder="Töltés..."
            valueKey="num"
            labelKey="num"
            value={this.state.telefonszamok && this.state.telefonszamok[idx].elohivo!=null ?  this.state.telefonszamok[idx].elohivo : this.state.telefonszamok[idx].orszag}
          />

        </div>
        <div className="col-md-3">
          <FormGroup>
            <Label for="jobb_prizma_a">Telefonszám :</Label>
            <Input
              name="jobb_prizma_a"
              idx={idx}
              onChange={this.handleTelefonSzamChange}
              id="jobb_prizma_a"
              value={this.state.telefonszamok && this.state.telefonszamok[idx].szam}
            />
          </FormGroup>
        </div>
        <div className="col-md-2">
          <FormGroup>
            <Label check style={{ marginTop: '30px' }}>
              <Input
                type="checkbox"
                id={'elsodleges' + idx}
                idx={idx}
                name={'elsodleges' + idx}
                checked={this.state.telefonszamok && this.state.telefonszamok[idx].elsodleges}
                onChange={(val) => { this.handleElsodlegesChange({ target: { idx: idx, checked: val.target.checked } }); }}
              />
              {' '}Elsődleges
            </Label>
          </FormGroup>
        </div>
        <div className="col-md-2">
          <Button
            color="danger"
            style={{ marginTop: '29px' }}
            type="button"
            onClick={() => this.handleDeleteTelefonszam(idx)}
          >
            Törlés
          </Button>{' '}
        </div>
      </React.Fragment>);
    });
    return (
      telefonszamok
    );
  }

  render() {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <React.Fragment>
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Telefonszámok:</h5></div>
        </div>
        {this.renderTelefonszamok()}
        <div className="col-md-12">
          <Button
            className="pull-left table-new-button"
            onClick={this.handleAddTelefonszam}
            color="success"
            type="button"
          >
            Telefonszám hozzáadása
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

PhoneNumbers.propTypes = {
};

// Specifies the default values for props:
PhoneNumbers.defaultProps = {
};

export default PhoneNumbers;
