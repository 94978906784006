import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Service from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

class FizetesimodLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalForm: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      fizetesimodLista: [],
      fizetesimod: {}
    };
  }

  componentDidMount() {
    this.updateTable();
  }

  updateTable = () => {
    this.setState({
      dataLoaded: false, dataFailed: false, fizetesimodLista: [], fizetesimod: {}
    });
    Service.listFizetesimod((err, res) => {
      if (err) {
        this.setState({ dataFailed: true });
      } else {
        this.setState({ fizetesimodLista: res[0], dataLoaded: true, dataFailed: false });
      }
    });
  }

  toggleForm = (updateTable) => {
    this.setState({ modalForm: !this.state.modalForm }, () => {
      if (updateTable && typeof updateTable === 'function') updateTable();
    });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  handleCancel = () => {
    this.setState({ modalForm: !this.state.modalForm }, () => {
      this.updateTable();
    });
  }


  handleEditClick = (id) => {
    Service.getFizetesimod(id, (err, res) => {
      if (!err) {
        let obj = res[0];
        obj.aktiv = obj.aktiv ? 'Aktív' : 'Inaktív';
        this.setState({ fizetesimod: res[0] }, () => { this.toggleForm(); });
      }
    });
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_FIZMOD', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  saveFizetesimod = (event, obj) => {
    obj.aktiv = obj.aktiv === 'Aktív';
    if (this.state.fizetesimod && !this.state.fizetesimod.id) {
      this.insert(obj);
    } else {
      this.edit(obj);
    }
  }

  insert = (obj) => {
    Service.addFizetesimod(obj, (err) => {
      if (!err) {
        this.toggleForm(() => this.updateTable());
        this.props.globals.addSuccess('Fizetési mód hozzáadva!');
      }
    });
  }


  edit = (obj) => {
    Service.editFizetesimod(obj, this.state.fizetesimod.id, (err) => {
      if (!err) {
        this.toggleForm(() => this.updateTable());
        this.props.globals.addSuccess('Fizetési mód módosítva!');
      }
    });
  }

  deleteFizetesimod = (id) => {
    this.toggleTorles();
    Service.deleteFizetesimod(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Fizetési mód törölve!');
      }
    });
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const statusType = {
      true: 'Aktív',
      false: 'Inaktív'
    };

    return this.state.fizetesimodLista.length ? (
      <BootstrapTable data={this.state.fizetesimodLista} pagination options={options}>
        <TableHeaderColumn dataField="kod" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Fizetési mód kódja</TableHeaderColumn>
        <TableHeaderColumn dataField="nev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Fizetési mód neve</TableHeaderColumn>
        <TableHeaderColumn dataField="varians" tdStyle={{ whiteSpace: 'pre' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Variánsok</TableHeaderColumn>
        <TableHeaderColumn dataField="aktiv" dataFormat={this.statusFormatter} filter={{ type: 'SelectFilter', options: statusType, placeholder: 'Összes' }} dataAlign="center">Státusz</TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          dataFormat={this.iconFormatter}
          hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_FIZMOD', 'KARB')}
        >
          Műveletek
        </TableHeaderColumn>
      </BootstrapTable>
    ) : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_FIZMOD', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.toggleForm}>Új fizetési mód</Button>
            </div>
          </div>
        )}
        {(this.state.dataLoaded && !this.state.dataFailed) ? this.renderTable() : (this.state.dataFailed) ? <NetworkEmptyState submitClick={this.updateTable} /> : this.renderLoader()}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
              Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteFizetesimod(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalForm} toggle={this.toggleForm} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={this.saveFizetesimod}>
            <ModalHeader >{!this.state.fizetesimod.id ? 'Új fizetési mód rögzítése' : 'Fizetési mód módosítása'}</ModalHeader>
            <ModalBody>
              <AvGroup>
                <Label for="kod">Fizetési mód kódja: *</Label>
                <AvInput name="kod" id="kod" value={this.state.fizetesimod.kod} required maxLength="3" disabled={this.state.fizetesimod.id} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="nev">Fizetési mód neve: *</Label>
                <AvInput name="nev" id="nev" value={this.state.fizetesimod.nev} required maxLength="50" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvField type="select" name="aktiv" label="Státusz: *" ref="statusz" errorMessage="Kötelező mező!" value={this.state.fizetesimod.aktiv} required>
                <option value="" disabled >Válassz státuszt...</option>
                <option >Aktív</option>
                <option >Inaktív</option>
              </AvField>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.handleCancel} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default FizetesimodLista;
