import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import QueryEmptyState from '../../../commons/queryempty';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class BanktipusLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'LOAD',
      modalFelvitel: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      baseJson: [],
      aktivTipusok: [],
      aktivStatuszok: [],
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.updateTable();
  }

  /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
  getEnums = () => {
    Services.listInflacioEnums((err, res) => {
      if (!err) {
        let aktivTipusok = res[0][0].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        let aktivStatuszok = res[0][1].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({
          aktivTipusok: aktivTipusok,
          aktivStatuszok: aktivStatuszok
        });
      }
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  updateTable = () => {
    // this.getEnums();
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listBankttipus((err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        this.setState({
          baseJson: res[0],
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });

    Services.getBanktipus(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false });
        res[0].felhasznalva ? res[0].felhasznalva = 'true' : res[0].felhasznalva = 'false';
        _modositoObj = res[0];
        this.toggleFelvitel();
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      ev: '',
      tipus: '',
      mertek: '',
      statusz: ''
    };
  }


  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.clearModositoObj();
    this.toggleFelvitel();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_BANKTIPUS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    return cell.name === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  felhasznalvaFormatter = (cell) => {
    return cell ? 'Igen' : 'Nem';
  }

  addBanktipus = (event, banktipusObj) => {
    this.emptyToNull(banktipusObj);
    Services.addBanktipus(banktipusObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Banktípus felvéve!');
      }
    });
  }

  updateInflacio = (event, banktipusObj) => {
    banktipusObj.id = this._currentEditId;

    Services.editBanktipus(banktipusObj, this._currentEditId, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Banktípus módosítva!');
      }
    });
  }

  deleteBanktipus = (id) => {
    this.toggleTorles();
    Services.deleteBanktipus(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Banktípus törölve!');
      }
    });
  }

  filterType = (cell) => {
    // just return type for filtering or searching.
    return cell.caption;
  }

  renderBaseTable = () => {
    const pagination = true;
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    return (
      <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options} >
        <TableHeaderColumn
          dataField="azonosito"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
            Azonosító
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="nev"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
            Név
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="cim"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
            Cím
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="szam"
          tdStyle={{ whiteSpace: 'normal' }}
          width="120"
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
            Szám
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          width="100"
          dataFormat={this.iconFormatter}
          hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_BANKTIPUS', 'KARB')}
        >
            Műveletek
        </TableHeaderColumn>
      </BootstrapTable>);
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_BANKTIPUS', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>Új banktípus</Button>
            </div>
          </div>
        )}
        {{
            QUERY: (<QueryEmptyState
              hasImage
            />),
            LOAD: (<Loader />),
            DATA: (<DataEmptyState
              hasImage
              subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
            />),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => this.updateTable()}
            />),
            TABL: (this.renderBaseTable())
          }[this.state.currentStatusBaseTable]}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteBanktipus(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addBanktipus : this.updateInflacio}>
            {_muvelet === 0 ? <ModalHeader>Új banktípus rögzítése</ModalHeader> : <ModalHeader>Meglévő banktípus módosítása</ModalHeader> }
            <ModalBody>
              <AvGroup>
                <Label for="azonosito">Azonosító: *</Label>
                <AvInput
                  name="azonosito"
                  id="azonosito"
                  required
                  value={_modositoObj && _modositoObj.azonosito}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="nev">Név: *</Label>
                <AvInput
                  name="nev"
                  id="nev"
                  required
                  value={_modositoObj && _modositoObj.nev}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="cim">Cím: *</Label>
                <AvInput
                  name="cim"
                  id="cim"
                  required
                  value={_modositoObj && _modositoObj.cim}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="szam">Szám: *</Label>
                <AvInput
                  name="szam"
                  id="szam"
                  required
                  value={_modositoObj && _modositoObj.szam}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default BanktipusLista;
