
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  FormGroup
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvInput,
  AvField
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import Lib from '../../../commons/lib';

class PartnercsoportListaNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'TABL',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'DATA',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // DATA
      baseJson: [],
      currentId: undefined,
      torlesId: undefined,
      // MODAL UTILS
      modalForm: false,
      modalDelete: false,
      saveButtonLoading: false,
      deleteButtonLoading: false,
      // FORM VALUES
      formNev: '',
      formAzonosito: '',
      formStatusz: '',
      // checkboxes
      formCTipus: false,
      formCSzallitoVevo: false,
      formCAzonosito: false,
      formCStatusz: false,
      formCNev: false,
      formCKapcstart: false,
      formCCim: false,
      formCSzallcim: false,
      formCSzamcim: false,
      formCBankszamla: false,
      formCTel: false,
      formCEmail: false,
      formCAdoszam: false,
      formCKozadoszam: false,
      formCFajl: false,
      formCMegjegyzes: false,
      formCKedvezmeny: false,
      formCCegjegyzekszam: false,

      formMAdoazon: false,
      formMAdoszam: false,
      formMAzonosito: false,
      formMBankszamla: false,
      formMCim: false,
      formMEmail: false,
      formMFajl: false,
      formMGdpr: false,
      formMKedvezmeny: false,
      formMKozadoszam: false,
      formMMegjegyzes: false,
      formMNem: false,
      formMNev: false,
      formMNevnap: false,
      formMStatusz: false,
      formMSzallcim: false,
      formMSzallitovevo: false,
      formMSzamcim: false,
      formMSzulido: false,
      formMSzulnev: false,
      formMTaj: false,
      formMTel: false,
      formMTipus: false,
    };
  }

  // componentDidMount() {
  //   this.listSzektor();
  // }

  // componentDidMount() {
  //   this.listPartnercsoport();
  //   this.getEnums();
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.type === 'PARTNERCSOP') {
  //     this.listPartnercsoport();
  //     this.getEnums();
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.listPartnercsoport();
        this.getEnums();
      }
    }
  }

  /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
  getEnums = () => {
    this.setState({ enums: [] });
    Services.listEnumPartnercsoport((err, res) => {
      if (!err) {
        this.setState({ enums: res[0] });
      }
    });
  }

  initPartnercsoport = () => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.initPartnercsoport((err, res) => {
      if (!err) {
        this.setState({
          formStatus: 'DATA',
          formNev: this.nullToString(res[0].nev),
          formAzonosito: this.nullToString(res[0].azonosito),
          formStatusz: this.nullToString(res[0].statusz && res[0].statusz.name),
          // checkbox
          formCTipus: res[0].cegkotelezoseg.c_tipus,
          formCSzallitoVevo: res[0].cegkotelezoseg.c_szallitovevo,
          formCAzonosito: res[0].cegkotelezoseg.c_azonosito,
          formCStatusz: res[0].cegkotelezoseg.c_statusz,
          formCNev: res[0].cegkotelezoseg.c_nev,
          formCKapcstart: res[0].cegkotelezoseg.c_kapcstart,
          formCCim: res[0].cegkotelezoseg.c_cim,
          formCSzallcim: res[0].cegkotelezoseg.c_szallcim,
          formCSzamcim: res[0].cegkotelezoseg.c_szamcim,
          formCBankszamla: res[0].cegkotelezoseg.c_bankszamla,
          formCTel: res[0].cegkotelezoseg.c_tel,
          formCEmail: res[0].cegkotelezoseg.c_email,
          formCAdoszam: res[0].cegkotelezoseg.c_adoszam,
          formCKozadoszam: res[0].cegkotelezoseg.c_kozadoszam,
          formCFajl: res[0].cegkotelezoseg.c_fajl,
          formCMegjegyzes: res[0].cegkotelezoseg.c_megjegyzes,
          formCKedvezmeny: res[0].cegkotelezoseg.c_kedvezmeny,
          formCCegjegyzekszam: res[0].cegkotelezoseg.c_cegjegyzek,

          formMAdoazon: res[0].maganszemelykotelezoseg.m_adoazon,
          formMAdoszam: res[0].maganszemelykotelezoseg.m_adoszam,
          formMAzonosito: res[0].maganszemelykotelezoseg.m_azonosito,
          formMBankszamla: res[0].maganszemelykotelezoseg.m_bankszamla,
          formMCim: res[0].maganszemelykotelezoseg.m_cim,
          formMEmail: res[0].maganszemelykotelezoseg.m_email,
          formMFajl: res[0].maganszemelykotelezoseg.m_fajl,
          formMGdpr: res[0].maganszemelykotelezoseg.m_gdpr,
          formMKedvezmeny: res[0].maganszemelykotelezoseg.m_kedvezmeny,
          formMKozadoszam: res[0].maganszemelykotelezoseg.m_kozadoszam,
          formMMegjegyzes: res[0].maganszemelykotelezoseg.m_megjegyzes,
          formMNem: res[0].maganszemelykotelezoseg.m_nem,
          formMNev: res[0].maganszemelykotelezoseg.m_nev,
          formMNevnap: res[0].maganszemelykotelezoseg.m_nevnap,
          formMStatusz: res[0].maganszemelykotelezoseg.m_statusz,
          formMSzallcim: res[0].maganszemelykotelezoseg.m_szallcim,
          formMSzallitovevo: res[0].maganszemelykotelezoseg.m_szallitovevo,
          formMSzamcim: res[0].maganszemelykotelezoseg.m_szamcim,
          formMSzulido: res[0].maganszemelykotelezoseg.m_szulido,
          formMSzulnev: res[0].maganszemelykotelezoseg.m_szulnev,
          formMTaj: res[0].maganszemelykotelezoseg.m_taj,
          formMTel: res[0].maganszemelykotelezoseg.m_tel,
          formMTipus: res[0].maganszemelykotelezoseg.m_tipus,
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  toggleFormModal = () => {
    this.setState(prevState => ({ modalForm: !prevState.modalForm }));
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  }

  getCorrectTextColor = (hex) => {
    /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

    I know this could be more compact, but I think this is easier to read/explain.

    */

    let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }
    function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16); }
    function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16); }
    function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16); }

    let hRed = hexToR(hex);
    let hGreen = hexToG(hex);
    let hBlue = hexToB(hex);

    let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) { return '#000000'; } return '#ffffff';
  }

  listPartnercsoport = () => {
    this.setState({
      tableStatus: 'LOAD'
    }, () => {
      Services.listPartnercsoport((err, res) => {
        if (!err) {
          this.setState({
            tableStatus: res[0].length > 0 ? 'TABL' : 'DATA',
            baseJson: res[0],
          });
        } else {
          this.setState({
            tableStatus: 'NETW'
          });
        }
      });
    });
  }

  getPartnercsoport = (id) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.getPartnercsoport(id, (err, res) => {
      if (!err && (res[0].cegkotelezoseg && res[0].maganszemelykotelezoseg)) {
        this.setState({
          formStatus: 'DATA',
          formNev: this.nullToString(res[0].nev),
          formAzonosito: this.nullToString(res[0].azonosito),
          formStatusz: this.nullToString(res[0].statusz && res[0].statusz.name),
          // checkbox
          formCTipus: res[0].cegkotelezoseg.c_tipus,
          formCSzallitoVevo: res[0].cegkotelezoseg.c_szallitovevo,
          formCAzonosito: res[0].cegkotelezoseg.c_azonosito,
          formCStatusz: res[0].cegkotelezoseg.c_statusz,
          formCNev: res[0].cegkotelezoseg.c_nev,
          formCKapcstart: res[0].cegkotelezoseg.c_kapcstart,
          formCCim: res[0].cegkotelezoseg.c_cim,
          formCSzallcim: res[0].cegkotelezoseg.c_szallcim,
          formCSzamcim: res[0].cegkotelezoseg.c_szamcim,
          formCBankszamla: res[0].cegkotelezoseg.c_bankszamla,
          formCTel: res[0].cegkotelezoseg.c_tel,
          formCEmail: res[0].cegkotelezoseg.c_email,
          formCAdoszam: res[0].cegkotelezoseg.c_adoszam,
          formCKozadoszam: res[0].cegkotelezoseg.c_kozadoszam,
          formCFajl: res[0].cegkotelezoseg.c_fajl,
          formCMegjegyzes: res[0].cegkotelezoseg.c_megjegyzes,
          formCKedvezmeny: res[0].cegkotelezoseg.c_kedvezmeny,
          formCCegjegyzekszam: res[0].cegkotelezoseg.c_cegjegyzek,

          formMAdoazon: res[0].maganszemelykotelezoseg.m_adoazon,
          formMAdoszam: res[0].maganszemelykotelezoseg.m_adoszam,
          formMAzonosito: res[0].maganszemelykotelezoseg.m_azonosito,
          formMBankszamla: res[0].maganszemelykotelezoseg.m_bankszamla,
          formMCim: res[0].maganszemelykotelezoseg.m_cim,
          formMEmail: res[0].maganszemelykotelezoseg.m_email,
          formMFajl: res[0].maganszemelykotelezoseg.m_fajl,
          formMGdpr: res[0].maganszemelykotelezoseg.m_gdpr,
          formMKedvezmeny: res[0].maganszemelykotelezoseg.m_kedvezmeny,
          formMKozadoszam: res[0].maganszemelykotelezoseg.m_kozadoszam,
          formMMegjegyzes: res[0].maganszemelykotelezoseg.m_megjegyzes,
          formMNem: res[0].maganszemelykotelezoseg.m_nem,
          formMNev: res[0].maganszemelykotelezoseg.m_nev,
          formMNevnap: res[0].maganszemelykotelezoseg.m_nevnap,
          formMStatusz: res[0].maganszemelykotelezoseg.m_statusz,
          formMSzallcim: res[0].maganszemelykotelezoseg.m_szallcim,
          formMSzallitovevo: res[0].maganszemelykotelezoseg.m_szallitovevo,
          formMSzamcim: res[0].maganszemelykotelezoseg.m_szamcim,
          formMSzulido: res[0].maganszemelykotelezoseg.m_szulido,
          formMSzulnev: res[0].maganszemelykotelezoseg.m_szulnev,
          formMTaj: res[0].maganszemelykotelezoseg.m_taj,
          formMTel: res[0].maganszemelykotelezoseg.m_tel,
          formMTipus: res[0].maganszemelykotelezoseg.m_tipus,
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      currentId: cell,
      modalForm: true
    }, () => {
      this.getPartnercsoport(cell);
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleDeleteModal();
    });
  }

  deletePartnercsoport = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deletePartnercsoport(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleDeleteModal();
        this.listPartnercsoport();
        this.props.globals.addSuccess('Partnercsoport törölve!');
        this.setState({
          deleteButtonLoading: false
        });
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formNev: '',
      formAzonosito: '',
      formStatusz: 'AKTIV',
    }, () => {
      this.toggleFormModal();
      this.initPartnercsoport();
    });
  }

  submitForm = () => {
    const {
      currentId,
      formNev,
      formAzonosito,
      formStatusz,
      formType,
      // checkboxes
      formCTipus,
      formCSzallitoVevo,
      formCAzonosito,
      formCStatusz,
      formCNev,
      formCKapcstart,
      formCCim,
      formCSzallcim,
      formCSzamcim,
      formCBankszamla,
      formCTel,
      formCEmail,
      formCAdoszam,
      formCKozadoszam,
      formCFajl,
      formCMegjegyzes,
      formCKedvezmeny,
      formCCegjegyzekszam,

      formMAdoazon,
      formMAdoszam,
      formMAzonosito,
      formMBankszamla,
      formMCim,
      formMEmail,
      formMFajl,
      formMGdpr,
      formMKedvezmeny,
      formMKozadoszam,
      formMMegjegyzes,
      formMNem,
      formMNev,
      formMNevnap,
      formMStatusz,
      formMSzallcim,
      formMSzallitovevo,
      formMSzamcim,
      formMSzulido,
      formMSzulnev,
      formMTaj,
      formMTel,
      formMTipus,
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {};

      submitObj.azonosito = this.emptyOrNull(formAzonosito);
      submitObj.nev = this.emptyOrNull(formNev);
      submitObj.statusz = this.emptyOrNull(formStatusz);

      submitObj.cegkotelezoseg = {
        c_adoszam: (formCAdoszam),
        c_azonosito: (formCAzonosito),
        c_bankszamla: (formCBankszamla),
        c_cim: (formCCim),
        c_email: (formCEmail),
        c_fajl: (formCFajl),
        c_kapcstart: (formCKapcstart),
        c_kedvezmeny: (formCKedvezmeny),
        c_kozadoszam: (formCKozadoszam),
        c_megjegyzes: (formCMegjegyzes),
        c_nev: (formCNev),
        c_statusz: (formCStatusz),
        c_szallcim: (formCSzallcim),
        c_szallitovevo: (formCSzallitoVevo),
        c_szamcim: (formCSzamcim),
        c_tel: (formCTel),
        c_tipus: (formCTipus),
        c_cegjegyzek: (formCCegjegyzekszam),
      };

      submitObj.maganszemelykotelezoseg = {
        m_adoazon: (formMAdoazon),
        m_adoszam: (formMAdoszam),
        m_azonosito: (formMAzonosito),
        m_bankszamla: (formMBankszamla),
        m_cim: (formMCim),
        m_email: (formMEmail),
        m_fajl: (formMFajl),
        m_gdpr: (formMGdpr),
        m_kedvezmeny: (formMKedvezmeny),
        m_kozadoszam: (formMKozadoszam),
        m_megjegyzes: (formMMegjegyzes),
        m_nem: (formMNem),
        m_nev: (formMNev),
        m_nevnap: (formMNevnap),
        m_statusz: (formMStatusz),
        m_szallcim: (formMSzallcim),
        m_szallitovevo: (formMSzallitovevo),
        m_szamcim: (formMSzamcim),
        m_szulido: (formMSzulido),
        m_szulnev: (formMSzulnev),
        m_taj: (formMTaj),
        m_tel: (formMTel),
        m_tipus: (formMTipus),
      };

      Services.addPartnercsoport(submitObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Partnercsoport felvéve!');
          this.setState({
            saveButtonLoading: false
          }, () => {
            this.toggleFormModal();
            this.listPartnercsoport();
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    } else if (formType === 'MOD') {
      let submitObj = {};

      submitObj.id = this.emptyOrNull(currentId);
      submitObj.azonosito = this.emptyOrNull(formAzonosito);
      submitObj.nev = this.emptyOrNull(formNev);
      submitObj.statusz = this.emptyOrNull(formStatusz);

      submitObj.cegkotelezoseg = {
        c_adoszam: (formCAdoszam),
        c_azonosito: (formCAzonosito),
        c_bankszamla: (formCBankszamla),
        c_cim: (formCCim),
        c_email: (formCEmail),
        c_fajl: (formCFajl),
        c_kapcstart: (formCKapcstart),
        c_kedvezmeny: (formCKedvezmeny),
        c_kozadoszam: (formCKozadoszam),
        c_megjegyzes: (formCMegjegyzes),
        c_nev: (formCNev),
        c_statusz: (formCStatusz),
        c_szallcim: (formCSzallcim),
        c_szallitovevo: (formCSzallitoVevo),
        c_szamcim: (formCSzamcim),
        c_tel: (formCTel),
        c_tipus: (formCTipus),
        c_cegjegyzek: (formCCegjegyzekszam),
      };

      submitObj.maganszemelykotelezoseg = {
        m_adoazon: (formMAdoazon),
        m_adoszam: (formMAdoszam),
        m_azonosito: (formMAzonosito),
        m_bankszamla: (formMBankszamla),
        m_cim: (formMCim),
        m_email: (formMEmail),
        m_fajl: (formMFajl),
        m_gdpr: (formMGdpr),
        m_kedvezmeny: (formMKedvezmeny),
        m_kozadoszam: (formMKozadoszam),
        m_megjegyzes: (formMMegjegyzes),
        m_nem: (formMNem),
        m_nev: (formMNev),
        m_nevnap: (formMNevnap),
        m_statusz: (formMStatusz),
        m_szallcim: (formMSzallcim),
        m_szallitovevo: (formMSzallitovevo),
        m_szamcim: (formMSzamcim),
        m_szulido: (formMSzulido),
        m_szulnev: (formMSzulnev),
        m_taj: (formMTaj),
        m_tel: (formMTel),
        m_tipus: (formMTipus),
      };

      Services.editPartnercsoport(submitObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Partnercsoport módosítva!');
          this.setState({
            saveButtonLoading: false
          }, () => {
            this.toggleFormModal();
            this.listPartnercsoport();
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  tableIconFormatter = (cell, row) => {
    return (
      <div>
        <React.Fragment>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PARTNER_CSOPORT', 'KARB') && (
            <React.Fragment>
              {row.mod && (
                <React.Fragment>
                  <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                  <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
                    {'Módosítás'}
                  </UncontrolledTooltip>
                </React.Fragment>
              )}
              <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'file'} onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'file'} delay={50}>
                {'Törlés'}
              </UncontrolledTooltip>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }

  tableBooleanFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Igen</span>
      : <span className="badge badge-danger">Nem</span>;
  }

  styleSzinkod = (szinkod) => {
    return {
      textAlign: 'center',
      whiteSpace: 'pre-line',
      backgroundColor: '#' + szinkod,
      color: this.getCorrectTextColor('#' + szinkod)
    };
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új partnercsoport létrehozása';
      case 'MOD':
        return 'Partnercsoport módosítása';
      default:
        return '';
    }
  }

  tableStatusFormatter = (cell) => {
    return cell === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-secondary">Inaktív</span>;
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      AKTIV: 'Aktív',
      INAKTIV: 'Inaktív',
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Partnercsoport azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Partnercsoport neve',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'statusz.name',
        align: 'center',
        text: 'Státusz',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        formatter: this.tableStatusFormatter
      },
      // {
      //   dataField: 'statusz',
      //   text: 'Státusz',
      //   filter: selectFilter({
      //     options: selectOptions,
      //     placeholder: 'Összes',
      //   }),
      //   style: {
      //     wordBreak: 'break-all',
      //     whiteSpace: 'pre-line',
      //     textAlign: 'center'
      //   },
      //   formatter: this.tableBooleanFormatter,
      //   sort: true
      // },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PARTNER_CSOPORT', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          wrapperClasses="table-responsive"
          classes="table-scroll"
          headerClasses="table-th"
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

renderForm = () => {
  const {
    formNev,
    formAzonosito,
    formStatusz,
    // checkboxes
    formCTipus,
    formCSzallitoVevo,
    formCAzonosito,
    formCStatusz,
    formCNev,
    formCKapcstart,
    formCCim,
    formCSzallcim,
    formCSzamcim,
    formCBankszamla,
    formCTel,
    formCEmail,
    formCAdoszam,
    formCKozadoszam,
    formCFajl,
    formCMegjegyzes,
    formCKedvezmeny,
    formCCegjegyzekszam,

    formMAdoazon,
    formMAdoszam,
    formMAzonosito,
    formMBankszamla,
    formMCim,
    formMEmail,
    formMFajl,
    formMGdpr,
    formMKedvezmeny,
    formMKozadoszam,
    formMMegjegyzes,
    formMNem,
    formMNev,
    formMNevnap,
    formMStatusz,
    formMSzallcim,
    formMSzallitovevo,
    formMSzamcim,
    formMSzulido,
    formMSzulnev,
    formMTaj,
    formMTel,
    formMTipus,

    formType
  } = this.state;

  const titleStyle = {
    marginBottom: 20,
    marginTop: 10,
    marginLeft: 10
  };

  const titleGDPRStyle = {
    marginBottom: 20,
    paddingBottom: 5,
    marginLeft: 10,
    borderBottom: '5px solid #cfd8dc'
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <AvGroup>
            <Label for="formAzonosito">Partnercsoport azonosító: *</Label>
            <AvInput
              name="formAzonosito"
              id="formAzonosito"
              maxLength="10"
              disabled={formType === 'MOD'}
              type="text"
              value={formAzonosito}
              onChange={this.handleInputChange}
              required
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="formNev">Partnercsoport neve: *</Label>
            <AvInput
              name="formNev"
              id="formNev"
              type="text"
              value={formNev}
              onChange={this.handleInputChange}
              required
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvField
            type="select"
            name="formStatusz"
            id="formStatusz"
            label="Státusz: *"
            errorMessage="Kötelező mező!"
            required
            value={formStatusz}
            onChange={this.handleInputChange}
          >
            <option value="">Válasszon státuszt...</option>
            <option value="AKTIV">Aktív</option>
            <option value="INAKTIV">Inaktív</option>
          </AvField>
        </div>
        <div className="col-md-12">
          <div>
            <h5 style={titleStyle}>
              Kötelezően kitöltendő mezők:
            </h5>
          </div>
        </div>

        <div className="col-md-6">
          <div>
            <h6 style={titleGDPRStyle}>
              {'Cég típusú partner:'.toUpperCase()}
            </h6>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <h6 style={titleStyle}>
                  {'Alapadatok'}
                </h6>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCTipus" id="formCTipus" value={formCTipus} onChange={this.handleInputChange} disabled />
                    <Label check for="checkbox"> Típus</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCSzallitoVevo" id="formCSzallitoVevo" value={formCSzallitoVevo} onChange={this.handleInputChange} disabled />
                    <Label check for="formCSzallitoVevo"> Szállító/Vevő</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCAzonosito" id="formCAzonosito" value={formCAzonosito} onChange={this.handleInputChange} disabled />
                    <Label check for="formCAzonosito"> Azonosító</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCStatusz" id="formCStatusz" value={formCStatusz} onChange={this.handleInputChange} disabled />
                    <Label check for="formCStatusz"> Státusz</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCNev" id="formCNev" value={formCNev} onChange={this.handleInputChange} disabled />
                    <Label check for="formCNev"> Név</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCKapcstart" id="formCKapcstart" value={formCKapcstart} onChange={this.handleInputChange} disabled={formCKapcstart === null} />
                    <Label check for="formCKapcstart"> Kapcsolattartó</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <h6 style={titleStyle}>
                  {'Címek, elérhetőségek'}
                </h6>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCCim" id="formCCim" value={formCCim} onChange={this.handleInputChange} disabled={formCCim === null} />
                    <Label check for="formCCim"> Hivatalos cím</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCSzallcim" id="formCSzallcim" value={formCSzallcim} onChange={this.handleInputChange} disabled={formCSzallcim === null} />
                    <Label check for="formCSzallcim"> Szállítasi cím</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCSzamcim" id="formCSzamcim" value={formCSzamcim} onChange={this.handleInputChange} disabled={formCSzamcim === null} />
                    <Label check for="formCSzamcim"> Levelezési cím</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCTel" id="formCTel" value={formCTel} onChange={this.handleInputChange} disabled={formCTel === null} />
                    <Label check for="formCTel"> Telefonszámok</Label>
                  </AvGroup>
                </FormGroup>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h6 style={titleStyle}>
                  {'Egyéb adatok'}
                </h6>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCBankszamla" id="formCBankszamla" value={formCBankszamla} onChange={this.handleInputChange} disabled={formCBankszamla === null} />
                    <Label check for="formCBankszamla"> Bank adatok</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCEmail" id="formCEmail" value={formCEmail} onChange={this.handleInputChange} disabled={formCEmail === null} />
                    <Label check for="formCEmail"> Email</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCAdoszam" id="formCAdoszam" value={formCAdoszam} onChange={this.handleInputChange} disabled={formCAdoszam === null} />
                    <Label check for="formCAdoszam"> Adószám</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCKozadoszam" id="formCKozadoszam" value={formCKozadoszam} onChange={this.handleInputChange} disabled={formCKozadoszam === null} />
                    <Label check for="formCKozadoszam"> Közösségi adószám</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCKedvezmeny" id="formCKedvezmeny" value={formCKedvezmeny} onChange={this.handleInputChange} disabled={formCKedvezmeny === null} />
                    <Label check for="formCKedvezmeny"> Kedvezmény</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCFajl" id="formCFajl" value={formCFajl} onChange={this.handleInputChange} disabled={formCFajl === null} />
                    <Label check for="formCFajl"> Fájlkezelés</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCMegjegyzes" id="formCMegjegyzes" value={formCMegjegyzes} onChange={this.handleInputChange} disabled={formCMegjegyzes === null} />
                    <Label check for="formCMegjegyzes"> Megjegyzés</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formCCegjegyzekszam" id="formCCegjegyzekszam" value={formCCegjegyzekszam} onChange={this.handleInputChange} disabled={formCCegjegyzekszam === null} />
                    <Label check for="formCCegjegyzekszam"> Cégjegyzékszám</Label>
                  </AvGroup>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h6 style={titleGDPRStyle}>
              {'Magánszemély típusú partner:'.toUpperCase()}
            </h6>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <h6 style={titleStyle}>
                  {'Alapadatok'}
                </h6>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMTipus" id="formMTipus" value={formMTipus} onChange={this.handleInputChange} disabled />
                    <Label check for="formMTipus"> Típus</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMSzallitovevo" id="formMSzallitovevo" value={formMSzallitovevo} onChange={this.handleInputChange} disabled />
                    <Label check for="formMSzallitovevo"> Szállító/Vevő</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMAzonosito" id="formMAzonosito" value={formMAzonosito} onChange={this.handleInputChange} disabled />
                    <Label check for="formMAzonosito"> Azonosító</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMStatusz" id="formMStatusz" value={formMStatusz} onChange={this.handleInputChange} disabled />
                    <Label check for="formMStatusz"> Státusz</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMNev" id="formMNev" value={formMNev} onChange={this.handleInputChange} disabled />
                    <Label check for="formMNev"> Név</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMSzulnev" id="formMSzulnev" value={formMSzulnev} onChange={this.handleInputChange} disabled={formMSzulnev === null} />
                    <Label check for="formMSzulnev"> Születési vezetéknév, születési keresztnév</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <h6 style={titleStyle}>
                  {'Címek, elérhetőségek'}
                </h6>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMCim" id="formMCim" value={formMCim} onChange={this.handleInputChange} disabled={formMCim === null} />
                    <Label check for="formMCim"> Hivatalos cím</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMSzallcim" id="formMSzallcim" value={formMSzallcim} onChange={this.handleInputChange} disabled={formMSzallcim === null} />
                    <Label check for="formMSzallcim"> Szállítasi cím</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMSzamcim" id="formMSzamcim" value={formMSzamcim} onChange={this.handleInputChange} disabled={formMSzamcim === null} />
                    <Label check for="formMSzamcim"> Levelezési cím</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMTel" id="formMTel" value={formMTel} onChange={this.handleInputChange} disabled={formMTel === null} />
                    <Label check for="formMTel"> Telefonszámok</Label>
                  </AvGroup>
                </FormGroup>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h6 style={titleStyle}>
                  {'Egyéb adatok'}
                </h6>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMBankszamla" id="formMBankszamla" value={formMBankszamla} onChange={this.handleInputChange} disabled={formMBankszamla === null} />
                    <Label check for="formMBankszamla"> Bank adatok</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMEmail" id="formMEmail" value={formMEmail} onChange={this.handleInputChange} disabled={formMEmail === null} />
                    <Label check for="formMEmail"> Email</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMSzulido" id="formMSzulido" value={formMSzulido} onChange={this.handleInputChange} disabled={formMSzulido === null} />
                    <Label check for="formMSzulido"> Születési idő</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMTaj" id="formMTaj" value={formMTaj} onChange={this.handleInputChange} disabled={formMTaj === null} />
                    <Label check for="formMTaj"> Taj szám</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMGdpr" id="formMGdpr" value={formMGdpr} onChange={this.handleInputChange} disabled />
                    <Label check for="formMGdpr"> GDPR hozzájárulás</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMNevnap" id="formMNevnap" value={formMNevnap} onChange={this.handleInputChange} disabled={formMNevnap === null} />
                    <Label check for="formMNevnap"> Névnap</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMNem" id="formMNem" value={formMNem} onChange={this.handleInputChange} disabled={formMNem === null} />
                    <Label check for="formMNem"> Nem</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMAdoszam" id="formMAdoszam" value={formMAdoszam} onChange={this.handleInputChange} disabled={formMAdoszam === null} />
                    <Label check for="formMAdoszam"> Adószám</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMAdoazon" id="formMAdoazon" value={formMAdoazon} onChange={this.handleInputChange} disabled={formMAdoazon === null} />
                    <Label check for="formMAdoazon"> Adóazonosító jel</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMKozadoszam" id="formMKozadoszam" value={formMKozadoszam} onChange={this.handleInputChange} disabled={formMKozadoszam === null} />
                    <Label check for="formMKozadoszam"> Közösségi adószám</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMKedvezmeny" id="formMKedvezmeny" value={formMKedvezmeny} onChange={this.handleInputChange} disabled={formMKedvezmeny === null} />
                    <Label check for="formMKedvezmeny"> Kedvezmény</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMFajl" id="formMFajl" value={formMFajl} onChange={this.handleInputChange} disabled={formMFajl === null} />
                    <Label check for="formMFajl"> Fájlkezelés</Label>
                  </AvGroup>
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <AvGroup check>
                    <AvInput type="checkbox" name="formMMegjegyzes" id="formMMegjegyzes" value={formMMegjegyzes} onChange={this.handleInputChange} disabled={formMMegjegyzes === null} />
                    <Label check for="formMMegjegyzes"> Megjegyzés</Label>
                  </AvGroup>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

render() {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PARTNER_CSOPORT', 'KARB') && (
            <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
              {'Új partnercsoport'}
            </Button>
          )}
        </div>
        <div className="col-md-12">
          {{
            LOAD: (<Loader />),
            DATA: (<DataEmptyState
              hasImage
              subtitle="Vigyél fel új tételt!"
            />),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => {
                this.listPartnercsoport();
                this.getEnums();
              }}
            />),
            TABL: (this.renderTable())
          }[this.state.tableStatus]}
        </div>
      </div>
      <Modal isOpen={this.state.modalForm} toggle={this.toggleFormModal} backdrop="static" size="lg">
        <AvForm onValidSubmit={this.submitForm}>
          <ModalHeader>{this.renderFormTitle()}</ModalHeader>
          <ModalBody>
            <div className="roq">
              <div className="col-md-12">
                {{
                  LOAD: (<Loader />),
                  NETW: (<NetworkEmptyState
                    hasImage
                    submitClick={() => { this.getPartnercsoport(this.state.currentId); }}
                  />),
                  DATA: (this.renderForm())
                }[this.state.formStatus]}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              loading={this.state.saveButtonLoading}
            >
              {'Mentés'}
            </ProgressButton>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleFormModal}>Mégsem</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <Modal isOpen={this.state.modalDelete} toggle={this.toggleDeleteModal} backdrop="static">
        <ModalHeader>Törlés</ModalHeader>
        <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
        <ModalFooter>
          <ProgressButton
            className="btn-cursor-pointer"
            color="primary"
            onClick={() => this.deletePartnercsoport()}
            loading={this.state.deleteButtonLoading}
          >
            {'Igen'}
          </ProgressButton>
          <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
}

PartnercsoportListaNew.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object
};

export default PartnercsoportListaNew;
