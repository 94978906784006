
import React, { useState, useEffect} from 'react';
import { 
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Col,
} from 'reactstrap';
import { AvForm, AvInput, } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import Services from '../services';
import Select from 'react-select/lib/Async';
import CreatableSelect  from 'react-select/lib/Creatable';
import { browserHistory } from 'react-router';
import { DateTimePicker } from 'react-widgets';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';
import _ from 'lodash';
import moment from 'moment';
import { CustomOption } from '../../../commons/customOption';
import SzallitoiCikkForm from './SzallitoiCikkForm';
import { setNettoPrices } from './SzallitoiPriceCalculator';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import getTooltip from './SzallitoiTooltips';

const ROW_STYLE = { marginBottom: '15px' };
const SzallitoiForm = (props) => {
    const {
      route,
    } = props;

    const [id, setId] = useState(null);
    const [partner, setPartner] = useState(null);
    const [cegadat, setCegadat] = useState(null);
    const [kelte, setKelte] = useState(new Date());
    const [penznem, setPenznem] = useState(null);
    const [allapot, setAllapot] = useState({name: 'ROGZITETT'});
    const [fizmod, setFizmod] = useState(null);
    const [szallitasiMod, setSzallitasiMod] = useState(null);
    const [szallitasiModOptions, setSzallitasiModOptions] = useState([]);
    const [megjegyzes, setMegjegyzes] = useState('');
    const [cikkek, setCikkek] = useState([]);
    const [isCikkBontas, toggleCikkBontas] = useState(false);
    const [currentCikkId, setCurrentCikkId] = useState(null);
    const [loadingSaveButton, toggleLoadingSaveButton] = useState(false);
    const [lockupModal, toggleLockupModal] = useState(false);
    const [formStatus, setFormStatus] = useState('DATA'); // ['LOAD', 'DATA', 'NETW']
    const [showCikk, toggleShowCikk] = useState(false);
    const [rendelesModal, setRendelesModal] = useState(false);
    const [rendelesModalCikkJSON, setRendelesModalCikkJSON] = useState([]);
    const [rendelesModalCikkSelectedList, setRendelesModalCikkSelectedList] = useState([]);
    const [rendelesModalHatarido, setRendelesModalHatarido] = useState(null);

    const isNotRogzitett = allapot && allapot.name !== 'ROGZITETT';
    const isElkuldott = allapot && allapot.name === 'ELKULDOTT';
    const isLezart = allapot && allapot.name === 'LEZART';

    useEffect(() => {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      if(id){
        setId(id);
      }
      loadSzallitasiModOptions();
    }, []);

    useEffect(() => {
      if(id){
        setFormStatus('LOAD');
      }
    }, [id]);

    useEffect(() => {
      if(!showCikk) {
        setCurrentCikkId(null);
        toggleCikkBontas(false);
      }
    }, [showCikk]);

    useEffect(() => {
      if(currentCikkId) {
        toggleShowCikk(!showCikk);
      }
    }, [currentCikkId]);

    useEffect(() => {
      switch(formStatus){
        case 'LOAD':
          getSzallitoiRendeles(id);
        break;
      }
    }, [formStatus]);

    const handleBack = () => {
      browserHistory.push("".concat('/rendelesek/rendeles?page=3'));
    }

    const loadPartnerOptions = (input, callback) => {
      if(_.isEmpty(input)) callback([]);
      Services.listPartner({type: 'RENDELES_SZALLITO_AKTIV_GET', text: input}, (err, res) => {
        if(!err){
          const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
          callback(options);
        }else{
          callback([]);
        }
      });
    }

    const loadCegadatOptions = (input, callback) => {
      if(_.isEmpty(input)) callback([]);
      Services.listCegadat({}, (err, res) => {
        if(!err){
          const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
          if (options.length == 1) setCegadat(options[0]);
          callback(options);
        }else{
          callback([]);
        }
      });
    }

    const loadFizmodOptions =  (input, callback) => {
      if(_.isEmpty(input)) callback([]);
      Services.listAktivFizetesimod({}, (err, res) => {
        if(!err){
          const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
          callback(options);
        }else{
          callback([]);
        }
      });
    }

    const loadPenznemOptions = (input, callback) => {
      if(_.isEmpty(input)) callback([]);
      Services.listAktivPenznem({}, (err, res) => {
        if(!err){
          const options = res[0].filter( k =>  k.defaultcurrency && k.nev.toLowerCase().includes(input.toLowerCase()))
          if (options.length == 1)
            setPenznem(options[0]);
          callback(options);
        }else{
          callback([]);
        }
      });
    }

    const loadSzallitasiModOptions = async (input) => {
      const options = await Services.listSzallitasiModRendeles({});
      setSzallitasiModOptions(options.map(m => { return {value: m.id , label: m.nev}}));
    }

    const createSzallitasiModOption = async (newSzallitasiMod) => {
      try{
        const res = await Services.addSzallitasiModRendeles({nev: newSzallitasiMod});
        const obj = { value: res.id, label: res.nev };
        setSzallitasiMod(obj);
        setSzallitasiModOptions([...szallitasiModOptions, obj]);
      }catch(ex){}
    }

    const deleteSzallitasiModOption = async (id) => {
      try{
        const res = await Services.deleteSzallitasiModRendeles(id);
        setSzallitasiMod(null);
        loadSzallitasiModOptions();
      }catch(ex){}
    }

    const loadCikkBontas = async id => {
      try{
        await toggleCikkBontas(true);
        setCurrentCikkId(id);
      }catch(ex){}
    }

    const submitSzallitoiRendeles = async(close) => {
      try{
        await toggleLoadingSaveButton(true);
        saveSzallitoiRendeles(close);
      }catch(ex){}
    }

    const isBontott = (id) => {
      return cikkek.filter(c => c.id != id).some(c => c.eredeti && c.eredeti.id == id);
    }

    const formatCreateLabel = (input) => <span><i>Hozzáadás: </i> {input}</span>;

    const generateUUID = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    const getSzallitoiRendeles = async (id) => {
      try{
        const res = await Services.getSzallitoiRendeles({id: id, gettiltottbontas: true});
        const resCikkek = res.cikkek.map(cikk => { return {...cikk, ...{penznem: res.penznem}}});
        setPartner(res.partner);
        setCegadat(res.cegadat ? { id: res.cegadat.id, nev: res.cegadat.teljesnev, cim: res.cegadat.cim } : {});
        setAllapot(res.allapot);
        setKelte(res.kelte);
        setPenznem(res.penznem);
        setFizmod(res.fizmod);
        setSzallitasiMod(res.szallitasiMod ? { value: res.szallitasiMod.id, label: res.szallitasiMod.nev } : null);
        setMegjegyzes(res.megjegyzes);
        setCikkek(resCikkek);
        setFormStatus('DATA');
      }catch(ex){
        setFormStatus('NETW')
      }
    }

    const saveSzallitoiRendeles = async(close) => {
      if (!kelte) {
        route.globals.addError('Kelte megadása kötelező!');
        return;
      }
      if (!partner) {
        route.globals.addError('Szállító választása kötelező!');
        return;
      }
      if (!cegadat) {
        route.globals.addError('Cégadat választása kötelező!');
        return;
      }
      if (!penznem) {
        route.globals.addError('Pénznem választása kötelező!');
        return;
      }
      if (!fizmod) {
        route.globals.addError('Fizetési mód választása kötelező!');
        return;
      }
      if (!id) {
        let submitObj = {};
        submitObj.kelte = kelte ? moment(kelte).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
        submitObj.partnerTortenetId = partner && partner.id;
        submitObj.cegadatId = cegadat && cegadat.id;
        submitObj.penznemId = penznem && penznem.id;
        submitObj.fizmodId = fizmod && fizmod.id;
        submitObj.szallitasiMod = szallitasiMod ? {id: szallitasiMod.value} : null;
        submitObj.megjegyzes = megjegyzes;
        submitObj.cikkek = [];
        cikkek.forEach(c => {
          if(c.new){
            submitObj.cikkek.push({...c, id: null});
          } else {
            submitObj.cikkek.push(c);
          }
          if(c.bontasok && c.bontasok.length > 0){
            c.bontasok.forEach(b => {
              b.visszaHatarido = b.visszaHatarido ? moment(b.visszaHatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
              if(b.new){
                b.id = null;
              }
            });
          }
        });
        try{
          const res = await Services.addSzallitoiRendeles(submitObj);
          route.globals.addSuccess('Szállítói rendelés felvéve!');
          handleBack();
        }catch(ex){
          toggleLoadingSaveButton(false);
        }
      } else {
        let submitObj = {};
        submitObj.id = id;
        submitObj.kelte = kelte ? moment(kelte).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
        submitObj.partnerTortenetId = partner && partner.id;
        submitObj.cegadatId = cegadat && cegadat.id;
        submitObj.penznemId = penznem && penznem.id;
        submitObj.fizmodId = fizmod && fizmod.id;
        submitObj.szallitasiMod = szallitasiMod ? {id: szallitasiMod.value} : null;
        submitObj.megjegyzes = megjegyzes;
        submitObj.cikkek = [];
        cikkek.forEach(c => {
          if(c.new){
            submitObj.cikkek.push({...c, id: null});
          } else {
            submitObj.cikkek.push(c);
          }
          if(c.bontasok && c.bontasok.length > 0){
            c.bontasok.forEach(b => {
              b.visszaHatarido = b.visszaHatarido ? moment(b.visszaHatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
              if(b.new){
                b.id = null;
              }
            }); 
          }
        });
        try{
          if(close){
            const res = await Services.editAcceptSzallitoiRendeles(submitObj);
            toggleLockupModal(!lockupModal);
          }else{
            const res = await Services.editSzallitoiRendeles(submitObj);
          }
          route.globals.addSuccess('Szállítói rendelés módosítva!');
          handleBack();
        }catch(ex){
          toggleLoadingSaveButton(false);
        }
      }
    }

    const handleRendelesModalClick = async () => {
      try {
        const rendelesCikkJson = await Services.listVevoiRendelesCikk();
        const rendelesCikkIdList = cikkek.filter(cikk => cikk.rendelesCikkEntity).map(cikk => cikk.rendelesCikkEntity.id);
        setRendelesModalCikkJSON(rendelesCikkJson.filter(cikkJSON => !rendelesCikkIdList.includes(cikkJSON.id)).map(c => { return { ...c, hatarido: '', nettoegysegarinput: '' } }));
        setRendelesModalCikkSelectedList([]);
        setRendelesModalHatarido(null);
        setRendelesModal(true);
      } catch {};
    };

    const handleRendelesSubmitClick = () => {
      let rendelesCikkArray = rendelesModalCikkJSON.filter(rendelesCikk => rendelesModalCikkSelectedList.includes(rendelesCikk.id));
      if (rendelesCikkArray.some(cikk => !cikk.hatarido) && !rendelesModalHatarido) {
        route.globals.addError('Határidő megadása kötelező!');
        return;
      }
      if (rendelesCikkArray.some(cikk => !cikk.nettoegysegarinput)) {
        route.globals.addError('Nettó egységár megadása kötelező!');
        return;
      }
      if ((rendelesModalHatarido && new Date(rendelesModalHatarido) < kelte) || rendelesCikkArray.some(cikk => cikk.hatarido && new Date(cikk.hatarido) < kelte)) {
        route.globals.addError('A szállítási határidő nem lehet korábbi a kelténél!');
        return;
      }

      for (const rendelesCikk of rendelesCikkArray) {
        let submitObj = {};
        submitObj.id = generateUUID();
        submitObj.new = true;
        submitObj.cikk = rendelesCikk.cikk;
        submitObj.cikkNev = rendelesCikk.cikk.megnevezes;
        submitObj.cikkId = rendelesCikk.cikk.id;
        submitObj.mennyiseg = rendelesCikk.szabadmennyiseg;
        submitObj.hatarido = rendelesCikk.hatarido ? moment(rendelesCikk.hatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : moment(rendelesModalHatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");

        let pricesArray = setNettoPrices(rendelesCikk.nettoegysegarinput, rendelesCikk.szabadmennyiseg, 0, 1, rendelesCikk.cikk);
        submitObj.nettoEgysegar = rendelesCikk.nettoegysegarinput;
        submitObj.nettoBeszerzesiar = pricesArray[0];
        submitObj.bruttoEgysegar = pricesArray[1];
        submitObj.bruttoBeszerzesiar = pricesArray[2];

        submitObj.rendelesCikkEntity = { id: rendelesCikk.id };
        submitObj.rendelesbizonylatszam = rendelesCikk.bizonylatszam;
        cikkek.push(submitObj);
      }
      setRendelesModal(false);
    }

    const handleCikkDeleteClick = (id, formatExtraData) => {
      // formatExtraData = cikkek state, csak a formatter adja át, hogy ne okozzon gondot a frissítések között
      setCikkek(formatExtraData.filter(c => c.id != id));
    }

    const handleCikkBontasDeleteClick = (id) => {
      const eredetiidForDeletion = cikkek.find(c => c.id == id).eredeti.id;
      setCikkek(cikkek.filter(c => !c.eredeti || c.eredeti.id != eredetiidForDeletion));
    }

    const tableIconFormatter = (cell, row, formatExtraData) => {
      // Igaz, ha a cikk bontásból származik, de ő maga még nem lett bontva
      const isBontasTorles = isElkuldott && !isBontott(row.id) && !!row.eredeti;
      // Igaz, ha minden másik olyan cikk, ami ugyanabból a bontásból származik mint a jelenlegi, nem volt bontva
      const isBontasDeleteClear = cikkek.filter(c => c != row && (c.eredeti && c.eredeti.id) == (row.eredeti && row.eredeti.id)).every(c => !isBontott(c.id));
      return (
        <div>
          {(!isLezart && !isBontott(row.id)) &&
            <React.Fragment>
              {/* Egy lépésben csak egyszer lehet bontani egy cikket, mert az alatta lévőknek nem lehet még eredetiid-t adni,
              valamint a cikk nem bontható, ha tartozik folyamatban/normál bevételezés hozzá */}
              {isElkuldott && !row.mostbontott && !row.tiltottBontas && 
                <React.Fragment>
                  <Button className="icon-grid table-action-icon" id={'action-view-' + row.id + '-bontas'} onClick={async () => {await loadCikkBontas(row.id); if (currentCikkId == row.id) { toggleShowCikk(true); }}} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                  <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + row.id + '-bontas'} delay={50}>
                    {'Bontás'}
                  </UncontrolledTooltip>
                </React.Fragment>
              }
              {/* TODO onClick => Ideiglenes megoldás, cserélni kell */}
              {(!row.rendelesCikkEntity || isElkuldott) && 
                <>
                  <Button className="icon-pencil table-action-icon" id={'action-view-' + row.id + '-edit'} onClick={() => { if (currentCikkId == row.id) { toggleShowCikk(true); } setCurrentCikkId(row.id); }} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                  <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + row.id + '-edit'} delay={50}>
                    {'Módosítás'}
                  </UncontrolledTooltip>
                </>
              }

              {(!isNotRogzitett || (isBontasTorles && isBontasDeleteClear)) &&
                <React.Fragment>
                  <Button className="icon-trash table-action-icon" id={'action-view-' + row.id + '-delete'} onClick={() => isBontasTorles ? handleCikkBontasDeleteClick(row.id) : handleCikkDeleteClick(row.id, formatExtraData) } onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                  <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + row.id + '-delete'} delay={50}>
                    {'Törlés'}
                  </UncontrolledTooltip>
                </React.Fragment>
              }

            </React.Fragment>
          }
        </div>
      );
    }


    const renderForm = () => {
      const selectStyles = {
        control: base => ({
          ...base,
          '&:focus': { borderColor: 'red' }, // border style on hover
          border: '1px solid lightgray', // default border color
          boxShadow: 'none', // no box-shadow
          borderRadius: '0'
        }),
      };
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-8" style={{paddingRight: '2px'}}>
              <h5>Adatai</h5>
            </div>
          </div>
          <div className="row" style={ROW_STYLE}>
            <div className="col-md-4">
              <Label for="kelte">Kelte: *</Label>
              <DateTimePicker
                id="kelte"
                name="kelte"
                value={kelte && new Date(kelte) }
                disabled={isNotRogzitett}
                onChange={(e,v) => setKelte(v ? v : null)}
                containerClassName={ "organw-date-time"}
                time={true}
                format="YYYY-MM-DD HH:mm:ss"
                parse={['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']}
                messages={{ dateButton: "dátum" , timeButton: "idő"}}
                required
              />
            </div>
            <div className="col-md-4" style={{paddingRight: '2px'}}>
              <Label for="partner">Szállító: *</Label>
              <Select
                styles={selectStyles}
                name="partner"
                id="partner"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => setPartner(v)}
                loadOptions={_.debounce(loadPartnerOptions, 1000)}
                getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
                getOptionValue={option => option.id}
                value={partner}
                isDisabled={isNotRogzitett}
                isClearable
                //defaultOptions
                required
              />
            </div>
            <div className="col-md-4" style={{paddingRight: '2px'}}>
            <Label for="cegadat">Cégadat: *</Label>
              <Select
                styles={selectStyles}
                name="cegadat"
                id="cegadat"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => setCegadat(v)}
                loadOptions={_.debounce(loadCegadatOptions, 1000)}
                getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
                getOptionValue={option => option.id}
                value={cegadat}
                isDisabled={isNotRogzitett}
                isClearable
                defaultOptions
                required
              />
            </div>
          </div>
          <div className="row" style={ROW_STYLE}>
            <div className="col-md-4">
            <Label for="penznem">Pénznem: *</Label>
              <Select
                styles={selectStyles}
                name="penznem"
                id="partner"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => setPenznem(v)}
                loadOptions={_.debounce(loadPenznemOptions, 1000)}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={penznem}
                isDisabled={isNotRogzitett}
                isClearable
                defaultOptions
                required
              />
            </div>
            <div className="col-md-4" style={{paddingRight: '2px'}}>
            <Label for="fizmod">Fizetési mód: *</Label>
              <Select
                styles={selectStyles}
                name="fizmod"
                id="fizmod"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => setFizmod(v)}
                loadOptions={_.debounce(loadFizmodOptions, 1000)}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={fizmod}
                isDisabled={isNotRogzitett}
                isClearable
                defaultOptions
                required
              />
            </div>
            <div className="col-md-4" style={{paddingRight: '2px'}}>
            <Label for="szallitasiMod">Szállítási mód: </Label>
              <CreatableSelect
                styles={selectStyles}
                name="szallitasiMod"
                id="szallitasiMod"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                formatCreateLabel={i => formatCreateLabel(i)}
                onCreateOption={v => createSzallitasiModOption(v)}
                onDeleteOption={id => deleteSzallitasiModOption(id)}
                components={{Option: CustomOption}}
                onChange={v => setSzallitasiMod(v)}
                options={szallitasiModOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                value={szallitasiMod}
                isDisabled={isNotRogzitett}
                isClearable
              />
            </div>
          </div>
          {allapot && allapot.name == 'ROGZITETT' && (
            <div className="row">
              <div className="col-md-2 mt-1 mb-2">
                <Button
                  color="primary"
                  type="button"
                  //disabled={shouldFieldsBeDisabled() || !partner.id || (ajanlat && ajanlat.id)}
                  onClick={handleRendelesModalClick}
                >
                  Vevői rendelés tételek hozzáadása
                </Button>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12" style={ROW_STYLE}>
              <Label for="megjegyzes">Megjegyzés: </Label>
              <AvInput
                //style={{height: '10px'}}
                className="munkalap-profil-textarea"
                type="textarea"
                name={"megjegyzes"}
                id={"megjegyzes"}
                value={megjegyzes}
                onChange={(e,v) => setMegjegyzes(v)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }

    const renderRendelesCikkTable = () => {
      const options = {
        sizePerPage: 5,
        showTotal: true,
        paginationTotalRenderer: PaginationTotal,
        sizePerPageList: [
          { text: '5', value: 5 },
          { text: '10', value: 10 },
          { text: '25', value: 25 }
        ],
      };

      const handleRowSelect = (row, isSelect) => {
        if (isSelect) {
          let array = [...rendelesModalCikkSelectedList];
          array.push(row.id);
          setRendelesModalCikkSelectedList(array);
        } else {
          setRendelesModalCikkSelectedList(rendelesModalCikkSelectedList.filter(id => id != row.id));
        }
      };

      const handleRowSelectAll = (isSelect, rows) => {
        if (isSelect) {
          setRendelesModalCikkSelectedList(rows.map(r => r.id));
        } else {
          setRendelesModalCikkSelectedList([]);
        }
      };

      const selectRow = {
        mode: 'checkbox',
        selected: rendelesModalCikkSelectedList,
        onSelect: handleRowSelect,
        onSelectAll: handleRowSelectAll
      };
      const columns=[
        {
          dataField: 'cikk.megnevezes',
          text: 'Cikk (Cikkszám)',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          filterValue: (cell, row) => {
            return cell + ' (' + row.cikk.cikkszam + ')';
          },
          formatter: (cell, row) => {
            return cell + ' (' + row.cikk.cikkszam + ')';
          },
          sort: true,
          editable: false
        },
        {
          dataField: 'bizonylatszam',
          text: 'Vevői rendelés bizonylatszám',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          sort: true,
          editable: false
        },
        {
          dataField: 'szabadmennyiseg',
          text: 'Menny.',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          filterValue: (cell, row) => {
            return NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg;
          },
          formatter: (cell, row) => {
            return NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg;
          },
          sort: true,
          editable: false
        },
        {
          // Akarattal más, mint a nettoEgysegar, hogy a felhasználó üres mezőt tölthessen
          dataField: 'nettoegysegarinput',
          text: 'Nettó egységár',
          type: "number",
          headerFormatter: column => <>{column.text} <CustomTooltip text={getTooltip('rendelesmodalnetto')} id="rendelesmodalnettoTT" /></>,
        },
        {
          dataField: 'hatarido',
          text: 'Határidő',
          headerFormatter: column => <>{column.text} <CustomTooltip text={getTooltip('rendelesmodalhataridooszlop')} id="rendelesmodalhataridooszlopTT" /></>,
          editor: {
            type: Type.DATE
          }
        }
      ];

    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={rendelesModalCikkJSON}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
        selectRow={selectRow}
        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
      />
    );
    }

    const renderCikkekTable = () => {

      // Ez a metódus nélkül React nem rendereli újra a műveletek oszlopot törlés után (ergo nem jelennek meg a műveletgombok)
      const bontottStyleColumn = (cell, row) => {
        if (isBontott(row.id)) {
          return { backgroundColor: '#999999'}
        }
      }

      const bontottStyleRow = (row) => {
        if (isBontott(row.id)) {
          return { backgroundColor: '#999999', color: '#5b5b5b' }
        }
      }

      const options = {
        sizePerPage: 5,
        showTotal: true,
        paginationTotalRenderer: PaginationTotal,
        sizePerPageList: [
          { text: '5', value: 5 },
          { text: '10', value: 10 },
          { text: '25', value: 25 }
        ],
      };

      const columns = [
        {
          dataField: 'cikk.cikkszam',
          text: 'Cikkszám',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: {
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
          },
          sort: true
        },
        {
          dataField: 'cikkNev',
          text: 'Megnevezés',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: {
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
          },
          sort: true,
        },
        {
          dataField: 'mennyiseg',
          text: 'Mennyiség',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: NumberFormatter.styleTextAlignWhiteSpace,
          formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
          sort: true,
        },
        {
          dataField: 'hatarido',
          text: 'Határidő',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: {
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
          },
          formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : '',
          sort: true,
        },
        {
          dataField: 'visszaMennyiseg',
          text: 'Vissza. mennyiség',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: {
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
          },
          sort: true,
        },
        {
          dataField: 'rendelesbizonylatszam',
          text: 'Vevői rendelés bizonylatszám',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          headerStyle: { whiteSpace: 'pre-line' },
          style: { whiteSpace: 'pre-line' },
          sort: true
        },
        {
          dataField: 'cikk.aktualisAfaErtek',
          text: 'ÁFA%',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: NumberFormatter.styleTextAlignWhiteSpace,
          formatter: NumberFormatter.formatterSpaceOsszeg,
          sort: true
        },
        {
          dataField: 'nettoEgysegar',
          text: 'Nettó egységár',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: NumberFormatter.styleTextAlignWhiteSpace,
          formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
          sort: true
        },
        {
          dataField: 'bruttoEgysegar',
          text: 'Bruttó egységár',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: NumberFormatter.styleTextAlignWhiteSpace,
          formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
          sort: true
        },
        {
          dataField: 'nettoBeszerzesiar',
          text: 'Nettó beszerzési érték',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: NumberFormatter.styleTextAlignWhiteSpace,
          formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
          sort: true
        },
        {
          dataField: 'bruttoBeszerzesiar',
          text: 'Bruttó beszerzési érték',
          filter: textFilter({
            delay: 400, // default is 500ms
            placeholder: 'Keresés...',
          }),
          style: NumberFormatter.styleTextAlignWhiteSpace,
          formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        },
        {
          dataField: 'id',
          text: 'Műveletek',
          style: bontottStyleColumn,
          formatter: (cell, row, rowIndex, formatExtraData) => tableIconFormatter(cell, row, formatExtraData),
          formatExtraData: cikkek
        }
      ];

      return(
        <React.Fragment>
          <div className="row" style={{marginTop: 50}}>
            {allapot && allapot.name == 'ROGZITETT' && (
              <div className="col-md-2" style={{paddingRight: '2px'}}>
                <Button type="button" className="pull-left table-new-button" color="primary" onClick={() => toggleShowCikk(true)}>
                  Cikk hozzáadása
                </Button>
              </div>
            )}
          </div>
          <div className="animated fadeIn">
          {cikkek && cikkek.length != 0 && (
            <React.Fragment>
              <BootstrapTable
                {...props.baseProps }
                bootstrap4
                keyField="id"
                data={cikkek}
                //rowClasses="kartya-row-class" 
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
                noDataIndication="Nincs megjelenítendő adat!"
                rowStyle={bontottStyleRow}
              />
            </React.Fragment>
          )}
        </div>
        </React.Fragment>
      );
    }

    const renderModals = () => {
      const isElkuldott = allapot && allapot.name == 'ELKULDOTT';
      return(
        <React.Fragment>
          {showCikk && (
            <SzallitoiCikkForm
              route={route}
              parentId={id}
              id={currentCikkId}
              isBontas={isCikkBontas}
              isElkuldott={isElkuldott}
              cikkek={cikkek}
              penznem={penznem}
              setCikkek={setCikkek}
              toggleShowCikk={toggleShowCikk}
            >
            </SzallitoiCikkForm>
          )}

          <Modal isOpen={lockupModal} toggle={() => toggleLockupModal(!lockupModal)} backdrop="static">
            <ModalHeader>Lezárás</ModalHeader>
            <ModalBody>Valóban lezárja a kiválasztott rendelést? Lezárást követen a rendelés nem módosítható.</ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                onClick={() => submitSzallitoiRendeles(true)}
              >
                {'Igen'}
              </ProgressButton>
              <Button type="button" color="secondary" onClick={() => toggleLockupModal(!lockupModal)}>Mégsem</Button>
            </ModalFooter>
          </Modal>

          <Modal
        isOpen={rendelesModal}
        toggle={() => {
          setRendelesModal(false);
        }}
        backdrop="static"
        size="lg"
      >
        <ModalHeader>
          Vevői rendelés tételek hozzáadása
          <CustomTooltip text={getTooltip('rendelesmodalcim')} id="rendelesmodalcimTT" />
          </ModalHeader>
        <ModalBody>
          <Col md="4">
            <Label for="hatarido">
              Határidő: *
              <CustomTooltip text={getTooltip('rendelesmodalhatarido')} id="rendelesmodalhataridoTT" />
              </Label>
            <DateTimePicker
              id="hatarido"
              name="hatarido"
              onChange={(e,v) => v && setRendelesModalHatarido(v)}
              containerClassName={ "organw-date-time"}
              format="YYYY-MM-DD"
              parse={['YYYY-MM-DD']}
              messages={{ dateButton: "dátum"}}
              time={false}
            />
          </Col>
          <Col>
            {rendelesModalCikkJSON && renderRendelesCikkTable()}
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleRendelesSubmitClick} disabled={rendelesModalCikkSelectedList.length < 1}>Hozzáadás</Button>
          <Button onClick={() => {
            setRendelesModal(false);
          }}
          >
            Mégse
          </Button>
        </ModalFooter>
      </Modal>
        </React.Fragment>
      );
    }

    const renderButtons = () => {
      return(
        <div className="row" style={{marginTop: 50}}>
          <div className="col-md-12">
            <Button
              style={{marginRight: 10}}
              className="btn-cursor-pointer"
              color="success"
              type="submit"
            >
              Mentés
            </Button>
            {isNotRogzitett &&
              <ProgressButton
                style={{marginRight: 10}}
                type="button"
                className="btn-cursor-pointer"
                color="success"
                loading={loadingSaveButton}
                onClick={() => toggleLockupModal(!lockupModal)}
              >
                Lezárás
              </ProgressButton>
            }
            <Button type="button" color="secondary" onClick={() => handleBack()}>
              Mégsem
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-header">
          Új szállítói rendelés
        </div>
        <AvForm onValidSubmit={() => submitSzallitoiRendeles(false)} >
          <div className="row" style={{padding: 20}}>
            <div className="col-md-12">
              {renderForm()}
              {renderCikkekTable()}
              {renderButtons()}
            </div>
          </div>
        </AvForm>
        {renderModals()}
      </div>
    );
}
export default SzallitoiForm;