import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import QueryEmptyState from '../../../commons/queryempty';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class FunkcioJogalapOsszerendeles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'LOAD',
      modalNezet: false,
      modalFelvitel: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      baseJson: [],
      aktivJogalap: [],
      aktivFunkcio: [],
      /*
       *  FORM VALUES
       */
      funkcioid: '',
      jogalapid: '',
      statusz: ''
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.updateTable();
    this.getAktivFunkcio();
    this.getAktivJogalap();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === 'FUNCKIO_JOGALAP') {
      this.updateTable();
      this.getAktivFunkcio();
      this.getAktivJogalap();
    }
  }

  getAktivFunkcio = () => {
    if (Lib.KeyCloak.hasRole(this.props.globals.keycloak, ['GDPR_JOGALAP'])) {
      Services.listAktivFunkcio((err, res) => {
        if (!err) {
          let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' [' + e.kod + '] - ' + e.tipus.caption}</option>);
          this.setState({ aktivFunkcio: resOptions });
        }
      });
    }
  }

  getAktivJogalap = () => {
    if (Lib.KeyCloak.hasRole(this.props.globals.keycloak, ['GDPR_JOGALAP'])) {
      Services.listAktivJogalap((err, res) => {
        if (!err) {
          let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' [' + e.kod + '] - ' + e.elevules + ' év'}</option>);
          this.setState({ aktivJogalap: resOptions });
        }
      });
    }
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listOsszerendeles((err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        res[0].forEach((element) => {
          let funkcionev = '';
          let jogalapnev = '';
          if (element.funkcioentity && element.funkcioentity.nev) {
            funkcionev += element.funkcioentity.nev + ' [' + element.funkcioentity.kod + '] - ' + element.funkcioentity.tipus.caption;
          }
          if (element.jogalapentity && element.jogalapentity.nev) {
            jogalapnev += element.jogalapentity.nev + ' [' + element.jogalapentity.kod + ']';
          }
          element.aktiv = element.statusz.name === 'AKTIV';
          element.funkcionev = funkcionev;
          element.jogalapnev = jogalapnev;
        });
        this.setState({
          baseJson: res[0],
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });
    Services.getOsszerendeles(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({
          funkcioid: res[0].funkcioentity.id,
          jogalapid: res[0].jogalapentity.id,
          statusz: res[0].statusz.name
        }, () => {
          this.toggleModositas();
        });
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      ev: '',
      tipus: '',
      mertek: '',
      statusz: '',
    };
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleModositas = () => {
    this.setState({
      modalFelvitel: !this.state.modalFelvitel
    });
  }

  toggleFelvitel = () => {
    this.setState({
      funkcioid: '',
      jogalapid: '',
      statusz: '',
      modalFelvitel: !this.state.modalFelvitel
    });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.clearModositoObj();
    this.toggleFelvitel();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.toggleNezet();
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_JOGALAP', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={10}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_JOGALAP', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  felhasznalvaFormatter = (cell) => {
    return cell ? 'Igen' : 'Nem';
  }

  addOsszerendeles = () => {
    let submitObj = {
      funkcioentity: {
        id: this.state.funkcioid
      },
      jogalapentity: {
        id: this.state.jogalapid
      },
      statusz: this.state.statusz
    };

    Services.addOsszerendeles(submitObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Összerendelés felvéve!');
      }
    });
  }

  updateOsszerendeles = () => {
    let submitObj = {
      id: this._currentEditId,
      funkcioentity: {
        id: this.state.funkcioid
      },
      jogalapentity: {
        id: this.state.jogalapid
      },
      statusz: this.state.statusz
    };

    Services.editOsszerendeles(submitObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Összerendelés módosítva!');
      }
    });
  }

  deleteOsszerendeles = (id) => {
    this.toggleTorles();
    Services.deleteOsszerendeles(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Összerendelés törölve!');
      }
    });
  }

  filterType = (cell) => {
    // just return type for filtering or searching.
    return cell.caption;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  statusFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  renderBaseTable = () => {
    const pagination = true;
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    const statusType = {
      true: 'Aktív',
      false: 'Inaktív'
    };

    return (
      <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options}>
        <TableHeaderColumn
          dataField="funkcionev"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
            Funkció
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="jogalapnev"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
            Jogalap
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="aktiv"
          dataFormat={this.statusFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'SelectFilter', options: statusType, placeholder: 'Összes' }}
          dataAlign="center"
        >
            Státusz
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          dataFormat={this.iconFormatter}
          width="100"
        >
            Műveletek
        </TableHeaderColumn>
      </BootstrapTable>);
  }

  renderAdatok = (id) => {
    let jogalapObj = this.state.baseJson.find(jogalapObj => jogalapObj.id === id);
    return (
      <div>
        <h6>
          Jogalap információ:
        </h6>
        <b>
          Kód:
        </b>
        {' ' + jogalapObj.jogalapentity.kod}
        <br />
        <b>
          Megnevezés:
        </b>
        {' ' + jogalapObj.jogalapentity.nev}
        <br />
        <b>
          Szöveg:
        </b>
        {' ' + jogalapObj.jogalapentity.szoveg}
        <br />
        <b>
          Jogszabály hivatkozás:
        </b>
        {' ' + jogalapObj.jogalapentity.jogszabalyiHivatkozas}
        <br />
        <b>
          Státusz:
        </b>
        {' ' + jogalapObj.jogalapentity.statusz.caption}
        <br />
        <b>
          Elévülés:
        </b>
        {' ' + jogalapObj.jogalapentity.elevules}
        <br />
        <br />
        <h6>
          Funkció információ:
        </h6>
        <b>
          Kód:
        </b>
        {' ' + jogalapObj.funkcioentity.kod}
        <br />
        <b>
          Megnevezés:
        </b>
        {' ' + jogalapObj.funkcioentity.nev}
        <br />
        <b>
          Típus:
        </b>
        {' ' + jogalapObj.funkcioentity.tipus.caption}
        <br />
      </div>
    );
  }

  render() {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_JOGALAP', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button
                className="pull-left table-new-button"
                color="primary"
                onClick={this.handleNewClick}
              >
                Új összerendelés
              </Button>
            </div>
          </div>
        )}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>
            Megtekintés
          </ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>
            Törlés
          </ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteOsszerendeles(this._currentDeleteId)}>
              OK
            </Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>
              Mégsem
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addOsszerendeles : this.updateOsszerendeles}>
            {_muvelet === 0 ? <ModalHeader>Új összerendelés rögzítése</ModalHeader> : <ModalHeader>Meglévő összerendelés módosítása</ModalHeader> }
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvField
                    type="select"
                    required
                    name="funkcioid"
                    id="funkcioid"
                    label="Funkció: *"
                    errorMessage="Kötelező mező!"
                    value={this.state.funkcioid}
                    onChange={this.handleInputChange}
                  >
                    <option disabled value="">
                      Válassz státuszt...
                    </option>
                    {this.state.aktivFunkcio}
                  </AvField>
                </div>
                <div className="col-md-12">
                  <AvField
                    type="select"
                    required
                    name="jogalapid"
                    id="jogalapid"
                    label="Jogalap: *"
                    errorMessage="Kötelező mező!"
                    value={this.state.jogalapid}
                    onChange={this.handleInputChange}
                  >
                    <option disabled value="">
                      Válassz státuszt...
                    </option>
                    {this.state.aktivJogalap}
                  </AvField>
                </div>
                <div className="col-md-12">
                  <AvField
                    type="select"
                    required
                    name="statusz"
                    id="statusz"
                    label="Státusz: *"
                    errorMessage="Kötelező mező!"
                    value={this.state.statusz}
                    onChange={this.handleInputChange}
                  >
                    <option disabled value="">
                      Válassz státuszt...
                    </option>
                    <option value="AKTIV">
                      Aktív
                    </option>
                    <option value="INAKTIV">
                      Inaktív
                    </option>
                  </AvField>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">
                Mentés
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel}>
                Mégse
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default FunkcioJogalapOsszerendeles;
