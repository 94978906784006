import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip,
  FormGroup,
  Input
} from 'reactstrap';
import {
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import { browserHistory } from 'react-router';
import { ProgressButton } from '../../commons/ProgressButton';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import QueryEmptyState from '../../commons/queryempty';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';


class UdulesiJogTabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseView: 'LOAD',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseRciView: 'LOAD',
      currentStatusBaseReszvenyView: 'LOAD',
      currentId: null,
      currentRciId: null,
      szerzodesid: null,
      udulesijogObj: null,
      rciViewObj: [],
      rciLoadingButton: false,
      reszveny: null,
      udulesijogJson: [],
      aktivSzallodak: [],
      aktivEpuletek: [],
      evekOptionData: [],
      evValue: new Date().getFullYear().toString(),
      szallodaValue: '',
      epuletValue: '',
      jogtulajdonosValue: '',
      udulesiJogAzonosito: '',
      modalRciNezet: false,
      modalReszvenyNezet: false,
      modalNezet: false,
      modalTorles: false,
      modalSzamlazasfelfuggesztve: false,
      szallodaHasValue: false,
      disableSzamlazasfelfuggesztve: false,
      modalMegerosites: false,
      // form values
      formFelfugesztesCheck: false,
      formEvesdij: '',
      formMegjegyzes: '',
      disableByAzonosito: false,
    };
  }

  componentDidMount() {
    // log(this.props.location);
    this.getAktivSzallodak();
    if (this.props.location && this.props.location.state && this.props.location.state.type && this.props.location.state.type === 'UDULJ') {
      // this.updateEloszures(this.props.location.state.szallodaid, this.props.location.state.epuletid, this.props.location.state.partner, this.props.location.state.azonosito);
      // if (this.props.location.state.szallodaid) {
      //   this.getAktivEpuletById(this.props.location.state.szallodaid);
      // }
    } else {
      this.getAktivEpulet();
    }
    // console.log('-sasosmasos', this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({ currentStatusBaseTable: 'LOAD', szerzodesid: nextProps.tableData }, () => {
        Services.listUdulesijogBySzerzodesId(nextProps.tableData, (err, res) => {
          if (err) {
            this.setState({
              currentStatusBaseTable: 'NETW'
            });
          } else {
            if (res[0].length === 0) this.props.globals.addInfo('A szerződéshez nem tartozik üdülési jog!');
            let newState = res[0];
            newState.forEach((item) => {
              switch (item.statusz.name) {
                case 'AKTIV':
                  item.statusz = 0;
                  break;
                case 'LEZART':
                  item.statusz = 1;
                  break;
                default:
                  item.statusz = -1;
                  break;
              }
            });
            this.setState({
              udulesijogJson: newState,
              currentStatusBaseTable: newState.length > 0 ? 'TABL' : 'DATA'
            });
          }
        });
      });
    }
  }

  handleChangeJogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ jogtulajdonosValue: value });
    } else {
      this.setState({ jogtulajdonosValue: '' });
    }
  }

  loadJogtulajdonosChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listUdulesijogTulaj('UDULESIJOG_TULAJ_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadUdulesiJogAzonositoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listBizonylatFilterValues(input, (err, res) => {
        if (!err) {
          this.setState({ disableByAzonosito: true });
          callback(res[0]);
        }
      });
    } else {
      this.setState({ disableByAzonosito: false });
    }
  }

  handleChangeUdulesiJogAzonosito = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        udulesiJogAzonosito: {
          a: value.a,
        },
        disableByAzonosito: true
      });
    } else {
      this.setState({ udulesiJogAzonosito: '', disableByAzonosito: false });
    }
  }

  // handleInputChangeAzonosito = (value, { action }) => {
  //   console.log(action)
  //   if (value && value.length !== '') {
  //     this.setState({
  //       udulesiJogAzonosito: {
  //         a: value,
  //       }
  //     });
  //   } else {
  //     this.setState({ udulesiJogAzonosito: '' });
  //   }
  // }

  handleInputChangeAzonosito = (value, { action }) => {
    switch (action) {
      case 'input-change':
        if (value && value.length !== '') {
          this.setState({
            udulesiJogAzonosito: {
              a: value,
            }
          });
        } else {
          this.setState({ udulesiJogAzonosito: '' });
        }
        break;
      default:
    }
  }

  navToForm = () => {
    browserHistory.push('/szerzodes/udulesijog/felvetel');
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleRciNezet = () => {
    this.setState({ modalRciNezet: !this.state.modalRciNezet });
  }

  toggleReszvenyNezet = () => {
    this.setState({ modalReszvenyNezet: !this.state.modalReszvenyNezet });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  initSzamlafelfuggesztes = () => {
    this.setState({ tableFelfuggesztesButtonLoading: true });
    let udulesiJogIds = [];
    this.state.udulesijogJson.forEach((item) => {
      udulesiJogIds.push(item.id);
    });
    Services.initSzamlafelfuggesztes(udulesiJogIds, (err, res) => {
      if (err) {
        this.setState({
          tableFelfuggesztesButtonLoading: false
        });
      } else {
        this.setState({
          evekOptionData: res[0],
          tableFelfuggesztesButtonLoading: false
        }, () => {
          this.toggleSzamlazasfelfuggesztve();
        });
      }
    });
  }

  toggleMegerositesModal = () => {
    this.setState(prevState => ({ modalMegerosites: !prevState.modalMegerosites }));
  }

  toggleSzamlazasfelfuggesztve = () => {
    this.setState({
      formFelfugesztesCheck: false,
      formEvesdij: '',
      formMegjegyzes: ''
    }, () => {
      this.setState(prevState => ({ modalSzamlazasfelfuggesztve: !prevState.modalSzamlazasfelfuggesztve }));
    });
  }

  handleViewClick = (id) => {
    this.setState({
      currentId: id,
      currentStatusBaseView: 'LOAD',
      modalNezet: true
    });
    Services.initForm(id, true, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseView: 'NETW'
        });
      } else {
        this.setState({
          udulesijogObj: res[0].udulesijog,
          currentStatusBaseView: 'LOADED'
        });
      }
    });
  }

  handleRciViewClick = (id) => {
    this.setState({
      currentRciId: id,
      currentStatusBaseRciView: 'LOAD',
      modalRciNezet: true
    });
    Services.getRciData(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseRciView: 'NETW'
        });
      } else {
        this.setState({
          rciViewObj: res[0],
          currentStatusBaseRciView: 'LOADED'
        });
      }
    });
  }

  handleReszvenyClick = (id) => {
    this.setState({
      currentStatusBaseReszvenyView: 'LOAD',
      modalReszvenyNezet: true
    });
    Services.getUdulesijog(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseReszvenyView: 'NETW'
        });
      } else {
        this.setState({
          reszveny: res[0],
          currentStatusBaseReszvenyView: 'LOADED'
        });
      }
    });
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleEditClick = (id) => {
    browserHistory.push({
      pathname: '/szerzodes/udulesijog/modositas',
      search: '?id=' + id,
      state: {
        type: 'UDULJ',
        szallitoid: null,
        jogosultid: null,
        ev: this.state.evValue,
        szallodaid: this.state.szallodaValue,
        epuletid: this.state.epuletValue,
        partner: this.state.jogtulajdonosValue && this.state.jogtulajdonosValue.id && this.state.jogtulajdonosValue.id,
        azonosito: this.state.udulesiJogAzonosito
      }
    });
  //  browserHistory.push('/szerzodes/udulesijog/modositas?id=' + id);
  }

  getAktivSzallodak = () => {
    Services.listAktivSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  getAktivEpulet = () => {
    Services.listAktivEpuletek((err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek });
      }
    });
  }

  getAktivEpuletById = (id) => {
    Services.listAktivEpuletekById(id, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek });
      }
    });
  }

  deleteUdulesijog = (id) => {
    this.toggleTorles();
    Services.deleteUdulesijog(id, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Üdülési jog törölve!');
        this.updateTable();
      }
    });
  }

  updateSzamlazasfelfuggesztve = () => {
    // console.log(this.state.formFelfugesztesCheck, this.state.formEvesdij, this.state.formMegjegyzes)
    this.setState({ felfuggesztesButtonLoading: true });
    let udulesiJogIds = [];
    this.state.udulesijogJson.forEach((item) => {
      udulesiJogIds.push(item.id);
    });

    let submitObj = {
      ids: udulesiJogIds,
      szlafelfugg: this.state.formFelfugesztesCheck,
      ev: this.state.formEvesdij,
      megjegyzes: this.state.formMegjegyzes
    };

    Services.updateSzamlazasfelfuggesztve(submitObj, (err) => {
      if (!err) {
        this.props.globals.addSuccess('A módosítás megtörtént!');
        this.toggleSzamlazasfelfuggesztve();
        this.toggleMegerositesModal();
        this.updateTable(true);
      }
      this.setState({ felfuggesztesButtonLoading: false });
    });
  }

  updateEloszures = (ev, szallodaid, epuletid, partner, azonosito) => {
    this.setState({
      evValue: ev,
      szallodaValue: szallodaid && szallodaid,
      szallodaHasValue: szallodaid && szallodaid,
      epuletValue: epuletid && epuletid,
      jogtulajdonosValue: partner,
      udulesiJogAzonosito: azonosito
    }, () => {
      this.updateTable();
    });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'LEK') && (
        <React.Fragment>
          <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
          <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={10}>
            {'Megtekintés'}
          </UncontrolledTooltip>
          <Button className="fa fa-building-o table-action-icon" id={'action-rci-view-' + cell} onClick={() => this.handleRciViewClick(cell)} />
          <UncontrolledTooltip placement="left" target={'action-rci-view-' + cell} delay={10}>
            {'RCI adatok megtekintés'}
          </UncontrolledTooltip>
        </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'LEK') && (
          <React.Fragment>
            <Button className="fa fa-registered table-action-icon" id={'action-reszveny-' + cell} onClick={() => this.handleReszvenyClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-reszveny-' + cell} delay={10}>
              {'Részvények'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  statuszFormatter = (cell) => {
    switch (cell) {
      case 0:
        return <Badge color="success">Aktív</Badge>;
      case 1:
        return <Badge color="secondary">Lezárt</Badge>;
      default:
        return <Badge color="danger">Ismeretlen</Badge>;
    }
  }

  szlafelfuggFormatter = (cell) => {
    switch (cell) {
      case true:
        return <Badge color="danger">Igen</Badge>;
      case false:
        return <Badge color="secondary">Nem</Badge>;
      default:
        return <Badge color="secondary">Ismeretlen</Badge>;
    }
  }

  evFormatter = (cell) => {
    return (
      <div>{cell.caption}</div>
    );
  }

  csvFormatter = (cell) => {
    return (cell === undefined ? '' : cell.caption);
  }

  csvDecode = (cell, row, items) => {
    return (cell === undefined ? '' : items[cell]);
  }

  renderTulajdonosListItem = (tulajdonos) => {
    return (
      <div className="col-md-6" key={tulajdonos.id.toString()}>
        <li key={tulajdonos.id.toString()} style={tulajdonos.fotulajdonos ? { color: 'forestgreen' } : {}}>
          {/* <b>{tulajdonos.tulajdonosbean.nev}</b><br /> */}
          <b>{tulajdonos.tulajdonosbean && tulajdonos.tulajdonosbean.azon + ' - ' + tulajdonos.tulajdonosbean.nev}</b>
          <br />
          <span><b>Cím: </b>{tulajdonos.tulajdonosbean.cim}</span><br />
          <span><b>Érvényesség kezdete: </b>{tulajdonos.kezdete}</span><br />
          <span><b>Érvényesség vége: </b>{tulajdonos.vege}</span><br />
          <span><b>RCI tag: </b>{tulajdonos.rcitag ? 'Igen' : 'Nem'}</span><br />
          <span><b>RCI státusz: </b>{tulajdonos.rcistatusz && tulajdonos.rcistatusz.caption}</span><br />
          <span><b>RCI Weeks azonosító: </b>{tulajdonos.rciweeksazon}</span><br />
          <span><b>RCI Points azonosító: </b>{tulajdonos.rcipointsazon}</span><br />
          <span><b>RCI típus: </b>{tulajdonos.rcitipus && tulajdonos.rcitipus.caption}</span><br />
          {tulajdonos.elhunyt && (
            <React.Fragment>
              <span><b>Elhunyt: </b>Igen</span><br />
            </React.Fragment>
          )}
          {tulajdonos.eladta && (
            <React.Fragment>
              <span><b>Eladta: </b>Igen</span><br />
            </React.Fragment>
          )}
          <br />
        </li>
      </div>
    );
  }

  renderReszvenyListItem=(data) =>{
    let reszvenyekTomb=[];
    return (
      <div className="col-md-6" style={{ padding: '10px' }} key={data.id.toString()}>
        <li key={data.id.toString()} style={data.fotulajdonos ? { color: 'forestgreen' } : {}}>
          <span><h4>Jogtulajdonos adatai: </h4></span>
          <br />
          <span>{(data.tulajdonosbean && data.tulajdonosbean.nev) + ' - ' + (data.tulajdonosbean && data.tulajdonosbean.azon)}</span>
          <br />
          <span><b>Cím: </b>{data.tulajdonosbean && data.tulajdonosbean.cim}</span>
          <br />
          <span><b>Érvényesség kezdete: </b>{data.tulajdonosbean && data.kezdete}</span>
          <br />
          <span><b>Érvényesség vége: </b>{data && data.vege ? data.vege : "-" }</span>
          <br />
          <br />
          <span><b>Részvények: </b></span>
          <br />
          <br />
          {data.reszvenyEntities.forEach((reszveny) => {
            reszvenyekTomb.push(
              <React.Fragment>
                <span><b> Részvény fajta: </b>{reszveny.fajta.caption}</span>
                <br />
                <span><b> Darabszám: </b>{reszveny.darabszam}</span>
                <br />
                <span><b> Bekerülési érték: </b>{reszveny.bekerulesiertek}</span>
                <br />
                <span><b> Névérték: </b>{reszveny.nevertek}</span>
                <br />
                <span><b> Értékpapírszámlán elhelyezett: </b>{reszveny && reszveny.ertekpapszamla ? "Igen" : "Nem"}</span>
                <br />
                <hr />
              </React.Fragment>
            );
          })}
          {reszvenyekTomb}
          <br />
        </li>
      </div>
    );
  }

  renderRciListItem = (data, idx) => {
    return (
      <div className="col-md-6" key={idx.toString()}>
        <span><b>Adatátvétel dátuma: </b>{data.deponalas && data.deponalas.erkezesDatum}</span><br />
        <span><b>Deponálás: </b></span><br />
        <div style={{ marginLeft: 10 }}>
          <span><b>Fő jogtulajdonos: </b>{data.deponalas && data.deponalas.deponaloVendegNev}</span><br />
          <span><b>RCI azonosító: </b>{data.deponalas && data.deponalas.rciAzonosito}</span><br />
          <span><b>Deponálás éve: </b>{data.deponalas && data.deponalas.deponalasEv}</span><br />
          <span><b>Deponáló vendég: </b>{data.deponalas && data.deponalas.deponaloVendegNev}</span><br />
          <span><b>Deponálás vége: </b>{data.deponalas && data.deponalas.deponalasVegeDatum}</span><br />
          <span><b>Bejövő státusz: </b>{data.deponalas && data.deponalas.bejovoStatusz}</span><br />
        </div>
        <span><b>Foglalás: </b></span><br />
        <div style={{ marginLeft: 10 }}>
          <span><b>Vendég neve: </b>{data.foglalas && data.foglalas.vendegNev}</span><br />
          <span><b>További vendég neve: </b>{data.foglalas && data.foglalas.vendegNev2}</span><br />
          <span><b>Vendég email címe: </b>{data.foglalas && data.foglalas.vendegEmail}</span><br />
          <span><b>Vendég telefonszáma: </b>{data.foglalas && data.foglalas.vendegTelefon}</span><br />
          <span><b>Vendég címe: </b>{data.foglalas && data.foglalas.vendegCim}</span><br />
          <span><b>Országkód: </b>{data.foglalas && data.foglalas.vendegOrszagkod}</span><br />
          <span><b>Irányítószám: </b>{data.foglalas && data.foglalas.vendegIrszam}</span><br />
          <span><b>Városnév: </b>{data.foglalas && data.foglalas.vendegVarosnev}</span><br /><br />
        </div>
      </div>
    );
  }

  renderStatuszListItem = (statusz) => {
    return (
      <li key={statusz.id.toString()}>
        <span><b>Státusz: </b>{statusz.statusz.caption}</span><br />
        <span><b>Érvényesség kezdete: </b>{statusz.kezdete}</span><br />
        <span><b>Érvényesség vége: </b>{statusz.vege}</span><br /><br />
      </li>
    );
  }

  renderAdatok = () => {
    let listItems = [];
    if (this.state.udulesijogObj) {
      for (let item of this.state.udulesijogObj.udulesijogtulajdonosentities) {
        listItems.push(this.renderTulajdonosListItem(item));
      }
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <h6>Üdülési jog információ:</h6>
            <b>Üdülési jog azonosító: </b>{this.state.udulesijogObj && this.state.udulesijogObj.azonosito}<br />
            <b>Érvényesség kezdete: </b>{this.state.udulesijogObj && this.state.udulesijogObj.kezdete}<br />
            <b>Érvényesség vége: </b>{this.state.udulesijogObj && this.state.udulesijogObj.vege}<br />
            <b>Számlakibocsátó: </b>{this.state.udulesijogObj && this.state.udulesijogObj.szamlakibocsatobean && this.state.udulesijogObj.szamlakibocsatobean.nev}<br />
            <b>Szálloda: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.szallodanev}<br />
            <b>Épület: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.epuletnev}<br />
            <b>Apartman: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.apartmannev +
              (this.state.udulesijogObj.apartmanbean.kategoria ? ' (' + this.state.udulesijogObj.apartmanbean.kategoria + ')' : '')}<br />
            <b>Hét sorszáma: </b>{this.state.udulesijogObj && this.state.udulesijogObj.het}<br />
            <b>Páros/páratlan év: </b>{this.state.udulesijogObj && this.state.udulesijogObj.gyakorisag && this.state.udulesijogObj.gyakorisag.caption}<br />
            <b>Osztott díj: </b>{this.state.udulesijogObj && this.state.udulesijogObj.reszlet ? 'Igen' : 'Nem' }<br />
            <b>Szín: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.hetszine}<br />
            <b>Jellemző: </b>{this.state.udulesijogObj && this.state.udulesijogObj.jellemzo}<br />
            <b>Lejárati státusz: </b>{this.state.udulesijogObj && this.state.udulesijogObj.lejaratistatusz && this.state.udulesijogObj.lejaratistatusz.caption}<br /><br />
          </div>
          <div className="col-md-6">
            <h6>Pénzügyi adatok:</h6>
            <b>Pénznem: </b>{this.state.udulesijogObj && this.state.udulesijogObj.penznem}<br />
            <b>Fizetési mód: </b>{this.state.udulesijogObj && this.state.udulesijogObj.fizmod}<br />
            <b>Számlázás nyelve: </b>{this.state.udulesijogObj && this.state.udulesijogObj.nyelv && this.state.udulesijogObj.nyelv.caption}<br />
            <b>Státusz: </b>{this.state.udulesijogObj && this.state.udulesijogObj.statusz && this.state.udulesijogObj.statusz.caption}<br />
            <b>Lezárás oka: </b>{this.state.udulesijogObj && this.state.udulesijogObj.lezarasoka && this.state.udulesijogObj.lezarasoka.caption}<br /><br />
          </div>
          <div className="col-md-12">
            <h6>Jogtulajdonos(ok) adatai:</h6>
            <ul className="row">
              {listItems}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderRciAdatok = () => {
    let listItems = [];
    if (this.state.rciViewObj) {
      this.state.rciViewObj.forEach((item, index) => {
        listItems.push(this.renderRciListItem(item, index));
      });
    }
    return (
      <React.Fragment>
        <h6>
          {'Üdülési jog információ:'}
        </h6>
        <div className="row">
          {listItems}
        </div>
      </React.Fragment>
    );
  }

  renderReszvenyAdatok = () => {
    let listItems = [];
    var udulesiJogTulajid = new Set();
    var reszvenyekossz = [];

    if (this.state.reszveny) {
      this.state.reszveny.udulesijogtulajdonosentities.forEach((jogtulaj) => {
        if (jogtulaj.reszvenyEntities.length > 0) {
          udulesiJogTulajid.add(jogtulaj);
        }
      });
      let arrUdulesiJogT = [...udulesiJogTulajid];
     for (let item of arrUdulesiJogT) {
        listItems.push(this.renderReszvenyListItem(item));
     }
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <ul className="row">
              {listItems.length > 0 ? listItems : 'Nincs megjeleníthető részvény.'}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }


  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  onRowSelect = (row) => {
    this.props.changeData(row.id);
  }


  renderTable = () => {
    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const statuszok = {
      0: 'Aktív',
      1: 'Lezárt'
    };

    const szlafelfugg = {
      true: 'Igen',
      false: 'Nem'
    };

    // const lezarasoka = {
    //   0: 'Apartmanfelmondás',
    //   1: 'Kompenzáció',
    //   2: 'Felminősítés alatt',
    //   3: 'Szerződés módosítása',
    // };

    const options = this.props.globals.bootstraptableProps;

    return (
      <div className="animated fadeIn">
        <BootstrapTable data={this.state.udulesijogJson} pagination options={options} selectRow={selectRowProp} exportCSV csvFileName="udulesijogok.csv">
          <TableHeaderColumn dataField="azonosito" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="AZONOSÍTÓ">Azonosító</TableHeaderColumn>
          <TableHeaderColumn dataField="szallodanev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="SZÁLLODA">Szálloda</TableHeaderColumn>
          <TableHeaderColumn dataField="epuletnev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="ÉPÜLET">Épület</TableHeaderColumn>
          <TableHeaderColumn dataField="apartmannev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="APARTMAN">Apartman</TableHeaderColumn>
          <TableHeaderColumn dataField="het" width="90" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="HÉT">Hét</TableHeaderColumn>
          <TableHeaderColumn dataField="gyakorisag" width="150" dataFormat={this.evFormatter} tdStyle={{ whiteSpace: 'normal' }} csvFormat={this.csvFormatter} csvHeader="PÁROS/PÁRATLAN ÉV">Páros/páratlan év</TableHeaderColumn>
          <TableHeaderColumn dataField="tulajdonos" tdStyle={{ whiteSpace: 'pre-line' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="JOGTULAJDONOS">Fő jogtulajdonos</TableHeaderColumn>
          <TableHeaderColumn dataField="jellemzo" tdStyle={{ whiteSpace: 'pre-line' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="JELLEMZŐ">Jellemző</TableHeaderColumn>
          <TableHeaderColumn dataField="szlafelfugg" width="120" dataAlign="center" dataFormat={this.szlafelfuggFormatter} tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'SelectFilter', options: szlafelfugg, placeholder: 'Összes' }} csvFormat={(cell, row, items)=>this.csvDecode(cell, row, szlafelfugg)} csvHeader="SZÁML. FELFÜGG.">Felfüggesztés akt. értéke</TableHeaderColumn>
          <TableHeaderColumn dataField="statusz" width="120" dataAlign="center" dataFormat={this.statuszFormatter} tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'SelectFilter', options: statuszok, placeholder: 'Összes' }} csvFormat={(cell, row, items)=>this.csvDecode(cell, row, statuszok)} csvHeader="STÁTUSZ">Státusz</TableHeaderColumn>
          <TableHeaderColumn dataField="id" width="150" isKey dataFormat={this.iconFormatter} export={false}>Műveletek</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  szallodaChange = (e) => {
    this.setState({
      szallodaHasValue: e.target.value, aktivEpuletek: [], szallodaValue: e.target.value ? e.target.value : '', epuletValue: ''
    });
    if (e.target.value) {
      this.getAktivEpuletById(e.target.value);
    }
  }

  hetKezdetChange = (e) => {
    this.setState({
      hetKezdet: e.target.value
    });
  }

  hetVegeChange = (e) => {
    this.setState({
      hetVege: e.target.value
    });
  }

  epuletChange = (e) => {
    this.setState({ epuletHasValue: true, epuletValue: e.target.value });
  }

  submitSearch = () => {
    Services.listUdulesijog(this.state.evValue, this.state.szallodaValue, this.state.epuletValue, this.state.jogtulajdonosValue && this.state.jogtulajdonosValue.id && this.state.jogtulajdonosValue.id, false, (err, res) => {
      if (!err) {
        this.setState({ udulesijogJson: res[0] });
      }
    });
  }

  loadRciAdatok(szallodaId) {
    const { hetKezdet, hetVege } = this.state;
    this.setState({ rciLoadingButton: true }, () =>
    Services.loadRciAdatok(szallodaId, hetKezdet, hetVege, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Rci adatok betöltése megtörtént!');
      }
      this.setState({ rciLoadingButton: false });
    })
    );
  }

  ujudulesijog() {
    browserHistory.push('/szerzodes/udulesijog/felvetel?szid=' + this.state.szerzodesid);
  }

  updateTable = (disableSzamlazasfelfuggesztve) => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listUdulesijog(
      !this.state.udulesiJogAzonosito ? this.state.evValue : null,
      !this.state.udulesiJogAzonosito ? this.state.szallodaValue : null,
      !this.state.udulesiJogAzonosito ? this.state.epuletValue : null,
      !this.state.udulesiJogAzonosito ? this.state.jogtulajdonosValue && this.state.jogtulajdonosValue.id && this.state.jogtulajdonosValue.id : null,
      false,
      this.state.udulesiJogAzonosito && this.state.udulesiJogAzonosito.a,
      (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseTable: 'NETW'
          });
        } else {
          let newState = res[0];
          newState.forEach((item) => {
            switch (item.statusz.name) {
              case 'AKTIV':
                item.statusz = 0;
                break;
              case 'LEZART':
                item.statusz = 1;
                break;
              default:
                item.statusz = -1;
                break;
            }
          });
          this.setState({
            udulesijogJson: newState,
            currentStatusBaseTable: newState.length > 0 ? 'TABL' : 'DATA',
            disableSzamlazasfelfuggesztve: disableSzamlazasfelfuggesztve
          });
        }
      }
    );
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  render() {
    // browserHistory.push('/szerzodes/udulesijog/felvetel?id=' + id);
    return (
      <div>
        <AvForm onValidSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-1">
              <AvField
                name="evValue"
                id="evValue"
                minLength="4"
                maxLength="4"
                type="number"
                onChange={this.handleInputChange}
                value={this.state.evValue}
                placeholder="Év"
                label="Év:"
                disabled={this.state.disableByAzonosito}
                errorMessage="Érvénytelen adat!"
              />
            </div>
            <div className="col-md-2">
              <AvField
                type="select"
                name="szallodaid"
                id="szallodaid"
                label="Szálloda: "
                errorMessage="Kötelező mező!"
                value={this.state.szallodaValue}
                onChange={this.szallodaChange}
                disabled={this.state.disableByAzonosito}
              >
                <option value="">Válassz szállodát...</option>
                {this.state.aktivSzallodak}
              </AvField>
            </div>
            <div className="col-md-2">
              <AvField
                type="select"
                name="epuletid"
                id="epuletid"
                label="Épület: "
                errorMessage="Kötelező mező!"
                value={this.state.epuletValue}
                onChange={this.epuletChange}
                disabled={!this.state.szallodaHasValue || this.state.disableByAzonosito}
              >
                <option value="">Válassz épületet...</option>
                {this.state.aktivEpuletek}
              </AvField>
            </div>
            <div className="col-md-3">
              <Label for="jogtulajId">Jogtulajdonos:</Label>
              {/* <Select.Async
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeJogtulajdonos(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadJogtulajdonosChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
              /> */}
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeJogtulajdonos(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadJogtulajdonosChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
                isDisabled={this.state.disableByAzonosito}
              />
            </div>
            <div className="col-md-2">
              <Label for="azonosito">Azonosító:</Label>
              {/* <Select.Async
                name="azonosito"
                id="azonosito"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeSzerzodesBizszam(v)}
                onInputChange={v => this.handleInputChangeAzonosito(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadSzerzodesBizszamChange(input, callback), 1000)}
                isLoading={false}
                valueKey="a"
                labelKey="a"
                value={this.state.udulesiJogAzonosito}
                cache={false}
                autoload={false}
              /> */}
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="azonosito"
                id="azonosito"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeUdulesiJogAzonosito(v)}
                onInputChange={this.handleInputChangeAzonosito}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadUdulesiJogAzonositoChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.a}
                getOptionValue={option => option.a}
                value={this.state.udulesiJogAzonosito}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-2">
              <Button className="margin-top-30" color="primary" onClick={() => this.updateTable()}>Keresés</Button>
            </div>
            <div className="col-md-2">
              <AvField
                name="hetkezdet"
                id="hetkezdet"
                label="Hét kezdet: "
                errorMessage="Kötelező mező!"
                onChange={this.hetKezdetChange}
              />
            </div>
            <div className="col-md-2">
              <AvField
                name="hetvege"
                id="hetvege"
                label="Hét vége: "
                errorMessage="Kötelező mező!"
                onChange={this.hetVegeChange}
              />
            </div>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'KARB') && (
              <div className="col-md-8" style={{ padding: 0, marginTop: 30 }}>
                {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_RCIFTP', 'KARB') && (
                  <ProgressButton color="primary" id="loadRci" loading={this.state.rciLoadingButton} onClick={() => this.loadRciAdatok(this.state.szallodaValue)}>Rci adatok betöltése</ProgressButton>
                )}
                {' '}
                {this.state.szerzodesid ? <Button color="primary" onClick={() => this.ujudulesijog()} style={{ marginRight: 4 }}>Új üdülési jog</Button> : null
                }
                {this.state.udulesijogJson.length > 0 ? <ProgressButton color="primary" loading={this.state.tableFelfuggesztesButtonLoading} onClick={() => this.initSzamlafelfuggesztes()} disabled={this.state.disableSzamlazasfelfuggesztve}>Számlázás felfüggesztése</ProgressButton> : null
                }
              </div>
            )}
          </div>
        </AvForm>

        <div className="row">
          <div className="col-md-12">
            {/* <Button className="szerzodes-collapse-buttons" color="primary" onClick={this.navToForm} >Új üdülési jog</Button> */}
          </div>
          <div className="col-md-12" style={{ marginTop: '30px' }}>
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.updateTable()}
              />),
              TABL: (this.renderTable())
            }[this.state.currentStatusBaseTable]}
          </div>

        </div>


        <Modal
          isOpen={this.state.modalNezet}
          toggle={this.toggleNezet}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <ModalHeader>
            {'Megtekintés'}
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleViewClick(this.state.currentId)}
              />),
              LOADED: (this.renderAdatok())
            }[this.state.currentStatusBaseView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalRciNezet}
          toggle={this.toggleRciNezet}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <ModalHeader>
            {'RCI adatok megtekintés'}
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleRciViewClick(this.state.currentRciId)}
              />),
              LOADED: (this.renderRciAdatok())
            }[this.state.currentStatusBaseRciView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleRciNezet}>
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalReszvenyNezet}
          toggle={this.toggleReszvenyNezet}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <ModalHeader>
            {'Részvény'}
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleReszvenyClick()}
              />),
              LOADED: (this.renderReszvenyAdatok())
            }[this.state.currentStatusBaseReszvenyView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleReszvenyNezet}>
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteUdulesijog(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalSzamlazasfelfuggesztve} toggle={this.toggleSzamlazasfelfuggesztve} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Számlázás felfüggesztése'}
          </ModalHeader>
          <ModalBody>
            <p style={{ textAlign: 'justify' }}><b>{'Ön a listában lekérdezett - aktív státuszú - üdülési jogok számlázásának felfüggesztését, illetve - az alábbi jelölés értelmében - számlázás felfüggesztésének megszüntetését kezdeményezte az alább megadott évre vonatkozóan:'}</b></p>
            <FormGroup check>
              <Input type="checkbox" name="formFelfugesztesCheck" id="formFelfugesztesCheck" checked={this.state.formFelfugesztesCheck} onChange={this.handleInputChange} />
              <Label for="formFelfugesztesCheck" check>Számlázás felfüggesztésének megszüntetése</Label>
            </FormGroup>
            <FormGroup style={{ margin: 10 }}>
              <Label for="formEvesdij">Éves díj éve:</Label>
              <Input type="select" name="formEvesdij" id="formEvesdij" value={this.state.formEvesdij} onChange={this.handleInputChange}>
                <option value="">Válassz évet...</option>
                {this.state.evekOptionData.map(e => <option key={e} value={e}>{e}</option>)}
              </Input>
            </FormGroup>
            <FormGroup row style={{ margin: 10 }}>
              <Label for="formMegjegyzes">Megjegyzés</Label>
              <Input type="textarea" name="formMegjegyzes" id="formMegjegyzes" maxLength={150} value={this.state.formMegjegyzes} onChange={this.handleInputChange} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ProgressButton className="btn-cursor-pointer" color="primary" onClick={() => this.toggleMegerositesModal()}>Mentés</ProgressButton>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleSzamlazasfelfuggesztve}>Mégse</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalMegerosites}
          toggle={this.toggleMegerositesModal}
          backdrop="static"
          size="sm"
        >
          <ModalHeader>
            {'Számlázás felfüggesztés módosításának megerősítése'}
          </ModalHeader>
          <ModalBody>
            Valóban végrehajtja a módosítást a számlázás felfüggesztése kapcsán?
          </ModalBody>
          <ModalFooter>
            <ProgressButton className="btn-cursor-pointer" color="primary" loading={this.state.felfuggesztesButtonLoading} onClick={() => this.updateSzamlazasfelfuggesztve()}>Igen</ProgressButton>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleMegerositesModal}>
              {'Mégse'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UdulesiJogTabla;
