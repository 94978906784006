import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { DatePicker, PaginationTotal } from '../../../commons/controls';
import DataEmptyState from '../../../commons/dataemptystate';
// import Services from './services';


class RaktarKeszletContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseJson: [
        {
          id: 1,
          raktar: 'Raktár 2',
          raktarkod: 'R2',
          cikkcsoport: 'Első cikkcsoport',
          cikkcsoportkod: '001',
          cikk: 'Lámpa',
          cikkkod: 'l11',
          mennyiseg: 33,
          mennyisegiegyseg: 'db',
          mozgastipus: 'Aktuális raktárkészlet'
        },
        {
          id: 2,
          raktar: 'Raktár abc',
          raktarkod: 'Rabc',
          cikkcsoport: 'Másik cikkcsoport',
          cikkcsoportkod: '004',
          cikk: 'Narancslé',
          cikkkod: 'nl02',
          mennyiseg: 140,
          mennyisegiegyseg: 'liter',
          mozgastipus: 'Aktuális raktárkészlet'
        }
      ],
    };
  }

  renderBaseTable = () => {
    const pagination = true;

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div className="margin-top-30">
        { this.state.baseJson.length ?
          <React.Fragment>
            <Button color="success" className="margin-bottom-20 fa fa-file-excel-o btn-cursor-pointer" >{' '}<span className="basic-font">Exportálás Excel-be</span></Button>{' '}
            <Button color="danger" className="margin-bottom-20 fa fa-file-pdf-o btn-cursor-pointer">{' '}<span className="basic-font">Exportálás PDF-be</span></Button>{' '}
            <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options} >
              <TableHeaderColumn
                dataField="raktar"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Raktár
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="raktarkod"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Raktár kód
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="cikkcsoport"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Cikkcsoport
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="cikkcsoportkod"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Cikkcsoport kód
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="cikk"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Cikk
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="cikkkod"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Cikk kód
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="mennyiseg"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Mennyiség
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="mennyisegiegyseg"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Mennyiségi egység
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="mozgastipus"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                Mozgás típusa
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                tdStyle={{ whiteSpace: 'normal' }}
                isKey
                dataFormat={this.baseTableIconFormatter}
              >
                Műveletek
              </TableHeaderColumn>
            </BootstrapTable>
          </React.Fragment> : <DataEmptyState />}
      </div>
    );
  }

  render() {
    return (
      <div>
        <AvForm>
          <div className="row">
            <div className="col-md-3">
              <AvField
                type="select"
                name="kivul"
                label="Mozgás típusa: *"
                errorMessage="Kötelező mező!"
                required
              >
                <option value="" >Válassz típust...</option>
                <option value="AKTUALIS">Aktuális raktárkészlet</option>
                <option value="MIND">Minden mozgás</option>
                <option value="BEVET">Bevételezés</option>
                <option value="KIAD">Kiadás</option>
                <option value="ATAD">Raktárközi átadás</option>
                <option value="SELEJT">Selejtezés</option>
              </AvField>
            </div>
            <div className="col-md-3">
              <Label for="kezdete">Időtartam kezdete:</Label>
              <DatePicker
                required
                type="select"
                name="kezdete"
                defaultValue={this.state.modositoObj && this.state.modositoObj.kezdete && new Date(this.state.modositoObj.kezdete)}
              />
            </div>
            <div className="col-md-3">
              <Label for="vege">Időtartam vége:</Label>
              <DatePicker
                required
                type="select"
                name="vege"
                defaultValue={this.state.modositoObj && this.state.modositoObj.vege && new Date(this.state.modositoObj.vege)}
              />
            </div>
            <div className="col-md-3" />
            <div className="col-md-5">
              <Label for="jogtulajId">Raktár:</Label>
              <Select.Async
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeJogtulajdonos(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadJogtulajdonosChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-5">
              <Label for="jogtulajId">Cikkcsoport:</Label>
              <Select.Async
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeJogtulajdonos(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadJogtulajdonosChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-5 margin-top-20">
              <Label for="jogtulajId">Cikk:</Label>
              <Select.Async
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeJogtulajdonos(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadJogtulajdonosChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-5 margin-top-20">
              <Label for="jogtulajId">Cikk kód:</Label>
              <Select.Async
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeJogtulajdonos(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadJogtulajdonosChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-2">
              <Button style={{ marginTop: 49 }} color="primary">Listázás</Button>{' '}
            </div>
          </div>
        </AvForm>
        {this.renderBaseTable()}
      </div>
    );
  }
}

export default RaktarKeszletContent;
