import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Lib from '../../../commons/lib';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';

class RaktarLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'LOAD',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // FORM VALUES
      formAzonosito: '',
      formMegnevezes: '',
      formUzlet: '',
      formStatusz: '',
      // UTILS
      errorFromServer: '-',
      idFromForm: null,
      idFromServer: null,
      currentId: null,
      baseJson: [],
      uzletOptions: [],
      modalState: false,
      modalTorles: false,
      modalValtozas: false,
      torlesId: null,
      saveButtonLoading: false,
      deleteButtonLoading: false
    };
  }

  componentDidMount() {
    this.getRaktarList();
    this.getCegadatOptions(null);
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  toggleValtozas = () => {
    this.setState(prevState => ({ modalValtozas: !prevState.modalValtozas }));
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  getCegadatOptions = (id) => {
    Services.listAktivCegadat(id, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
        this.setState({ uzletOptions: resOptions });
      }
    });
  }

  deleteRaktar = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteRaktar(this.state.torlesId, (err) => {
      if (!err) {
        this.setState({
          deleteButtonLoading: false
        });
        this.toggleTorles();
        this.getRaktarList();
        this.props.globals.addSuccess('Raktár törölve!');
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  getRaktarList = () => {
    this.setState({
      tableStatus: 'LOAD'
    });
    Services.getRaktarList((err, res) => {
      if (!err) {
        this.setState({
          tableStatus: 'TABL',
          baseJson: res[0]
        });
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
      }
    });
  }

  getRaktar = (id, cegadatid) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Promise.all([
      this.getRaktarService(id),
      // this.getCegadatService(cegadatid)
    ])
      .then(() => {
        this.setState({
          formStatus: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  getRaktarService = (id) => {
    return new Promise((resolve, reject) => {
      Services.getRaktar(id, (err, res) => {
        if (!err) {
          this.setState({
            formUzlet: this.nullToString(res[0].cegadatid),
            formAzonosito: this.nullToString(res[0].azonosito),
            formMegnevezes: this.nullToString(res[0].nev),
            formStatusz: this.nullToString(res[0].statusz.name),
          }, () => {
            this.getCegadatService(res[0].cegadatid, resolve)
          });
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getCegadatService = (id, resolve) => {
    Services.listAktivCegadat(id, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
        this.setState({ uzletOptions: resOptions }, () => {
          resolve(id);
        });
      }
    });
  };

  handleSubmit = () => {
    const {
      formAzonosito,
      formMegnevezes,
      formStatusz,
      formUzlet,
      formType,
      currentId
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {
        azonosito: formAzonosito,
        nev: formMegnevezes,
        cegadatid: formUzlet,
        statusz: formStatusz
      };

      Services.addRaktar(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getRaktarList();
          this.props.globals.addSuccess('Raktár felvéve!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }

    if (formType === 'MOD') {
      let submitObj = {
        id: currentId,
        azonosito: formAzonosito,
        nev: formMegnevezes,
        cegadatid: formUzlet,
        statusz: formStatusz
      };

      Services.existsNew(formUzlet, (err, res) => {
        if (!err) {
          this.setState({
            saveButtonLoading: false
          });
          if (res[0].hiba === null) {
            Services.editRaktar(submitObj, (err2) => {
              if (!err2) {
                this.toggleModal();
                this.getRaktarList();
                this.props.globals.addSuccess('Raktár módosítva!');
                this.setState({
                  saveButtonLoading: false
                });
              } else {
                this.setState({
                  saveButtonLoading: false
                });
              }
            });
          } else {
            this.setState({ errorFromServer: res[0].hiba, idFromServer: res[0].id, idFromForm: formUzlet, submitObj: submitObj }, () => {
              this.toggleValtozas();
            });
          }
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  editRaktar = (id) => {
    let submitObj = JSON.parse(JSON.stringify(this.state.submitObj));
    submitObj.cegadatid = id;
    Services.editRaktar(submitObj, (err2, res2) => {
      if (!err2) {
        this.setState({ modalValtozas: false }, () => {
          this.setState({ modalState: false }, () => {
            this.getRaktarList();
            this.props.globals.addSuccess('Raktár módosítva!');
          });
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'DATA',
      formAzonosito: '',
      formMegnevezes: '',
      formUzlet: '',
      formStatusz: '',
    }, () => {
      this.toggleModal();
    });
  }

  handleEditClick = (cell, row) => {
    this.setState({
      formType: 'MOD',
      formStatus: 'LOAD',
      currentId: cell,
      formAzonosito: '',
      formMegnevezes: '',
      formUzlet: '',
      formStatusz: '',
    }, () => {
      this.getRaktar(cell, row.cegadatid);
      this.toggleModal();
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  tableIconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'RAKTAR_RAKTAR', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell, row)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={40}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'torles'} delay={40}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
      }
      </div>
    );
  }

  tableStatusFormatter = (cell) => {
    return cell === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új raktár létrehozása';
      case 'MOD':
        return 'Raktár módosítása';
      default:
        return '';
    }
  }

  renderForm = () => {
    const {
      formAzonosito,
      formMegnevezes,
      formUzlet,
      formStatusz,
      formType,
      uzletOptions
    } = this.state;

    return (
      <React.Fragment>
        <AvGroup>
          <Label for="formAzonosito">Azonosító: *</Label>
          <AvInput
            name="formAzonosito"
            id="formAzonosito"
            disabled={formType === 'MOD'}
            required
            maxLength="10"
            value={formAzonosito}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvGroup>
          <Label for="formMegnevezes">Megnevezés: *</Label>
          <AvInput
            name="formMegnevezes"
            id="formMegnevezes"
            required
            maxLength="50"
            value={formMegnevezes}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvField
          type="select"
          name="formUzlet"
          id="formUzlet"
          label="Cégadat: *"
          required
          errorMessage="Kötelező mező!"
          value={formUzlet}
          onChange={this.handleInputChange}
        >
          <option value="">Válassz cégadatot...</option>
          {uzletOptions}
        </AvField>
        <AvField
          type="select"
          name="formStatusz"
          id="formStatusz"
          label="Státusz: *"
          errorMessage="Kötelező mező!"
          required
          value={formStatusz}
          onChange={this.handleInputChange}
        >
          <option value="">Válasszon státuszt...</option>
          <option value="INAKTIV">Inaktív</option>
          <option value="AKTIV">Aktív</option>
        </AvField>
      </React.Fragment>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      AKTIV: 'Aktív',
      INAKTIV: 'Inaktív',
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'cegnev',
        text: 'Cégadat neve',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'cegtipus',
        text: 'Cégadat típus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'statusz.name',
        align: 'center',
        text: 'Státusz',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        formatter: this.tableStatusFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'RAKTAR_RAKTAR', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'RAKTAR_RAKTAR', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új raktár'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getRaktarList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalState} toggle={this.toggleModal} backdrop="static">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getRaktar(this.state.currentId)}
                />),
                DATA: (this.renderForm())
              }[this.state.formStatus]}
              <Modal isOpen={this.state.modalValtozas} toggle={this.toggleValtozas} className={this.props.className} backdrop="static">
                <ModalHeader>Cégadat módosult</ModalHeader>
                <ModalBody style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.errorFromServer}
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    className="btn-cursor-pointer"
                    color="primary"
                    onClick={() => {
                      this.editRaktar(this.state.idFromServer);
                    }}
                  >
                    Igen
                  </Button>
                  <Button type="button" className="btn-cursor-pointer" color="secondary" onClick={() => this.editRaktar(this.state.idFromForm)}>Nem</Button>
                  <Button type="button" className="btn-cursor-pointer" color="secondary" onClick={() => this.toggleValtozas()}>Mégsem</Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModal}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton loading={this.state.deleteButtonLoading} color="primary" onClick={() => this.deleteRaktar()}>Igen</ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

RaktarLista.propTypes = {
  globals: PropTypes.object
};

export default RaktarLista;
