import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Service from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

class PenznemLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalForm: false,
      modalNezet: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      penznemLista: [],
      penznem: {},
    };
  }

  componentDidMount() {
    this.updateTable();
  }

  updateTable = () => {
    this.setState({
      dataLoaded: false, dataFailed: false, penznemLista: []
    });
    Service.listPenznem((err, res) => {
      if (err) {
        this.setState({ dataFailed: true });
      } else {
        this.setState({ penznemLista: res[0], dataLoaded: true, dataFailed: false, penznem: {} });
      }
    });
  }

  toggleForm = (updateTable) => {
    this.setState({ modalForm: !this.state.modalForm }, () => {
      if (updateTable && typeof updateTable === 'function') updateTable();
    });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  handleCancel = () => {
    this.setState({ modalForm: !this.state.modalForm }, () => {
      this.updateTable();
    });
  }

  handleEditClick = (id) => {
    Service.getPenznem(id, (err, res) => {
      if (!err) {
        let obj = res[0];
        obj.aktiv = obj.aktiv ? 'Aktív' : 'Inaktív';
        obj.tizedesjegy = obj.tizedesjegy == 0 ? '0' : obj.tizedesjegy;
        this.setState({ penznem: obj }, () => { this.toggleForm(); });
      }
    });
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.toggleNezet();
  }

  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_PENZNEM', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  savePenznem = (event, obj) => {
    obj.aktiv = obj.aktiv === 'Aktív';
    obj.defaultcurrency = obj.defaultcurrency != false ? true : null;
    if (RegExp("[^0-9]").test(obj.tizedesjegy)){
      this.props.globals.addError("A tizedesjegy pontossága csak természetes szám lehet!");
    } else {
      if (this.state.penznem && !this.state.penznem.id) {
        this.insert(obj);
      } else {
        this.edit(obj);
      }
    }
  }

  insert = (obj) => {
    Service.addPenznem(obj, (err) => {
      if (!err) {
        this.toggleForm(() => this.updateTable());
        this.props.globals.addSuccess('Pénznem hozzáadva!');
      }
    });
  }


  edit = (obj) => {
    Service.editPenznem(obj, this.state.penznem.id, (err) => {
      if (!err) {
        this.toggleForm(() => this.updateTable());
        this.props.globals.addSuccess('Pénznem módosítva!');
      }
    });
  }

  deletePenznem = (id) => {
    this.toggleTorles();
    Service.deletePenznem(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Pénznem törölve!');
      }
    });
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const statusType = {
      true: 'Aktív',
      false: 'Inaktív'
    };

    return (
      this.state.penznemLista.length ? (
        <BootstrapTable data={this.state.penznemLista} pagination options={options} >
          <TableHeaderColumn dataField="azonosito" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Pénznem azonosító</TableHeaderColumn>
          <TableHeaderColumn dataField="nev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Pénznem neve</TableHeaderColumn>
          <TableHeaderColumn dataField="jel" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Pénznem jele</TableHeaderColumn>
          <TableHeaderColumn dataField="aktiv" dataFormat={this.statusFormatter} filter={{ type: 'SelectFilter', options: statusType, placeholder: 'Összes' }} dataAlign="center">Státusz</TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            isKey
            dataFormat={this.iconFormatter}
            hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_PENZNEM', 'KARB')}
          >
            Műveletek
          </TableHeaderColumn>
        </BootstrapTable>
      ) : <DataEmptyState />);
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_PENZNEM', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.toggleForm}>Új pénznem</Button>
            </div>
          </div>
        )}
        {(this.state.dataLoaded && !this.state.dataFailed) ? this.renderTable() : (this.state.dataFailed) ? <NetworkEmptyState submitClick={this.updateTable} /> : this.renderLoader()}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deletePenznem(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalForm} toggle={this.toggleForm} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={this.savePenznem}>
            <ModalHeader>{!this.state.penznem.id ? 'Új pénznem rögzítése' : 'Pénznem módosítása'}</ModalHeader>
            <ModalBody>
              <AvGroup>
                <Label for="azonosito">Pénznem azonosítója: *</Label>
                <AvInput name="azonosito" id="azonosito" value={this.state.penznem.azonosito} required={!this.state.penznem.id} disabled={this.state.penznem.id} maxLength="3" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="nev">Pénznem neve: *</Label>
                <AvInput name="nev" id="nev" value={this.state.penznem.nev} required maxLength="50" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="jel">Pénznem jele: *</Label>
                <AvInput name="jel" id="jel" value={this.state.penznem.jel} required maxLength="4" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="tizedesjegy">Tizedesjegy pontosság: *</Label>
                <AvInput
                  name="tizedesjegy"
                  id="tizedesjegy"
                  value={this.state.penznem.tizedesjegy}
                  required
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <div className="row">
                <div className="col-sm-8">
                  <AvField type="select" name="aktiv" label="Státusz: *" errorMessage="Kötelező mező!" value={this.state.penznem.aktiv} required>
                    <option value="" disabled >Válassz státuszt...</option>
                    <option>Aktív</option>
                    <option>Inaktív</option>
                  </AvField>
                </div>
                <div className="col-sm-4">
                  <AvGroup>
                    <Label for="defaultcurrency">
                      <div className="row" style={{ paddingTop: '35px' }}>
                        <AvField
                          type="checkbox"
                          name="defaultcurrency"
                          id="defaultcurrency"
                          value={this.state.penznem.defaultcurrency}
                        />
                        <div style={{paddingLeft: '7px'}}>Alapértelmezett</div>
                      </div>
                    </Label>
                  </AvGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.handleCancel} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default PenznemLista;
