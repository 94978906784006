import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';
import AllapotItem from './AllapotItem';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './FeladatlistaTooltips';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  paddingBottom: 0,
  width: '100%'
});

class FeladatTipusContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'LOAD',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // FORM VALUES
      formAzonosito: '',
      formNev: '',
      formStatusz: '',
      // UTILS
      currentId: null,
      baseJson: [],
      modalState: false,
      modalTorles: false,
      torlesId: null,
      saveButtonLoading: false,
      deleteButtonLoading: false,

      fixedAllapotItems: [],
      selectableAllapotItems: [],
      items: [],
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getFeladattipusList();
      }
    }
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  }

  toggleNested = () => {
    this.setState(prevState => ({ nestedModal: !prevState.nestedModal }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  getCorrectTextColor = (hex) => {
    /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

    I know this could be more compact, but I think this is easier to read/explain.

    */

    let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }
    function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16); }
    function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16); }
    function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16); }

    let hRed = hexToR(hex);
    let hGreen = hexToG(hex);
    let hBlue = hexToB(hex);

    let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) { return '#000000'; } return '#ffffff';
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  deleteFeladatTipus = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteFeladatTipus(this.state.torlesId, (err) => {
      if (!err) {
        this.setState({
          deleteButtonLoading: false
        });
        this.toggleTorles();
        this.getFeladattipusList();
        this.props.globals.addSuccess('Feladattípus törölve!');
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  getFeladattipusList = () => {
    this.setState({
      tableStatus: 'LOAD'
    });
    Services.getFeladattipusList((err, res) => {
      if (!err) {
        this.setState({
          tableStatus: 'TABL',
          baseJson: res[0]
        });
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
      }
    });
  }

  contains = (element, array) => {
    let result = false;
    array.forEach((allapot) => {
      if (element.id === allapot.id) {
        result = true;
      }
    });
    return result;
  }

  listAllapotModal = () => {
    this.setState({
      formAllapotStatus: 'LOAD'
    });
    Services.listAllapot((err, res) => {
      if (!err) {
        res[0].pop();
        res[0].shift();
        let selectable = [];
        res[0].forEach((element) => {
          if (!(this.contains(element, this.state.items))) {
            selectable.push(element);
          }
        });
        this.setState({
          formAllapotStatus: 'DATA',
          selectableAllapotItems: selectable
        });
      } else {
        this.setState({
          formAllapotStatus: 'NETW'
        });
      }
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  handleSubmit = () => {
    const {
      formAzonosito,
      formNev,
      formStatusz,
      formFunkcio,
      formType,
      currentId
    } = this.state;


    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      console.log('LISTS: ', this.state.fixedAllapotItems, this.state.selectableAllapotItems, this.state.items);

      let allapotids = [];
      let newItems = JSON.parse(JSON.stringify(this.state.items));
      allapotids.push(this.state.fixedAllapotItems[0].id);
      newItems.forEach((tetel) => {
        allapotids.push(tetel.id);
      });
      allapotids.push(this.state.fixedAllapotItems[1].id);

      let submitObj = {
        allapotids,
        azonosito: this.emptyOrNull(formAzonosito),
        nev: this.emptyOrNull(formNev),
        statusz: this.emptyOrNull(formStatusz),
        funkcio: this.emptyOrNull(formFunkcio),
      };

      Services.createFeladattipus(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getFeladattipusList();
          this.props.globals.addSuccess('Feladattípus felvéve!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }

    if (formType === 'MOD') {
      console.log('LISTS: ', this.state.fixedAllapotItems, this.state.selectableAllapotItems, this.state.items);

      let allapotids = [];
      let newItems = JSON.parse(JSON.stringify(this.state.items));
      allapotids.push(this.state.fixedAllapotItems[0].id);
      newItems.forEach((tetel) => {
        allapotids.push(tetel.id);
      });
      allapotids.push(this.state.fixedAllapotItems[1].id);

      let submitObj = {
        allapotids,
        id: currentId,
        azonosito: this.emptyOrNull(formAzonosito),
        nev: this.emptyOrNull(formNev),
        funkcio: this.emptyOrNull(formFunkcio),
        statusz: this.emptyOrNull(formStatusz),
      };

      Services.updateFeladattipus(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getFeladattipusList();
          this.props.globals.addSuccess('Feladattípus módosítva!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'DATA',
      formAzonosito: '',
      formNev: '',
      formFunkcio: '',
      formStatusz: '',
    }, () => {
      this.initFormData();
    });
  }

  initFormData = (id) => {
    Promise.all([
      // this.getFeladatLista(id, isFelvitel),
      this.initForm(id)
    ])
      .then(() => {
        this.setState({
          formStatus: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  initForm = (id) => {
    return new Promise((resolve, reject) => {
      Services.initTipusForm(id, (err, res) => {
        if (!err) {
          let statuszArray = res[0].statusz.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
          let funkcioArray = res[0].funkcio.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);

          // let workArray = id ? res[0].tipus.allapotok : res[0].allapot;
          let workArray = res[0].allapot;
          let result = [];
          if (Array.isArray(workArray) && workArray.length >= 2) {
            result.push(workArray[0]);
            result.push(workArray[workArray.length - 1]);
          }

          this.setState({
            statuszOptions: statuszArray,
            funkcioOptions: funkcioArray,
            formNev: res[0].tipus && res[0].tipus.nev,
            formAzonosito: res[0].tipus && res[0].tipus.azonosito,
            formStatusz: res[0].tipus && res[0].tipus.statusz && res[0].tipus.statusz.name,
            formFunkcio: res[0].tipus && res[0].tipus.funkcio && res[0].tipus.funkcio.name,
            items: id ? workArray.slice(1, -1) : [],
            fixedAllapotItems: result

          }, () => {
            this.toggleModal();
            // this.getFeladattipusList();
            resolve(res[0]);
          });
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      formStatus: 'LOAD',
      currentId: cell,
      formAzonosito: '',
      formNev: '',
      formFunkcio: '',
      formStatusz: '',
    }, () => {
      this.initFormData(cell);
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  onNewAllapotModalClick = () => {
    this.setState({
      nestedModal: true
    }, () => {
      this.listAllapotModal();
    });
  }

  onAllapotSelect = (allapot) => {
    this.setState({
      nestedModal: false,
    }, () => {
      let items = JSON.parse(JSON.stringify(this.state.items));
      items.push(allapot);
      this.setState({
        items
      });
    });
  }

  handleDeleteItemClick = (id) => {
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.splice(items.findIndex((item) => {
      return item.id === id;
    }), 1);
    this.setState({
      items: items
    });
  }

  renderAllapotList = () => {
    return this.state.selectableAllapotItems.map((allapot) => {
      return (
        <div
          style={{
            padding: 8,
            paddingBottom: 2,
            width: '100%',
            cursor: 'pointer'
          }}
          onClick={() => {
            this.onAllapotSelect(allapot);
          }}
        >
          <div
            className="card bg-light dnd-list"
            style={{ marginBottom: 0 }}
          >
            <div className="card-body" style={{ padding: 10 }}>
              <span>
                {allapot.nev}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  tableIconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADATTIPUS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={40}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'torles'} delay={40}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
      }
      </div>
    );
  }

  tableStatusFormatter = (cell) => {
    return cell === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  allapotFormatter = (cell, row) => {
    return row.allapotok.map((allapot) => {
      return (
        <React.Fragment>
          <span className="badge" style={{ backgroundColor: '#' + allapot[0], color: this.getCorrectTextColor('#' + allapot[0]) }}>{allapot[1]}</span>
          <br />
        </React.Fragment>
      );
    });
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új feladattípus létrehozása';
      case 'MOD':
        return 'Feladattípus módosítása';
      default:
        return '';
    }
  }

  renderForm = () => {
    const {
      formAzonosito,
      formNev,
      formStatusz,
      formFunkcio,
      formType
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <p style={{
                  marginBottom: 15,
                  marginTop: 15,
                  fontSize: '1.25rem',
                  fontWeight: 500,
                  lineHeight: 1.1
                }}
                >
                Alapadatok:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AvGroup>
                  <Label for="formAzonosito">Azonosító: *</Label>
                  <AvInput
                    name="formAzonosito"
                    id="formAzonosito"
                    disabled={formType === 'MOD'}
                    required
                    maxLength="10"
                    value={formAzonosito}
                    onChange={this.handleInputChange}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AvGroup>
                  <Label for="formNev">Név: *</Label>
                  <AvInput
                    name="formNev"
                    id="formNev"
                    required
                    maxLength="100"
                    value={formNev}
                    onChange={this.handleInputChange}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Label>
                  Funkció:
                  <CustomTooltip text={getTooltip('feladattipus_funkcio')} id="feladattipus_funkcioTT" />
                </Label>
                <AvField
                  type="select"
                  name="formFunkcio"
                  id="formFunkcio"
                  // label="Funkció: "
                  errorMessage="Kötelező mező!"
                  // required
                  value={formFunkcio}
                  onChange={this.handleInputChange}
                >
                  <option value="">Válasszon funkciót...</option>
                  {this.state.funkcioOptions}
                </AvField>

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AvField
                  type="select"
                  name="formStatusz"
                  id="formStatusz"
                  label="Státusz: *"
                  errorMessage="Kötelező mező!"
                  required
                  value={formStatusz}
                  onChange={this.handleInputChange}
                >
                  <option value="">Válasszon státuszt...</option>
                  {this.state.statuszOptions}
                </AvField>
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ backgroundColor: '#ececec', borderLeft: '1px solid #cfd8dc' }}>
            <p style={{
              marginBottom: 15,
              marginTop: 15,
              fontSize: '1.25rem',
              fontWeight: 500,
              lineHeight: 1.1
            }}
            >
              Állapotok:
              <CustomTooltip text={getTooltip('feladattipus_allapotok')} id="feladattipus_allapotokTT" />
              {/* <i
                className="fa fa-info-circle pull-right"
                id="dnd-info-tooltip"
                aria-hidden="true"
                style={{ fontSize: '0.875rem', fontWeight: 'normal', lineHeight: 1.5 }}
              /> */}
            </p>
            {/* <UncontrolledTooltip placement="right" target="dnd-info-tooltip">
              A sorrend megváltoztatásához mozgasd az állapot kártyákat.
            </UncontrolledTooltip> */}
            <div style={{
              padding: 8, paddingBottom: 2, width: '100%', cursor: 'no-drop'
            }}
            >
              <div className="card bg-light dnd-list" style={{ marginBottom: 0 }}>
                <div className="card-body" style={{ padding: 10 }}>
                  <span>
                    {Array.isArray(this.state.fixedAllapotItems) && this.state.fixedAllapotItems.length === 2 && this.state.fixedAllapotItems[0].nev}
                  </span>
                </div>
              </div>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.items.map((item, index) => (
                      <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                        {(providedd, snapshott) => (
                          <AllapotItem
                            id={item.id}
                            text={item.nev}
                            bgColor={item.szin}
                            textColor={this.getCorrectTextColor('#' + item.szin)}
                            provided={providedd}
                            snapshot={snapshott}
                            deleteItem={this.handleDeleteItemClick}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div style={{
              padding: 8,
              paddingTop: 0,
              width: '100%',
              cursor: 'no-drop'
            }}
            >
              <div className="card bg-light dnd-list" style={{ marginBottom: 6 }}>
                <div className="card-body" style={{ padding: 10 }}>
                  <span>
                    {Array.isArray(this.state.fixedAllapotItems) && this.state.fixedAllapotItems.length === 2 && this.state.fixedAllapotItems[1].nev}
                  </span>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-success btn-circle pull-right"
              style={{
                marginBottom: 10,
                marginRight: 6
              }}
              onClick={() => {
                this.onNewAllapotModalClick();
              }}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      AKTIV: 'Aktív',
      INAKTIV: 'Inaktív',
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'funkcio.caption',
        text: 'Funkció',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'statusz.name',
        align: 'center',
        text: 'Státusz',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        formatter: this.tableStatusFormatter
      },
      {
        dataField: 'allapot',
        text: 'Állapotok',
        formatter: this.allapotFormatter,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: false
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADATTIPUS', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADATTIPUS', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új feladattípus'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getFeladattipusList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalState} toggle={this.toggleModal} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody style={{ paddingTop: 0, paddingBottom: 0 }}>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.initForm(this.state.currentId)}
                />),
                DATA: (this.renderForm())
              }[this.state.formStatus]}
              <Modal isOpen={this.state.nestedModal} toggle={this.toggleNested} backdrop="static">
                <ModalHeader>Állapot hozzáadása</ModalHeader>
                <ModalBody>
                  {{
                    LOAD: (<Loader />),
                    NETW: (<NetworkEmptyState
                      hasImage
                      submitClick={() => {}}
                    />),
                    DATA: (this.renderAllapotList())
                  }[this.state.formAllapotStatus]}
                </ModalBody>
                <ModalFooter>
                  <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleNested}>Mégsem</Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModal}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton loading={this.state.deleteButtonLoading} color="primary" onClick={() => this.deleteFeladatTipus()}>Igen</ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

FeladatTipusContent.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default FeladatTipusContent;
