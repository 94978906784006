import Lib from '../../commons/lib';

export default class Service {
  static partnerUrl = window.location.origin + '/api/partner/';

  static szamlazasUrl = window.location.origin + '/api/udulesijogszla/';

  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  static udulesijogUrl = window.location.origin + '/api/udulesijog/';

  // SZÁMLA START

  static listSzamla(kibocsatoid, jogtulajid, ev, penznemid, fizmodid, feladas, kiegInf, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/list',
      args: { requestType: 'szamla-list' },
      params: {
        kibocsato: kibocsatoid,
        jogtulaj: jogtulajid,
        ev: ev,
        feladas: feladas,
        info: kiegInf,
        penznemid: penznemid,
        fizmodid: fizmodid
      }
    }], fnDone);
  }

  static getSzamla(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla',
      args: { requestType: 'szamla-get' },
      params: { id: id },
    }], fnDone);
  }

  static getSzamlaEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/enum',
      args: { requestType: 'szamla-get-enum' }
    }], fnDone);
  }

  // tipus
  // számla: 'SZAMLA'
  // számlaelőkészítás: 'ELO'
  // számlaküldés: 'KULD'
  static getSzamlaKibocsato(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  // tipus
  // számla: 'SZAMLA'
  // számlaelőkészítás: 'ELO'
  // számlaküldés: 'KULD'
  static getSzamlaKibocsatoChange(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  // tipus
  // számla: 'SZAMLA'
  // számlaelőkészítás: 'ELO'
  // számlaküldés: 'KULD'
  static getPenznem(tipus, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/getdistinctszamlapenznem',
      args: { requestType: 'szamla-getdistinctszamlapenznem' },
      params: { tipus: tipus }
    }], fnDone);
  }

  // tipus
  // számla: 'SZAMLA'
  // számlaelőkészítás: 'ELO'
  // számlaküldés: 'KULD'
  static getFizmod(tipus, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/getdistinctszamlafizmod',
      args: { requestType: 'szamla-getdistinctszamlafizmod' },
      params: { tipus: tipus }
    }], fnDone);
  }

  static updateSzamlaVisszavonas(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamla/visszavonas',
      args: { requestType: 'szamla-visszavonas' },
      data: data,
    }], fnDone);
  }

  static updateSzamlaFeladas(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szamlazasUrl + 'szamla/feladas',
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      args: { requestType: 'szamla-feladas' },
      data: data,
    }], fnDone);
  }

  static getSzamlaInitModify(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/initmodify',
      args: { requestType: 'szamla-get-initmodify' },
      params: { id: id },
    }], fnDone);
  }

  static editSzamla(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamla',
      args: { requestType: 'szamla-edit' },
      data: data,
    }], fnDone);
  }

  // SZÁMLA END

  // SZÁMLA ELŐKÉSZÍTÉS START

  static listSzamlaElokeszites(kibocsatoid, jogtulajid, ev, penznemid, fizmodid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamlaelokeszites/list',
      args: { requestType: 'szamlaelokeszites-list' },
      params: {
        kibocsato: kibocsatoid,
        jogtulaj: jogtulajid,
        ev: ev,
        penznemid: penznemid,
        fizmodid: fizmodid
      }
    }], fnDone);
  }

  static getFotulajdonos(tipus, nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnertortenet/getdistinctfojogtulajbeans',
      args: { requestType: 'partnertortenet-getdistinctfojogtulajbeans' },
      params: {
        tipus: tipus,
        nev: nev
      }
    }], fnDone);
  }

  static getSzamlaKuldesAlapadat(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamlaelokeszites/universalget2',
      args: { requestType: 'szamlaelokeszites/universalget2' },
      data: ['SZAMLA_AFA', 'SZAMLA_TELJESITES', 'SZAMLA_FIZHAT', 'SZAMLA_KIBOCSATO', 'SZAMLA_KS_PROD_NAME'],
      params: { string: true }
    }], fnDone);
  }

  static getSzamlaAdat(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'alapadat/base',
      args: { requestType: 'alapadat-base' }
    }], fnDone);
  }

  static updateAlapadat(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter/saven',
      args: { requestType: 'parameter-saven' },
      data: data,
    }], fnDone);
  }

  static getInflacioAdat(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'inflacio/base',
      args: { requestType: 'inflacio-base' }
    }], fnDone);
  }

  static getGenEvVizsgalat(ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'evesdij/exists',
      args: { requestType: 'evesdij-exists' },
      params: { ev: ev }
    }], fnDone);
  }

  static getGenInflacio(ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/generate',
      args: { requestType: 'evesdij-generate' },
      params: {
        ev: ev
      }
    }], fnDone);
  }

  static getInflacioList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'inflacio/listbytipus',
      args: { requestType: 'inflacio-list' },
      params: {
        tipus: 'EVES'
      }
    }], fnDone);
  }

  static updateInflacio(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szamlazasUrl + 'inflacio',
      args: { requestType: 'inflacio-update' },
      data: data,
    }], fnDone);
  }

  static sendSzamlaElokeszites(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szamlazasUrl + 'szamlakuldes ',
      args: { requestType: 'szamlakuldes-send' },
      data: data,
    }], fnDone);
  }

  // SZÁMLA ELŐKÉSZÍTÉS END

  // SZÁMLA KÜLDÉS START

  static updateSzamlaKuldes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamlakuldes',
      args: { requestType: 'szamlakuldes-update' },
      data: data,
    }], fnDone);
  }

  static sendToAtadas(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamlakuldes/setatadhato',
      args: { requestType: 'szamlakuldes-setatadhato' },
      data: data,
    }], fnDone);
  }

  static sendToAtadhato(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamlakuldes/setatadott',
      args: { requestType: 'szamlakuldes-setatadott' },
      data: data,
    }], fnDone);
  }

  static getSzamlaKuldes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamlakuldes',
      args: { requestType: 'szamlakuldes-get' },
      params: {
        id: id
      }
    }], fnDone);
  }

  static getSzamlaKuldesEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamlakuldes/enum',
      args: { requestType: 'szamlakuldes-enum-get' }
    }], fnDone);
  }

  static listSzamlaKuldes(kibocsatoid, ev, penznemid, fizmodid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamlakuldes/list',
      args: { requestType: 'szamlakuldes-list' },
      params: {
        kibocsato: kibocsatoid,
        ev: ev,
        penznemid: penznemid,
        fizmodid: fizmodid
      }
    }], fnDone);
  }

  static getUdulesijogData(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szlakuldudjog',
      args: { requestType: 'evesdij-exists' },
      params: { id: id }
    }], fnDone);
  }

  static deleteSzamlakuldes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szamlazasUrl + 'szamlakuldes',
      args: { requestType: 'szamlakuldes-delete' },
      params: { id: id }
    }], fnDone);
  }

  static getUdulesijogPrices(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamlakuldes/getbeforemodify',
      args: { requestType: 'szamlakuldes-getbeforemodify' },
      params: { id: id }
    }], fnDone);
  }

  static listAktivPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-list' }
    }], fnDone);
  }

  static listAktivFizetesimod(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod/aktiv',
      args: { requestType: 'fizmod-list' }
    }], fnDone);
  }

  //
  // Visszaküldés oksai :  http://192.168.2.142:8194/szamla/enum
  //
  static getVisszakuldesOkai(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/enum',
      args: { requestType: 'getVisszakuldesOkai' }
    }], fnDone);
  }

  //
  // Visszaküldés oksai lekérdezés :  http://192.168.2.142:8194/szamla/enum
  //
  static getSzamlaKiegeszitoAdat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/kiegadat',
      params: {
        id: id
      },
      args: { requestType: 'getSzamlaKiegeszitoAdat' }
    }], fnDone);
  }

  //
  // Visszaküldés oksai modosítása :  http://192.168.2.142:8194/szamla/enum
  //
  static setSzamlaKiegeszitoAdat(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'szamla/kiegadat',
      data: data,
      args: { requestType: 'setSzamlaKiegeszitoAdat' }
    }], fnDone);
  }

  // SZÁMLA KÜLDÉS END

  // profil form

  static getSzamlaUrlap(tipus, nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + '/partner/getaktivpartnerbeansbypartnercsopazonnev',
      args: { requestType: 'partnertortenet-getdistinctszamlakibocsatobeans' },
      params: {
        tipus: tipus,
        nev: nev
      }
    }], fnDone);
  }

  static getSzamlazasiProfilListaAll(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'profil/list',
      args: { requestType: 'getSzamlazasiProfilLista' }
    }], fnDone);
  }

  static getSzamlazasiProfilLista(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'profil',
      params: {
        id: id,
      },
      args: { requestType: 'getSzamlazasiProfilLista' }
    }], fnDone);
  }

  static setSzamlazasiProfil(tipus, data, fnDone) {
    if (tipus === 'm') {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.szamlazasUrl + 'profil',
        data: data,
        args: { requestType: 'setVisszakuldesOkaiSzamla' }
      }], fnDone);
    }
    if (tipus === 'f') {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.szamlazasUrl + 'profil',
        data: data,
        args: { requestType: 'setVisszakuldesOkaiSzamla' }
      }], fnDone);
    }
  }

  static SzamlazasiProfilDelete(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szamlazasUrl + 'profil',
      params: {
        id: id,
      },
      args: { requestType: 'SzamlazasiProfilDelete' }
    }], fnDone);
  }

  // profil form vége

  // FIZETÉSI FELSZÓLÍTÓ START

  static listKKamat(kibocsatoid, jogtulajid, evtol, evig, kkamat, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'kkamat/list',
      args: { requestType: 'szamla-list' },
      params: {
        kibocsato: kibocsatoid,
        fojogtulaj: jogtulajid,
        evtol: evtol,
        evig: evig,
        kkamat: kkamat
      }
    }], fnDone);
  }

  static listFelsz(kibocsatoid, jogtulajid, evtol, evig, kkamat, gentol, genig, id, allapot, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'kkamat/list/saved',
      args: { requestType: 'szamla-list' },
      params: {
        kibocsato: kibocsatoid,
        fojogtulaj: jogtulajid,
        evtol: evtol,
        evig: evig,
        kkamat: kkamat,
        gentol: gentol,
        genig: genig,
        id: id,
        allapot: allapot,
      }
    }], fnDone);
  }

  static getObject(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'kkamat/object',
      args: { requestType: 'szerzodes-get' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      params: { fizfelszolId: id }
    }], fnDone);
  }

  static generateFizfel(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'kkamat/generate',
      args: { requestType: 'kkamat-generate' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      data: data,
    }], fnDone);
  }

  static searchIktatoszam(iktatoszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'kkamat/iktatoszam',
      args: { requestType: 'iktatoszam' },
      params: {
        text: iktatoszam,
      }
    }], fnDone);
  }

  static initForm(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'kkamat/init',
      args: { requestType: 'init' },
    }], fnDone);
  }

  static finalizeFizFelszol(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'kkamat/fizfelszol/veglegesit',
      args: { requestType: 'finalize' },
      data: ids,
    }], fnDone);
  }

  static deleteFizFelszol(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szamlazasUrl + 'kkamat/fizfelszol',
      args: { requestType: 'delete' },
      data: ids,
    }], fnDone);
  }

  static exportXLSXFizFelszol(kibocsatoid, jogtulajid, evtol, evig, kkamat, gentol, genig, id, allapot, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'kkamat/list/xlsx',
      args: { requestType: 'szamla-xlsx' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      withFileName: true,
      params: {
        kibocsato: kibocsatoid,
        fojogtulaj: jogtulajid,
        evtol: evtol,
        evig: evig,
        kkamat: kkamat,
        gentol: gentol,
        genig: genig,
        id: id,
        allapot: allapot,
      }
    }], fnDone);
  }

  // FIZETÉSI FELSZÓLÍTÓ END
}
