import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import MomentTimezone from 'moment-timezone';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import moment from 'moment';
import PropTypes from 'prop-types';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import Loader from '../../commons/loader';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';
import { ProgressButton } from '../../commons/ProgressButton';

class KompenzacioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      szerzodesid: null,
      currentStatusBaseView: 'LOAD',
      udulesijogObj: null,
      kompenzacioTetelObj: null,
      udulesijogJson: [],
      aktivSzallodak: [],
      aktivEpuletek: [],
      aktivApartman: [],
      epuletHasValue: false,
      szallodaHasValue: false,
      szuroEpuletHasValue: false,
      szuroSzallodaHasValue: false,
      szuroAktivSzallodak: [],
      szuroAktivEpuletek: [],
      szuroSzallodaValue: '',
      szuroEpuletValue: '',
      szuroJogtulajdonosValue: '',
      tetelek: null,
      UjTetelModal: false,
      tetelModositas: false,
      modalNezet: false,
      szallodaValue: '',
      epuletValue: '',
      apartmanValue: '',
      apartmanHasValue: false,
      tetelcell: null,
      tetelTorles: false,
      udulesijogid: 0,
      ujTetel: false,
      tetelEv: null,
      tetelHet: null,
      hetek: [],
      filterEv: '',
      udulesijogMentes: null,
      evHetSzallodak: [],
      loadingButton: false,
    };
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.initFilter();
    this.genWeeks();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        currentStatusBaseTable: 'LOAD'
      });
      this.setState({ szerzodesid: nextProps.tableData });
      Services.listUdulesijogBySzerzodesId(nextProps.tableData, (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseTable: 'NETW'
          });
        } else {
          if (res[0].length === 0) this.props.globals.addInfo('A szerződéshez nem tartozik üdülési jog!');
          let newState = res[0];
          newState.forEach((item) => {
            item.futamido = item.futamidokezdete + ' - ' + item.futamidovege;
            item.szamlakibocsato = item.szamlakibocsatobean.nev + ' ' + item.szamlakibocsatobean.cim;
            item.kompenzaciostatusz = item.statusz;
            switch (item.statusz.name) {
              case 'AKTIV':
                item.statusz = 0;
                break;
              case 'LEZART':
                item.statusz = 1;
                break;
              default:
                item.statusz = -1;
                break;
            }
          });
          this.setState({
            udulesijogJson: newState,
            currentStatusBaseTable: newState.length > 0 ? 'TABL' : 'DATA'
          });
        }
      });
    }
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i += 1) {
      weeks.push(<option key={i.toString()} value={i}>{i}</option>);
    }
    this.setState({ hetek: weeks });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  initFilter = () => {
    Services.initFilter((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].szalloda.map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        this.setState({ szuroAktivSzallodak: aktivSzallodak });
      }
    });
  }

  getAktivApartmanById = (id) => {
    Services.listApartmanokById(id, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        if (res[0].length > 0) {
          this.setState({ aktivApartman: aktivApartmanok });
        }
      }
    });
  }

  getSzuroAktivSzallodak = () => {
    Services.listAktivSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        this.setState({ szuroAktivSzallodak: aktivSzallodak });
      }
    });
  }

  getSzuroAktivEpulet = () => {
    Services.listAktivEpuletek((err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ szuroAktivEpuletek: aktivEpuletek });
      }
    });
  }

  getSzuroAktivEpuletById = (id) => {
    Services.listAktivEpuletekById(id, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ szuroAktivEpuletek: aktivEpuletek });
        this.getAktivApartmanById(res[0][0].id);
      }
    });
  }

  getSzuroAktivApartmanById = (id) => {
    Services.listApartmanokById(id, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        if (res[0].length > 0) {
          this.setState({ szuroAktivApartman: aktivApartmanok });
        }
      }
    });
  }

  updateTetel= (event, tetelObj) => {
    this.setState({ loadingButton: true });

    let submitObj = tetelObj;
    let mentesiobj = {
      apartmanid: 0,
      ev: 0,
      het: 0,
      id: 0,
    };

    if (!parseInt(submitObj.apartman, 10)) { submitObj.apartman = this.state.kompenzacioTetelObj.apartmanbean.id; }
    mentesiobj.apartmanid = submitObj.apartman;
    mentesiobj.ev = submitObj.ev;
    mentesiobj.het = submitObj.het;

    let ssObj = {};
    ssObj.id = this.state.kompenzacioTetelObj.id;
    ssObj.utolsoHwAtadas = submitObj.utolsoHwAtadas && MomentTimezone.tz(new Date(submitObj.utolsoHwAtadas), this.tz).format('YYYY-MM-DDT00:00:00.000ZZ');


    Services.updateKompenzacioTetel(ssObj, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres tétel módosítás!');
        this.toggletetelModositas();
        this.updateTable();
        this.lekerdezeTetel(this.state.udulesijogid);
        this.setState({ loadingButton: false });
      } else {
        this.props.globals.addError('Sikertelen tétel módosítás!');
        this.setState({ loadingButton: false });
      }
    });
  }


  mentes = (event, tetelObj) => {
    this.setState({ loadingButton: true });

    let submitObj = tetelObj;
    submitObj.azonosito = null;
    submitObj.udulesijogId = this.state.udulesijogid;
    submitObj.apartmanid = parseInt(submitObj.apartmanid, 10);
    submitObj.ev = parseInt(submitObj.ev, 10);
    submitObj.het = parseInt(submitObj.het, 10);

    Services.insertKompenzacioTetel(submitObj, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres tétel felvitel!');
        this.toggleUjTetel();
        this.updateTable();
        this.lekerdezeTetel(this.state.udulesijogid);
        this.setState({ loadingButton: false });
      } else {
        this.setState({ loadingButton: false });
        // this.props.globals.addError('Sikertelen tétel felvitel!');
      }
    });
  }

  onRowSelect = (row) => {
    this.setState({ udulesijogid: row.id, udulesijogMentes: row });
    this.lekerdezeTetel(row.id);
    this.setState({ ujTetel: true });
  }

  lekerdezeTetel = (id) => {
    Services.getKompenzacioTetelList(id, this.state.filterEv, (err, res) => {
      if (!err) {
        this.setState({ tetelek: res[0] });
      }
    });
  }

  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'LEK') && (
          <React.Fragment>
            <Button style={{ marginRight: '15px' }} className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekint'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekint'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  iconFormatterTetel = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'KARB') && (
          <React.Fragment>
            {row.statusz && row.statusz.name !== 'TOROLT' && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'action-modosit-' + cell + 'modositas'} onClick={() => this.handleModositoTetel(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-modosit-' + cell + 'modositas'} delay={0}>
                Módosítás
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {row.statusz && row.statusz.name === 'FELVITT' && (
              <React.Fragment>
                <Button className="icon-check table-action-icon" id={'action-igenybe-' + cell + 'igenybevetel'} onClick={() => this.kompenzacioStatuszmodositasIgeny(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-igenybe-' + cell + 'igenybevetel'} delay={0}>
                Foglalás igénybevétele
                </UncontrolledTooltip>
                <Button className="icon-trash table-action-icon" id={'action-torol-' + cell + 'torles'} onClick={() => this.toggleTetelTorles(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-torol-' + cell + 'torles'} delay={0}>
                Törlés
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  evFormatter = (cell) => {
    return (
      <div>{cell.caption}</div>
    );
  }

  toggleTetelTorles = (cell) => {
    this.setState({ tetelTorles:!this.state.tetelTorles, tetelcell: cell });
  }

  teteltorles = () => {
    Services.deleteKompenzacioTetel(this.state.tetelcell, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres tétel törlés!');
        this.toggleTetelTorles();
        this.updateTable();
        this.lekerdezeTetel(this.state.udulesijogid);
      } else {
        this.props.globals.addError('Sikertelen tétel törlés!');
      }
    });
  }

  handleModositoTetel = (cell) => {
    Services.getKompenzacioTetel(cell, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseView: 'NETW'
        });
      } else {
        this.getAktivApartmanById(res[0].apartmanbean.id);

        this.setState({
          kompenzacioTetelObj: res[0],
          currentStatusBaseView: 'LOADED'
        });
      }
    });
    this.toggletetelModositas();
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  handleUjTetelClick = () => {
    Services.getKompenzacioInit(this.state.udulesijogid, null, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].apartman.map(e => <option key={e.id} value={e.id}>{e.apartmannev + (e.kategoria ? (' (' + e.kategoria + ')') : '')}</option>);
        this.setState({ aktivApartman: aktivApartmanok }, () => {
          this.toggleUjTetel();
        });
      }
    });
  }

  handleViewSzallodaClick = (id) => {
    Services.getEvHetSzallodaList(id, (err, res) => {
      if (!err) {
        let evHetSzallodaArray = res[0].map(e => <li>{e}</li>);
        this.setState({ evHetSzallodak: evHetSzallodaArray }, () => {
          this.toggleSzallodaModal();
        });
      }
    });
  }

  handleViewKompenzacioClick = (id) => {
    Services.getUdulesijog(id, (err, res) => {
      if (!err) {
        this.setState({ kompenzacioView: res[0] }, () => {
          this.toggleKompenzacioModal();
        });
      }
    });
  }

  toggleUjTetel = () => {
    this.setState({ UjTetelModal: !this.state.UjTetelModal });
  }

  toggleSzallodaModal = () => {
    this.setState({ szallodaModal: !this.state.szallodaModal });
  }

  toggleKompenzacioModal = () => {
    this.setState({ kompenzacioModal: !this.state.kompenzacioModal });
  }

  toggletetelModositas = () => {
    this.setState({ tetelModositas: !this.state.tetelModositas });
    this.setState({ aktivEpuletek: [], aktivApartman: [] });
  }

  statuszFormatter = (cell) => {
    if (cell != null) {
      switch (cell.name) {
        case 'FELVITT':
          return <Badge color="success">{cell.caption}</Badge>;
        case 'IGV':
          return <Badge color="secondary">{cell.caption}</Badge>;
        case 'LMT':
          return <Badge color="warning">{cell.caption}</Badge>;
        case 'TOROLT':
          return <Badge color="danger">{cell.caption}</Badge>;
        default:
          return cell.name;
      }
    }
    return null;
  }

  statuszFormatter2 = (cell) => {
    if (cell != null) {
      switch (cell.name) {
        case 'FELVITT':
          return <Badge color="success">{cell.caption}</Badge>;
        case 'LEZART':
          return <Badge color="secondary">{cell.caption}</Badge>;
        default:
          return '-';
      }
    }
    return null;
  }

  dijFormatter = (cell, row) => {
    if (cell != null) {
      return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG) + ' ' + row.penznem;
    }
    return null;
  }

  handleUtolsoHwAtadasChange = (e) => {
    let newState = this.state.kompenzacioTetelObj;
    newState.utolsoHwAtadas = e.target.value;
    this.setState({ kompenzacioTetelObj: newState });
  }

  handleViewClick = (id) => {
    this.setState({
      currentStatusBaseView: 'LOAD',
      modalNezet: true
    });
    Services.getUdulesijog(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseView: 'NETW'
        });
      } else {
        this.setState({
          udulesijogObj: res[0],
          currentStatusBaseView: 'LOADED'
        });
      }
    });
  }

  renderTulajdonosListItem = (tulajdonos) => {
    return (
      <li key={tulajdonos.id.toString()} style={tulajdonos.fotulajdonos ? { color: 'forestgreen' } : {}}>
        <b>{tulajdonos.tulajdonosbean.nev}</b><br />
        <span><b>Cím: </b>{tulajdonos.tulajdonosbean.cim}</span><br />
        <span><b>Érvényesség kezdete: </b>{tulajdonos.kezdete}</span><br />
        <span><b>Érvényesség vége: </b>{tulajdonos.vege}</span><br />
        {!tulajdonos.kompenzacios && (
          <React.Fragment>
            <span><b>RCI tag: </b>{tulajdonos.rcitag ? 'Igen' : 'Nem'}</span><br />
            <span><b>RCI státusz: </b>{tulajdonos.rcistatusz && tulajdonos.rcistatusz.caption}</span><br />
            <span><b>RCI Weeks azonosító: </b>{tulajdonos.rciweeksazon}</span><br />
            <span><b>RCI Points azonosító: </b>{tulajdonos.rcipointsazon}</span><br />
            <span><b>RCI típus: </b>{tulajdonos.rcitipus && tulajdonos.rcitipus.caption}</span><br />
          </React.Fragment>
        )}
        {tulajdonos.elhunyt && (
          <React.Fragment>
            <span><b>Elhunyt: </b>Igen</span><br />
          </React.Fragment>
        )}
        <br />
      </li>
    );
  }

  renderAdatok = () => {
    let listItems = [];
    if (this.state.udulesijogObj) {
      this.state.udulesijogObj.udulesijogtulajdonosentities.forEach(item => listItems.push(this.renderTulajdonosListItem(item)));
    }

    return (
      <div>
        <h6>Üdülési jog információ:</h6>
        <b>Üdülési jog azonosító: </b>{this.state.udulesijogObj && this.state.udulesijogObj.azonosito}<br />
        <b>Érvényesség kezdete: </b>{this.state.udulesijogObj && this.state.udulesijogObj.kezdete}<br />
        <b>Érvényesség vége: </b>{this.state.udulesijogObj && this.state.udulesijogObj.vege}<br />
        <b>Számlakibocsátó: </b>{this.state.udulesijogObj && this.state.udulesijogObj.szamlakibocsatobean && this.state.udulesijogObj.szamlakibocsatobean.nev}<br />
        <b>Szálloda: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.szallodanev}<br />
        <b>Épület: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.epuletnev}<br />
        <b>Apartman: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.apartmannev}<br />
        <b>Hét sorszáma: </b>{this.state.udulesijogObj && this.state.udulesijogObj.het}<br />
        <b>Páros/páratlan év: </b>{this.state.udulesijogObj && this.state.udulesijogObj.gyakorisag && this.state.udulesijogObj.gyakorisag.caption}<br />
        <b>Osztott díj: </b>{this.state.udulesijogObj && this.state.udulesijogObj.reszlet ? 'Igen' : 'Nem' }<br />
        <b>Szín: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.hetszine}<br />
        <b>Jellemző: </b>{this.state.udulesijogObj && this.state.udulesijogObj.jellemzo}<br />
        <b>Lejárati státusz: </b>{this.state.udulesijogObj && this.state.udulesijogObj.lejaratistatusz && this.state.udulesijogObj.lejaratistatusz.caption}<br /><br />
        <h6>Pénzügyi adatok:</h6>
        <b>Pénznem: </b>{this.state.udulesijogObj && this.state.udulesijogObj.penznem}<br />
        <b>Fizetési mód: </b>{this.state.udulesijogObj && this.state.udulesijogObj.fizmod}<br />
        <b>Számlázás nyelve: </b>{this.state.udulesijogObj && this.state.udulesijogObj.nyelv && this.state.udulesijogObj.nyelv.caption}<br />
        <b>Státusz: </b>{this.state.udulesijogObj && this.state.udulesijogObj.statusz && this.state.udulesijogObj.statusz.caption}<br />
        <b>Lezárás oka: </b>{this.state.udulesijogObj && this.state.udulesijogObj.lezarasoka && this.state.udulesijogObj.lezarasoka.caption}<br /><br />
        <h6>Jogtulajdonos adatai:</h6>
        <ul>
          {listItems}
        </ul>
      </div>
    );
  }

  calculateKompenzacioDijOneThree = (hetMennyiseg, dij) => {
    if (!hetMennyiseg || !dij) {
      return '';
    }

    let decDigit = this.state.kompenzacioView && this.state.kompenzacioView.tizedesjegy ? this.state.kompenzacioView.tizedesjegy : 0;
    const decMult = decDigit === 0 ? 1 : decDigit;

    let sumEgyebDij = 0;
    if (this.state.kompenzacioView.egyebdij) {
      this.state.kompenzacioView.egyebdij.forEach((item) => { sumEgyebDij += item.alapdijhozadas && item.osszeg ? Math.round(parseFloat(NumberFormatter.removeSpacesFromString(item.osszeg), 10) * (10 ** decMult)) / (10 ** decMult) : 0; });
    }

    return (Math.round((sumEgyebDij + parseFloat(NumberFormatter.removeSpacesFromString(hetMennyiseg)) * parseFloat(NumberFormatter.removeSpacesFromString(dij))) * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit);
  }

  calculateKompenzacioHetDijTwo = () => {
    if (!this.state.kompenzacioView || !this.state.kompenzacioView.evhet || this.state.kompenzacioView.evhet.length === 0) {
      return ['', ''];
    }

    let decDigit = this.state.kompenzacioView && this.state.kompenzacioView.tizedesjegy ? this.state.kompenzacioView.tizedesjegy : 0;
    const decMult = decDigit === 0 ? 1 : decDigit;
    let sumHet = 0;
    let sumDij = 0;
    let sumEgyebDij = 0;

    this.state.kompenzacioView.evhet.forEach((item) => {
      sumHet += item.het ? parseFloat(NumberFormatter.removeSpacesFromString(item.het), 10) : 0;
      sumDij += item.het && item.dij ? Math.round((parseFloat(NumberFormatter.removeSpacesFromString(item.het), 10) * parseFloat(NumberFormatter.removeSpacesFromString(item.dij))) * (10 ** decMult)) / (10 ** decMult) : 0;

      if (item.egyebdij) {
        item.egyebdij.forEach((item2) => { sumEgyebDij += item2.alapdijhozadas && item2.osszeg ? Math.round(parseFloat(NumberFormatter.removeSpacesFromString(item2.osszeg), 10) * (10 ** decMult)) / (10 ** decMult) : 0; });
      }
    });

    return [sumHet, (Math.round((sumEgyebDij + sumDij) * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit)];
  }

  getEgyebDij = (egyebDij) => {
    return (
      <ul>
        {egyebDij}
      </ul>
    );
  }

  renderKompenzaciosFeltetelek = () => {
    let jogtulajItems = [];
    if (this.state.kompenzacioView) {
      this.state.kompenzacioView.udulesijogtulajdonosentities.filter(x => x.kompenzacios).forEach(item => jogtulajItems.push(this.renderTulajdonosListItem(item)));
    }

    let decDigit = this.state.kompenzacioView && this.state.kompenzacioView.tizedesjegy ? this.state.kompenzacioView.tizedesjegy : 0;
    const decMult = decDigit === 0 ? 1 : decDigit;

    let egyebDijItems = [];
    if (this.state.kompenzacioView && this.state.kompenzacioView.egyebdij) {
      this.state.kompenzacioView.egyebdij.forEach(item => egyebDijItems.push(
        <li>
          {item.koltsegkod + ': ' + NumberFormatter.formatterSpaceOsszegPenznem((Math.round(item.osszeg * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit), null, this.state.kompenzacioView.penznem) + (item.alapdijhozadas ? ' (Alapdíjhoz hozzáadni)' : '')}
        </li>
      ));
    }

    let evHetItems = [];
    if (this.state.kompenzacioView && this.state.kompenzacioView.evhet) {
      this.state.kompenzacioView.evhet.sort((a, b) => { return a.ev > b.ev ? 1 : -1; }).forEach((item, idx) => {
        let egyebdij = [];
        if (item.egyebdij && item.egyebdij.length > 0) {
          item.egyebdij.forEach((e) => {
            egyebdij.push(<li>{e.koltsegkod + ': ' + NumberFormatter.formatterSpaceOsszegPenznem((Math.round(e.osszeg * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit), null, this.state.kompenzacioView.penznem) + (e.alapdijhozadas ? ' (Alapdíjhoz hozzáadni)' : '')}</li>);
          });
        }

        evHetItems.push(
          <li>
            {idx > 0 && <br />}
            <b>Év: </b>
            {item.ev + '.'}
            <b>    Hetek mennyisége: </b>
            {NumberFormatter.formatterSpaceOsszegPenznem(item.het, null, 'db')}
            <b>    Díj: </b>
            {NumberFormatter.formatterSpaceOsszegPenznem((Math.round(item.dij * (10 ** decMult)) / (10 ** decMult)).toFixed(decDigit), null, this.state.kompenzacioView.penznem)}
            <br />
            {egyebdij.length > 0 && (
              <React.Fragment>
                <b> Egyéb díjak:</b>
                <ul>
                  {egyebdij}
                </ul>
              </React.Fragment>
            )}
          </li>
        );
      });
    }

    let evHetSum = this.calculateKompenzacioHetDijTwo();

    return (
      <div>
        <b>Információk: </b>
        {this.state.kompenzacioView && this.state.kompenzacioView.kompenzacioinformacio}
        <br />

        <b>Kompenzáció típusa: </b>
        {this.state.kompenzacioView && this.state.kompenzacioView.kompenzaciotipus && this.state.kompenzacioView.kompenzaciotipus.caption}
        {this.state.kompenzacioView && this.state.kompenzacioView.kompenzaciotipus && (this.state.kompenzacioView.kompenzaciotipus.name === 'R' || this.state.kompenzacioView.kompenzaciotipus.name === 'UNF') && (
          <div>
            <b>Kompenzációs hetek mennyisége: </b>
            {this.state.kompenzacioView && this.state.kompenzacioView.kompenzaciohetmennyiseg
              && NumberFormatter.formatterSpaceOsszegPenznem(this.state.kompenzacioView.kompenzaciohetmennyiseg, null, 'db')}
            <br />
            <b>Kompenzációs díj: </b>
            {this.state.kompenzacioView && this.state.kompenzacioView.kompenzaciodij
              && NumberFormatter.formatterSpaceOsszegPenznem(this.state.kompenzacioView.kompenzaciodij, null, this.state.kompenzacioView.penznem + '/hét')}
            <br />
            <b>Kompenzációs díj összesen: </b>
            {this.state.kompenzacioView && this.state.kompenzacioView.kompenzaciodij && this.state.kompenzacioView.kompenzaciohetmennyiseg
              && NumberFormatter.formatterSpaceOsszegPenznem(this.calculateKompenzacioDijOneThree(this.state.kompenzacioView.kompenzaciodij,
                this.state.kompenzacioView.kompenzaciohetmennyiseg), null, this.state.kompenzacioView.penznem)}
            <br />
            {egyebDijItems && egyebDijItems.length > 0 && (
              <div>
                <br />
                <h6>Egyéb díjak:</h6>
                <ul>
                  {egyebDijItems}
                </ul>
              </div>
            )}
          </div>
        )}

        {this.state.kompenzacioView && this.state.kompenzacioView.kompenzaciotipus && this.state.kompenzacioView.kompenzaciotipus.name === 'UF' && evHetItems && evHetItems.length > 0 && (
          <div>
            <b>Kompenzációs hetek mennyisége összesen: </b>
            {NumberFormatter.formatterSpaceOsszegPenznem(evHetSum[0], null, 'db')}
            <br />
            <b>Kompenzációs díj összesen: </b>
            {NumberFormatter.formatterSpaceOsszegPenznem(evHetSum[1], null, this.state.kompenzacioView.penznem)}
            <br />
            <br />
            <h6>Év-hét adatok:</h6>
            <ul>
              {evHetItems}
            </ul>
          </div>
        )}

        <br />
        <h6>Kompenzációs jogtulajdonos(ok) adatai:</h6>
        <ul>
          {jogtulajItems}
        </ul>

      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderTable = () => {
    let selectedArray = [];
    if (this.state.udulesijogid) {
      selectedArray.push(this.state.udulesijogid);
    }

    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect,
      selected: selectedArray,
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable data={this.state.udulesijogJson} pagination options={options} selectRow={selectRowProp}>
          <TableHeaderColumn dataField="azonosito" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Üdülési jog azonosító</TableHeaderColumn>
          <TableHeaderColumn dataField="ugyszam" dataAlign="left" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Ügyszám</TableHeaderColumn>
          <TableHeaderColumn dataField="kompenzaciostatusz" width="140" dataAlign="center" dataFormat={this.statuszFormatter2} tdStyle={{ whiteSpace: 'normal' }}>Kompenzáció állapota</TableHeaderColumn>
          <TableHeaderColumn dataField="szamlakibocsato" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Számlakibocsátó</TableHeaderColumn>
          <TableHeaderColumn dataField="tulajdonos" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Jogtulajdonos</TableHeaderColumn>
          <TableHeaderColumn dataField="kategoria" width="90" tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Kategória</TableHeaderColumn>
          <TableHeaderColumn dataField="dij" dataFormat={this.dijFormatter} tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}>Számlázandó díj</TableHeaderColumn>
          <TableHeaderColumn dataField="futamido" tdStyle={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>Futamidő</TableHeaderColumn>
          <TableHeaderColumn dataField="hetek" width="280" dataAlign="left" tdStyle={{ whiteSpace: 'pre-wrap' }}>Kompenzációs hetek</TableHeaderColumn>
          <TableHeaderColumn dataField="id" width="120" isKey dataFormat={this.iconFormatter}>Műveletek</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  iconFormatter = (cell) => {
    return (
      Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_KOMPENZACIOFOGLALAS', 'LEK') && (
        <div>
          <Button style={{ marginRight: '15px' }} className="fa fa-bed table-action-icon" id={'action-view-' + cell + 'szalloda'} onClick={() => this.handleViewSzallodaClick(cell)} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'szalloda'} delay={0}>
            Foglalással igénybe vehető szállodák
          </UncontrolledTooltip>
          <Button style={{ marginRight: '15px' }} className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekint'} onClick={() => this.handleViewKompenzacioClick(cell)} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekint'} delay={0}>
            Megtekintés
          </UncontrolledTooltip>
        </div>
      )
    );
  }

  kompenzacioStatuszmodositasIgeny = (id) => {
    Services.kompenzacioIgenybevett(id, (err) => {
      if (!err) {
        this.props.globals.addSuccess('A kompenzációs szállásfoglalás igénybevétele sikeres!');
        this.lekerdezeTetel(this.state.udulesijogid);
      }
    });
  }

  apartmanChange = (e) => {
    this.setState({ apartmanHasValue: true, apartmanValue: e.target.value });
  }

  szuroSzallodaChange = (e) => {
    this.setState({
      szuroSzallodaHasValue: true, szuroAktivEpuletek: [], szuroAktivApartman: [], szuroSzallodaValue: e.target.value, szuroEpuletValue: ''
    });
    this.getSzuroAktivEpuletById(e.target.value);
  //  if (this.state.kompenzacioTetelObj) this.state.kompenzacioTetelObj.apartmanbean.epuletnev = null;
  }

  submitSearch = () => {
    Services.listUdulesijog(this.state.szuroSzallodaValue, this.state.szuroEpuletValue, this.state.szuroJogtulajdonosValue && this.state.szuroJogtulajdonosValue.id && this.state.szuroJogtulajdonosValue.id, true, (err, res) => {
      if (!err) {
        this.setState({ udulesijogJson: res[0] });
      }
    });
  }

  handleChangeJogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szuroJogtulajdonosValue: value });
    } else {
      this.setState({ szuroJogtulajdonosValue: '' });
    }
  }

  loadJogtulajdonosChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listUdulesijogTulaj('KOMPENZACIO_LISTA_JOGTULAJDONOS_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD',
      tetelek: [{}],
      udulesijogid: 0
    });

    this.setState({ ujTetel: false });
    Services.listUdulesijogSzall(this.state.szuroSzallodaValue, this.state.szuroJogtulajdonosValue && this.state.szuroJogtulajdonosValue.id && this.state.szuroJogtulajdonosValue.id, this.state.filterEv, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        let newState = res[0];
        newState.forEach((item) => {
          item.futamido = item.futamidokezdete + ' - ' + (item.futamidovege ? ('\n' + item.futamidovege) : '');
          item.szamlakibocsato = item.szamlakibocsatobean.nev + ' ' + item.szamlakibocsatobean.cim;
          item.kompenzaciostatusz = item.statusz;
          switch (item.statusz.name) {
            case 'AKTIV':
              item.statusz = 0;
              break;
            case 'LEZART':
              item.statusz = 1;
              break;
            default:
              item.statusz = -1;
              break;
          }
        });

        this.setState({
          udulesijogJson: res[0],
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  tetelekTabla = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      defaultSortName: 'het',
      defaultSortOrder: 'asc',
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable data={this.state.tetelek} pagination options={options}>
          <TableHeaderColumn dataField="azonosito" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Azonosító</TableHeaderColumn>
          <TableHeaderColumn dataField="apartman" tdStyle={{ whiteSpace: 'normal' }}>Apartman (HostWare)</TableHeaderColumn>
          <TableHeaderColumn dataField="ev" dataSort tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}>Év</TableHeaderColumn>
          <TableHeaderColumn dataField="het" dataSort tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}>RCI hét</TableHeaderColumn>
          <TableHeaderColumn dataField="dij" dataFormat={(cell) => { return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG); }} tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}>Számlázott összeg</TableHeaderColumn>
          <TableHeaderColumn dataField="statusz" dataFormat={this.statuszFormatter} tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}>Státusz</TableHeaderColumn>
          <TableHeaderColumn dataField="id" tdStyle={{ whiteSpace: 'normal' }} isKey dataFormat={this.iconFormatterTetel}>Műveletek</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Modal isOpen={this.state.szallodaModal} toggle={this.toggleSzallodaModal} className={this.props.className} backdrop="static">
          <ModalHeader>Foglalással igénybe vehető szállodák:</ModalHeader>
          <ModalBody>
            {this.state.evHetSzallodak && this.state.evHetSzallodak.length > 0 && (
              <ul>
                {this.state.evHetSzallodak}
              </ul>
            )}

            <div className="row">
              <div className="col-md-12">
                <Button type="button" color="primary" onClick={this.toggleSzallodaModal} className="btn-cursor-pointer margin-top-30 pull-right">Bezárás</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.kompenzacioModal} toggle={this.toggleKompenzacioModal} className={this.props.className} backdrop="static">
          <ModalHeader>Kompenzációs feltételek:</ModalHeader>
          <ModalBody>
            {this.renderKompenzaciosFeltetelek()}

            <div className="row">
              <div className="col-md-12">
                <Button type="button" color="primary" onClick={this.toggleKompenzacioModal} className="btn-cursor-pointer margin-top-30 pull-right">Bezárás</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.UjTetelModal} toggle={this.toggleUjTetel} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={this.mentes}>
            <ModalHeader>Foglalás rögzítése</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="azonosito">Azonositó: </Label>
                    <AvInput disabled name="azonosito" id="azonosito" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvField
                    type="select"
                    name="apartmanid"
                    id="apartmanid"
                    label="Apartman:"
                    onChange={this.apartmanChange}
                    errorMessage="Kötelező mező!"
                    required
                  >
                    <option disabled value="">Válassz Apartmant...</option>
                    {this.state.aktivApartman}
                  </AvField>
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="ev">Év: </Label>
                    <AvInput required name="ev" id="ev" maxLength="4" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <AvField
                      type="select"
                      name="het"
                      id="het"
                      label="RCI Hét: "
                      errorMessage="Kötelező mező!"
                      required
                      onChange={(e) => {
                        this.setState({ tetelHet: e.target.value });
                      }}
                    >
                      <option disabled value="">Válassz hetet...</option>
                      {this.state.hetek}
                    </AvField>
                  </AvGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ProgressButton className="margin-top-30" color="primary" loading={this.state.loadingButton} disabled={!(this.state.apartmanHasValue)}>Mentés</ProgressButton>
              <Button type="button" color="secondary" onClick={this.toggleUjTetel} className="btn-cursor-pointer margin-top-30">Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.tetelModositas} toggle={this.toggletetelModositas} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={this.updateTetel}>
            <ModalHeader>Foglalás módosítása </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvField
                    disabled
                    type="select"
                    name="apartman"
                    id="apartman"
                    label="Apartman:"
                    onChange={this.apartmanChange}
                    errorMessage="Kötelező mező!"
                    required
                    value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.apartmannev}
                  >
                    <option disabled value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.apartmannev}>{this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.apartmannev}</option>
                    {this.state.aktivApartman}
                  </AvField>
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="ev">Év: </Label>
                    <AvInput
                      disabled
                      required
                      name="ev"
                      id="ev"
                      value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.ev}
                    />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <AvField
                      disabled
                      type="select"
                      name="het"
                      id="het"
                      label="RCI Hét: "
                      errorMessage="Kötelező mező!"
                      required
                      value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.het}
                      onChange={(e) => {
                        this.setState({ tetelHet: e.target.value });
                      }}
                    >
                      <option value="" disabled>Válassz hetet...</option>
                      {this.state.hetek}
                    </AvField>

                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                {Lib.KeyCloak.hasRole(this.props.globals.keycloak, ['UDULESIJOG_HWNAPLO']) && (
                  <div className="col-md-12">
                    <AvGroup>
                      <Label for="utolsoHwAtadas">Utolsó HostWare átadás dátuma: </Label>
                      <AvInput
                        name="utolsoHwAtadas"
                        id="utolsoHwAtadas"
                        value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.utolsoHwAtadas && moment(this.state.kompenzacioTetelObj.utolsoHwAtadas).format('YYYY-MM-DD HH:mm:ss.SSS')}
                        onChange={this.handleUtolsoHwAtadasChange}
                      />
                    </AvGroup>
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <ProgressButton className="margin-top-30" color="primary" loading={this.state.loadingButton}>Mentés</ProgressButton>
              <Button type="button" color="primary" onClick={this.toggletetelModositas} className="btn-cursor-pointer margin-top-30">Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.tetelTorles} toggle={this.toggleTetelTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Tétel törlése</ModalHeader>
          <ModalBody>

            Valóban törölni akarod a tételt?
            <div className="row">

              <div className="col-md-3" style={{ marginRight: '-35px', left: '300px' }}>
                <Button className="margin-top-30" color="primary" onClick={this.teteltorles}>Igen</Button>
              </div>
              <div className="col-md-9" style={{ left: '300px' }}>
                <Button type="button" color="primary" onClick={this.toggleTetelTorles} className="btn-cursor-pointer margin-top-30">Nem</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleViewClick()}
              />),
              LOADED: (this.renderAdatok())
            }[this.state.currentStatusBaseView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>

        <AvForm onValidSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">
              <AvField
                type="select"
                name="szallodaid"
                id="szallodaid"
                label="Szálloda: "
                errorMessage="Kötelező mező!"
                value={this.state.szuroSzallodaValue}
                onChange={this.szuroSzallodaChange}
              >
                <option value="">Válassz szállodát...</option>
                {this.state.szuroAktivSzallodak}
              </AvField>
            </div>
            <div className="col-md-2">
              <AvGroup>
                <Label for="filterEv">Kompenzáció éve: </Label>
                <AvInput
                  name="filterEv"
                  id="filterEv"
                  maxlength="4"
                  value={this.state.filterEv}
                  onChange={this.handleInputChange}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-6">
              <Label for="jogtulajId">Jogtulajdonos:</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeJogtulajdonos(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadJogtulajdonosChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.szuroJogtulajdonosValue}
                // disabled={this.state.szuroJogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-2">
              <Button className="margin-top-30" color="primary" onClick={this.updateTable}>Keresés</Button>
            </div>
          </div>
        </AvForm>
        {this.state.szerzodesid != null ? (<Button className="margin-top-30" color="primary" onClick={() => this.ujudulesijog()}>Új üdülési jog</Button>) : (null)}

        <div className="row">
          <div className="col-md-12">
            {/* <Button className="szerzodes-collapse-buttons" color="primary" onClick={this.navToForm} >Új üdülési jog</Button> */}
          </div>
          <div className="col-md-12" style={{ marginTop: '30px' }}>
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.updateTable()}
              />),
              TABL: (this.renderTable())
            }[this.state.currentStatusBaseTable]}
          </div>

        </div>
        {this.state.udulesijogid !== 0 && (
          <div className="row">
            <div className="col-xs-2" style={{ paddingLeft: '10px' }}>
              <Button className="margin-top-30" disabled={!this.state.ujTetel} onClick={this.handleUjTetelClick} color="primary">Új foglalás</Button>
            </div>
          </div>
        )}
        { this.state.tetelek && (
          <div>
            <br />
            <hr style={{ height: '10px', boreder: '10px', margin: '0px' }} />
            <div className="row" style={{ padding: 20 }}>
              <div className="col-md-12">
                <Label for="kezdete">Kompenzációs tételek:</Label>
                {this.tetelekTabla()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

KompenzacioLista.propTypes = {
  tableData: PropTypes.object,
  globals: PropTypes.object,
  className: PropTypes.string,
};

export default KompenzacioLista;
