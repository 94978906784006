import Lib from '../../../commons/lib';

export default class Service {
  static partnerUrl = window.location.origin + '/api/partner/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  static gdprUrl = window.location.origin + '/api/gdpr/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  // PARTNER START

  static initPartner(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner/init',
      args: { requestType: 'partner-init' }
    }], fnDone);
  }

  static listPartner(filterObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'partner/list',
      args: { requestType: 'partner-list' },
      data: filterObject
    }], fnDone);
  }

  static listXlsx(filterObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'partner/xlsx',
      acceptType: 'application/json, application/octet-stream',
      args: { requestType: 'partner-xlsx' },
      data: filterObject
    }], fnDone);
  }

  static addPartner(partnerObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.partnerUrl + 'partner',
      args: { requestType: 'partner-post' },
      data: partnerObject
    }], fnDone);
  }

  static editPartner(partnerObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'partner',
      args: { requestType: 'partner-put' },
      data: partnerObject
    }], fnDone);
  }

  static checkPhone(telefon, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'partner/checktelefon',
      args: { requestType: 'partner-checktelefon-put' },
      data: telefon
    }], fnDone);
  }

  static listEnumPartner(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner/enum',
      args: { requestType: 'partner-enum-list' }
    }], fnDone);
  }

  static initPartnerCsop(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnercsoport/init',
      args: { requestType: 'partnercsoport-init-list-active' }
    }], fnDone);
  }

  static listTelefonEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner/telefonenum',
      args: { requestType: 'partner-telefonenum-list' }
    }], fnDone);
  }

  static deletePartner(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.partnerUrl + 'partner',
      args: { requestType: 'partner-delete' },
      params: { id: id }
    }], fnDone);
  }

  static getPartner(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner',
      args: { requestType: 'partner-list' },
      params: { id: id }
    }], fnDone);
  }

  static listExport(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'export/list',
      args: { requestType: 'export-list' },
      params: { id: id }
    }], fnDone);
  }

  // static exportXls(id, fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'GET',
  //     url: Service.gdprUrl + 'export/list',
  //     args: { requestType: 'export-list' },
  //     params: { id: id }
  //   }], fnDone);
  // }

  static exportXls(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'export/xls',
      args: { requestType: 'export-xls' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      params: { id: id }
    }], fnDone);
  }

  static exportCustomXls(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'export/xls',
      acceptType: 'application/json, application/octet-stream',
      params: {
        id: id,
      },
    }], fnDone);
  }

  static exportCustomXlsx(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'export/xlsx',
      acceptType: 'application/json, application/octet-stream',
      params: {
        id: id,
      },
    }], fnDone);
  }

  static downloadObject(objid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner/object',
      args: { requestType: 'partner-get-object' },
      acceptType: 'application/octet-stream',
      params: { id: objid }
    }], fnDone);
  }

  // PARTNER END

  // PARTNERCSOPORT START

  static listPartnercsoport(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnercsoport/list',
      args: { requestType: 'partnercsoport-list' }
    }], fnDone);
  }

  static addPartnercsoport(partnercsoportObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.partnerUrl + 'partnercsoport',
      args: { requestType: 'partnercsoport-post' },
      data: partnercsoportObject
    }], fnDone);
  }

  static editPartnercsoport(partnercsoportObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'partnercsoport',
      args: { requestType: 'partnercsoport-put' },
      data: partnercsoportObject
    }], fnDone);
  }

  static listEnumPartnercsoport(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnercsoport/enum',
      args: { requestType: 'partnercsoport-enum-list' }
    }], fnDone);
  }

  static listActivePartnercsoport(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnercsoport/aktiv',
      args: { requestType: 'partnercsoport-list-active' }
    }], fnDone);
  }

  static deletePartnercsoport(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.partnerUrl + 'partnercsoport',
      args: { requestType: 'partnercsoport-delete' },
      params: { id: id }
    }], fnDone);
  }

  static getPartnercsoport(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnercsoport',
      args: { requestType: 'partnercsoport-list' },
      params: { id: id }
    }], fnDone);
  }

  static initPartnercsoport(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partnercsoport/initform',
      args: { requestType: 'partnercsoport-initform' },
    }], fnDone);
  }

  // PARTNERCSOPORT END

  // TELEPÜLÉS START

  static listTelepules(iranyitoszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-list' },
      params: { iranyitoszam: iranyitoszam }
    }], fnDone);
  }

  static listIranyitoszam(telepules, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/irsz',
      args: { requestType: 'iranyitoszam-list' },
      params: { telepulesnev: telepules }
    }], fnDone);
  }

  static listTelepulesByOrszag(telepules, orszagId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/irsz',
      args: { requestType: 'iranyitoszam-list' },
      params: {
        telepulesnev: telepules,
        id: orszagId
      }
    }], fnDone);
  }

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'telepules-orszag-list' },
      params: { name: name }
    }], fnDone);
  }

  static getOrszag(kod, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/kod',
      args: { requestType: 'telepules-orszag-get' },
      params: { kod: kod },
    }], fnDone);
  }

  static existsIrsz(orszagid, irsz, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/existsirsz',
      args: { requestType: 'telepules-exists-irsz' },
      params: {
        orszagid: orszagid,
        irsz: irsz
      }
    }], fnDone);
  }

  static getAlapertelmezettOrszag(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/default',
      args: { requestType: 'telepules-orszag-alapert-get' },
    }], fnDone);
  }

  // TELEPÜLÉS END

  // GDPR JOGALAP HOZZÁJÁRULÁS START
  static getJogalapList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'jogalap/aktiv',
      args: { requestType: 'jogalap-lenyillo-get' },
    }], fnDone);
  }

  static setHozzajarul(id, op, data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'gdprhozzaj',
      data: data,
      args: { requestType: 'jogalap-hozzajarul-put' },
      params: {
        id: id,
        op: op
      },
    }], fnDone);
  }

  static pdfGen(pid, jid, cid, begin, end, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'gdprhozzaj/pdf',
      args: { requestType: 'jogalap-pdf-kuld' },
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      params: {
        pid: pid,
        jid: jid,
        cid: cid,
        begin: begin,
        end: end
      },
    }], fnDone);
  }


  // GDPR JOGALAP HOZZÁJÁRULÁS END

  // CÉGADAT START

  static getCegadatList(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search/',
      args: { requestType: 'cegadat-search-put' },
      params: {
        aktiv: ids == null || ids.length === 0,
        resultclass: '55'
      },
      data: ids
    }], fnDone);
  }

  // CÉGADAT END
}
