import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Lib from '../../../commons/lib';
import FizetesimodLista from './FizetesimodLista';
import PenznemLista from './PenznemLista';
import InflacioLista from './InflacioLista';
import BankszamlaLista from './BankszamlaLista';
import BanktipusLista from './BanktipusLista';
import PenztarList from './PenztarList';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import getTooltip from './PenzugyTooltips';

class PenzMenuBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PENZTAR'], true)) activeTab = '6';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_BANKSZAMLA'], true)) activeTab = '5';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_BANKTIPUS'], true)) activeTab = '4';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_INFLACIO'], true)) activeTab = '3';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_PENZNEM'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_FIZMOD'], true)) activeTab = '1';
    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {'Pénzügyi adatok'}
              <CustomTooltip text={getTooltip('penzugy')} id="penzugyTT" />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_FIZMOD'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                          this.toggle('1');
                          if (this.child1) this.child1.updateTable();
                        }}
                      >
                        {'Fizetési mód'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_PENZNEM'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                          this.toggle('2');
                          if (this.child2) this.child2.updateTable();
                        }}
                      >
                        {'Pénznem'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_INFLACIO'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => {
                          this.toggle('3');
                          if (this.child3) this.child3.updateTable();
                        }}
                      >
                        {'Infláció'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_BANKTIPUS'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => {
                          this.toggle('4');
                          if (this.child4) this.child4.updateTable();
                        }}
                      >
                        {'Banktípus'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_BANKSZAMLA'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '5' })}
                        onClick={() => {
                          this.toggle('5');
                          if (this.child5) this.child5.updateTable();
                        }}
                      >
                        {'Bankszámla'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PENZTAR'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '6' })}
                        onClick={() => {
                          this.toggle('6');
                          if (this.child6) this.child6.updateTable();
                        }}
                      >
                        {'Pénztár'}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_FIZMOD'], true) && (
                    <TabPane tabId="1">
                      <FizetesimodLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_PENZNEM'], true) && (
                    <TabPane tabId="2">
                      <PenznemLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child2 = instance; }}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_INFLACIO'], true) && (
                    <TabPane tabId="3">
                      <InflacioLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child3 = instance; }}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_BANKTIPUS'], true) && (
                    <TabPane tabId="4">
                      <BanktipusLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child4 = instance; }}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PENZUGYKOZOS_BANKSZAMLA'], true) && (
                    <TabPane tabId="5">
                      <BankszamlaLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child5 = instance; }}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PENZTAR'], true) && (
                    <TabPane tabId="6">
                      <PenztarList
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child6 = instance; }}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PenzMenuBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default PenzMenuBase;
