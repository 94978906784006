import React, { Component } from 'react';
import { Link } from 'react-router';

class Sidebar extends Component {
  handleClick = (e) => {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  hasRole = (roles, onlyLEK) => {
    let keycloak = this.props.route.globals.keycloak;
    for (let i = 0; i < roles.length; i += 1) {
      if (keycloak.hasResourceRole('ROLE_' + roles[i] + '_LEK') || (!onlyLEK && keycloak.hasResourceRole('ROLE_' + roles[i] + '_KARB'))) {
        return true;
      }
    }
    return false;
  }

  hasRoleUnique = (role) => {
    let keycloak = this.props.route.globals.keycloak;
    if (keycloak.hasResourceRole(role)) {
      return true;
    }
    return false;
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown';
  }

  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            {this.hasRole(['DASHBOARD_DASHBOARD', 'DASHBOARD_POSTALADA']) && (
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link" activeClassName="active">
                  <i className="icon-home" />
                  {'Dashboard'}
                </Link>
              </li>
            )}
            {(this.hasRole([
              'CIKK_TORZS',
              'CIKK_CSOPORT',
              'CIKK_AFA',
              'PARTNER_TORZS',
              'PARTNER_CSOPORT',
              'RAKTAR_RAKTAR',
              'BIZONYLAT_TOMB',
              'BIZONYLAT_TIPUS',
              'APARTMAN_APARTMAN',
              'APARTMAN_EPULET',
              'APARTMAN_SZALLODA',
              'PENZUGYKOZOS_PENZNEM',
              'PENZUGYKOZOS_FIZMOD',
              'PENZUGYKOZOS_INFLACIO',
              'PENZUGYKOZOS_BANKTIPUS',
              'PENZUGYKOZOS_BANKSZAMLA',
              'BANK_PENZTAR',
              'KARTOTEK_VIZSGALO',
              'HELYSZIN_HELYSZIN',
              'HELYSZIN_TEREM',
              'JEGYTERMEK_SZEKTOR',
              'SZERVEZET_SZEMELYI',
              'ELOADAS_ELOADAS',
              'TELEPULES_ORSZAG',
              'TELEPULES_TELEPULES'
            ], true)) && (
            <li className={this.activeRoute('/torzsadatok')}>
              <a className="nav-link nav-dropdown-toggle nav-head" onClick={this.handleClick.bind(this)}>
                <i className="fa fa-folder-o" />
                {'Törzsadatok'}
              </a>
              <ul className="nav-dropdown-items">
                {this.hasRole(['CIKK_TORZS', 'CIKK_CSOPORT', 'CIKK_AFA'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/cikk" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-shopping-bag" />
                      {'Cikk'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['TELEPULES_ORSZAG', 'TELEPULES_TELEPULES'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/foldrajziegyseg" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="icon-location-pin" />
                      {'Földrajzi egységek'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['PARTNER_TORZS', 'PARTNER_CSOPORT'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/partnerek" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="icon-people" />
                      {'Partner'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['RAKTAR_RAKTAR'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/raktar" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-cubes" />
                      {'Raktár'}
                    </Link>
                  </li>
                )}
                {this.hasRole(['BIZONYLAT_TOMB', 'BIZONYLAT_TIPUS'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/bizonylatkezeles" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-edit" />
                      {'Bizonylatkezelés'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['APARTMAN_APARTMAN', 'APARTMAN_EPULET', 'APARTMAN_SZALLODA'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/apartman" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-university" />
                      {'Apartman'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['PENZUGYKOZOS_FIZMOD', 'PENZUGYKOZOS_PENZNEM', 'PENZUGYKOZOS_INFLACIO', 'PENZUGYKOZOS_BANKTIPUS', 'PENZUGYKOZOS_BANKSZAMLA', 'BANK_PENZTAR'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/penzugy" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="icon-wallet" />
                      {'Pénzügyi adatok'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['KARTOTEK_VIZSGALO'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/vizsgalo" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-user-o" />
                      {'Vizsgáló személy'}
                    </Link>
                  </li>
                )}

                {this.hasRole(['SZERVEZET_SZEMELYI'], true) && (
                  <li className="nav-item">
                    <Link to="/torzsadatok/szemelyitorzs" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-address-card-o" />
                      {'Személyi törzs'}
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            )}
            {(this.hasRole(['KESZLETNYILV_BEVETELEZES', 'KESZLETNYILV_CSOKKENES', 'KESZLETNYILV_SELEJTEZES', 'KESZLETNYILV_KESZLETINFO', 'KESZLETNYILV_FORGALOM',
              'KESZLETNYILV_LELTAR'/* , 'ROLE_RAKTAR_RAKTARLISTA_LEK' */], true))
            && (
            <li className={this.activeRoute('/keszlet')}>
              <a className="nav-link nav-dropdown-toggle nav-head" onClick={this.handleClick.bind(this)}>
                <i className="icon-drawer" />
                Készlet
              </a>
              <ul className="nav-dropdown-items">
                {/* {this.hasRoleUnique('ROLE_RAKTAR_RAKTARLISTA_LEK') &&
                  (
                    <li className="nav-item">
                      <Link
                        to="/keszlet/raktarlista"
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className="fa fa-list" />
                        Raktárkészlet lista
                      </Link>
                    </li>
                  )
                } */}

                {this.hasRole(['KESZLETNYILV_BEVETELEZES', 'KESZLETNYILV_CSOKKENES', 'KESZLETNYILV_SELEJTEZES', 'KESZLETNYILV_FORGALOM', 'KESZLETNYILV_LELTAR'], true)
                && (
                  <li className="nav-item">
                    <Link
                      to="/keszlet/keszletkezeles"
                      className="nav-link"
                      activeClassName="active"
                    >
                      &emsp;&emsp;Készletkezelés
                    </Link>
                  </li>
                )}

                {this.hasRole(['KESZLETNYILV_KESZLETINFO', true])
                && (
                  <li className="nav-item">
                    <Link
                      to="/keszlet/keszletinformacio"
                      className="nav-link"
                      activeClassName="active"
                    >
                      &emsp;&emsp;Készletinformáció
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            )}

            {(this.hasRole(['RENDELES_SZALLITOI', 'RENDELES_AJANLAT', 'RENDELES_VEVOI', 'RENDELES_EMAILSABLON'], true) || this.hasRoleUnique('ROLE_RENDELES_BEALLITAS'))
            && (
              <li className={this.activeRoute('/rendelesek')}>
                <a className="nav-link nav-dropdown-toggle nav-head" onClick={this.handleClick.bind(this)}>
                  <i className="fa fa-truck" />
                  {'Rendelések'}
                </a>
                <ul className="nav-dropdown-items">
                  {(this.hasRole(['RENDELES_SZALLITOI', 'RENDELES_AJANLAT', 'RENDELES_VEVOI'], true) || this.hasRoleUnique('ROLE_RENDELES_BEALLITAS')) && (
                    <li className="nav-item">
                      <Link to="/rendelesek/rendeles" className="nav-link" activeClassName="active">
                        <i className="icon-envelope-letter" />
                        {'Rendelés'}
                      </Link>
                    </li>
                  )}
                  {this.hasRole(['RENDELES_EMAILSABLON'], true) && (
                    <li className="nav-item">
                      <Link to="/rendelesek/email" className="nav-link" activeClassName="active">
                        <i className="fa fa-envelope-o" />
                        {'Email sablonok'}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {this.hasRole(['KARTOTEK_KARTON'], true) && (
              <li className="nav-item">
                <Link to="/kartotek" className="nav-link" activeClassName="active">
                  <i className="icon-layers" />
                  {'Kartotékrendszer'}
                </Link>
              </li>
            )}

            {this.hasRole(['MUNKALAP_OSSZ'], true) && (
              <li className="nav-item">
                <Link to="/munkalap" className="nav-link" activeClassName="active">
                  <i className="icon-docs" />
                  {'Munkalap'}
                </Link>
              </li>
            )}

            {this.hasRole(['KARBANTARTAS_KARBANTARTAS', 'KARBANTARTAS_ALKALOM', 'KARBANTARTAS_ELLENORZESILISTA'], true) && (
              <li className="nav-item">
                <Link to="/karbantartas" className="nav-link" activeClassName="active">
                  <i className="fa fa-wrench" />
                  {'Karbantartás'}
                </Link>
              </li>
            )}

            {this.hasRole(['FELADATLISTA_FELADAT', 'FELADATLISTA_FELADATTIPUS'], true) && (
              <li className="nav-item">
                <Link to="/feladatlista" className="nav-link" activeClassName="active">
                  <i className="fa fa-file-text-o" />
                  {'Feladatlista'}
                </Link>
              </li>
            )}

            {this.hasRole(['UDULESIJOGSZLA_SZAMLAKEZELES', 'UDULESIJOGSZLA_SZAMLAKULDES', 'UDULESIJOGSZLA_SZAMLAPROFIL', 'UDULESIJOGSZLA_FIZFELSZ'], true) && (
              <li className="nav-item">
                <Link to="/szamlazas" className="nav-link" activeClassName="active">
                  <i className="icon-credit-card" />
                  {'Számla kezelés'}
                </Link>
              </li>
            )}

            {this.hasRole(['SZERZODES_SZERZODES', 'UDULESIJOG_UDJOG'], true) && (
              <li className="nav-item">
                <Link to="/szerzodes" className="nav-link" activeClassName="active">
                  <i className="fa fa-handshake-o" />
                  {'Szerződés'}
                </Link>
              </li>
            )}

            {this.hasRole(['BANK_PUTELJ', 'BANK_PENZTAR'], true) && (
              <li className="nav-item">
                <Link to="/penzugyiteljesites" className="nav-link" activeClassName="active">
                  <i className="fa fa-usd" />
                  {'Pénzügyi teljesítés'}
                </Link>
              </li>
            )}

            {this.hasRole(['UDULESIJOG_RECEPCIO', 'UDULESIJOG_UDJOG'], true) && (
              <li className="nav-item">
                <Link to="/recepcio" className="nav-link" activeClassName="active">
                  <i className="fa fa-bell" />
                  {'Recepció'}
                </Link>
              </li>
            )}

            {this.hasRole(['GDPR_NAPLO'], true) && (
              <li className="nav-item">
                <Link to="/gdpr" className="nav-link" activeClassName="active">
                  <i className="fa fa-calendar" />
                  {'GDPR'}
                </Link>
              </li>
            )}

            {(this.hasRole(['CEGADAT_CEGADAT', 'CIKK_PROFIL', 'UDULESIJOGSZLA_FIZFELSZPROFIL', 'GDPR_PROFIL', 'GDPR_JOGALAP', 'UDULESIJOG_RCIPROFIL'], true) || this.hasRoleUnique('ROLE_CONFIG_USER_KARB')) && (
              <li className={this.activeRoute('/profil')}>
                <a className="nav-link nav-dropdown-toggle nav-head" onClick={this.handleClick.bind(this)}>
                  <i className="fa fa-cogs" />
                  Profil
                </a>
                <ul className="nav-dropdown-items">

                  {this.hasRole(['CIKK_PROFIL'], true) && (
                    <li className="nav-item">
                      <Link to="/profil/cikk" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-shopping-bag" />
                        Cikk
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['CEGADAT_CEGADAT'], true) && (
                    <li className="nav-item">
                      <Link to="/profil/cegadatok" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-building-o" />
                        Cégadatok
                      </Link>
                    </li>
                  )}

                  {this.hasRoleUnique('ROLE_CONFIG_USER_KARB') && (
                    <li className="nav-item">
                      <Link to="/profil/felhasznalok" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-user" />
                        Felhasználók
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['GDPR_PROFIL', 'GDPR_JOGALAP'], true) && (
                    <li className="nav-item">
                      <Link to="/profil/partnergdpr" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-lock" />
                        Partner GDPR
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['UDULESIJOGSZLA_FIZFELSZPROFIL'], true) && (
                    <li className="nav-item">
                      <Link to="/profil/felszolito" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-envelope-o" />
                        Fizetési felszólító
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['UDULESIJOG_RCIPROFIL'], true) && (
                    <li className="nav-item">
                      <Link to="/profil/rci" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-book" />
                        RCI
                      </Link>
                    </li>
                  )}

                  {/* <li className="nav-item">
                    <Link to="/profil/rciadatokatvetele" className="nav-link" activeClassName="active">
                      &emsp;
                      <i className="fa fa-share" />
                      RCI adatok átvétele
                    </Link>
                  </li> */}
                </ul>
              </li>
            )}

            {(this.hasRole(['UDULESIJOGSZLA_SZAMLAKEZELES', 'BANK_PUTELJ', 'UDULESIJOG_UDJOG'], true) || this.hasRoleUnique('ROLE_APARTMAN_APARTMAN_SZABAD')) && (
              <li className={this.activeRoute('/lista')}>
                <a className="nav-link nav-dropdown-toggle nav-head" onClick={this.handleClick.bind(this)} >
                  <i className="fa fa-list" />
                  Listák
                </a>
                <ul className="nav-dropdown-items">

                  {this.hasRoleUnique('ROLE_APARTMAN_APARTMAN_SZABAD') && (
                    <li className="nav-item">
                      <Link to="/lista/szabadapartman" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-university" />
                        Szabad apartman lekérdezés
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['UDULESIJOG_UDJOG'], true) && (
                    <li className="nav-item">
                      <Link to="/lista/kompenzacio" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-handshake-o" />
                        Kompenzáció
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['UDULESIJOGSZLA_SZAMLAKEZELES'], true) && (
                    <li className="nav-item">
                      <Link to="/lista/szamla" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="icon-credit-card" />
                        Számla
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['BANK_PUTELJ'], true) && (
                    <li className="nav-item">
                      <Link to="/lista/bankalaplista" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-bank" />
                        Bank alaplista
                      </Link>
                    </li>
                  )}

                  {this.hasRole(['UDULESIJOG_UDJOG'], true) && (
                    <li className="nav-item">
                      <Link to="/lista/udulesijoglista" className="nav-link" activeClassName="active">
                        &emsp;
                        <i className="fa fa-list-alt" />
                        Üdülésijog
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
